import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';

import { BehaviorSubject, debounceTime, Observable, Observer, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { Constants, TEXT } from '../../helpers';
import { Project, ProjectSearch, ProjectSearchItem } from '../../interfaces';
import { LocalStorageService } from '../../services/local-storage.service';
import { NavigationService } from '../../services/navigation.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { ButtonIcons } from '../icon-button/common/icon-button.constants';
import { IconButtonConfig } from '../icon-button/common/icon-button.interfaces';
import { SearchBarTextConst } from './common/search-bar.constants';

@Component({
	selector: 'paint-project-search-bar',
	templateUrl: './project-search-bar.component.html',
	styleUrls: ['./project-search-bar.component.scss'],
})
export class ProjectSearchBarComponent implements OnInit {
	public errorMessage: string;
	public iconConfig: IconButtonConfig;
	public isNavigateButtonActive: boolean = false;
	public isNavigateButtonHidden: Observable<Event>;
	public isNavigateButtonHide: boolean;
	public search: string = '';
	public searchPlaceholder: string = TEXT.quicksearch;
	public searchProject: ProjectSearch = {};
	public suggestionsObservable: Observable<Project[]>;
	public topResult: Project;

	private routeUrls: typeof SearchBarTextConst = SearchBarTextConst;

	constructor(
		public router: Router,
		private navigationService: NavigationService,
		private projectService: ProjectService,
		private route: ActivatedRoute,
		private userService: UserService,
		private localStorage: LocalStorageService
	) {
		this.isNavigateButtonHidden = this.router.events.pipe(
			filter((e: Event) => e instanceof NavigationEnd),
			tap((e: NavigationEnd) => {
				this.isNavigateButtonHide = e.url === this.routeUrls.projects;
			})
		);
	}

	public ngOnInit(): void {
		// This is here cause navigation end does not always fire, we set to Observable of true if route is /projcets
		if (this.router.url === this.routeUrls.projects) {
			this.isNavigateButtonHide = true;
		}
		if (this.localStorage.getUserId) {
			this.addProjectsToDropDown();
		}
		this.iconConfig = {
			icon: ButtonIcons.shareSquare,
		};
	}

	/**
	 * Navigates to a project.
	 * @param project
	 */
	public navigateToProject(project?: ProjectSearchItem): void {
		if (project && project._id) {
			this.navigationService.setRoute([`/project/${project._id}`]);
		} else {
			this.navigationService.setRoute([`/project/${this.topResult._id}`]);
		}
	}

	/**
	 * Generates the list of projects depending on user input
	 */
	private addProjectsToDropDown(): void {
		this.suggestionsObservable = new Observable((observer: Observer<string>) => {
			observer.next(this.search);
		}).pipe(
			debounceTime(300),
			switchMap((searchString: string) => {
				return this.projectService.getProjectList(searchString);
			}),
			tap((projects: Array<Project>) => {
				this.isNavigateButtonActive = !!(projects && projects.length);
				this.topResult = projects[0];
			}),
			catchError(() => {
				this.isNavigateButtonActive = false;
				return [];
			})
		);
	}
}
