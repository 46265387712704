import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { Permissions } from '../interfaces';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';
import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable()
export class RolePermissionsGuard {
	private permittedActions: Permissions;

	constructor(
		private authService: AuthService,
		private navigationService: NavigationService,
		private permissionService: RolePermissionsService
	) {
		this.permissionService.permittedActions.subscribe(permissions => {
			this.permittedActions = permissions;
		});
	}

	public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const page = route.data['page'];
		const action = route.data['action'];

		if (this.permittedActions[page][action]) {
			return of(true);
		}
		this.navigationService.setRoute(['']);
		return of(false);
	}
}
