import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class TierAccessService {
	public userCount: number;

	constructor(private localStorageService: LocalStorageService) {}

	public canCreateMoreUsers(): boolean {
		// this is just for the demo page.
		return this.getUserCountForEntity() < this.getUserLimitForTier();
	}

	public getUserLimitForTier(): number {
		const user = this.localStorageService.getUserDetails();
		return user.entity.userLimit;
	}

	public getUserCountForEntity(): number {
		const user = this.localStorageService.getUserDetails();
		this.userCount = user.entity.userCount;
		return this.userCount;
	}
}
