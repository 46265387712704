import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-general-layout',
	templateUrl: './general-layout.component.html',
	imports: [
		RouterOutlet,
	],
	standalone: true,
})
export class GeneralLayoutComponent {}