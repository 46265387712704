import { Component, Input, OnInit } from '@angular/core';

import { EmbeddedCoatProduct, Product } from '../../../../interfaces';
import { S3Service } from '../../../../services/s3.service';

@Component({
	selector: 'app-coating-system-layer-view',
	templateUrl: './coating-system-layer-view.component.html',
	styleUrls: ['./coating-system-layer-view.component.scss'],
})
export class CoatingSystemLayerViewComponent implements OnInit {
	@Input() public coatProducts: EmbeddedCoatProduct[];
	@Input() public imageSize: number;

	public imagesPromises: Promise<any>[] = [];
	public displayProducts: Product[][] = [];
	public productsToRender: Product[] = [];

	constructor(private s3Service: S3Service) {}

	public ngOnInit(): void {
		this.getProductImageUrls();
		this.setDisplayUrls();
	}

	/**
	 * Retrieve images for each product. New array needed since not every product will have an image.
	 */
	private getProductImageUrls(): void {
		for (const coatProduct of this.coatProducts) {
			if (coatProduct.product && (coatProduct.product.pictureKey || coatProduct.product.pictureUrl)) {
				this.productsToRender.push(coatProduct.product);

				if (!coatProduct.product.imageUrl) {
					if (coatProduct.product.pictureKey) {
						this.imagesPromises.push(this.setProductImageUrlPromise(coatProduct));
					} else {
						coatProduct.product.imageUrl = coatProduct.product.pictureUrl;
					}
				}
			} else {
				this.productsToRender.push(coatProduct.product);
			}
		}
	}

	/**
	 * Returns a promise to get the signed url from a key and assign it to coatProduct.product.imageUrl
	 */
	private async setProductImageUrlPromise(coatProduct: EmbeddedCoatProduct): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			this.s3Service.getSignedUrl(coatProduct.product.pictureKey).subscribe(url => {
				coatProduct.product.imageUrl = url;
				return resolve();
			}, reject);
		});
	}

	/**
	 * Arrange the products for display on the HTML.
	 */
	private setDisplayUrls(): void {
		// Place imageUrls in structured array.
		for (let i = 0; i < this.productsToRender.length; i++) {
			const currentProduct = this.productsToRender[i];

			// If not even, add it and the previous image in an array. If last image, add it in its own array.
			if (i % 2 !== 0) {
				this.displayProducts.push([this.productsToRender[i - 1], this.productsToRender[i]]);
			} else if (i === this.productsToRender.length - 1) {
				this.displayProducts.push([currentProduct]);
			}
		}
	}
}
