import * as moment from 'moment';
import { Attachment, Project } from '../../../../interfaces';
import { ProjectModel } from '../../../../models';
import { ClaimModel } from './claim.model';
import { VariationClaimModel } from './variation-claim.model';
import { PaymentModel } from './payment.model';

export class InvoiceModel {
	public claims: ClaimModel[] = [];
	public dateDue: Date;
	public dateSent: Date;
	public description: string;
	public generatedDocument: Attachment;
	public id: string;
	public invoiceNumber: number;
	public isPaid: boolean = false;
	public project: string | ProjectModel | Project;
	public variationClaims: VariationClaimModel[] = [];
	public isActive: boolean = false;
	public payments: PaymentModel[] = [];

	/**
	 * Finds the first variation claim with a description (or undefined if there are none) and returns only that
	 * description
	 */
	public get firstVariationDescription() {
		return this.variationClaims.find(variationClaim => {
			if (variationClaim.description) {
				return variationClaim;
			}
		})?.description;
	}
	public get totalAmount() {
		return this.claims.reduce((total, claim, variationClaim) => total + claim.amount, 0) + this.variationTotalAmount;
	}

	public get variationTotalAmount() {
		return this.variationClaims.reduce((total, variationClaim) => total + variationClaim.amount, 0);
	}

	public get isOverdue() {
		return !this.isPaid && moment().isAfter(moment(this.dateDue), 'days');
	}

	public get totalPayments() {
		return this.payments.reduce((total, payment) => total + payment.amount, 0);
	}

	public get client() {
		const wonClient = (this.project as Project).clientWonLost;
		const clients = (this.project as ProjectModel).clients;
		return clients.find(client => client.id === wonClient) || clients[0];
	}

	constructor(model?: Partial<InvoiceModel>) {
		if (model) {
			Object.assign(this, model);
			if (model.claims) {
				model.claims.map(claim => new ClaimModel(claim));
			}
		}
	}
}
