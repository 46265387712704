// Framework imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

// project specific imports
import { NavigationMenuItem } from '../../common/navigation-menu.interfaces';

@Component({
	selector: 'paint-navigation-menu-options',
	templateUrl: './navigation-menu-options.component.html',
	styleUrls: ['./navigation-menu-options.component.scss'],
})
export class NavigationMenuOptionsComponent {
	@Input()
	public dropdownItems: NavigationMenuItem[] = [];

	@Output()
	public itemClicked: EventEmitter<NavigationMenuItem> = new EventEmitter();

	constructor(private router: Router) {}

	// Checks if the option item is the current page
	public isCurrentPage(item): boolean {
		return this.router.url === item.route;
	}

	public onItemClicked(item): void {
		if (!item.isDisabled) {
			this.itemClicked.emit(item);
		}
	}
}
