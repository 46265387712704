import { AddressDetail, EntityEditRequest } from '../interfaces';
import { Feature } from '../helpers/feature';

export class EntityModel {
	public address: AddressDetail;
	// Array of brand ids
	public brands: string[];
	public canImportData: boolean;
	public canStartFreeTrial: boolean;
	public canUploadCustomDocuments: boolean;
	public email: string;
	public hasMeasuringToolAccess: boolean;
	public id: string;
	public isActive: boolean;
	public language: string;
	public name: string;
	public owner: any;
	public phone: string;
	public projectCount: number;
	public projectLimit: number;
	public states: string[];
	public stripeId: string;
	public suppliers: string[]; // Array of supplier ids
	public supportHours: number;
	public timezone: string;
	public userCount: number;
	public userLimit: number;
	public features: Record<Feature, boolean>;

	constructor(json?: any) {
		if (json) {
			Object.assign(this, json);
		}
	}

	/**
	 * Checks if the user's entity has reached is project limit
	 */
	public canCreateMoreProjects(): boolean {
		return this.projectCount < this.projectLimit;
	}

	/**
	 * Checks if the user's entity has reached is user limit
	 */
	public canCreateMoreUsers(): boolean {
		return this.userCount < this.userLimit;
	}

	/**
	 * Creates an update object with fields that entity/edit allows
	 * These fields are also verified on the backend
	 */
	public getUpdateEntityObject(): EntityEditRequest {
		return {
			address: this.address,
			email: this.email,
			name: this.name,
			id: this.id,
			language: this.language,
			phone: this.phone,
			states: this.states,
			timezone: this.timezone,
		};
	}
}
