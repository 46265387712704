import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { AddFollowupTaskDialogComponent, AddTaskDialogComponent } from '../../../../dialog/components';
import { Constants, SnackBarHelperComponent } from '../../../../helpers';
import { Email, NewTask, Project, Task } from '../../../../interfaces';
import { UserModel } from '../../../../models';
import { ModalService } from '../../../../services/modal.service';
import { TaskService } from '../../../../services/task.service';

@Component({
	selector: 'app-project-tasks',
	templateUrl: './project-tasks.component.html',
	styleUrls: ['./project-tasks.component.css'],
})
export class ProjectTasksComponent {
	public readonly taskTypes = Constants.TASK_TYPES;
	public tasks: Array<Task> = [];
	public totalPages: number = 0;
	public pageNo: number = 1;
	public currentTask: Task;
	public addFollowUpTaskData: { currentUser: UserModel; targetTask: Task };
	public saveCompletePromise: Promise<Task>;
	public refreshTasksPromise: Promise<Task[]>;
	private readonly dateFormats = Constants.DATE_FORMATS;
	public tabIconPath: string = Constants.SVG_ICON_PATHS.projectDescription;
	public selectedTab: boolean = false;

	@Input() project: Project;
	@Input() initialQuoteDueDate: Date;
	@Input() users: Array<UserModel>;
	@Input() isDashboard: boolean = false;
	@Input() selectedUser: UserModel;
	@Input() isEditDisabled: boolean;

	@Output() pushEmail: EventEmitter<Email> = new EventEmitter<Email>();
	@Output() updateInitialQuoteDueDate: EventEmitter<Date> = new EventEmitter<Date>();
	@Output('deselectTab') deselectTab: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('addFollowUpTaskDialog', { static: true })
	addFollowUpTaskDialogRef: ElementRef;
	@ViewChild(AddFollowupTaskDialogComponent, { static: false })
	addFollowUpTaskDialogComponent: AddFollowupTaskDialogComponent;
	@ViewChild('addTaskDialog', { static: true }) addTaskDialogRef: ElementRef;
	@ViewChild(AddTaskDialogComponent, { static: false })
	addTaskDialogComponent: AddTaskDialogComponent;
	@ViewChild('taskViewDialog', { static: true }) taskViewDialogRef: ElementRef;

	constructor(
		public snack: SnackBarHelperComponent,
		private taskService: TaskService,
		private modalService: ModalService
	) {}

	private refreshTasks(): void {
		if (this.project) {
			this.refreshTasksPromise = this.taskService
				.getProjectsTasks(this.project.id)
				.toPromise()
				.then(tasks => (this.tasks = tasks));
		}
	}

	onAddFollowUpTask(task: Task) {
		this.modalService.closeModal();
		this.addFollowUpTaskData = {
			currentUser: this.selectedUser,
			targetTask: task,
		};
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.task,
			content: this.addFollowUpTaskDialogRef,
			submitButton: {
				click: async () => {
					await this.addFollowUpTaskDialogComponent.onSaveFollowUpTask();
					this.refreshTasks();
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	public async saveCompleteTask(task: Task) {
		task.isComplete = !task.isComplete;
		task.completeDate = new Date();
		this.saveCompletePromise = this.taskService.toggleTaskComplete(task.id, task.project.id).toPromise();
		task.isComplete = (await this.saveCompletePromise).isComplete;
	}

	/**
	 * Shows the add task modal.
	 */
	showAddTaskDialog() {
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.task,
			content: this.addTaskDialogRef,
			submitButton: {
				click: async () => {
					this.addTaskDialogComponent.addTask(this.project.id, this.selectedUser).then(newTask => {
						this.tasks.push(newTask);
						this.modalService.closeModal();
					});
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	/**
	 * Shows task in a modal view.
	 */
	showTaskViewModal(task: Task) {
		this.currentTask = task;
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.task,
			content: this.taskViewDialogRef,
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	onSelectTab(tabEvent: TabDirective) {
		if (tabEvent.active) {
			this.selectedTab = true;
			this.refreshTasks();
		}
	}

	onDeselectTab() {
		this.selectedTab = false;
	}

	onTabClick() {
		if (this.selectedTab === true) {
			this.selectedTab = false;
			this.deselectTab.emit();
		}
	}

	/**
	 * Adds or edits a task for this projects quote due date
	 * @param project
	 * @param currentUser
	 */
	public async addOrEditQuoteDueDateTask(project, currentUser): Promise<void> {
		// Create or update "Quote due date" task.
		const quoteDueDateTask = await this.taskService.getQuoteDueDateTask(this.project.id).toPromise();

		if (quoteDueDateTask) {
			quoteDueDateTask.dueDate = project.quoteDueDate;
			await this.taskService.toggleTaskComplete(quoteDueDateTask.id, this.project.id).toPromise();
		} else {
			const newTask: NewTask = {
				action: Constants.TASK_ACTIONS.quoteDueDate,
				assignee: currentUser,
				dueDate: project.quoteDueDate,
				author: currentUser,
				project: project.id,
				isComplete: false,
				taskType: Constants.TASK_TYPES.quoteDueDate,
			};
			await this.taskService.createTask(newTask, project.id).toPromise();
		}

		this.refreshTasks();
	}
}
