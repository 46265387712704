/**
 * Created by Hadi Roudaki from In The Code on 21/6/18.
 */
import { PostOrder } from './post-order';
import { PostParams } from './post-params';

export interface Paginate {
	limit: number;
	skip: number;
	fields?: string;
	items?: PostOrder[];
	params?: PostParams;
	search?: object;
	stringSearch?: string;
}

export interface PaginateResponse<T> {
	paginatedObject: { items: T[]; total: number };
}
