import { AfterViewInit, Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { filter, finalize, Observable, Subscription } from 'rxjs';

import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ContentCardModel } from '../../../../@itc-core/components/components/content-card/common/content-card.models';
import { NavigationWarningService } from '../../../../@itc-core/components/navigation-warning/common/navigation-warning.service';
import { ItcModalConfig } from '../../../../@itc-core/directives/modal/common/modal.models';
import { ButtonIcons } from '../../../components/icon-button/common/icon-button.constants';
import { AddContactPersonDialogComponent, AddEditContactDialogComponent, MergeClientDialogComponent } from '../../../dialog/components';
import { AddContactTextConst } from '../../../dialog/components/contact-dialog/components/add-contact-person/common/add-contact-person.constants';
import { Constants, countriesArray, DateHelper, LOGO_UPLOADER_TITLES, PageJumpHelper, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { Company, Contact, GetAddContactValidation, GetCompanyProjectSearchValidation, GetCompanyValidation, GetTemplateValidation, Project, Region } from '../../../interfaces';
import { UserModel } from '../../../models';
import { ClientService, GetClientResolver, GetClientsResolver } from '../../../services/client.service';
import { CompanyService, GetCompaniesResolver, GetCompanyResolver } from '../../../services/company.service';
import { ExportService } from '../../../services/export.service';
import { ModalService } from '../../../services/modal.service';
import { NavigationService } from '../../../services/navigation.service';
import { ProductTourService } from '../../../services/product-tour.service';
import { ProjectService } from '../../../services/project.service';
import { RolePermissionsService } from '../../../services/role-permissions.service';
import { S3Service } from '../../../services/s3.service';
import { StateService } from '../../../services/state.service';
import { GetCurrentUserResolver, GetUsersResolver } from '../../../services/user.service';
import { getSteps as defaultSteps } from './common/company-add-edit.tour.config';
import { textConstants } from './common/company-add-edit.constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { User } from '@sentry/angular-ivy';

@Component({
	selector: 'app-company-add-edit',
	templateUrl: './company-add-edit.component.html',
	styleUrls: ['./company-add-edit.component.scss'],
})
export class CompanyAddEditComponent implements OnInit, AfterViewInit {
	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerCompanyResolvers = {
		company: GetCompanyResolver,
		clientsCompanyList: GetCompaniesResolver,
		currentUser: GetCurrentUserResolver,
		clients: GetClientsResolver,
	};

	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerClientResolvers = {
		client: GetClientResolver,
		clientsCompanyList: GetClientsResolver,
		currentUser: GetCurrentUserResolver,
	};

	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerAddCompanyResolvers = {
		clientsCompanyList: GetCompaniesResolver,
		currentUser: GetCurrentUserResolver,
	};

	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerAddClientResolvers = {
		clientsCompanyList: GetClientsResolver,
		estimators: GetUsersResolver,
		currentUser: GetCurrentUserResolver,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	public static routerCompanyData = {
		restrictToClient: false,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	public static routerClientData = {
		restrictToClient: true,
	};

	@ViewChild('inputFile', { static: true }) public attachmentInput: ElementRef;
	@ViewChild('mergeClientDialog', { static: true })
	public mergeClientDialog: ElementRef;
	@ViewChild('mergeClientDialog', { static: true })
	public mergeClientDialogRef: ElementRef;
	@ViewChild(MergeClientDialogComponent, { static: false })
	public mergeClientDialogComponent: MergeClientDialogComponent;
	@ViewChild('addEditContactDialog', { static: true })
	public addEditContactDialogRef: ElementRef;
	@ViewChild(AddEditContactDialogComponent, { static: false })
	public addEditContactDialogComponent: AddEditContactDialogComponent;
	@ViewChild('contactViewDialog', { static: true })
	public contactViewDialogRef: ElementRef;
	@ViewChild(AddContactPersonDialogComponent, { static: false })
	public addContactPersonDialogComponent: AddContactPersonDialogComponent;

	public addContactModalConfig: ItcModalConfig;

	public childClientsInputConfig: ContentCardModel;
	public childrenClients: Company[];
	public childrenClientsPage: Company[];
	public childrenCompaniesInputConfig: ContentCardModel;
	public clientCompanyProjects: Project[] = [];
	public clientDetailsInputConfig: ContentCardModel;
	public clientIndex: number;
	public clients: Company[];
	public clientsCompanyList: Company[];
	public companyClient: Company = new Company();
	public companyPictureFile: File | Blob;
	public companyProjectListInputConfig: ContentCardModel;
	public contactPersonsInputConfig: ContentCardModel;
	public contactValidators;
	public contactView: Contact;
	public countries: string[] = countriesArray;
	public countHoursAndRevenuePromise: Promise<{
		totalQuotedHours: number;
		totalRevenue: number;
		totalWonHours: number;
	}>;
	public countProjectPromise: Promise<number>;
	public currentUser: UserModel;
	public errorStatus: boolean = false;
	public estimators: UserModel[];
	public filteredClients: Company[];
	public filterProjectsFrom: string;
	public filterProjectsTo: string;
	public focus = { contact: false };
	public FORM_STATES = Constants.FORM_STATES;
	public formState: string;
	public formSubmitted: boolean = false;
	public headers: { [key: string]: string }[] = Constants.LIBRARY_TYPES.childrenClient.headers;
	public headerText: string = '';
	public imageUrl;
	public isContractsAndAbove: boolean = false;
	public isSuperAdmin: boolean = false;
	public isTeamLeadAndAbove: boolean = false;
	public isUserAndAbove: boolean = false;
	public isFocus = false;
	public isIE = false;
	public isParentDisable = false;
	public loadingPictureUrlPromise: Promise<string>;
	public locationInputConfig: ContentCardModel;
	public maxPage: number = 1;
	public mergeClientData;
	public navString = '';
	public newEstimator: UserModel = undefined;
	public pageNo: number = 1;
	public paginateProjectsSubscription: Subscription;
	public paginationLimit: number = Constants.PAGINATE_ITEMS_10PER_PAGE;
	public projectCount: number;
	public projectLink = '/' + Constants.ROUTE_LINKS.project + '/';
	public projectListValidation;
	public readonly PROJECT_STATUS = Constants.PROJECT_STATUS;
	public providerList: Company[];
	public readonly pictureUploadTitle = LOGO_UPLOADER_TITLES.picture;
	public regionItems: Region[];
	public restrictToClient = true;
	public sameAsAccount: boolean = false;
	public saveCompanyPromise: Promise<Company>;
	public selectedContact: Contact;
	public selectedContactPerson = '';
	public selectedProvider: Company;
	public showAddContactModal: boolean = false;
	public states = [];
	public statesSubscription: Subscription;
	public templateValidators;
	public textConstants = textConstants;
	public text: typeof AddContactTextConst = AddContactTextConst;
	public totalPages: number;
	public totalQuotedHours: number = 0;
	public totalRevenue: number = 0;
	public totalWonHours: number = 0;
	public validators;
	private destroyRef = inject(DestroyRef);
	public userId: string;
	private pageTopId: string = 'top';

	constructor(
		private activatedRoute: ActivatedRoute,
		private clientService: ClientService,
		private companyService: CompanyService,
		private projectService: ProjectService,
		private rolePermissions: RolePermissionsService,
		private exportService: ExportService,
		private router: Router,
		private s3Service: S3Service,
		private stateService: StateService,
		public snack: SnackBarHelperComponent,
		private modalService: ModalService,
		private navigationService: NavigationService,
		private navWarningService: NavigationWarningService,
		private rolesPermissions: RolePermissionsService,
		private shepherdService: ShepherdService,
		private productTourService: ProductTourService,
		private userService: LocalStorageService
	) {}

	public ngOnInit() {
		this.companyService.getActiveProviders().subscribe(companies => {
			this.providerList = companies;
			const index = this.providerList.findIndex(item => {
				return item.id === this.companyClient?.id;
			});
			if (index !== -1) {
				this.providerList.splice(index, 1);
			}
		});
		this.setPermissions();
		this.setInitialDateRange();
		this.getStates(this.companyClient?.country);

		this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

		this.projectListValidation = GetCompanyProjectSearchValidation();

		this.activatedRoute.data.subscribe(({ restrictToClient, clientsCompanyList, client, company, currentUser }) => {
			this.restrictToClient = restrictToClient;
			//TODO Files/Folder
			// this.templateTypes = resolverCodeTypes.template_type;

			if (this.restrictToClient) {
				this.formState = Constants.FORM_STATES.EDIT;
				this.clientsCompanyList = clientsCompanyList;
				this.companyClient = new Company(client);
				this.headerText = 'Client';
				this.navString = '/clients/';
			} else {
				this.formState = Constants.FORM_STATES.EDIT;
				if (company) {
					this.companyClient = new Company(company);
					this.getProjectsPage(1);
				}
				this.headerText = 'Company';
				this.navString = '/admin/companies/';
			}
			this.statesSubscription = this.stateService
				.postList({ isActive: true })
				.pipe(
					finalize(() => {
						this.statesSubscription.unsubscribe();
					})
				)
				.subscribe((states: any) => {
					this.states = states;
					if (this.companyClient?.state) {
						const currentState = this.states.filter(state => state.id === this.companyClient.state.id)[0];
						this.regionItems = currentState.regions;
					}
				});

			// we need the headerText to set the config
			this.setCardConfigs();

			this.currentUser = currentUser;

			this.getImageUrls();

			if (!this.companyClient) {
				this.formState = Constants.FORM_STATES.ADD;
				this.resetCompanyClient();
			}

			// Gets children and available Clients.
			this.childrenClients = [];
			this.filteredClients = [];
			clientsCompanyList.forEach(clientCompany => {
				if (this.companyClient?.id !== clientCompany.id && clientCompany.isActive) {
					this.filteredClients.push(clientCompany);
				}
				if (clientCompany.parentClient && clientCompany.parentClient.id === this.companyClient?.id) {
					clientCompany.state = clientCompany.state ? clientCompany.state.name : '-';
					this.childrenClients.push(clientCompany);
				}
			});
			this.totalPages = Math.ceil(this.childrenClients.length / this.paginationLimit);
			this.childrenClientsPage = this.childrenClients.slice(0, this.paginationLimit);
		});
		this.activatedRoute.queryParamMap.subscribe(params => {
			this.selectedContactPerson = params.get('contact');
		});

		this.validators = GetCompanyValidation();
		this.contactValidators = GetAddContactValidation();
		this.templateValidators = GetTemplateValidation();
		this.setDisableInputs();

		this.companyClient.isProvider = true;
		this.newEstimator = this.currentUser;
		this.searchProjects();
		this.userId = this.userService.getUserId();
	}

	public ngAfterViewInit(): void {
		this.navWarningService.setStatus(false);
		this.shepherdService.defaultStepOptions = {
			scrollTo: true,
			cancelIcon: {
				enabled: true,
			},
		};
		this.shepherdService.modal = true;
		this.productTourService.activeTourSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(tourId => {
			if (tourId === 'Companies' && !this.shepherdService.isActive) {
				this.productTourService.showSteps(defaultSteps(this.shepherdService), true);
			}
		});

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === 'popstate') {
					if (this.shepherdService.isActive) {
						this.shepherdService.cancel();
					}
				}
			});
	}

	public countryChanged(country: string): void {
		this.companyClient.state = null;
		this.states = [];
		this.getStates(country);
		this.updateRegionDropdownItems();
	}

	/**
	 * Get signed urls
	 */
	public getImageUrls() {
		this.imageUrl = undefined;
		if (this.companyClient) {
			// If displayPictureUrl is provided, set the image url
			if (this.companyClient.displayPictureUrl) {
				this.imageUrl = this.companyClient.displayPictureUrl;
			} else if (this.companyClient.displayPictureKey) {
				// If displayPictureKey is provided, set the image url after retrieving it
				this.loadingPictureUrlPromise = this.s3Service.getSignedUrl(this.companyClient.displayPictureKey).toPromise();
				this.loadingPictureUrlPromise.then(url => {
					this.imageUrl = url;
				});
			}
		}
	}

	public onClickSearchProjects() {
		const errors = ValidatorHelper.checkErrors(this.projectListValidation);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		this.searchProjects();
	}

	public searchProjects() {
		this.filterProjectsTo = new Date(this.filterProjectsTo).setHours(0, 0, 0, 0) ? this.filterProjectsTo : undefined;
		this.filterProjectsFrom = new Date(this.filterProjectsFrom).setHours(23, 59, 59, 999) ? this.filterProjectsFrom : undefined;

		const search = {
			createdTo: this.filterProjectsTo,
			createdFrom: this.filterProjectsFrom,
			company: this.companyClient.id,
		};

		if (search.company) {
			this.countProjectPromise = this.projectService.postCount(search).toPromise();
			this.countProjectPromise.then(count => {
				this.projectCount = count;
				this.maxPage = Math.ceil(this.projectCount / this.paginationLimit);
			});
			this.countHoursAndRevenuePromise = this.projectService.getProjectHoursAndRevenue(search).toPromise();
			this.countHoursAndRevenuePromise.then(result => {
				this.totalQuotedHours = result.totalQuotedHours;
				this.totalWonHours = result.totalWonHours;
				this.totalRevenue = result.totalRevenue;
			});
		}
		this.getProjectsPage(1);
	}

	/**
	 * API call to get a paginated list of projects for the company
	 * @param {number} pageSelected
	 * @param {boolean} isSearch
	 */
	public getProjectsPage(pageSelected: number) {
		this.pageNo = pageSelected;

		this.filterProjectsTo = new Date(this.filterProjectsTo).setHours(0, 0, 0, 0) ? this.filterProjectsTo : undefined;
		this.filterProjectsFrom = new Date(this.filterProjectsFrom).setHours(23, 59, 59, 999) ? this.filterProjectsFrom : undefined;

		const search = {
			createdTo: this.filterProjectsTo,
			createdFrom: this.filterProjectsFrom,
			company: this.companyClient.id,
		};
		const pagin = {
			search: search,
			skip: (this.pageNo - 1) * this.paginationLimit,
			limit: this.paginationLimit,
		};

		if (search.company) {
			this.paginateProjectsSubscription = this.projectService
				.postPaginate(pagin)
				.pipe(
					finalize(() => {
						this.paginateProjectsSubscription.unsubscribe();
					})
				)
				.subscribe((res: any) => {
					this.clientCompanyProjects = res.items;
				});
		}
	}

	/**
	 * Update the Company
	 */
	public async saveCompany() {
		this.formSubmitted = true;
		this.updatePageEditedAndValidation();
		this.saveCompanyPromise = new Promise(async (resolve, reject) => {
			const error = this.errorCheck(this.validators);
			if (error) {
				this.errorStatus = true;
				return reject(error);
			}
			this.navWarningService.setStatus(false);

			// Uploads the new pictures
			const uploadPicturePromises: Promise<void>[] = [this.uploadCompanyImage(this.companyPictureFile)];
			this.companyClient.contacts.forEach(async contact => {
				uploadPicturePromises.push(this.uploadContactImage(contact.imageFile, contact));
			});
			await Promise.all(uploadPicturePromises);

			let serviceMethodObservable: Observable<Company>;
			if (!this.companyClient.id) {
				serviceMethodObservable = this.restrictToClient ? this.clientService.addClient(this.companyClient) : this.companyService.addCompany(this.companyClient);
			} else {
				serviceMethodObservable = this.restrictToClient ? this.clientService.updateClient(this.companyClient) : this.companyService.updateCompany(this.companyClient);
			}
			serviceMethodObservable.subscribe(resolve, reject);
		});

		this.saveCompanyPromise
			.then(item => {
				this.companyClient = new Company(item);
				this.formState = Constants.FORM_STATES.EDIT;
				this.getImageUrls();
				const navRoute = this.restrictToClient ? '/clients' : '/admin/companies';
				this.navigationService.setRoute([navRoute]);
				PageJumpHelper.pageJump(this.companyClient.id);
			})
			.catch(() => {});
	}

	public cancelAddOrEditClient() {
		this.navigationService.setRoute([this.navString]);
		PageJumpHelper.pageJump(this.companyClient.id);
	}

	/**
	 * Sets up a blank company
	 */
	public resetCompanyClient() {
		if (this.restrictToClient) {
			this.companyClient = new Company({
				name: '',
				isActive: true,
				isClient: true,
				contacts: [],
				isProvider: false,
				isParent: false,
			});
		} else {
			this.companyClient = new Company({
				name: '',
				isActive: true,
				contacts: [],
				isProvider: false,
				isParent: false,
				providerChildren: [],
			});
		}
	}

	public errorCheck(validators) {
		const errors = ValidatorHelper.checkErrors(validators);
		if (errors) {
			this.snack.snackError(errors);
			return errors;
		}
		return null;
	}

	/**
	 *
	 * @param companyClient
	 */
	public selectCompanyClient(companyClient) {
		if (companyClient) {
			this.formState = Constants.FORM_STATES.EDIT;
			this.companyClient = new Company(companyClient);
			this.isFocus = false;
		} else {
			this.validators = GetCompanyValidation();
			this.contactValidators = GetAddContactValidation();
			this.templateValidators = GetTemplateValidation();
		}
	}

	public updatePageEditedAndValidation(): void {
		this.navWarningService.setStatus(true);
		if (this.formSubmitted) {
			const hasErrors = ValidatorHelper.checkErrors(this.validators) !== undefined && ValidatorHelper.checkErrors(this.validators) !== '';
			this.errorStatus = hasErrors;
		}
	}

	public updateRegionDropdownItems(): void {
		this.companyClient.region = undefined;
		if (this.companyClient.state && this.companyClient.state.regions.length) {
			this.regionItems = this.companyClient.state.regions;
		} else {
			this.regionItems = [{ id: 'empty', name: this.textConstants.emptyRegionsDropdown }];
		}
	}

	/**
	 * Uploads the company image and returns the aws key.
	 * @param {File | Blob} blob
	 */
	public uploadCompanyImage(blob: File | Blob): Promise<void> {
		if (blob === undefined) {
			return Promise.resolve();
		}

		return new Promise<void>(async resolve => {
			const path = `${this.navString}/${this.companyClient.id}/picture_${new Date().toISOString()}_${uuid()}`;
			this.companyClient.displayPictureKey = blob ? await this.s3Service.uploadFile(blob, path, 'image/png').toPromise() : null;
			this.companyClient.displayPictureUrl = null;

			return resolve();
		});
	}

	/**
	 * Uploads the contact image and returns the aws key.
	 * @param {File | Blob} blob
	 * @param {Contact} contact
	 */
	public uploadContactImage(blob: File | Blob, contact: Contact): Promise<void> {
		if (blob === undefined) {
			return Promise.resolve();
		}
		return new Promise<void>(async resolve => {
			const path = `${this.navString}/${this.companyClient.id}/contact/picture_${new Date().toISOString()}_${uuid()}`;
			contact.displayPictureKey = blob ? await this.s3Service.uploadFile(blob, path, blob.type).toPromise() : null;
			contact.displayPictureUrl = null;

			resolve();
		});
	}

	private getStates(country: string): void {
		const getStates = this.stateService
			.postList({ isActive: true, country: country })
			.pipe(finalize(() => getStates.unsubscribe()))
			.subscribe((states: any) => {
				this.states = states;
			});
	}

	/**
	 * Set initial date range to current financial year (1st July to 30th June).
	 */
	private setInitialDateRange(): void {
		const dateRange = DateHelper.getFinancialYearDateRange();
		this.filterProjectsFrom = dateRange.fromDate.format(Constants.DATE_FORMATS.displayDate);
		this.filterProjectsTo = dateRange.toDate.format(Constants.DATE_FORMATS.displayDate);
	}

	/**
	 * sets boolean values for isTeamLeadAnd above, and isContractsAndAbove depending on the current clients role.
	 * @private
	 */
	private setPermissions(): void {
		this.isSuperAdmin = this.rolesPermissions.isSuperAdmin();
		this.isTeamLeadAndAbove = this.rolesPermissions.isTeamLeaderAndAbove();
		this.isContractsAndAbove = this.rolesPermissions.isContractsAndAbove();
		this.isUserAndAbove = this.rolesPermissions.isUserAndAbove();
	}

	public openMergeClientDialog() {
		this.mergeClientData = {
			client: this.companyClient,
			clients: this.clientsCompanyList,
		};
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.mergeClient,
			content: this.mergeClientDialogRef,
			submitButton: {
				click: async () => {
					this.mergeClientDialogComponent
						.mergeClients()
						.then(newClient => {
							this.modalService.closeModal();
							this.navigationService.setRoute(['/client/', newClient.id]);
							PageJumpHelper.pageJump(this.pageTopId);
						})
						.catch(() => {});
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	/**
	 * Toggles active of the client.
	 */
	public editIsActive() {
		const subscription = this.companyService
			.postEditIsActive(this.companyClient.id, !this.companyClient.isActive)
			.pipe(finalize(() => subscription.unsubscribe()))
			.subscribe(companyClient => {
				this.companyClient = new Company(companyClient);
				this.getImageUrls();
			});
	}

	// Navigates on the client page when a children client is clicked.
	public onClientClick(client: Company) {
		this.navigationService.setRoute([`client/${client.id}`]);
		PageJumpHelper.pageJump(this.pageTopId);
	}

	public getNextPage(pageNo: number) {
		this.pageNo = pageNo;
		this.childrenClientsPage = this.childrenClients.slice((pageNo - 1) * this.paginationLimit, pageNo * this.paginationLimit);
	}

	public openAddContactFormDialog(contact?: Contact): void {
		this.addEditContactDialogComponent.resetFields();
		this.setAddContactModalConfig(!!contact);
		this.selectedContact = contact ? contact : undefined;
		this.showAddContactModal = true;
	}

	public onCloseFormDialog(): void {
		this.showAddContactModal = false;
		this.selectedContact = undefined;
		this.addEditContactDialogComponent.contactValidators = GetAddContactValidation();
	}

	public async saveContactPerson(): Promise<void> {
		const newContact = await this.addEditContactDialogComponent.saveContact();
		if (!newContact) {
			return;
		}
		if (this.selectedContact) {
			// Save contact
			const index = this.companyClient.contacts.indexOf(this.selectedContact);
			this.companyClient.contacts.splice(index, 1, newContact);
		} else {
			// Add Contact
			this.companyClient.contacts.push(newContact);
		}

		this.selectedContact = undefined;
		this.showAddContactModal = false;

		if (this.shepherdService.isActive) {
			this.shepherdService.next();
		}
	}

	/**
	 * Delete the contact that we selected form the list of contacts
	 */
	public deleteContact(contact: Contact) {
		if (!contact) {
			return;
		}
		const index = this.companyClient.contacts.indexOf(contact);
		this.companyClient.contacts.splice(index, 1);
	}

	public openContactViewDialog(contact: Contact) {
		this.contactView = contact;
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.contactPerson,
			content: this.contactViewDialogRef,
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	public onIsClient() {
		if (this.companyClient.isClient === true) {
			this.companyClient.isParent = false;
			this.isParentDisable = true;
		} else {
			this.isParentDisable = false;
		}

		this.updatePageEditedAndValidation();
	}

	/**
	 * Delete selected client
	 * @param {Number} index
	 */
	public deleteProvider(company: Company) {
		const index = this.companyClient.providerChildren.indexOf(company);
		this.companyClient.providerChildren.splice(index, 1);

		this.updatePageEditedAndValidation();
	}

	/**
	 * Add a new provider to list of clients of users
	 */
	public addProvider() {
		if (this.selectedProvider) {
			if (this.selectedProvider && this.companyClient.providerChildren.findIndex(provider => provider.id === this.selectedProvider.id) !== -1) {
				this.snack.snackError(Constants.VALIDATION_ERROR_UNIQUE_COMPANIES);
				return;
			}

			this.companyClient.providerChildren.push(this.selectedProvider);

			this.selectedProvider = undefined;
		}

		this.updatePageEditedAndValidation();
	}

	public setFile(file: File): void {
		this.companyPictureFile = file;
	}

	private setAddContactModalConfig(isEdit?: boolean): void {
		this.addContactModalConfig = new ItcModalConfig({
			confirmButtonText: isEdit ? this.textConstants.saveContact : this.textConstants.addContact,
			showCloseModalButton: true,
			showCancelButton: false,
			title: this.textConstants.addContactPerson,
		});
	}

	private setCardConfigs(): void {
		this.childClientsInputConfig = {
			titleText: this.textConstants.childrenClients,
		};
		this.clientDetailsInputConfig = {
			hasDivider: true,
			titleText: `${this.headerText} ${this.textConstants.details}`,
		};
		this.childrenCompaniesInputConfig = {
			hasDivider: true,
			titleText: this.textConstants.childrenCompanies,
		};
		this.companyProjectListInputConfig = {
			hasDivider: true,
			titleText: this.textConstants.projectList,
		};
		this.contactPersonsInputConfig = {
			hasDivider: true,
			titleText: this.textConstants.contactPerson,
			link: !this.isContractsAndAbove
				? undefined
				: {
						icon: ButtonIcons.user,
						text: textConstants.addContact,
					},
		};
		this.locationInputConfig = {
			hasDivider: true,
			titleText: this.textConstants.location,
		};
	}

	/**
	 * Disable all inputs that use Reactive Forms for current role depending on RolePermissions/Page
	 * (this component covers both pages: Company and Client with different permission rules for each)
	 * @private
	 */
	private setDisableInputs(): void {
		if ((!this.restrictToClient && !this.isSuperAdmin) || (this.restrictToClient && !this.isContractsAndAbove)) {
			ValidatorHelper.setFormControlsEnabled(this.validators, false, {});
			ValidatorHelper.setFormControlsEnabled(this.contactValidators, false, {});
			ValidatorHelper.setFormControlsEnabled(this.templateValidators, false, {});
		}
	}

	public onSameAsAccount() {
		if (this.sameAsAccount) {
			Object.assign(this.companyClient, {
				name: this.currentUser.entity.name,
				email: this.currentUser.entity.email,
				phone: this.currentUser.entity.phone,
				addressLine1: this.currentUser.entity.address.addressLine1,
				addressLine2: this.currentUser.entity.address.addressLine2,
				addressLine3: this.currentUser.entity.address.addressLine3,
				city: this.currentUser.entity.address.city,
				country: this.currentUser.entity.address.country,
				state: this.currentUser.entity.address.state,
				region: this.currentUser.entity.address.region,
				postalCode: this.currentUser.entity.address.postCode,
			});
		} else {
			Object.assign(this.companyClient, {
				name: '',
				email: '',
				phone: '',
				addressLine1: '',
				addressLine2: '',
				addressLine3: '',
				city: '',
				country: '',
				state: null,
				region: null,
				postalCode: '',
			});
		}
	}
}
