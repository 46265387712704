import { TEXT } from '../../../../helpers/text.constants';
import { SentenceCasePipe } from '../../../../pipes/sentence-case-pipe';

const sentenceCasePipe: SentenceCasePipe = new SentenceCasePipe();

export const CalculationTypes = {
	surfaceArea: 'surface area',
	wallsArea: 'walls area',
	linealMetre: 'lineal metre',
};

export const UserToolTypes = {
	measure: 'measure',
	item: 'item',
	annotation: 'annotation',
};

export const presetColours = [
	'rgb(255,255,255)',
	'rgb(255,0,0)',
	'rgb(255,150,0)',
	'rgb(255,255,0)',
	'rgb(50,255,50)',
	'rgb(50,140,50)',
	'rgb(0,170,255)',
	'rgb(0,40,255)',
	'rgb(90,0,255)',
	'rgb(255,0,255)',
	'rgb(90,0,0)',
	'rgb(0,0,0)',
];
export const presetColoursLabel: string = 'Preset Colours';

export const TextConstants = {
	afterHours: TEXT.afterHours,
	annotation: sentenceCasePipe.transform(TEXT.annotation),
	borderColour: sentenceCasePipe.transform(TEXT.borderColour),
	brandFilter: TEXT.brandFilter,
	calculationType: sentenceCasePipe.transform(TEXT.calculationType),
	chargeOuts: TEXT.chargeOuts,
	coatingSystem: TEXT.coatingSystem,
	colour: sentenceCasePipe.transform(TEXT.colour),
	defaultHeight: TEXT.defaultHeight,
	ebaRate: TEXT.ebaRate,
	environment: sentenceCasePipe.transform(TEXT.environment),
	fontSize: sentenceCasePipe.transform(TEXT.fontSize),
	itemSize: sentenceCasePipe.transform(TEXT.itemSize),
	fillColour: sentenceCasePipe.transform(TEXT.fillColour),
	fontColour: sentenceCasePipe.transform(TEXT.fontColour),
	height: sentenceCasePipe.transform(TEXT.height),
	highlight: sentenceCasePipe.transform(TEXT.highlight),
	itemCalculationType: sentenceCasePipe.transform(TEXT.itemCalculationType),
	lineColour: sentenceCasePipe.transform(TEXT.lineColour),
	lineDash: sentenceCasePipe.transform(TEXT.lineDash),
	lineStyle: sentenceCasePipe.transform(TEXT.lineStyle),
	lineType: sentenceCasePipe.transform(TEXT.lineType),
	lineWeight: sentenceCasePipe.transform(TEXT.lineWeight),
	lineWidth: sentenceCasePipe.transform(TEXT.lineWidth),
	lineWidthError: sentenceCasePipe.transform(TEXT.lineWidthError),
	location: sentenceCasePipe.transform(TEXT.location),
	name: sentenceCasePipe.transform(TEXT.name),
	perimeter: TEXT.perimeter,
	productFilter: TEXT.productFilter,
	profile: sentenceCasePipe.transform(TEXT.profile),
	ratePerUnit: TEXT.ratePerUnit,
	saveToUserTools: TEXT.saveToUserTools,
	select: sentenceCasePipe.transform(TEXT.select) + '...',
	setBoqDetails: sentenceCasePipe.transform(TEXT.setBoqDetails),
	strokeColour: sentenceCasePipe.transform(TEXT.strokeColour),
	subLocation1: sentenceCasePipe.transform(TEXT.subLocation1),
	subLocation2: sentenceCasePipe.transform(TEXT.subLocation2),
	subLocation3: sentenceCasePipe.transform(TEXT.subLocation3),
	substrateFilter: TEXT.substrateFilter,
	surfaceArea: TEXT.surfaceArea,
	toolName: sentenceCasePipe.transform(TEXT.toolName),
	tools: sentenceCasePipe.transform(TEXT.tools),
	toolType: sentenceCasePipe.transform(TEXT.toolType),
	typeHere: sentenceCasePipe.transform(TEXT.typeHere) + '...',
	userToolType: sentenceCasePipe.transform(TEXT.userToolType),
};
