import { Component, OnInit } from '@angular/core';

import { Constants, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { GetIncExcCommentTemplateValidation, UserIncExcCommentTemplate } from '../../../interfaces';
import { InclusionExclusionTemplateModel } from '../../../models';
import { IncExcCommentTemplateService } from '../../../services/inc-exc-comment-template.service';

@Component({
	selector: 'app-save-inc-exc-template-dialog',
	templateUrl: './save-inc-exc-template-dialog.component.html',
})
export class SaveIncExcTemplateDialogComponent implements OnInit {
	public templateTitle: string;
	public incExcTemplateValidators;
	public isFavourite: boolean;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public saveTemplatePromise: Promise<UserIncExcCommentTemplate>;

	constructor(
		private snack: SnackBarHelperComponent,
		private templateService: IncExcCommentTemplateService
	) {}

	ngOnInit() {
		this.incExcTemplateValidators = GetIncExcCommentTemplateValidation();
		this.isFavourite = false;
	}

	/**
	 * Saves the template and add to the user favourite if it is.
	 * @param {string} commentType
	 * @param {string} templateMessage
	 * @param {string} companyId
	 * @return {Promise<UserIncExcCommentTemplate>}
	 */
	saveTemplate(isInclusion: boolean, templateMessage: string, companyId: string): Promise<UserIncExcCommentTemplate> {
		this.incExcTemplateValidators.Message.setValue(templateMessage);
		this.incExcTemplateValidators.Type.setValue(isInclusion);
		const errors = ValidatorHelper.checkErrors(this.incExcTemplateValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		this.saveTemplatePromise = new Promise((resolve, reject) => {
			// Initialise template object
			const newTemplate = new InclusionExclusionTemplateModel({
				title: this.templateTitle,
				isActive: true,
				isInclusion: isInclusion,
				message: templateMessage,
				company: companyId,
			});

			// Create the new template
			this.templateService.addTemplate(newTemplate, this.isFavourite).subscribe(template => resolve({ template, isFavourite: this.isFavourite }), reject);
		});
		return this.saveTemplatePromise;
	}
}
