import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CountResponse, Paginate, PostParams, UserGroup, UserGroupResponse, UserGroupsResponse } from '../interfaces';

@Injectable()
export class UserGroupService {
	constructor(private http: HttpClient) {}

	// extends BaseService<UserGroupModel> {
	// constructor(private http: HttpClient) {
	//   super(
	//     http,
	//     Constants.END_POINTS.userGroup,
	//     UserGroupModel
	//   );
	// }

	/**
	 * Gets all userGroups
	 * @returns {Observable<Array<UserGroup>>}
	 */
	public postList(params?) {
		return this.http.post<UserGroupsResponse>(`${Constants.BASE_API_URL}/user-group/list`, params).pipe(map(res => res.userGroups));
	}

	/**
	 * Gets the user group matching the id
	 * @param {String} id
	 * @returns {Observable<UserGroup>}
	 */
	public postDetails(id: String) {
		return this.http
			.post<UserGroupResponse>(`${Constants.BASE_API_URL}/user-group/details`, {
				id: id,
			})
			.pipe(map(res => res.userGroup));
	}

	/**
	 * Adds a new user group
	 * @param {UserGroup} userGroup
	 * @returns {Observable<UserGroup>}
	 */
	public postCreate(userGroup: UserGroup) {
		return this.http
			.post<UserGroupResponse>(`${Constants.BASE_API_URL}/user-group/create`, {
				userGroup,
			})
			.pipe(map(res => res.userGroup));
	}

	/**
	 * Updates a given user group.
	 * @param userGroup
	 */
	public postEdit(userGroup: UserGroup) {
		return this.http
			.post<UserGroupResponse>(`${Constants.BASE_API_URL}/user-group/edit`, {
				userGroup,
			})
			.pipe(map(res => res.userGroup));
	}

	/**
	 * Toggles the active state of an existing new user group, using its id.
	 * @param {string} id
	 */
	public postEditIsActive(id: string, isActive: boolean) {
		return this.http.post<UserGroupResponse>(`${Constants.BASE_API_URL}/user-group/edit-active`, { id, isActive }).pipe(map(res => res.userGroup));
	}

	/**
	 * Gets User groups for pagination
	 * @returns {Observable<Array<UserGroup>>}
	 */
	public postPaginate(pagin: Paginate) {
		return this.http.post<UserGroupsResponse>(`${Constants.BASE_API_URL}/user-group/paginate`, pagin).pipe(map(res => res.userGroups));
	}

	/**
	 * Gets totalCount
	 * @returns {Observable<number>}
	 */
	public postCount(params: PostParams = {}) {
		return this.http.post<CountResponse>(`${Constants.BASE_API_URL}/user-group/count`, params).pipe(map(res => res.total));
	}
}
