import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { IncExcCommentTemplate, IncExcCommentTemplateResponse } from '../interfaces';
import { InclusionExclusionTemplateModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class IncExcCommentTemplateService extends BaseService<InclusionExclusionTemplateModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.incExcCommentTemplate, InclusionExclusionTemplateModel);
	}

	/**
	 * Adds a new template
	 * @param {IncExcCommentTemplate} template
	 * @param {boolean} isFavourite indicates if the template should be added to the user userTemplates
	 * @returns {Observable<IncExcCommentTemplate>}
	 */
	public addTemplate(template: InclusionExclusionTemplateModel, isFavourite: boolean = false) {
		return this.httpClient
			.post<IncExcCommentTemplateResponse>(`${Constants.BASE_API_URL}/inc-exc-comment-template/create`, {
				template: template.getPostObject(),
				isFavourite,
			})
			.pipe(map(res => new InclusionExclusionTemplateModel(res.item)));
	}

	/**
	 * Edits an existing template
	 * @param {IncExcCommentTemplate} template
	 * @returns {Observable<IncExcCommentTemplate>}
	 */
	public editTemplate(template: InclusionExclusionTemplateModel) {
		return this.httpClient
			.post<IncExcCommentTemplateResponse>(`${Constants.BASE_API_URL}/inc-exc-comment-template/edit`, {
				id: template.id,
				title: template.title,
				isActive: template.isActive,
				isInclusion: template.isInclusion,
				message: template.message,
				company: template.company,
				createdAt: template.createdAt,
				updatedAt: new Date().getTime(),
			})
			.pipe(map(res => res.item));
	}
}
