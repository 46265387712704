import { ColourModel } from './colour.model';
import { DistributionItemModel } from './distribution-item.model';
import { ProductModel } from './product.model';

export class DistributionSummaryModel {
	public colour: ColourModel;
	public distribution: DistributionItemModel[] = [];
	public product: ProductModel;

	constructor(json?: Partial<DistributionSummaryModel>) {
		if (json) {
			Object.assign(this, json);
			if (json.colour) {
				this.colour = new ColourModel(json.colour);
			}
			if (json.product) {
				this.product = new ProductModel(json.product);
			}
			if (json.distribution?.length) {
				this.distribution = json.distribution.map((distributionItem: DistributionItemModel) => {
					return new DistributionItemModel(distributionItem);
				});
			}
		}
	}
}
