import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	inject, Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
	NavigationMenuItem,
} from '../../../@itc-core/components/navigation-panels/navigation-menu/common/navigation-menu.interfaces';
import { PopoverComponent } from '../../../@mos-core/components/popover/popover.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { S3Service } from '../../services/s3.service';
import { ProjectModel, UserModel } from '../../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectService } from '../../services/project.service';
import { LibrarySort, Project } from '../../interfaces';
import { debounceTime, distinctUntilChanged, filter, Subject } from 'rxjs';
import { SORT_BY_FIELD } from '../../helpers';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../pages/alert/common/alert.service';
import { environment } from '../../../environments/environment';
import { SelectedProjectService } from '../../services/select-project.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { Feature } from '../../helpers/feature';
import { RolePermissionsService } from '../../services/role-permissions.service';

interface NavRoute {
	name: string;
	route: string;
	subRoutes?: NavRoute[];
	isExpanded?: boolean;
	icon?: string;
	disabled?: boolean;
	hidden?: boolean;
}

@Component({
	selector: 'paint-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
}) export class SidebarComponent implements OnInit {
	@Output() public navigationItemClicked: EventEmitter<NavigationMenuItem> = new EventEmitter();
	@ViewChild('userPopover') public userPopover!: PopoverComponent;
	@ViewChild('projectPopover') public projectPopover!: PopoverComponent;
	@ViewChild('mobileProjectPopover') public mobileProjectPopover!: PopoverComponent;

	@Input() public canCreateNewProject: any;

	public isProjectExpanded: boolean = false;
	public navRoutes:  NavRoute[] = []
	public projectRoutes: NavRoute[]  = []
	public userRoutes: NavRoute[]  = []
	public currentUser: UserModel;
	public userFullName: string;
	public initials: string;
	public avatarUrl: string | null = null;
	public projects: (ProjectModel | Project)[] = [];
	public filteredProjects: Project[] = [];
	public loadingProjects: boolean = false;
	public destroyRef = inject(DestroyRef)
	public selectedProject: Project;
	private searchSubject = new Subject<string>();
	public searchString: string = '';
	private pageNo: number = 1;
	private paginateItemsPerPage: number = 50;
	private hasMoreProjects: boolean = true;
	public isInitialLoad: boolean = true;
	public isCollapsed: boolean = false;
	public isHovered: boolean = false;
	public isEntityOwner: boolean = false;
	public totalAlerts: number = 0;
	public userRole: string;
	public isEntityActive: boolean = false;
	public isMobileMenuOpen: boolean = false;
	public isContractsAndAbove: boolean = false;
	public isNewProjectButtonDisabled: boolean = false;

	constructor(private router: Router,
				private localStorageService: LocalStorageService,
				private s3Service: S3Service,
				private cdr: ChangeDetectorRef,
				private projectService: ProjectService,
				private navigationService: NavigationService,
				private authService: AuthService,
				private alertService: AlertService,
				private selectedProjectService: SelectedProjectService,
				private featureFlagService: FeatureFlagService,
				public rolePermissions: RolePermissionsService
	) {}

	public ngOnInit(): void {
		// Initialise user, projects and routes
		this.initialiseUser();
		this.loadProjects();
		this.setupSearch();
		this.initialiseStandardRoutes();
		this.initialiseUserRoutes();

		// Collapse expanded routes on navigation
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd))
				.subscribe(() => {
				this.collapseExpandedRoutes();
			});

		// Get total alerts
		this.alertService.alerts.subscribe(total => {
			this.totalAlerts = total;
		});

		// Persist the project using local storage or set first project as selected
		this.selectedProjectService.getSelectedProject()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(project => {
				this.selectedProject = project;
				if (!this.selectedProject && this.projects?.length > 0) {
					this.setSelectedProject(this.projects[0]);
				}
				this.initialiseProjectRoutes();
			})
		// Persist sidebar state using local storage
		const sidebarState = localStorage.getItem('sidebarCollapsed');
		this.isCollapsed = sidebarState === 'true';
	}

	private initialiseUser() {
		this.currentUser = this.localStorageService.getUserDetails();
		this.userRole = this.localStorageService.getUserDetails().role;
		this.isEntityOwner = this.authService.isEntityOwner();
		this.isEntityActive = this.authService.isEntityActive();
		this.userFullName = this.currentUser.fullName;
		this.isContractsAndAbove = this.rolePermissions.isContractsAndAbove();
		this.isNewProjectButtonDisabled = !(this.rolePermissions.isTrainee() || this.rolePermissions.isUser() || this.rolePermissions.isTeamLeaderAndAbove()) || !this.canCreateNewProject;
		this.getPictureUrl();
	}

	private initialiseProjectRoutes() {
		this.projectRoutes = [
			{ name: 'Overview', route: `/project/${this.selectedProject?.id}/overview` },
			{ name: 'Charges', route: `/project/${this.selectedProject?.id}/` },
			{ name: 'Variations', route: `/project/${this.selectedProject?.id}/variations` },
			{ name: 'Measuring Tool', route: `/measuring-tool/${this.selectedProject?.id}` },
			{ name: 'Applications Rates', route: `/project/${this.selectedProject?.id}/application-rates` },
			{ name: 'Inclusions/Exclusions', route: `/project/${this.selectedProject?.id}/inclusions-exclusions` },
			{ name: 'Labour Costs', route: `/project/${this.selectedProject?.id}/labour-costs` },
			{ name: 'Discounts', route: `/project/${this.selectedProject?.id}/discounts` },
			{
				name: 'Export Documents',
				route: `/project/${this.selectedProject?.id}/export/tender-document`,
				subRoutes: [
					{ name: 'Tender Document', route: `/project/${this.selectedProject?.id}/export/tender-document` },
					{ name: 'Quote Proposal', route: `/project/${this.selectedProject?.id}/export/quote-proposal` },
					{ name: 'Office Folder', route: `/project/${this.selectedProject?.id}/export/office-folder` },
					{ name: 'Site Folder', route: `/project/${this.selectedProject?.id}/export/site-folder` },
					{ name: 'Safety Folder', route: `/project/${this.selectedProject?.id}/export/safety-folder` },
					{ name: 'QA Folder', route: `/project/${this.selectedProject?.id}/export/qa-folder` },
					{ name: 'Variation Quote', route: `/project/${this.selectedProject?.id}/export/variation-quote` },
					{ name: 'Quote', route: `/project/${this.selectedProject?.id}/export/quote` },
					{ name: 'Bill of Quantity', route: `/project/${this.selectedProject?.id}/export/boq-export` },
				]
			},
			{ name: 'Comments', route: `/project/${this.selectedProject?.id}/comment` },
			{ name: 'Attachments', route: `/project/${this.selectedProject?.id}/attachments` },
			{ name: 'Emails/Approvals', route: `/project/${this.selectedProject?.id}/emails` },
			{ name: 'Tasks', route: `/project/${this.selectedProject?.id}/tasks` },
			{ name: 'Time', route: `/project/${this.selectedProject?.id}/time` },
			{ name: 'Invoice', route: `/project/${this.selectedProject?.id}/invoice` },
			{ name: 'Product Purchasing', route: `/project/${this.selectedProject?.id}/product-purchasing` },
			{ name: 'Versions', route: `/project/${this.selectedProject?.id}/versions` },
		];
		this.cdr.detectChanges();
	}

	private initialiseStandardRoutes() {
		this.navRoutes = [
			{ name: 'Dashboard', route: '/', icon: 'home', disabled: false, hidden: false },
			{ name: 'Projects', route: '/projects', icon: 'folder-closed', disabled: false, hidden: false },
			{ name: 'Clients', route: '/clients', icon: 'users', disabled: false, hidden: false },
			{
				name: 'Reports',
				route: '/reports',
				subRoutes: [
					{ name: 'Targeting', route: '/reports/targeting' },
					{ name: 'Forecasting', route: '/reports/forecasting' },
					{ name: 'Sales', route: '/reports/sales' },
					{ name: 'Budgets', route: '/reports/budgets' },
				],
				icon: 'chart-column',
				disabled: false,
				hidden: false
			},
			{ name: 'Invoices', route: '/invoices', icon: 'file', disabled: false, hidden: !this.isContractsAndAbove },
			{
				name: 'Schedule',
				route: '/schedule',
				subRoutes: [
					{ name: 'My Shifts', route: '/shifts' },
					{ name: 'Project/Location', route: '/schedule/projects' },
					{ name: 'Resources', route: '/schedule/resources' },
					{ name: 'Schedule', route: '/schedule/schedule-view' },
				],
				isExpanded: false,
				icon: 'calendar',
				disabled: !this.featureFlagService.has(Feature.ScheduleTool),
				hidden: false
			},
			{
				name: 'Libraries',
				route: '/libraries',
				subRoutes: [
					{ name: 'Brands', route: '/libraries/brands' },
					{ name: 'Coating Systems', route: '/libraries/coating-systems' },
					{ name: 'Colours', route: '/libraries/colours' },
					{ name: 'Measure Tools', route: '/libraries/measureTools' },
					{ name: 'Competitors', route: '/libraries/competitors' },
					{
						name: 'Files/Folders',
						route: '/libraries/files-folders/tender-document',
						subRoutes: [
							{ name: 'Tender Document', route: '/libraries/files-folders/tender-document' },
							{ name: 'Quote Proposal', route: '/libraries/files-folders/quote-proposal' },
							{ name: 'Office Folder', route: '/libraries/files-folders/office-folder' },
							{ name: 'Site Folder', route: '/libraries/files-folders/site-folder' },
							{ name: 'Safety Folder', route: '/libraries/files-folders/safety-folder' },
							{ name: 'QA Folder', route: '/libraries/files-folders/qa-folder' },
							{ name: 'Variation Quote', route: '/libraries/files-folders/variation-quote' },
							{ name: 'Quote', route: '/libraries/files-folders/quote' },
							{ name: 'Bill of Quantity', route: '/libraries/files-folders/boq' },
							{ name: 'Product Purchasing', route: '/libraries/files-folders/product-purchasing' },
							{ name: 'Invoice', route: '/libraries/files-folders/invoice' },
						]
					},
					{ name: 'Inclusions/Exclusions', route: '/libraries/inclusions-exclusions' },
					{ name: 'Labour Rates', route: '/libraries/labour-rates' },
					{ name: 'Products', route: '/libraries/products' },
					{ name: 'Suppliers', route: '/libraries/suppliers' },
					{ name: 'Marketing Items', route: '/libraries/marketing-items' },
				],
				icon: 'table',
				disabled: false,
				hidden: !this.isContractsAndAbove
			},
		];
	}

	private initialiseUserRoutes() {
		this.userRoutes = [
			{ name: 'Alerts', route: '/alerts', icon: 'bell' },
			{ name: 'Profile', route: '/user', icon: 'user' },
			{ name: 'Settings', route: '/admin/account-details', icon: 'settings' },
			{ name: 'Help', route: 'https://intercom.help/paintprojex', icon: 'life-buoy' },
		];

		if (this.isEntityOwner) {
			this.userRoutes.push({ name: 'Subscription', route: '/subscription', icon: 'badge-dollar-sign' });
		}
	}

	public userMenuSelection(route: NavRoute, event: MouseEvent) {
		event.stopPropagation();
		if (route.route.startsWith('https')) {
			window.open(route.route, '_blank');
		} else {
			this.navigationService.setRoute([route.route]);
		}
		this.userPopover.close();
		this.toggleMobileMenu();
	}

	private setupSearch() {
		this.searchSubject.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			takeUntilDestroyed(this.destroyRef)
		).subscribe(term => {
			this.pageNo = 1;
			this.projects = [];
			this.hasMoreProjects = true;
			this.loadProjects(term);
		});
	}

	public onSearchInput(event: any) {
		const term = event.target.value;
		this.searchString = term;
		this.searchSubject.next(term);
	}

	private loadProjects(searchTerm: string = '') {
		if (!this.hasMoreProjects) return;

		this.loadingProjects = true;
		const skip = (this.pageNo - 1) * this.paginateItemsPerPage;
		const sortObj: LibrarySort = {
			field: SORT_BY_FIELD.createdAt.key,
			order: -1,
		};

		this.projectService.postPaginate(
			{
				search: { name: searchTerm },
				skip: skip,
				limit: this.paginateItemsPerPage,
			},
			sortObj
		).pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (res) => {
					this.projects = [...this.projects, ...res.items];
					this.filteredProjects = this.projects;
					this.hasMoreProjects = res.items.length === this.paginateItemsPerPage;
					this.loadingProjects = false;
					this.pageNo++;
					if (this.isInitialLoad && this.projects.length > 0) {
						this.setInitialSelectedProject();
					}
					this.cdr.detectChanges();
				},
				error: (error) => {
					console.error('Error loading projects:', error);
					this.loadingProjects = false;
				}
			});
	}

	private setInitialSelectedProject(): void {
		if (!this.selectedProject && this.projects.length > 0) {
			this.selectedProject = this.projects[0] as Project;
			this.isInitialLoad = false;
			this.initialiseProjectRoutes();
		}
	}

	public setSelectedProject(project: Project): void {
		this.selectedProject = project;
		this.selectedProjectService.setSelectedProject(project);
		this.initialiseProjectRoutes();
		this.projectPopover.close();
		this.mobileProjectPopover.close();

		const currentUrl = this.router.url;

		if (currentUrl.includes('project')) {
			const path = currentUrl.split('/').pop();

			this.router.navigate([`/project/${project.id}/${path}`]);
		} else {
			this.router.navigate([`/project/${project.id}/overview`]);
		}
	}

	public onScroll() {
		if (!this.loadingProjects) {
			this.loadProjects(this.searchString);
		}
	}

	public expandProjectRoutes(): void {
		this.isProjectExpanded = !this.isProjectExpanded;
	}

	public handleNavigation(route: NavRoute): void {
		if (route.disabled) {
			this.router.navigate(['/subscription']);
			return;
		}

		if (route.subRoutes && !route.disabled) {
			route.isExpanded = !route.isExpanded;
		} else {
			this.router.navigate([route.route]);
		}
		this.toggleMobileMenu();
	}

	public handleProjectNavigation(route: NavRoute): void {
		if (route.subRoutes) {
			this.toggleSubRoutes(route, new Event('click'));
		} else {
			this.router.navigate([route.route]);
		}
	}

	public toggleSubRoutes(route: NavRoute, event: Event): void {
		event.stopPropagation();
		if (!route.disabled) {
			route.isExpanded = !route.isExpanded;
		}
		this.cdr.detectChanges();
	}

	private collapseExpandedRoutes() {
		const currentUrl = this.router.url;
		this.navRoutes.forEach(route => {
			if (route.subRoutes) {
				if (!currentUrl.startsWith(route.route)) {
					route.isExpanded = false;
				}
			}
		});
		this.cdr.detectChanges();
	}

	public openUserPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.userPopover.open(event);
	}

	public openProjectPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.projectPopover.open(event);
	}

	public openMobileProjectPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.mobileProjectPopover.open(event);
	}

	public getPictureUrl() {
		if (this.currentUser && this.currentUser.displayPictureKey) {
			this.s3Service.getSignedUrl(this.currentUser.displayPictureKey).subscribe(url => {
				this.avatarUrl = url;
				this.cdr.detectChanges();
			})
		} else {
			this.getInitials();
		}
	}

	public getInitials(): string {
		if (!this.currentUser || !this.userFullName) {
			return '';
		}
		const [firstName, lastName] = this.currentUser.fullName.split(' ');
		this.initials = `${firstName[0]} ${lastName[0]}`;
	}

	public toggleSidebar(event: MouseEvent): void {
		event.stopPropagation()
		this.isCollapsed = !this.isCollapsed;
		localStorage.setItem('sidebarCollapsed', this.isCollapsed.toString());
		if (this.isCollapsed) {
			this.isHovered = false;
		}
		this.cdr.detectChanges();
	}

	public onMouseEnter(): void {
		if (this.isCollapsed) {
			this.isHovered = true;
			this.cdr.detectChanges();
		}
	}

	public onMouseLeave(): void {
		if (this.isCollapsed) {
			this.isHovered = false;
			this.cdr.detectChanges();
		}
	}

	public toggleMobileMenu() {
		this.isMobileMenuOpen = !this.isMobileMenuOpen;
	}

	public closeMobileMenu() {
		this.isMobileMenuOpen = false;
	}

	protected readonly environment = environment;
}