// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  width: 1300px;
}
.modal-container .column {
  min-width: 220px;
}
.modal-container .small-column {
  max-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/coating-system-edit-dialog/coating-system-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;AACC;EACC,gBAAA;AACF;AAEC;EACC,gBAAA;AAAF","sourcesContent":[".modal-container {\n\twidth: 1300px;\n\n\t.column {\n\t\tmin-width: 220px;\n\t}\n\n\t.small-column {\n\t\tmax-width: 120px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
