// Framework imports
import { CommonModule, NgComponentOutlet, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ItcBusyModule } from '../../../../@itc-core/directives/itc-busy/component/itc-busy.module';
import { InvoiceListTotalsCardModule } from '../../../pages/invoices/components/invoice-list-totals-card/common/invoice-list-totals-card.module';

import { SlideOverComponent } from '../slide-over.component';

@NgModule({
	declarations: [SlideOverComponent],
	exports: [SlideOverComponent],
	imports: [CommonModule, ModalModule, MatSlideToggleModule, ItcBusyModule, InvoiceListTotalsCardModule],
	providers: [],
})
export class SlideOverModule {}
