import { TEXT } from '../../../../helpers';
import { SentenceCasePipe } from '../../../../pipes/sentence-case-pipe';

const sentenceCasePipe = new SentenceCasePipe();

export const ChargesConstants = {
	activate: TEXT.activate,
	bulkEdit: TEXT.bulkEdit,
	calculationOverrideWarning1: sentenceCasePipe.transform(TEXT.calculationOverrideWarning1),
	calculationOverrideWarning2: sentenceCasePipe.transform(TEXT.calculationOverrideWarning2),
	cancelOrder: TEXT.cancelOrder,
	changeOrder: TEXT.changeOrder,
	charge: TEXT.charge,
	charges: TEXT.charges,
	clearFilters: TEXT.clearFilters,
	clone: TEXT.clone,
	cloneCharges: TEXT.cloneCharges,
	coatingSystem: TEXT.coatingSystem,
	collapseAll: TEXT.collapseAll,
	colour: TEXT.colour,
	confirm: TEXT.confirm,
	confirmCalculationOverride: TEXT.confirmCalculationOverride,
	cost: TEXT.cost,
	costCharge: `$${TEXT.cost}/${TEXT.charge}`,
	deactivate: TEXT.deactivate,
	deselectAll: TEXT.deselectAll,
	difficulty: TEXT.difficulty,
	discount: TEXT.discount,
	editCoatingSystem: TEXT.editCoatingSystem,
	editOrder: TEXT.editOrder,
	environment: TEXT.environment,
	expandAll: TEXT.expandAll,
	filter: TEXT.filter,
	gp: TEXT.gp,
	grandTotals: TEXT.grandTotals,
	hideInactive: TEXT.hideInactive,
	labourHours: TEXT.labourHours,
	location: TEXT.location,
	newCharge: TEXT.createNewCharge,
	none: TEXT.none,
	profit: TEXT.profit,
	rate: TEXT.rate,
	rateCharge: `$${TEXT.rate}/${TEXT.charge}`,
	saveComments: TEXT.saveComments,
	saveChanges: TEXT.saveChanges,
	saveOrder: TEXT.saveOrder,
	selectAll: TEXT.selectAll,
	selectPlaceholder: TEXT.selectPlaceholder,
	showAll: TEXT.showAll,
	showFilters: TEXT.showFilters,
	subLocation1: TEXT.subLocation1,
	subLocation2: TEXT.subLocation2,
	subLocation3: TEXT.subLocation3,
	substrate: TEXT.substrate,
	toggleSubgroups: TEXT.toggleSubgroups,
	totals: TEXT.totals,
	totalCost: TEXT.totalCost,
	units: TEXT.units,
	view: TEXT.view,
	writeAComment: TEXT.writeAComment,
};
