import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { BoqDimensionsModel, BoqModel } from '../models';
import { Colour } from './colour';
import { EmbeddedCoatingSystem } from './embedded-coating-system';
import { LabourRate } from './labourRate';
import { Product } from './product';
import { LabourCost, Project } from './project';
import { ReferenceCode } from './reference-code';

// Boq object
export interface Boq {
	id?: string;
	division: string;
	environment?: string;
	subLocation1?: string;
	subLocation2?: string;
	subLocation3?: string;
	substrate: ReferenceCode;
	heightRange: string;
	difficulty: number;
	colour: Colour;
	product: Product;
	dimensions: BoqDimensionsModel;
	coatingSystem: EmbeddedCoatingSystem;
	labourCost?: LabourRate | LabourCost;
	chargeOutAmounts: {
		chargeCategory: string;
		ratePerUnit: number;
		margin: number;
		comment: string;
	};
	isActive: boolean;
	isSelected: boolean;
	totalRate?: number;
	chargeType: string;

	discount: number;
	discountPercentage: number;
	isDiscountPercentage: boolean;
	isBasedOnMargin: boolean;
	areaDimensionsUnits: number;

	//Calculation
	labourCostPerUnit: number;
	labourCostTotal: number;
	labourHoursTotal: number;
	materialCostPerUnit: number;
	materialCostTotal: number;
	wasteCostPerUnit: number;
	wasteCostTotal: number;
	totalCostPerUnit: number;
	totalCost: number;
	totalProfit: number;
	grossTotalChargeOut: number;
	chargeOutLabourCostPerUnit: number;
	chargeOutLabourCostTotal: number;
	chargeOutMaterialCostPerUnit: number;
	chargeOutMaterialCostTotal: number;
	chargeOutHeightFeeTotal: number;
	totalCharge: number;
	project: string;
	distribution: [
		{
			product: string;
			totalRequiredLitre: number;
			totalLabourHour: number;
			distribution: {
				totalUnits: number;
				size: number;
				cost: number;
			};
			totalWasteCost: number;
		},
	];

	variation?: string;
}

//Response type given back when updating a Boq and Project
export interface BoqUpdateResponse {
	status: boolean;
	boq: BoqModel;
	project: Project;
}

//Object structure we get when we update Boq and Project
export interface BoqUpdateData {
	boq: BoqModel;
	project: Project;
}

//Response type given back when retrieving a Boq
export interface BoqResponse {
	status: boolean;
	boq: BoqModel;
}

// Response type given back when retrieving a list of Boq
export interface BoqsResponse {
	status: boolean;
	boqs: BoqModel[];
}

export function GetPaintInformationValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Product',
			validators: [Validators.required],
		},
	]);
}
