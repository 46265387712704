import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { ColourHelper, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../../../../../helpers';
import { GetColourValidation } from '../../../../../../../interfaces';
import { BrandModel, ColourModel } from '../../../../../../../models';
import { BoqService } from '../../../../../../../services/boq.service';
import { ColourService } from '../../../../../../../services/colour.service';
import { RefCodeService, RefCodeWithTypesResolver } from '../../../../../../../services/reference-code.service';

@Component({
	selector: 'app-add-colour-dialog',
	templateUrl: './add-colour-dialog.component.html',
	styleUrls: ['./add-colour-dialog.component.scss'],
})
export class AddColourDialogComponent implements OnInit {
	/**
	 * Router config for resolvers
	 * @type {}
	 */
	public static routerResolvers = {
		referenceCodeTypes: RefCodeWithTypesResolver,
	};

	@Input() data;
	public brand: BrandModel = undefined;
	public brands: BrandModel[];
	public colour: ColourModel = new ColourModel({
		name: undefined,
		brand: undefined,
		code: undefined,
		base: undefined,
		hexColour: '#000000',
		red: 0,
		green: 0,
		blue: 0,
		isActive: true,
	});
	public colours: ColourModel[];
	public textConstants: typeof TEXT = TEXT;
	public savingPromise: Promise<ColourModel>;
	public validators;

	private backgroundColor = 'rgb(0,0,0)';

	constructor(
		private boqService: BoqService,
		private colourService: ColourService,
		private refCodeService: RefCodeService,
		private changeDetectorRef: ChangeDetectorRef,
		public snack: SnackBarHelperComponent
	) {}

	public ngOnInit(): void {
		this.brands = this.data.brands;
		this.colours = this.data.colours;
		this.validators = GetColourValidation();
	}

	/**
	 * Saves the color to the brand
	 */
	public saveDialog(): Promise<ColourModel> {
		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return Promise.reject(errors);
		}
		//Set the brand before save because the selected brand may have changed
		this.colour.brand = this.brand.id;

		if (this.colour.red >= 0 && this.colour.red <= 255 && this.colour.green >= 0 && this.colour.green <= 255 && this.colour.blue >= 0 && this.colour.blue <= 255) {
			// Add the new colour
			this.savingPromise = this.colourService.postCreate(this.colour).toPromise();
			return this.savingPromise;
		}
	}

	/**
	 * Load Colours
	 */
	public selectBrand(): void {
		this.colour = new ColourModel({
			base: undefined,
			blue: 0,
			brand: undefined,
			code: undefined,
			hexColour: '#000000',
			green: 0,
			isActive: true,
			name: undefined,
			red: 0,
		});
	}

	/**
	 * Update the colour panel background colour
	 */
	public updateColourPanel(isHex): void {
		//RGB preview for Edit Colour form
		this.changeDetectorRef.detectChanges();
		//This catch is needed to stop console errors from popping
		//up when a user deletes the numbers in the input field
		if (isHex) {
			if (ColourHelper.hexToRgb(this.colour.hexColour)) {
				this.updateColorsForRgb(this.colour.hexColour);
			} else {
				this.colour.hexColour = undefined;
			}
		} else {
			this.colour.hexColour = this.rgbToHex(this.colour.red, this.colour.green, this.colour.blue);
		}
		this.backgroundColor = `rgb(${this.colour.red},${this.colour.green},${this.colour.blue})`;
	}

	/**
	 * Converts RGB numbers to Hex string
	 */
	private rgbToHex(r: number, g: number, b: number): string {
		return '#' + ColourHelper.colorNumberToHex(r) + ColourHelper.colorNumberToHex(g) + ColourHelper.colorNumberToHex(b);
	}

	private updateColorsForRgb(hexColour): void {
		this.colour.red = ColourHelper.hexToRgb(hexColour).r;
		this.colour.green = ColourHelper.hexToRgb(hexColour).g;
		this.colour.blue = ColourHelper.hexToRgb(hexColour).b;
	}
}
