import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { matAutocompleteDropdownLimit, RegexHelper, TEXT } from '../../../helpers';
import { ProjectRouteService } from '../../../pages/project/common/project.service';
import { ProjectService } from '../../../services/project.service';
import { DropdownData } from '../charges/common/charges.interfaces';

@Component({
	selector: 'paint-charge-sub-location-autocompletes',
	templateUrl: './charge-sub-location-autocompletes.component.html',
	styleUrls: ['./charge-sub-location-autocompletes.component.scss'],
})
export class ChargeSubLocationAutocompletesComponent implements OnInit {
	public textConstants: typeof TEXT = TEXT;

	@Input()
	public projectId: string;

	@Input()
	public showAsRequired: boolean = false;

	@Input()
	public subLocationNumber: number = 1;

	@Input()
	public placeholder: string = '';

	@Input()
	public showNumber: boolean = true;

	@Input()
	public subLocationLabel: string = this.textConstants.subLocation + ' ' + this.subLocationNumber;

	@Input()
	public set subLocationFormControl(subLocationFormControl: FormControl) {
		this._subLocationFormControl = subLocationFormControl;
		if (this._subLocationFormControl && this.dropdownData) {
			this.setSubLocationObservable();
		}
	}
	public get subLocationFormControl(): FormControl {
		return this._subLocationFormControl;
	}

	@Output()
	public subLocationsInputChanged: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	public subLocationsInputBlur: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	public subLocationsSelected: EventEmitter<void> = new EventEmitter<void>();

	public dropdownData: DropdownData;
	public projectDropdownLoader: Observable<DropdownData>;
	public subLocationFilteredOptions: Observable<string[]>;

	private _subLocationFormControl: FormControl;

	constructor(
		private projectRouteService: ProjectRouteService,
		private projectService: ProjectService
	) {}

	public ngOnInit(): void {
		// Retrieve subLocation dropdown data
		this.projectDropdownLoader = this.projectService.getProjectFilterDropdowns(this.projectId).pipe(
			tap((dropdownData: DropdownData) => {
				this.dropdownData = dropdownData;
				this.setSubLocationObservable();
			})
		);
	}

	// Clears out an input
	public clearInvalidInput(formControl: UntypedFormControl): void {
		formControl.setValue('');
	}

	public onSubLocationInputChanged(): void {
		this.subLocationsInputChanged.emit();
	}

	public onSubLocationInputBlur(): void {
		this.subLocationsInputBlur.emit();
	}

	public onSubLocationSelected(): void {
		this.subLocationsSelected.emit();
	}

	// Set the observable for updating the autocomplete options on sublocation
	private setSubLocationObservable(): void {
		this.subLocationFilteredOptions = this.subLocationFormControl.valueChanges.pipe(
			startWith(this.subLocationFormControl.value ?? ''),
			map((value: string) => {
				// Retrieve the data based on the given subLocationNumber
				let returnData = this.dropdownData['subLocations' + this.subLocationNumber] || [];
				// Don't filter if no search term
				if (value || value.length) {
					returnData = returnData.filter(subLocation => subLocation.match(new RegExp(RegexHelper.escape(value), 'gi')));
				}
				// Limiting the number of options to display removed (was matAutocompleteDropdownLimit)
				return returnData.slice(0);
			})
		);
	}
}
