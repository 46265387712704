import { Division } from '../../../interfaces';
import { State } from '../../../interfaces';
import { DateRange } from './project-filter.interfaces';

export class ProjectFilterModel {
	public defaultDates?: DateRange;
	public divisions: Division[] = [];
	public states: State[] = [];

	constructor(model?: Partial<ProjectFilterModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
