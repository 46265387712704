import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { AdditionalCharge, AdditionalChargeAttachmentsResponse, AdditionalChargeResponse, AdditionalChargeUpdateData, ItemsResponseData, ReferenceCode } from '../interfaces';
import { AdditionalChargeSupplierProductData } from '../pages/project/product-purchasing/components/create-purchase-order/components/purchase-order-form/components/purchase-order-supplier-product-table/common/purchase-order-supplier-product-table.interfaces';

@Injectable({
	providedIn: 'root',
})
export class AdditionalChargeService {
	constructor(private http: HttpClient) {}

	/**
	 * Adds a new additionalCharge
	 * @param {AdditionalCharge} additionalCharge
	 * @param {string} projectId
	 * @returns {Observable<any>}
	 */
	public addAdditionalCharge(additionalCharge: AdditionalCharge, projectId: string) {
		return this.http
			.post<AdditionalChargeUpdateData>(`${Constants.BASE_API_URL}/project/${projectId}/additionalcharge/`, {
				additionalCharge,
				projectId,
			})
			.pipe(
				map((result: AdditionalChargeUpdateData) => {
					return {
						additionalCharge: result.additionalCharge,
						project: result.project,
					};
				})
			);
	}

	/**
	 * @param {String} additionalChargeId
	 * @returns {Observable<any>}
	 */
	public getAdditionalCharge(additionalChargeId: string) {
		return this.http
			.post<AdditionalChargeResponse>(`${Constants.BASE_API_URL}/project/additionalcharge/${additionalChargeId}`, {
				id: additionalChargeId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	/**
	 * Updates an additional charge with a new one
	 * @param {string} additionalChargeId
	 * @param {AdditionalCharge} additionalCharge
	 * @param {string} projectId
	 * @returns {Observable<any>}
	 */
	public editAdditionalCharge(additionalChargeId: string, additionalCharge: AdditionalCharge, projectId: string) {
		return this.http
			.put<AdditionalChargeUpdateData>(`${Constants.BASE_API_URL}/project/additionalcharge/${additionalChargeId}`, {
				additionalCharge,
				projectId,
			})
			.pipe(
				map((result: AdditionalChargeUpdateData) => {
					return {
						additionalCharge: result.additionalCharge,
						project: result.project,
					};
				})
			);
	}

	/**
	 * Updates an additional charge with a new one
	 * @param id
	 * @param subLocation1
	 * @param environment
	 * @param projectId
	 */
	public editAdditionalChargeLocation(id: string, subLocation1: string, environment: string, projectId: string): Observable<AdditionalCharge> {
		return this.http
			.post<AdditionalChargeResponse>(`${Constants.BASE_API_URL}/project/additionalcharge/edit-location`, {
				id: id,
				subLocation1: subLocation1,
				environment: environment,
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	/***
	 * Toggles the active state of a Additional Charge
	 * @param projectId
	 * @param id
	 * @param activeStatus
	 */
	public postActiveToggle(id: string, activeStatus: boolean, projectId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/project/additionalcharge/toggle-activation`, {
				id: id,
				isActive: activeStatus,
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	public getAdditionalChargeAttachments(id: string, projectId: string) {
		return this.http
			.post<AdditionalChargeAttachmentsResponse>(`${Constants.BASE_API_URL}/project/additional-charge/attachments`, {
				id: id,
				projectId: projectId,
			})
			.pipe(map(res => res.attachments));
	}

	public listEmbeddedSupplierRates(projectId: string): Observable<AdditionalChargeSupplierProductData[]> {
		return this.http
			.post<ItemsResponseData<AdditionalChargeSupplierProductData>>(`${Constants.BASE_API_URL}/project/additional-charge/embedded-supplier-rates`, {
				projectId: projectId,
			})
			.pipe(map(res => res.items));
	}
}

@Injectable({
	providedIn: 'root',
})
export class GetAdditionalChargeResolver {
	constructor(private additionalChargeService: AdditionalChargeService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.additionalChargeService.getAdditionalCharge(route.params.additionalchargeId);
	}
}
