import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NavigationWarningComponent } from '../navigation-warning.component';
import { ButtonComponent } from '../../../../@mos-core/components/button/button.component';
import { DialogComponent } from '../../../../@mos-core/components/dialog/dialog.component';

@NgModule({
	declarations: [NavigationWarningComponent],
	exports: [NavigationWarningComponent],
	imports: [CommonModule, ButtonComponent, DialogComponent],
	providers: [],
})
export class NavigationWarningModule {}
