import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { State } from '../interfaces';
import { StateModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class StateService extends BaseService<StateModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.state, StateModel);
	}

	/**
	 * Renames a state
	 * @param {State} state
	 * @param {string} newName
	 * @param {string} abbreviation
	 * @param {string} country
	 * @returns {*}
	 */
	public postEditState(state: State, newName: string, abbreviation: string, country: string) {
		return this.httpClient
			.post(`${this.baseUrl}/${this.endPoint}/edit`, {
				stateId: state.id,
				name: newName,
				abbreviation: abbreviation,
				country: country,
			})
			.pipe(map((json: any) => new StateModel(json.item)));
	}

	public getStates(): Observable<StateModel[]> {
		return this.postList({ isActive: true });
	}

	public getAllStates(): Observable<StateModel[]> {
		return this.httpClient
			.post<any>(`${this.baseUrl}/${this.endPoint}/list-all`, {
				isActive: true,
			})
			.pipe(map(res => res.items.map(item => new StateModel(item))));
	}
}

@Injectable()
export class GetStatesResolver {
	constructor(private stateService: StateService) {}

	public resolve(): Observable<any> {
		return this.stateService.postList({ isActive: true });
	}
}
