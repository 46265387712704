import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {
	public scrollToElement(element: Element): void {
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
	}
}
