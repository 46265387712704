import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavigationWarningService {
	public isPageEdited: boolean = false;

	private modalResponseSubject: Subject<boolean> = new Subject();
	public modalResponse: Observable<boolean> = this.modalResponseSubject.asObservable();
	private showModalSubject: Subject<null> = new Subject();
	public showModal: Observable<null> = this.showModalSubject.asObservable();
	public saveMeasuringToolSubject: Subject<boolean> = new Subject();
	public triggerSave = this.saveMeasuringToolSubject.asObservable();
	public showSaveAndLiveButton$ = new BehaviorSubject(false);

	constructor() {}

	public setStatus(isEdited: boolean): void {
		this.isPageEdited = isEdited;
	}

	public setShowSaveAndLiveButton(show: boolean): void {
		this.showSaveAndLiveButton$.next(show);
	}

	public setEdited(): void {
		this.isPageEdited = true;
	}

	public setUnedited(): void {
		this.isPageEdited = false;
	}

	public showModalRequest(): Observable<boolean> {
		if (this.isPageEdited) {
			this.showModalSubject.next(null);
			return this.modalResponse;
		}
		return of(true);
	}

	public updateModalResponse(response: boolean): void {
		this.modalResponseSubject.next(response);
	}

	public saveMeasuringTool(response?: boolean): void {
		this.saveMeasuringToolSubject.next(response);
	}

}
