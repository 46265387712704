import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[itc-coloured-dot]',
	host: { style: 'border-radius: 50%' },
})
export class ItcColouredDotDirective {
	@Input()
	public colour: string;
	@Input()
	public borderColour: string = '#e0e0e0';

	@HostBinding('style.background-color')
	private get backgroundColor(): string {
		return this.colour ? this.colour : 'transparent';
	}

	@HostBinding('style.border')
	private get border(): string {
		return this.colour ? 'none' : `0.5px solid ${this.borderColour}`;
	}

	constructor() {}
}
