import { StripeSubscriptionAddons } from '../interfaces';
import { StripeProductPriceModel } from './stripe-product.models';

export enum SubscriptionStatus {
	active = 'active',
	canceled = 'canceled',
	incomplete = 'incomplete',
	incomplete_expired = 'incomplete_expired',
	past_due = 'past_due',
	trialing = 'trialing',
	unpaid = 'unpaid',
}

export class StripeSubscriptionModel {
	public additionalUsersCount: number;
	public cancelAtPeriodEnd: boolean;
	public currentPeriodEnd: Date;
	public endedAt?: Date;
	public isValidSubscription: boolean = false;
	public price?: StripeProductPriceModel;
	public status: SubscriptionStatus;
	public stripeCustomerId: string;
	public stripeId: string;
	public stripePriceId: string;
	public subscriptionAddons: StripeSubscriptionAddons[];
	public trialEnd: Date;

	constructor(json: Partial<StripeSubscriptionModel>) {
		Object.assign(this, json);

		if (this.price) {
			this.price = new StripeProductPriceModel(this.price);
		}

		for (const addonPrice of this.subscriptionAddons) {
			if (addonPrice.price) {
				addonPrice.price = new StripeProductPriceModel(addonPrice.price);
			}
		}

		this.isValidSubscription = this.status === SubscriptionStatus.active || this.status === SubscriptionStatus.trialing;
	}
}
