import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';

@Injectable()
export class ForgotPasswordService {
	constructor(private http: HttpClient) {}

	/**
	 * Sends a recovery email
	 * @returns {Observable<any>}
	 */
	sendForgotPasswordEmail(email) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/forgotpassword/`, { email: email }).pipe(map(res => res));
	}
}
