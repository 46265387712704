// Framework imports
import { Component, EventEmitter, Input, Output } from '@angular/core';

// 3rd party imports
// @itc-Core imports
import { OptionsInterface } from '../../../../@itc-core/components/split-button-dropdown/common/split-button-dropdown.interface';
// Project specific imports
import { PageFooterConfigModel } from './common/page-footer.models';

@Component({
	selector: 'paint-page-footer',
	templateUrl: './page-footer.component.html',
	styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent {
	@Input()
	public pageFooterConfig: PageFooterConfigModel;

	@Output()
	public extraButtonPressed: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	public splitButtonOptionSelected: EventEmitter<OptionsInterface> = new EventEmitter<OptionsInterface>();

	@Output()
	public splitButtonPressed: EventEmitter<null> = new EventEmitter<null>();

	constructor() {}

	/**
	 * Emit extra button identifier on click
	 * @param buttonIdentifier
	 */
	public onExtraButtonClick(buttonIdentifier: string): void {
		this.extraButtonPressed.emit(buttonIdentifier);
	}

	/**
	 * Emit option select
	 * @param option
	 */
	public onSplitButtonOptionSelected(option: OptionsInterface): void {
		this.splitButtonOptionSelected.emit(option);
	}

	/**
	 * Emit split button press
	 */
	public onSplitButtonPressed(): void {
		this.splitButtonPressed.emit();
	}
}
