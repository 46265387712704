// Framework imports
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { Text } from '../../../../@itc-core/common/text.constants';
import { whiteLabelConfig } from '../../../../config/config';
import { DashboardStrikeRateModel } from './common/dashboard-strike-rate.models';

@Component({
	selector: 'paint-dashboard-strike-rate',
	templateUrl: './dashboard-strike-rate.component.html',
	styleUrls: ['./dashboard-strike-rate.component.scss'],
})
export class DashboardStrikeRateComponent {
	@ViewChild('strikeRateNumberProgressBar', { static: true })
	private strikeRateNumberProgressBarElement: ElementRef;

	@ViewChild('strikeRateAmountProgressBar', { static: true })
	private strikeRateAmountProgressBarElement: ElementRef;

	@Input()
	public set inputConfig(config: DashboardStrikeRateModel) {
		this._inputConfig = config;
		// The progress bar will show the amount of won projects compared to total amount of projects submitted + won
		if (this._inputConfig.projectsSubmitted + this._inputConfig.projectsWon === 0) {
			this.strikeRateNumberProgressBarPercentage = 0;
			this.strikeRateAmountProgressBarPercentage = 0;
		} else {
			this.strikeRateNumberProgressBarPercentage = this._inputConfig.strikeRate;
			const sumRevenues = this._inputConfig.revenueWon + this._inputConfig.revenueLost + this._inputConfig.revenueSubmitted;
			this.strikeRateAmountProgressBarPercentage = (this._inputConfig.revenueWon / this._inputConfig.revenueSubmitted) * 100;
		}
	}
	public get inputConfig(): DashboardStrikeRateModel {
		return this._inputConfig;
	}

	public defaultUserImage: IconDefinition = faUser;
	public strikeRateNumberProgressBarPercentage: number;
	public strikeRateAmountProgressBarPercentage: number;
	public textConstants: typeof Text = Text;
	public whiteLabelConfig = whiteLabelConfig.dashboard.dashboardStrikeRate;

	private _inputConfig: DashboardStrikeRateModel;

	constructor() {}
}
