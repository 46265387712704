import { UserRoles } from './constants';

export const PermissionsActions = {
	access: 'access',
	addRemoveBrands: 'addRemoveBrands',
	addRemoveCoatingSystem: 'addRemoveCoatingSystem',
	addRemoveColour: 'addRemoveColour',
	addRemoveCompetitor: 'addRemoveCompetitor',
	addRemoveFiles: 'addRemoveFiles',
	addRemoveIncExc: 'addRemoveIncExc',
	addRemoveLabourRates: 'addRemoveLabourRates',
	addRemoveProduct: 'addRemoveProduct',
	addRemoveSuppliers: 'addRemoveSuppliers',
	addRemoveMarketingItems: 'addRemoveMarketingItems',
	addVariations: 'addVariations',
	approvals: 'approvals',
	assign: 'assign',
	assignTask: 'assignTask',
	create: 'create',
	createCharges: 'createCharges',
	createChargesRelated: 'createCharges',
	createRelated: 'createRelated',
	delete: 'delete',
	edit: 'edit',
	editBrands: 'editBrands',
	editCoatingSystem: 'editCoatingSystem',
	editColour: 'editColour',
	editCompanies: 'editCompanies',
	editIncExc: 'editIncExc',
	editLabourRates: 'editLabourRates',
	editProduct: 'editProduct',
	editRefCodes: 'editRefCodes',
	editRelated: 'editRelated',
	editRoles: 'editRoles',
	editStates: 'editStates',
	editSuppliers: 'editSuppliers',
	editUsers: 'editUsers',
	importData: 'importData',
	share: 'share',
	view: 'view',
	viewRelated: 'viewRelated',
};

export const PermissionsRoles = {
	superAdmin: UserRoles.superAdmin.key,
	admin: UserRoles.admin.key,
	teamLeader: UserRoles.teamLeader.key,
	contracts: UserRoles.contracts.key,
	user: UserRoles.user.key,
	operations: UserRoles.operations.key,
	field: UserRoles.field.key,
	trainee: UserRoles.trainee.key,
	viewer: UserRoles.viewer.key,
};

export const PermissionsPages = {
	client: 'client',
	contact: 'contact',
	export: 'export',
	library: 'library',
	project: 'project',
	settings: 'settings',
};
