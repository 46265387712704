import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable({
	providedIn: 'root',
})
export class RedirectEmployeeGuard {
	constructor(private permissionsService: RolePermissionsService, private router: Router) {}

	public canActivate() {
		if (this.permissionsService.isUserAndBelow()) {
			this.router.navigate(['/resource']);
			return false;
		}
		return true;
	}
}
