import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DatePipeService extends DatePipe {
	constructor(@Inject(LOCALE_ID) private appLocale: string) {
		super(appLocale);
	}
}
