export class ContactModel {
	public email: string;
	public firstName: string;
	public lastName: string;
	public phone: string;

	constructor(json?: Partial<ContactModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
