import { DistributionItemModel } from './distribution-item.model';
import { DistributionSummaryModel } from './distribution-summary.model';

export class DistributionSummaryRowItemModel extends DistributionSummaryModel {
	public amount: number;
	public currentOrderDistribution: DistributionItemModel[] = [];
	public fullDistribution: DistributionItemModel[] = [];

	constructor(json?: Partial<DistributionSummaryRowItemModel>) {
		super();
		if (json) {
			Object.assign(this, json);
		}
	}
}
