import { ButtonTextOptions, LayoutOptions, StyleOptions } from './split-button-dropdown.constants';
import { OptionsInterface } from './split-button-dropdown.interface';

export class InputButtonConfig {
	public buttonTextCase?: ButtonTextOptions = ButtonTextOptions.upperCase;
	public dropdownOptions?: OptionsInterface[] = [];
	public isDropUp?: boolean = false;
	public layout?: LayoutOptions = LayoutOptions.connected;
	public showLockButton?: boolean = false;
	public style?: StyleOptions = StyleOptions.primary;

	constructor(model?: Partial<InputButtonConfig>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
