import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { Region, RegionResponse, State } from '../interfaces';

@Injectable()
export class RegionService {
	private static readonly URL = `${Constants.BASE_API_URL}/region`;

	constructor(private http: HttpClient) {}

	/**
	 * Adds a region to a state
	 * @param {State} state
	 * @param {string} regionName
	 * @returns {*}
	 */
	addRegionToState(state: State, regionName: string) {
		return this.http
			.post<RegionResponse>(`${RegionService.URL}/add`, {
				stateId: state.id,
				name: regionName,
			})
			.pipe(map(res => res.region));
	}

	/**
	 * Renames a region
	 * @param {State} state
	 * @param {Region} region
	 * @param {string} newName
	 * @returns {*}
	 */
	renameRegion(state: State, region: Region, newName: string) {
		return this.http
			.post<RegionResponse>(`${RegionService.URL}/rename`, {
				stateId: state.id,
				regionId: region.id,
				name: newName,
			})
			.pipe(map(res => res.region));
	}

	/**
	 * Deletes a region
	 * @param {State} state
	 * @param {Region} region
	 * @returns {*}
	 */
	deleteRegion(state: State, region: Region) {
		return this.http.post<RegionResponse>(`${RegionService.URL}/delete`, {
			stateId: state.id,
			regionId: region.id,
		});
	}
}
