import { DestroyRef, EventEmitter, inject, Injectable, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ShepherdService } from 'angular-shepherd';
import { BehaviorSubject } from 'rxjs';
import Step from 'shepherd.js/src/types/step';
import { tap } from 'rxjs/operators';
import { UserModel } from '../models';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';
import { FeatureFlagService } from './feature-flag.service';
import { Feature } from '../helpers/feature';

@Injectable({
	providedIn: 'root',
})
export class ProductTourService {
	public activeTourSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	@Output() public tourCompleted = new EventEmitter<number>();
	public completedTourCount = new BehaviorSubject<number>(0);
	public user: UserModel;
	public userRole: string;
	public userId: string;
	public hasProjects: boolean;
	private destroyRef = inject(DestroyRef);

	public productTours = {
		superAdmin: [
			{ id: 'Account', name: 'Enter Your Account Details', time: '3 minutes' },
			{ id: 'Companies', name: 'Enter Your Company Details', time: '4 minutes' },
			{ id: 'Divisions', name: 'Create Your Divisions', time: '3 minutes' },
			{ id: 'Users', name: 'Create Your Users', time: '3 minutes' },
			{ id: "Clients", name: 'Add Your Clients', time: '4 minutes' },
			//{ id: 'Timesheets', name: 'Learn about your Timesheets', time: '3 minutes' },
			{ id: 'Help', name: "Check out 100's of helpful Articles in our Help Centre", time: 'Articles' },
		],
		scheduleTool: [
			//{ id: 'My Shifts', name: 'Check Your Shifts', time: '3 minutes' },
			{ id: 'Project/Location', name: 'Learn to Schedule Projects', time: '3 minutes' },
			{ id: 'Resources', name: 'Learn to Schedule Resources', time: '3 minutes' },
			{ id: 'Schedule', name: 'Learn to Shift Resources on Projects', time: '3 minutes' },
		]
	};

	constructor(
		private shepherdService: ShepherdService,
		private localStorageService: LocalStorageService,
		private userService: UserService,
		private features: FeatureFlagService,
	) {
		this.user = this.localStorageService.getUserDetails();
		if (this.user) {
			this.hasProjects = this.user.entity && this.user.entity.projectCount && this.user.entity.projectCount >= 1;
			this.userRole = this.user.role;
			this.userId = this.user.id;
			//Only show the schedule tool tours if the entity has at least 1 project.
			if (features.has(Feature.ScheduleTool) && this.hasProjects) {
				this.productTours.superAdmin = [...this.productTours.superAdmin, ...this.productTours.scheduleTool];
			}
		}

	}

	public getTours(userRole: string): any[] {
		return this.productTours[userRole] || [];
	}

	public getCurrentTour(): string | null {
		return this.activeTourSubject.value;
	}

	public next() {
		this.shepherdService.next();
	}

	public complete() {
		this.activeTourSubject.next(null);
		this.shepherdService.tourObject = null;
	}

	public setTour(tourId: string): void {
		this.activeTourSubject.next(tourId);
	}

	public showSteps(steps: Step.StepOptions[], finalPage: boolean): void {
		this.shepherdService.addSteps(steps);

		this.shepherdService.tourObject.once('cancel', () => {
			this.shepherdService.onTourFinish('cancel');
			this.complete();
		});

		this.shepherdService.tourObject.once('complete', () => {
			this.shepherdService.onTourFinish('complete');

			if (finalPage || this.getCurrentTour() === 'Help') {
				this.saveTourProgress(this.getCurrentTour());
				this.setUserTours();
			}
		});
		this.shepherdService.start();
	}

	public saveTourProgress(tourId: string): void {
		this.userService
			.updateUserTour(tourId, true)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap((user: UserModel) => {
					this.tourCompleted.emit();
				})
			)
			.subscribe();
		this.complete();
	}

	public setUserTours() {
		this.userService.getUserTours(this.userId).subscribe({
			next: response => {
				this.updateTourStatuses(response.tours);

				// we only care about completed tours that are present in the generated productTours array for this user
				// this is to stop negative tour remaining badges
				const availableTours = this.getTours(response.role);
				const hasAvailableTour = (id) => availableTours.findIndex((x) => x.id === id);
				const completedCount = Object.keys(response.tours).reduce((acc, id) => acc + (hasAvailableTour(id) ? 1 : 0), 0);

				this.completedTourCount.next(completedCount);
			},
		});
	}

	public updateTourStatuses(completedTours: { [key: string]: boolean }): void {
		Object.keys(this.productTours).forEach(role => {
			this.productTours[role].forEach(tour => {
				tour.completed = completedTours[tour.id];
			});
		});
	}
}
