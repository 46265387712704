import { NavigationStart, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(router: Router, service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {
	let userNavItem = 3;

	const navItems = {
		2: 'Settings',
		3: 'Help',
	};

	const navParams = {
		Account: '/admin/account-details',
		Users: '/admin/users',
		Companies: '/admin/companies',
		Divisions: '/admin/divisions',
		Help: 'https://intercom.help/paintprojex/en/',
	};

	if (productTourService.getCurrentTour() !== 'Help') {
		userNavItem = 2;
	}

	const selector = `.desktop .user-menu-popover-${userNavItem}`;

	function handleAvatarClick() {
		service.next();
	}

	function handler() {
		const navItem = document.querySelector(selector);
		if (navItem) {
			navItem.removeEventListener('click', handler);
			navItem.removeEventListener('click', handleCompleteTour);
		}
		service.next();
	}

	function handleCompleteTour() {
		const avatarElement = document.querySelector('.desktop .user-menu');
		if (avatarElement) {
			avatarElement.removeEventListener('click', handleCompleteTour);
		}
		const navItem = document.querySelector(selector);
		if (navItem) {
			navItem.removeEventListener('click', handler);
			navItem.removeEventListener('click', handleCompleteTour);
		}
		service.complete();
	}

	return [
		{
			id: 'step1',
			title: 'Open the User Menu',
			text: ['Open the User Menu by clicking on your avatar'],
			attachTo: {
				element: '.desktop .user-menu',
				on: 'top',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 2,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			when: {
				show: () => {
					const avatarElement = document.querySelector('.desktop .user-menu');
					if (avatarElement) {
						avatarElement.addEventListener('click', handleAvatarClick);
					}
				},
				hide: () => {
					const avatarElement = document.querySelector('.desktop .user-menu');
					if (avatarElement) {
						avatarElement.removeEventListener('click', handleAvatarClick);
					}
				},
				cancel: () => {
					const avatarElement = document.querySelector('.desktop .user-menu');
					if (avatarElement) {
						avatarElement.removeEventListener('click', handleAvatarClick);
					}
				},
			},
		},
		{
			id: 'step2',
			title: `Navigate to ${navItems[userNavItem]}`,
			text: [`Click on ${navItems[userNavItem]}`],
			attachTo: {
				element: selector,
				on: 'right',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 250);
				});
			},
			buttons: [
				{
					text: 'Next',
					action: function() {
						if (productTourService.getCurrentTour() === 'Help') {
							window.open(navParams[productTourService.getCurrentTour()], '_blank');
							handleCompleteTour();
						} else {
							router.navigateByUrl(`${navParams[productTourService.getCurrentTour()]}`);
							handler();
						}
					},
				},
			],
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 2,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			when: {
				show: () => {
					const navItem = document.querySelector(selector);
					if (navItem && productTourService.getCurrentTour() !== 'Help') {
						navItem.addEventListener('click', handler);
					} else {
						navItem.addEventListener('click', handleCompleteTour);
					}
				},
				hide: () => {
					const avatarElement = document.querySelector('.desktop .user-menu');
					if (avatarElement) {
						avatarElement.removeEventListener('click', handleAvatarClick);
					}
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
						navItem.removeEventListener('click', handleCompleteTour);
					}
				},
				cancel: () => {
					const avatarElement = document.querySelector('.desktop .user-menu');
					if (avatarElement) {
						avatarElement.removeEventListener('click', handleAvatarClick);
					}
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
						navItem.removeEventListener('click', handleCompleteTour);
					}
				},
			},
		},
	];
}
