/**
 * Created by Tom Broadwood from In The Code on 1/3/18.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CommentResponse, CommentsResponse, CommentUpdate, CompaniesResponse, Company, CompanyResponse, Contact, Paginate, PaginateResponse, ReferenceCode } from '../interfaces';
import { CompanyModel } from '../models';

@Injectable()
export class CompanyService {
	//keep list of companies in the cache observable.
	public companiesObservable: Observable<any> = null;

	constructor(private http: HttpClient) {}

	/**
	 * Gets all companies
	 * @returns {Observable<CompanyModel[]>}
	 */
	public getCompanies(): Observable<CompanyModel[]> {
		this.companiesObservable = this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/company/list`, {
				params: {
					isActive: true,
					isProvider: true,
					isParent: true,
				},
			})
			.pipe(
				map(res => res.companies.map(item => new Company(item))),
				shareReplay(1)
			);
		return this.companiesObservable;
	}

	/**
	 * Gets companies for pagination
	 * @returns {Observable<any>}
	 */
	public paginateCompany(pagin: Paginate) {
		return this.http.post<PaginateResponse<Company>>(`${Constants.BASE_API_URL}/company/companypagin`, pagin).pipe(
			map(res => res.paginatedObject),
			shareReplay(1)
		);
	}

	/**
	 * Gets all active providers
	 * @returns {Observable<any>}
	 */
	public getActiveProviders() {
		return this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/company/list`, {
				params: {
					isActive: true,
					isProvider: true,
					isClient: undefined,
					isParent: false,
				},
			})
			.pipe(map(res => res.companies.map(item => new Company(item))));
	}

	/**
	 * Get a company for the given id
	 * @param {string} id
	 * @returns {Observable<any>}
	 */
	public getCompany(id: string) {
		return this.http.get<CompanyResponse>(`${Constants.BASE_API_URL}/company/${id}`).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Adds a new company
	 * @param {Company} company
	 * @returns {Observable<any>}
	 */
	public addCompany(company: Company) {
		return this.http.post<CompanyResponse>(`${Constants.BASE_API_URL}/company/`, company).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Adds a new contact
	 * @param {string} companyId
	 * @param {Contact} contact
	 * @returns {Observable<Company>}
	 */
	public addContact(companyId: string, contact: Contact): Observable<Company> {
		// this will be refactored in future ticket, where we need to address issues with company models
		const contactPostObject: any = Object.assign({}, contact);
		if (contactPostObject.state) {
			contactPostObject.state = contact.state.id;
		}
		if (contactPostObject.estimators?.length) {
			contactPostObject.estimators = contactPostObject.estimators.map(estimator => estimator.id);
		}
		return this.http
			.post<CompanyResponse>(`${Constants.BASE_API_URL}/company/${companyId}/add-contact`, { companyId, contactPostObject })
			.pipe(map(res => new Company(res.company)));
	}

	/**
	 * Update a company
	 * @param {Company} company
	 * @returns {Observable<Company>}
	 */
	public updateCompany(company: Company) {
		return this.http.put<CompanyResponse>(`${Constants.BASE_API_URL}/company/${company.id}`, company).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Toggles the active state of an existing new companyClient, using its id.
	 * @param {string} id
	 * @param {boolean} isActive
	 */
	public postEditIsActive(id: string, isActive: boolean) {
		return this.http
			.post<CompanyResponse>(`${Constants.BASE_API_URL}/company/edit-active`, {
				id,
				isActive,
			})
			.pipe(map(res => new Company(res.company)));
	}

	/**
	 * Gets a list of comments for the provided company
	 * @param companyId
	 */
	public postList(companyId) {
		return this.http.post<CommentsResponse>(`${Constants.BASE_API_URL}/company/comment/list`, { companyId }).pipe(map(res => res.comments));
	}

	/**
	 * Adds a new comment
	 * @param comment
	 * @param companyId
	 */
	public addComment(comment, companyId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/company/comment/create`, { comment, companyId }).pipe(map(res => res.comment));
	}

	/**
	 * Updates comment
	 * @param commentUpdate: CommentUpdate
	 * @param companyId: string
	 */
	public editComment(commentUpdate: CommentUpdate, companyId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/company/comment/update`, { commentUpdate, companyId }).pipe(map(res => res.comment));
	}

	/**
	 * Delete a comment
	 * @param commentId
	 * @param companyId
	 */
	public deleteComment(commentId, companyId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/company/comment/delete`, { commentId, companyId }).pipe(map(res => res.comment));
	}
}

@Injectable()
export class GetCompanyResolver {
	constructor(private companyService: CompanyService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.companyService.getCompany(route.params.id);
	}
}

@Injectable()
export class GetCompaniesResolver {
	constructor(private companyService: CompanyService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.companyService.getCompanies();
	}
}

@Injectable()
export class GetActiveProvidersResolver {
	constructor(private companyService: CompanyService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.companyService.getActiveProviders();
	}
}
