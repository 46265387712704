// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
  position: relative;
  transition: width var(--sidebar-transition-time) ease-in-out;
  z-index: 50;
}
.sidebar-container.collapsed {
  width: var(--sidebar-width-collapsed);
}
.sidebar-container.expanded {
  width: var(--sidebar-width-expanded);
}

.icon-wrapper {
  position: absolute;
  top: 15px;
  right: 0;
  transform: translateX(calc(100% - 10px));
  transition: transform var(--sidebar-transition-time) ease-in-out;
  z-index: 60;
}

.sidebar-content {
  position: relative;
  z-index: 55;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,4DAAA;EACA,WAAA;AACF;AACE;EACE,qCAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,wCAAA;EACA,gEAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;AADF","sourcesContent":[".sidebar-container {\n  position: relative;\n  transition: width var(--sidebar-transition-time) ease-in-out;\n  z-index: 50;\n\n  &.collapsed {\n    width: var(--sidebar-width-collapsed);\n  }\n\n  &.expanded {\n    width: var(--sidebar-width-expanded);\n  }\n}\n\n.icon-wrapper {\n  position: absolute;\n  top: 15px;\n  right: 0;\n  transform: translateX(calc(100% - 10px));\n  transition: transform var(--sidebar-transition-time) ease-in-out;\n  z-index: 60;\n}\n\n.sidebar-content {\n  position: relative;\n  z-index: 55;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
