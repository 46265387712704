import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sentenceCase',
})

// When possible please use the text-sentence-case class instead of this pipe as it's more efficient
export class SentenceCasePipe implements PipeTransform {
	public transform(value: string): string {
		if (!value) {
			return value;
		}
		return value[0].toUpperCase() + value.substr(1).toLowerCase();
	}
}
