import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ProjectsBrandsData, ProjectsStatusData, SuccessRatesData, Top5ClientsData, UsersResponse, WonAndSubmittedProjectProfitByMonthData } from '../interfaces';
import { SchedulingToolDataItem } from '../pages/dashboard/common/dashboard.interface';

@Injectable()
export class DashboardService {
	constructor(private http: HttpClient) {}

	/**
	 * Returns data to populate the success rate component
	 * @returns {Observable<*>}
	 */
	public getSuccessRates(userIds, dateTo, dateFrom, division, state, status): Observable<SuccessRatesData> {
		if (state && state.id) {
			state = state.id;
		}

		return this.http
			.post<{ data: SuccessRatesData }>(`${Constants.BASE_API_URL}/dashboard/success-rates/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Returns data to populate the top 5 clients component
	 * @returns {Observable<*>}
	 */
	public getTop5Clients(userIds, dateTo, dateFrom, division, state, status, selectedTop5) {
		if (state && state.id) {
			state = state.id;
		}

		return this.http
			.post<{ data: Array<Top5ClientsData> }>(`${Constants.BASE_API_URL}/dashboard/top-clients/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
				selectedTop5,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Returns data to populate the projects split by status graph
	 * @returns {Observable<*>}
	 */
	public getProjectsSplitByStatus(userIds, dateTo, dateFrom, division, state, status) {
		if (state && state.id) {
			state = state.id;
		}

		return this.http
			.post<{ data: Array<ProjectsStatusData> }>(`${Constants.BASE_API_URL}/dashboard/projects-statuses/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Returns data to populate projects split by brand graph
	 * @returns {Observable<*>}
	 */
	public getProjectsSplitByBrand(userIds, dateTo, dateFrom, division, state, status) {
		if (state && state.id) {
			state = state.id;
		}

		return this.http
			.post<{ data: Array<ProjectsBrandsData> }>(`${Constants.BASE_API_URL}/dashboard/projects-brands/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Returns data to populate the scheduling tool
	 * @returns {Observable<*>}
	 */
	public getSchedulingToolData(userIds, dateTo, dateFrom, division, state, status) {
		return this.http
			.post<{ data: SchedulingToolDataItem[] }>(`${Constants.BASE_API_URL}/dashboard/scheduling-tool/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Returns data to populate won and submitted profit graph
	 * @returns {Observable<*>}
	 */
	public getWonAndSubmittedProfit(userId, dateTo, dateFrom, division, state, status) {
		const userIds = [userId];
		if (state && state.id) {
			state = state.id;
		}

		return this.http
			.post<{ data: WonAndSubmittedProjectProfitByMonthData }>(`${Constants.BASE_API_URL}/dashboard/won-submitted-profit/`, {
				userIds,
				dateTo,
				dateFrom,
				division,
				state,
				status,
			})
			.pipe(map(res => res.data));
	}

	/**
	 * Gets a list of users to swap to from dashboard.
	 * For Admins, get all users within the user's companies (providers).
	 * For Team Leaders, get all users within the user's user group.
	 * For a user with both, collate them.
	 * @returns {Observable<UsersResponse>}
	 */
	public getUsersForDashboard(userId) {
		return this.http.post<UsersResponse>(`${Constants.BASE_API_URL}/dashboard/users-for-dashboard`, { userId }).pipe(map(res => res.users));
	}
}
