import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../../../helpers';
import { ItemsResponseData } from '../../../interfaces';
import { AuthService } from '../../../services/auth.service';

import { LocalStorageService } from '../../../services/local-storage.service';
import { AlertHttpService } from './alert.http.service';
import { AlertModel } from './alert.models';

@Injectable()
export class AlertService {
	private totalAlerts: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	public alerts: Observable<number> = this.totalAlerts.asObservable();

	constructor(
		private alertHttpService: AlertHttpService,
		private userService: LocalStorageService,
		private auth: AuthService,
		private http: HttpClient
	) {
		this.updateTotalUnreads();
	}

	/**
	 * Set the total of unread alerts to be used for the header
	 */
	public updateTotalUnreads(): void {
		if (this.auth.isAuthenticated()) {
			this.alertHttpService
				.getTotalUnread(this.userService.getUserId())
				.pipe(
					tap(total => {
						this.totalAlerts.next(total);
					})
				)
				.subscribe();
		}
	}

	/**
	 * When an alert changes its status, automatically in notified to the service
	 * @param total
	 */
	public setTotalAlerts(total: number): void {
		this.totalAlerts.next(total);
	}

	/**
	 * Returns the variable of total alerts
	 * @returns
	 */
	public getTotalAlerts() {
		return this.totalAlerts;
	}

	public paginate(skip: number, limit: number, userId: string, status?: string[], dateFrom?: Date, dateTo?: Date): Observable<ItemsResponseData<AlertModel>> {
		return this.http.post<ItemsResponseData<AlertModel>>(`${Constants.BASE_API_URL}/alerts/paginate`, {
			skip,
			limit,
			userId,
			status: status ?? undefined,
			dateFrom,
			dateTo,
		});
	}
}
