export const TextConstants = {
	adminMessage: 'Admin message',
	archiveMessages: 'Archived alerts',
	archive: 'Archive',
	date: 'Date',
	markAsRead: 'Mark as read',
	message: 'Message',
	noNotifications: 'You dont have alerts',
	readMessages: 'Read alerts',
	title: 'Alerts',
	unarchive: 'Unarchive',
	unreadMessages: 'Unread alerts',
};
