import { Component, Input, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Constants } from '../../../helpers';
import { EmbeddedCoatingSystem } from '../../../interfaces';
import { ProjectService } from '../../../services/project.service';

@Component({
	selector: 'app-coating-systems-tab',
	templateUrl: './coating-systems-tab.component.html',
})
export class CoatingSystemsTabComponent implements OnInit {
	public _projectId: string;
	@Input() set projectId(value: string) {
		if (value) {
			this._projectId = value;
		}
	}

	@Input() totalCount: number;
	public coatingSystems: EmbeddedCoatingSystem[] = [];
	public headers = Constants.LIBRARY_TYPES.coatingSystem.headers;
	public loadPromise: Promise<EmbeddedCoatingSystem[]>;
	public tabIconPath: string = Constants.SVG_ICON_PATHS.palette;
	public selectedTab: boolean = false;

	constructor(private projectService: ProjectService) {
		//
	}

	public ngOnInit(): void {}

	onSelectTab(tabEvent: TabDirective | UIEvent) {
		if (tabEvent && this._projectId) {
			this.loadPromise = this.projectService.postCoatingSystems(this._projectId).toPromise();
			this.loadPromise.then(items => {
				this.coatingSystems = items;
				this.totalCount = this.coatingSystems.length;
			});
			this.selectedTab = true;
		}
	}

	onDeselectTab() {
		this.selectedTab = false;
	}

	load() {
		this.loadPromise = this.projectService.postCoatingSystems(this._projectId).toPromise();
		this.loadPromise.then(items => {
			this.coatingSystems = items;
			this.totalCount = this.coatingSystems.length;
		});
	}
}
