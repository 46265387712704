import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { PopoverComponent } from '../popover/popover.component';

@Component({
	selector: 'mos-date-picker',
	templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {
	@ViewChild('monthPopover') public monthPopover!: PopoverComponent;
	@ViewChild('yearPopover') public yearPopover!: PopoverComponent;

	@Input() public singleDate: boolean = false;
	@Input() public dateRange: boolean = true;
	@Output() selectedDate: EventEmitter<Date | null> = new EventEmitter<Date | null>();
	@Output() selectedRange: EventEmitter<{ from: Date | null; to: Date | null }> = new EventEmitter<{ from: Date | null; to: Date | null }>();

	public isOpen = false;
	public selectedSingleDate: Date | null = null;
	public selectedRangeValue: { from: Date | null; to: Date | null } = { from: null, to: null };
	public months = Array.from({ length: 12 }, (_, i) => i);
	public yearRange = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
	public month: number = new Date().getMonth();
	public year: number = new Date().getFullYear();
	public daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
	public daysCache: { day: number, empty: boolean }[] | null = null;

	@HostListener('document:click', ['$event'])
	handleClickOutside(event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (this.isOpen && !target.closest('.date-picker-container')) {
			this.isOpen = false;
		}
	}

	public toggleDatePicker() {
		this.isOpen = !this.isOpen;
	}

	public prevMonth() {
		if (this.month === 0) {
			this.month = 11;
			this.year--;
		} else {
			this.month--;
		}
		this.clearDaysCache();
	}

	public nextMonth() {
		if (this.month === 11) {
			this.month = 0;
			this.year++;
		} else {
			this.month++;
		}
		this.clearDaysCache();
	}

	// Cache the result of getDaysInMonth to avoid recalculations
	public getDaysInMonth(): { day: number, empty: boolean }[] {
		if (this.daysCache) {
			return this.daysCache;
		}

		const year = this.year;
		const month = this.month;

		const firstDay = new Date(year, month, 1).getDay();
		const daysInMonth = new Date(year, month + 1, 0).getDate();

		const startDay = (firstDay + 6) % 7;
		const daysArray = [];

		for (let i = 0; i < startDay; i++) {
			daysArray.push({ day: null, empty: true });
		}

		for (let i = 1; i <= daysInMonth; i++) {
			daysArray.push({ day: i, empty: false });
		}

		this.daysCache = daysArray;
		return daysArray;
	}

	public clearDaysCache() {
		this.daysCache = null;
	}

	public selectDate(day: number) {
		const selected = new Date(this.year, this.month, day);

		if (this.singleDate) {
			this.selectedSingleDate = selected;
			this.selectedDate.emit(selected);
			this.isOpen = false;
		} else {
			if (!this.selectedRangeValue.from || this.selectedRangeValue.to) {
				this.selectedRangeValue = { from: selected, to: null };
			} else {
				if (selected < this.selectedRangeValue.from) {
					this.selectedRangeValue = { from: selected, to: null };
				} else {
					this.selectedRangeValue.to = selected;
					this.isOpen = false;
					this.selectedRange.emit(this.selectedRangeValue);
				}
			}
		}
	}

	public isSelected(day: number): boolean {
		const current = new Date(this.year, this.month, day).getTime();

		if (this.singleDate && this.selectedSingleDate) {
			return current === this.selectedSingleDate.getTime();
		}

		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			return current === this.selectedRangeValue.from.getTime() || current === this.selectedRangeValue.to.getTime();
		}

		return this.selectedRangeValue.from && current === this.selectedRangeValue.from.getTime();
	}

	public isInRange(day: number): boolean {
		const current = new Date(this.year, this.month, day).getTime();
		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			const from = this.selectedRangeValue.from.getTime();
			const to = this.selectedRangeValue.to.getTime();
			return current > from && current < to;
		}
		return false;
	}

	public getDateRangeString(): string {
		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			const from = this.selectedRangeValue.from.toLocaleDateString();
			const to = this.selectedRangeValue.to.toLocaleDateString();
			return `${from} - ${to}`;
		}
		if (this.selectedSingleDate) {
			return this.selectedSingleDate.toLocaleDateString();
		}
		return '';
	}

	public getMonthName(month: number): string {
		return new Date(0, month).toLocaleString('default', { month: 'long' });
	}

	public setMonth(month: number) {
		this.month = month;
		this.monthPopover.close();
		this.clearDaysCache();
	}

	public setYear(year: number) {
		this.year = year;
		this.yearPopover.close();
		this.clearDaysCache();
	}

	public openMonthPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.monthPopover.open(event);
	}

	public openYearPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.yearPopover.open(event);
	}
}
