import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { DocumentGeneratorParams, DocumentGeneratorResponse, ExportDocument } from '../interfaces';

@Injectable()
export class DocumentGeneratorService {
	private static readonly URL = `${Constants.BASE_API_URL}/document-generator`;

	constructor(private http: HttpClient) {}

	/**
	 * Generates a document
	 * @param documentGeneratorParams
	 */
	public generateDocument(documentGeneratorParams: DocumentGeneratorParams) {
		return this.http.post<DocumentGeneratorResponse>(`${DocumentGeneratorService.URL}/generate`, documentGeneratorParams);
	}

	public getSingleFilesFolder(projectId: string, attachmentTypeName: string): Observable<{ file: ExportDocument }> {
		const body = {
			projectId: projectId,
			attachmentTypeName: attachmentTypeName,
		};

		return this.http.post<{ file: ExportDocument }>(`${DocumentGeneratorService.URL}/getSingleFilesFolder`, body);
	}

	/**
	 * Merges MSDS sheets of products on this project
	 * @param {string} projectId
	 * @param {string} attachmentTypeName
	 * @returns {Observable<*>}
	 */
	public mergeProductMsdsSheets(projectId: string, attachmentTypeName) {
		const body = {
			projectId: projectId,
			attachmentTypeName: attachmentTypeName,
		};

		return this.http.post<DocumentGeneratorResponse>(`${DocumentGeneratorService.URL}/merge-msds`, body).pipe(map(res => res.key));
	}

	/**
	 * Requests a delete policy
	 * @returns {Observable<any>}
	 */
	uploadAndSanitiseDocument(file, path: string) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('path', path);

		return this.http.post<DocumentGeneratorResponse>(`${DocumentGeneratorService.URL}/sanitise-upload`, formData).pipe(map(res => res.key));
	}
}
