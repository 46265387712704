import {
	AfterViewInit,
	Component, DestroyRef, ElementRef, HostListener, inject, OnDestroy, OnInit, Renderer2, ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Project, ProjectsFullListSearchResponse } from '../../../app/interfaces';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ProjectService } from '../../../app/services/project.service';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { LucideAngularModule } from 'lucide-angular';
import { SkeletonLoader } from '../skeleton-loader/skeleton-loader.component';
import { SpinnerLoader } from '../spinner-loader/spinner-loader.component';
import { SelectedProjectService } from '../../../app/services/select-project.service';

@Component({
	selector: 'mos-command-palette',
	templateUrl: 'command-palette.component.html',
	styleUrl: 'command-palette.component.css',
	imports: [
		FormsModule,
		NgClass,
		LucideAngularModule,
		SkeletonLoader,
		SpinnerLoader,
	],
	standalone: true,
}) export class CommandPaletteComponent implements AfterViewInit {
	@ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
	@ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
	public isHelp: boolean = false;
	public emptyState: boolean = false;
	public isOpen: boolean = false;
	public fuzzySearchString: string = "";
	public loadingSearchProjects: Subscription;
	public projects: Project[] = [];
	public activeProject = -1;
	public selectedAction = -1;
	public destroyRef = inject(DestroyRef);
	public loadingProjects: boolean = false;
	private boundDestroy: () => void;

	private searchSubject: Subject<string> = new Subject<string>();

	constructor(
		private projectService: ProjectService,
		private router: Router,
		private selectProjectService: SelectedProjectService,
		private renderer: Renderer2
	) {
		this.boundDestroy = this.destroy.bind(this);

		this.searchSubject.pipe(
			debounceTime(400),
			takeUntilDestroyed(this.destroyRef)
		).subscribe(searchTerm => {
			this.fuzzySearchString = searchTerm;
			this.fuzzySearch();
		})
	}

	public ngAfterViewInit() {
		this.dialog.nativeElement.addEventListener('click', (event: MouseEvent) => {
			const target = event.target as Element;
			if (target.nodeName === 'DIALOG') {
				this.close();
			}
		});
	}

	public onSearch(event: any) {
		this.fuzzySearchString = event.target.value.toLowerCase();
		this.isHelp = false;
		this.emptyState = false;
		this.projects = [];
		this.activeProject = -1;
		this.selectedAction = -1;

		if (!this.fuzzySearchString || this.fuzzySearchString.length === 0) {
			this.projects = [];
			this.emptyState = false;
			this.loadingProjects = false;
			return;
		}

		if (this.fuzzySearchString === '?') {
			this.isHelp = !this.isHelp;
			this.emptyState = false;
			return;
		}

		this.searchSubject.next(this.fuzzySearchString)
	}

	public clearSearch() {
		this.fuzzySearchString = "";
		this.isHelp = false;
		this.projects = [];
		this.emptyState = false;
		this.activeProject = -1;
		this.selectedAction = -1;
		this.isOpen = false;

		if (this.searchInput && this.searchInput.nativeElement) {
			this.searchInput.nativeElement.value = "";
		}
	}

	open() {
		this.isOpen = true;
		this.dialog.nativeElement.showModal();
	}

	close() {
		this.clearSearch();
		this.dialog.nativeElement.close();
		this.dialog.nativeElement.classList.remove('closing');
		this.dialog.nativeElement.removeEventListener('animationend', this.boundDestroy);
	}

	destroy() {
		this.clearSearch();
		this.dialog.nativeElement.close();
		this.dialog.nativeElement.classList.remove('closing');
		this.dialog.nativeElement.removeEventListener('animationend', this.boundDestroy);
	}

	public fuzzySearch(): void {
		this.loadingProjects = true;
		this.loadingSearchProjects = this.projectService
			.getFullProjectList(this.fuzzySearchString)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result: ProjectsFullListSearchResponse) => {
				if (result.projects && result.projects.length > 0) {
					this.projects = result.projects;
					this.emptyState = false;
					this.loadingProjects = false;
				} else {
					this.projects = [];
					this.emptyState = true;
					this.loadingProjects = false;
				}
			});
	}

	@HostListener('window:keydown', ['$event'])
	public handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'ArrowDown') {
			event.preventDefault();
			if (this.activeProject < this.projects.length - 1) {
				this.activeProject++;
				this.scrollWithProject();
				this.searchInput.nativeElement.blur();
			} else if (this.selectedAction < 0) {
				this.selectedAction = 0;
			}
		} else if (event.key === 'ArrowUp') {
			if (this.activeProject > 0) {
				this.activeProject--;
				this.scrollWithProject();
			} else if (this.selectedAction > -1) {
				this.selectedAction = -1;
			}
		} else if (event.key === 'Enter') {
			if (this.activeProject >= 0) {
				this.navigateToProject(this.projects[this.activeProject]);
			}
			if (this.selectedAction === 0) {
				this.createNewProject();
			}
		}
	}

	public scrollWithProject() {
		const activeElement = document.getElementById(`option-${this.activeProject}`);
		if (activeElement) {
			activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	public navigateToProject(project: Project) {
		this.close();
		this.selectProjectService.setSelectedProject(project);
		this.router.navigate([`/project/${project.id}/overview`]);
	}

	public createNewProject() {
		this.close();
		this.router.navigate(['/create-project']);
	}
}