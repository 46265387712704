import { InputTextAlignment } from './display-input-number.constants';

export class InputNumberConfig {
	public alignInputText?: InputTextAlignment = InputTextAlignment.left;
	public min?: number;
	public max?: number;
	public precision?: number;
	public removePadding?: boolean = false;

	constructor(model?: Partial<InputNumberConfig>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
