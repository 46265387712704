import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { SupplierModel, SupplierProductModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class SupplierProductService extends BaseService<SupplierProductModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.supplierProduct, SupplierProductModel);
	}

	/**
	 * Post to create action of endpoint and return an instance of modelType
	 * @param postObject
	 */
	public postCreateSupplierProduct(postObject: SupplierProductModel): Observable<{ supplier: SupplierModel; newId: string }> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/create`, postObject.getPostObject()).pipe(
			map((json: any) => {
				return { supplier: new SupplierModel(json.item), newId: json.newId };
			})
		);
	}

	/**
	 * Post to edit action of endpoint and return an instance of modelType
	 * @param postObject
	 */
	public postEditSupplierProduct(postObject: SupplierProductModel): Observable<{ supplier: SupplierModel; newId: string }> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/edit`, postObject.getPostObject()).pipe(
			map((json: any) => {
				return { supplier: new SupplierModel(json.item), newId: json.newId };
			})
		);
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param id
	 * @param isActive
	 */
	public postDeleteSupplierProduct(postObject: SupplierProductModel): Observable<SupplierModel> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/delete`, postObject.getPostObject()).pipe(map((json: any) => new SupplierModel(json.item)));
	}

	/**
	 * Post to upload-file action of endpoint and return an instance of modelType
	 * @param formData FormData
	 */
	public postUploadFileSupplierProduct(formData: FormData): Observable<SupplierModel> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/upload-file`, formData).pipe(map((json: any) => new SupplierModel(json.item)));
	}
}
