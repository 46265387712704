import { LayoutModule } from '@angular/cdk/layout';
import { PercentPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IntercomModule } from '@supy-io/ngx-intercom';
// This is a forked npm package, the original (ng-intercom) is outdated and broken for angular 10+
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieModule } from 'ngx-cookie';
import { NgxStripeModule } from 'ngx-stripe';

import { NavigationMenuModule } from '../@itc-core/components/navigation-panels/navigation-menu/common/navigation-menu.module';
import { NavigationWarningProjectSessionGuard } from '../@itc-core/components/navigation-warning/common/navigation-warning-project-session.guard';
import { NavigationWarningGuard } from '../@itc-core/components/navigation-warning/common/navigation-warning.guard';
import { NavigationWarningModule } from '../@itc-core/components/navigation-warning/common/navigation-warning.module';
import { UserCountGuard } from '../@itc-core/components/navigation-warning/common/user-count.guard';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ProductTourComponent } from './components/product-tour/product-tour.component';
import { SlideOverModule } from './components/slide-over/common/slide-over.module';
import { CoreModule } from './core/core.module';
import { InputNumberDirective } from './core/directives';
import { AuthInterceptor, PipeExportModule, router, SnackBarHelperComponent } from './helpers';
import { MaterialModule } from './material.module';
import { AlertHttpService } from './pages/alert/common/alert.http.service';
import { AlertService } from './pages/alert/common/alert.service';
import { CoatingSystemFormModule } from './pages/coating-system/coating-system-form/coating-system-form.module';
import { LibrariesDataService } from './pages/libraries/libraries/common/libraries.data.service';
import { SignupFormService } from './pages/login/signup-form/common/signup-form.service';
import { LabourCostsService } from './pages/project/labour-costs/common/labour-costs.service';
import { QuoteApprovalModule } from './pages/quote-approval/common/quote-approval.module';
import { AdminGuard } from './route-guards/admin-guard.service';
import { AuthGuard, NoAuthGuard } from './route-guards/auth-guard.service';
import { BlockRootAccessGuard } from './route-guards/block-root-access-guard.service';
import { CreateNewProjectGuard } from './route-guards/create-new-project.guard';
import { EntityOwnerGuard } from './route-guards/entity-owner.guard';
import { ProjectSessionCreateGuard } from './route-guards/project-session-create-guard.service';
import { ProjectSessionGuard } from './route-guards/project-session-guard.service';
import { RolePermissionsGuard } from './route-guards/role-permissions-guard.service';
import { SuperAdminGuard } from './route-guards/super-admin.guard';
import { TraineeAndAboveGuard } from './route-guards/trainee-and-above-guard.service';
import { UploadCustomDocumentsGuard } from './route-guards/upload-custom-documents.guard';
import { UserAndAboveGuard } from './route-guards/user-and-above-guard.service';
import { ViewerAndAboveGuard } from './route-guards/viewer-and-above-guard.guard';
import { AttachmentService } from './services/attachment.service';
import { AuthService } from './services/auth.service';
import { BoqService, GetBoqResolver } from './services/boq.service';
import { BrandService, GetBrandsResolver } from './services/brand.service';
import { CalculationService } from './services/calculation.service';
import { ClientService, GetActiveClientsResolver, GetClientResolver, GetClientsResolver } from './services/client.service';
import { CoatingSystemService } from './services/coating-system.service';
import { ColourService, GetActiveColoursResolver } from './services/colour.service';
import { CommentService } from './services/comment.service';
import { CompanyService, GetActiveProvidersResolver, GetCompaniesResolver, GetCompanyResolver } from './services/company.service';
import { CompetitorService } from './services/competitor.service';
import { DivisionService, GetDivisionsResolver } from './services/division.service';
import { DocumentGeneratorService } from './services/document-generator.service';
import { EmailLogService } from './services/email-log.service';
import { EmailService } from './services/email.service';
import { EntityService } from './services/entity.service';
import { EstimatorUserService } from './services/estimator-user/estimator-user.service';
import { ExportService } from './services/export.service';
import { FileUploadService } from './services/file-uploader.service';
import { FilesFolderService } from './services/files-folder.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { GenericLibraryService } from './services/generic-library.service';
import { ImportDataService } from './services/import-data.service';
import { IncExcCommentTemplateService } from './services/inc-exc-comment-template.service';
import { GetRatesCountResolver, LabourRateService } from './services/labour-rate.service';
import { LocalStorageService } from './services/local-storage.service';
import { GetMarketingItemsResolver, MarketingService } from './services/marketing.service';
import { ModalService } from './services/modal.service';
import { NavigationService } from './services/navigation.service';
import { ProductTourService } from './services/product-tour.service';
import { GetActiveProductsResolver, ProductService } from './services/product.service';
import { ProjectSessionService } from './services/project-session.service';
import { GetTotalTimeSpentOnProject, ProjectTimeService } from './services/project-time.service';
import { GetCloneProjectResolver, GetProjectResolver } from './services/project.service';
import { GetAllProvidersForUsersResolver, ProviderService } from './services/provider.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { ActiveRefCodeWithTypesResolver, RefCodeService, RefCodeTypesResolver, RefCodeWithTypesResolver } from './services/reference-code.service';
import { RegionService } from './services/region.service';
import { ResetPasswordService } from './services/reset-password.service';
import { S3Service } from './services/s3.service';
import { GetStatesResolver, StateService } from './services/state.service';
import { SupplierProductService } from './services/supplier-product.service';
import { SupplierService } from './services/supplier.service';
import { GetAssignedTasksResolver, TaskService } from './services/task.service';
import { UserGroupService } from './services/user-group.service';
import { UserTimeService } from './services/user-time-service';
import { GetActiveAdminsResolver, GetCurrentUserResolver, GetUsersResolver, UserService } from './services/user.service';
import { VCProjectService } from './services/vc-project.service';
import { CacheService } from './services/cache.service';
import { QuoteApprovalGuard } from './route-guards/quote-approval.guard';
import * as Sentry from '@sentry/angular-ivy';
import { FeatureFlagService } from './services/feature-flag.service';
import { featureFlagGuard } from './route-guards/feature-flag.guard';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import {
	CircleCheckBig,
	CircleHelp, Eye, EyeOff,
	Grid2x2,
	List,
	LucideAngularModule,
	MessagesSquare,
	Plus,
	Rocket,
	Users,
} from 'lucide-angular';
import { ExternalAccountingService } from './services/external-accounting.service';

@NgModule({
	declarations: [AppComponent, InputNumberDirective],
	imports: [
		CoreModule,
		AngularSvgIconModule.forRoot(),
		BrowserAnimationsModule,
		BrowserModule,
		BsDropdownModule,
		BsDropdownModule.forRoot(),
		CollapseModule,
		CookieModule.forRoot(),
		FormsModule,
		HttpClientModule,
		DragToSelectModule.forRoot(),
		IntercomModule.forRoot({
			appId: environment.intercomAppId,
			updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
		}),
		LayoutModule,
		MaterialModule,
		NavigationMenuModule,
		NavigationWarningModule,
		NgSelectModule,
		PipeExportModule,
		ReactiveFormsModule,
		RouterModule.forRoot(router, { useHash: false }),
		TypeaheadModule.forRoot(),
		NgxStripeModule.forRoot(environment.stripePublishableAPIKey),
		CoatingSystemFormModule,
		SlideOverModule,
		SidebarModule,
		LucideAngularModule.pick({Plus, CircleHelp, MessagesSquare, Rocket, List, Grid2x2, Users, CircleCheckBig, Eye, EyeOff})
	],
	providers: [
		ActiveRefCodeWithTypesResolver,
		AdminGuard,
		AlertService,
		AlertHttpService,
		AttachmentService,
		AuthGuard,
		AuthService,
		BlockRootAccessGuard,
		BoqService,
		BrandService,
		CalculationService,
		ClientService,
		CoatingSystemService,
		ColourService,
		CommentService,
		CompanyService,
		CompetitorService,
		DivisionService,
		CreateNewProjectGuard,
		DocumentGeneratorService,
		EmailLogService,
		EmailService,
		EntityOwnerGuard,
		EntityService,
		EstimatorUserService,
		ExportService,
		ExternalAccountingService,
		FeatureFlagService,
		FilesFolderService,
		FileUploadService,
		ForgotPasswordService,
		GenericLibraryService,
		GetActiveAdminsResolver,
		GetActiveClientsResolver,
		GetActiveColoursResolver,
		GetDivisionsResolver,
		GetActiveProductsResolver,
		GetActiveProvidersResolver,
		GetAllProvidersForUsersResolver,
		GetAssignedTasksResolver,
		GetBoqResolver,
		GetBrandsResolver,
		GetClientResolver,
		GetClientsResolver,
		GetCompaniesResolver,
		GetCompanyResolver,
		GetCurrentUserResolver,
		GetProjectResolver,
		GetCloneProjectResolver,
		GetRatesCountResolver,
		GetStatesResolver,
		GetTotalTimeSpentOnProject,
		GetMarketingItemsResolver,
		GetUsersResolver,
		ImportDataService,
		IncExcCommentTemplateService,
		LabourCostsService,
		LabourRateService,
		LibrariesDataService,
		LocalStorageService,
		ModalService,
		NavigationService,
		NavigationWarningGuard,
		NavigationWarningProjectSessionGuard,
		NoAuthGuard,
		ProductService,
		ProjectSessionCreateGuard,
		ProjectSessionGuard,
		ProjectSessionService,
		ProjectTimeService,
		ProviderService,
		PurchaseOrderService,
		RefCodeService,
		RefCodeTypesResolver,
		RefCodeWithTypesResolver,
		RegionService,
		ResetPasswordService,
		RolePermissionsGuard,
		S3Service,
		SnackBarHelperComponent,
		StateService,
		SuperAdminGuard,
		SupplierProductService,
		SupplierService,
		TaskService,
		MarketingService,
		TraineeAndAboveGuard,
		UploadCustomDocumentsGuard,
		UserAndAboveGuard,
		UserCountGuard,
		UserGroupService,
		UserService,
		UserTimeService,
		ViewerAndAboveGuard,
		PercentPipe,
		VCProjectService,
		PercentPipe,
		QuoteApprovalGuard,
		ProductTourService,
		SignupFormService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { hasBackdrop: true },
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { floatLabel: 'always' },
		},
		{ provide: MAT_DIALOG_DATA, useValue: [] },
		{ provide: MatDialogRef, useValue: {} },
		// This changes the date format on the date pickers to DD/MM/YYYY
		// This is only a partial solution and requires the date picker to disable manual input
		// as entering manual input will still only support US format (MM/DD/YYY). The method provided
		// on the angular material datepicker docs does not work.
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		CacheService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
