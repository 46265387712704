import { UntypedFormGroup } from '@angular/forms';

import { ReferenceCode } from '../interfaces';
import { BaseModel } from './base.model';

export class ReferenceCodeModel extends BaseModel implements ReferenceCode {
	// Properties
	code1: string;
	code2: string;
	code3: string;
	codeType: string;
	description: string;
	isActive: boolean;
	isLocked: boolean;
	title: string;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
