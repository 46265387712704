import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { UserTime, UserTimeResponse } from '../interfaces';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class UserTimeService {
	endPoint: string = Constants.END_POINTS.userTime;
	userTime: UserTime;
	updateActiveInterval;

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private localStorageService: LocalStorageService
	) {}

	/**
	 * Creates a new user time session
	 */
	startNew(): void {
		const userId = this.localStorageService.getUserId();
		if (userId) {
			this.http.post<UserTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/start`, { userId }).subscribe(res => {
				// If the request was successful, update the active time periodically
				if (res.status) {
					this.userTime = res.userTime;
					// Clear any previous interval
					if (this.updateActiveInterval) {
						clearInterval(this.updateActiveInterval);
					}
					this.updateActiveInterval = setInterval(() => {
						this.updateActiveTime();
					}, Constants.ACTIVE_TIME_UPDATE_RATE);
				}
			});
		}
	}

	/**
	 * Completes a user time session
	 */
	complete(projectTimeId: string = undefined): void {
		if (this.userTime && this.authService.isAuthenticated()) {
			this.http
				.post<UserTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/complete`, {
					userTimeId: this.userTime.id,
					projectTimeId: projectTimeId,
				})
				.subscribe();
			this.userTime = null;
		}
	}

	/**
	 * Updates the last active date of a user time session
	 */
	updateActiveTime(): void {
		if (this.userTime && this.authService.isAuthenticated()) {
			this.http.post<UserTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/last-active`, { userTimeId: this.userTime.id }).subscribe();
		}
	}

	/**
	 * Gets the total time spent on the application as well as the time entries for last week
	 * @param userId
	 */
	getTimeEntries(userId: string) {
		return this.http
			.post<{ result: { sessions: UserTime[]; total: number } }>(`${Constants.BASE_API_URL}/${this.endPoint}/last-week-user-times`, {
				userId,
			})
			.pipe(map(res => res.result));
	}
}
