import { TEXT } from '../../../../helpers';

export const textConstants = {
	estimator: TEXT.estimator,
	acceptance: TEXT.acceptance,
	chargeCategory: TEXT.chargeCategory,
	client: TEXT.client,
	clientContactAddress: TEXT.clientContactAddress,
	clientContactEmail: TEXT.clientContactEmail,
	clientContactName: TEXT.clientContactName,
	commentsToEstimator: TEXT.commentsToEstimator,
	company: TEXT.company,
	created: TEXT.created,
	dueDate: TEXT.dueDate,
	gp: TEXT.gp + '%',
	grossProfit: TEXT.grossProfit,
	high: TEXT.high,
	industry: TEXT.industry,
	leadSource: TEXT.leadSource,
	location: TEXT.location,
	low: TEXT.low,
	no: TEXT.no,
	profit: TEXT.profit,
	projectRating: TEXT.projectRating,
	projectStartDate: TEXT.projectStartDate,
	quoteDueDate: TEXT.quoteDueDate,
	quoteSubmittedDate: TEXT.quoteSubmittedDate,
	revenue: TEXT.revenue,
	salesPerson: TEXT.salesPerson,
	strikeRate: TEXT.strikeRate,
	subIndustry: TEXT.subIndustry,
	totalProjectBuildValue: TEXT.totalProjectBuildValue,
	winProbability: TEXT.winProbability,
	yes: TEXT.yes,
};
