// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
	width: 600px;
	height: 235px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/export-email-dialog/export-email-dialog.component.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;AACd","sourcesContent":["textarea {\n\twidth: 600px;\n\theight: 235px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
