export interface ExportDocument {
	id?: string;
	name: string;
	folder: string;
	fileKey?: string;
	key?: string;
	order?: number;
	isGenerated: boolean;
	isSelected: boolean;
	isProductMsds?: boolean;
	latestFileHash?: string;
}

// Response type given back when retrieving documents and count
export interface ExportDocumentsResponse {
	status: boolean;
	items: ExportDocument[];
}
