// tslint:disable:max-line-length
export const RegEx = {
	// RFC 2822 standard email validation
	emailvalidator:
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
	// used to get an extesion
	fileExtension: /(?:\.([^.]+))?$/,
	// String must contain either http:// or https://
	httpOrHttps: /^(http:\/\/|https:\/\/)/,
	// String must contain all of: uppercase letter, lowercase letter, number and special character
	password: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s]+)/,
	// String must only contain positive numbers and 0
	positiveNumbersOnly: /^[0-9]*$/,
	// Entire string must only contain lowercase letters and numbers
	siteName: /^[a-z0-9]+$/,
};
