import { TEXT } from '../../../../../helpers';

export const IncExcText = {
	excLibrary: TEXT.excLibrary,
	exclusions: TEXT.exclusions,
	excComments: TEXT.excComments,
	incLibrary: TEXT.incLibrary,
	inclusions: TEXT.inclusions,
	incComments: TEXT.incComments,
	message: TEXT.message,
	noItems: TEXT.noItems,
	saveAs: TEXT.saveAs,
	select: TEXT.select,
	template: TEXT.template,
	typeHere: TEXT.typeHere,
};
