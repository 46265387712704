import { FIELDS_DAYS_OF_WEEK } from '../helpers';
import { AddressDetailModel } from './address-detail.model';
import { TradingHourModel } from './trading-hour.model';

export class SupplierLocationModel {
	public _id?: string;
	public addressDetail: AddressDetailModel = new AddressDetailModel();
	public distanceFromDevice?: number;
	public email: string;
	public isFavourite: boolean = false;
	public tradingHours: TradingHourModel[] = [];
	public phone: string;

	constructor(json?: Partial<SupplierLocationModel>) {
		if (json) {
			Object.assign(this, json);
			if (json.addressDetail) {
				this.addressDetail = new AddressDetailModel(json.addressDetail);
			}
			if (json.tradingHours && json.tradingHours.length > 0) {
				this.tradingHours = this.tradingHours.map(day => {
					return new TradingHourModel(day);
				});
			}
		} else {
			this.tradingHours = FIELDS_DAYS_OF_WEEK.map(item => {
				return {
					day: item.day,
					open: '',
					close: '',
					isOpen: false,
				};
			});
		}
	}
}
