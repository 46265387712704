// component specific constant
export enum LayoutOptions {
	connected,
	disconnected,
}

export enum StyleOptions {
	active,
	danger,
	dark,
	declined,
	info,
	light,
	lost,
	muted,
	primary,
	quoteApproved,
	quoteReady,
	secondary,
	submitted,
	success,
	target,
	warning,
	won,
}

export enum ButtonTextOptions {
	capitalize,
	upperCase,
	sentenceCase,
}
