export enum ButtonIcons {
	add,
	brand,
	coatingSystem,
	colour,
	comment,
	company,
	competitor,
	download,
	downloadOutline,
	fileAlt,
	filesFolders,
	folder,
	incExcTemplate,
	labourRate,
	pen,
	product,
	referenceCode,
	state,
	supplier,
	shareSquare,
	tasks,
	marketing,
	user,
	userTool,
	users,
}
