import { UntypedFormGroup } from '@angular/forms';

import { Region, State } from '../interfaces';
import { BaseModel } from './base.model';

export class StateModel extends BaseModel implements State {
	// Properties
	abbreviation: string;
	country: string;
	name: string;
	regions: Region[];
	isActive: boolean;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
