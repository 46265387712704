// Framework imports
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PageImageInformation } from './measuring-tool.interfaces';

// 3rd party imports

// @itc-Core imports

// project specific imports

@Injectable({
	providedIn: 'root',
})
export class MeasuringToolService {
	private canvasLoadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(null);
	public canvasLoading: Observable<boolean> = this.canvasLoadingSubject.asObservable();

	private pageImageInfo: PageImageInformation;
	private editingImage: boolean = false;

	constructor() {}

	public setCanvasLoading(value: boolean): void {
		this.canvasLoadingSubject.next(value);
	}

	public setImageInfo(imageInfo: PageImageInformation): void {
		this.pageImageInfo = imageInfo;
	}

	public getImageInfo(): PageImageInformation {
		return this.pageImageInfo;
	}

	public setEditingImage(editing: boolean): void {
		this.editingImage = editing;
	}

	public getEditingImage(): boolean {
		return this.editingImage;
	}
}
