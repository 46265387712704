// Framework imports
import { Component, Input } from '@angular/core';

import { TEXT } from '../../../helpers';
import { HelpCard } from './common/help-card.interfaces';

// 3rd party imports

// @itc-Core imports

// project specific imports

@Component({
	selector: 'paint-help-card',
	templateUrl: './help-card.component.html',
	styleUrls: ['./help-card.component.scss'],
})
export class HelpCardComponent {
	@Input() public helpFile: HelpCard;

	public learnMore: string = TEXT.learnMore;

	constructor() {}
}
