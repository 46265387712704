import { UntypedFormGroup } from '@angular/forms';

import { FileFolder } from '../interfaces';
import { BaseModel } from './base.model';

export class FileFolderModel extends BaseModel implements FileFolder {
	// Properties
	public company: string;
	public documentType: string;
	public entity: string;
	public fileKey: string;
	public folder: string;
	public isActive: boolean;
	public order: number;
	public templateTitle: string;
	public isProductMsds: boolean;

	//non db property
	public fileFile: File;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		if (this.fileFile) {
			const formData = new FormData();
			formData.append('file', this.fileFile);
			return formData;
		}
		return undefined;
	}
}
