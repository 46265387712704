// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  pointer-events: all;
}
:host .search-bar {
  width: 12rem;
  position: relative;
  top: -0.2rem;
}
:host .search-bar .search-input {
  height: 22px;
  border-radius: 5px;
  display: block;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  background-clip: padding-box;
  margin-bottom: 10px;
}
:host .search-bar .search-input:focus {
  border-color: var(--primary-color) !important;
  border-radius: 0.375rem !important;
  border-width: 3px !important;
}
:host .search-bar ::ng-deep .icon-style {
  font-size: 1.2rem;
  position: relative;
  top: -0.2rem;
}
:host .search-bar ::ng-deep typeahead-container {
  max-height: 250px;
  max-width: 250px;
}
:host .search-bar ::ng-deep typeahead-container .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/components/project-search-bar/project-search-bar.component.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;AACD;AACC;EACC,YAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACC,YAAA;EACA,kBAAA;EACA,cAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,4BAAA;EACA,mBAAA;AACH;AAEE;EACC,6CAAA;EACA,kCAAA;EACA,4BAAA;AAAH;AAGE;EACC,iBAAA;EACA,kBAAA;EACA,YAAA;AADH;AAIE;EACC,iBAAA;EACA,gBAAA;AAFH;AAGG;EACC,gBAAA;EACA,uBAAA;AADJ","sourcesContent":[":host {\n\tpointer-events: all;\n\n\t.search-bar {\n\t\twidth: 12rem;\n\t\tposition: relative;\n\t\ttop: -0.2rem;\n\n\t\t.search-input {\n\t\t\theight: 22px;\n\t\t\tborder-radius: 5px;\n\t\t\tdisplay: block;\n\t\t\tfont-size: 0.8125rem;\n\t\t\tfont-weight: 400;\n\t\t\tline-height: 1.5;\n\t\t\tbackground-color: #ffffff;\n\t\t\tbackground-clip: padding-box;\n\t\t\tmargin-bottom: 10px;\n\t\t}\n\n\t\t.search-input:focus {\n\t\t\tborder-color: var(--primary-color) !important;\n\t\t\tborder-radius: 0.375rem !important;\n\t\t\tborder-width: 3px !important;\n\t\t}\n\n\t\t::ng-deep .icon-style {\n\t\t\tfont-size: 1.2rem;\n\t\t\tposition: relative;\n\t\t\ttop: -0.2rem;\n\t\t}\n\n\t\t::ng-deep typeahead-container {\n\t\t\tmax-height: 250px;\n\t\t\tmax-width: 250px;\n\t\t\t.dropdown-item {\n\t\t\t\toverflow: hidden;\n\t\t\t\ttext-overflow: ellipsis;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
