import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { NavigationText } from './common/navigation-warning.constants';
import { NavigationWarningService } from './common/navigation-warning.service';
import { DialogButtonConfig, DialogComponent } from '../../../@mos-core/components/dialog/dialog.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
	selector: 'itc-navigation-warning',
	templateUrl: './navigation-warning.component.html',
	styleUrls: ['./navigation-warning.component.scss'],
})
export class NavigationWarningComponent implements OnInit {
	@ViewChild('navWarning') public navWarning!: DialogComponent;


	public navigationText: typeof NavigationText = NavigationText;
	public showModal: Observable<null>;
	public showSaveAndLiveButton$ = new BehaviorSubject(false);
	public navWarningButtons$: Observable<DialogButtonConfig[]>;

	constructor(
		private navWarningService: NavigationWarningService
	) {}

	public ngOnInit(): void {
		this.showSaveAndLiveButton$ = this.navWarningService.showSaveAndLiveButton$;
		this.showModal = this.navWarningService.showModal.pipe(
			tap(() => {
				this.openModal();
			})
		);

		this.navWarningButtons$ = this.showSaveAndLiveButton$.pipe(
			map(showSaveAndLeave => {
				const buttons: DialogButtonConfig[] = [];

				if (showSaveAndLeave) {
					buttons.push({ label: 'Save and leave', action: this.saveAndLeave.bind(this), type: 'primary' });
				}
				buttons.push({ label: 'Leave without saving', action: this.decline.bind(this), type: 'danger' });
				buttons.push({ label: 'Stay on this page', action: this.confirm.bind(this), type: 'normal' });

				return buttons;
			})
		);
	}

	// stay on page
	public confirm(): void {
		this.navWarningService.updateModalResponse(false);
		this.navWarning.close();
	}

	// leave page
	public decline(): void {
		this.navWarningService.setUnedited();
		this.navWarningService.updateModalResponse(true);
		this.navWarning.close();
	}

	// save and leave page
	public saveAndLeave(): void {
		this.navWarningService.saveMeasuringTool();
		this.navWarningService.updateModalResponse(true);
		this.navWarningService.setUnedited();
		this.navWarning.close();
	}

	public openModal(): void {
		if (this.navWarningService.isPageEdited) {
			this.navWarning.open()
		}
	}
}
