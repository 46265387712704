import { Attachment } from '../interfaces';
import { AddressDetailModel } from './address-detail.model';
import { DistributionSummaryModel } from './distribution-summary.model';
import { SupplierProductOrder } from './supplier-product-order.model';
import { SupplierModel } from './supplier.model';

export class PurchaseOrderModel {
	public id?: string;
	public deliverDate: Date;
	public deliverTo: AddressDetailModel = new AddressDetailModel();
	public distributionSummaries: DistributionSummaryModel[] = [];
	public generatedDocument: Attachment;
	public isReceived: boolean = false;
	public poDate: Date;
	public poNumber: number;
	public project: string;
	public specialInstructions?: string;
	public supplier: SupplierModel;
	public supplierLocation: string;
	public supplierProductOrders: SupplierProductOrder[] = [];
	public totalAmount: number;

	constructor(json?: Partial<PurchaseOrderModel>) {
		if (json) {
			Object.assign(this, json);
			if (json.deliverTo) {
				this.deliverTo = new AddressDetailModel(json.deliverTo);
			}
			if (json.distributionSummaries?.length) {
				this.distributionSummaries = json.distributionSummaries.map((distributionSummary: DistributionSummaryModel) => {
					return new DistributionSummaryModel(distributionSummary);
				});
			}
			if (json.supplier) {
				this.supplier = new SupplierModel(json.supplier);
			}
		}
	}
}
