import { projectStatuses } from '../../common/project.constants';

// Items to be displayed along the status bar each containing values that match the item
export const statusBarItems = [
	[projectStatuses.target.value],
	[projectStatuses.declined.value],
	[projectStatuses.active.value],
	[projectStatuses.quoteReady.value],
	[projectStatuses.quoteApproved.value],
	[projectStatuses.submitted.value],
	[projectStatuses.won.value, projectStatuses.lost.value],
	[projectStatuses.live.value],
	[projectStatuses.completed.value],
];
