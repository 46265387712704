import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import {
	CoatingSystem,
	EmbeddedCoatProduct,
	GetAddCoatingSystemProductValidation,
	GetCoatingSystemProductValidation,
	Product,
	ProductApplicationMethod,
} from '../../../interfaces';
import { BrandModel } from '../../../models';
import { BrandService } from '../../../services/brand.service';
import { ProductService } from '../../../services/product.service';

@Component({
	selector: 'app-coat-products-table',
	templateUrl: 'coat-products-table.component.html',
	styleUrls: ['./coat-products-table.component.scss'],
})
export class CoatProductsTableComponent implements OnInit {
	@Input() set coatingSystem(coatingSystem: CoatingSystem) {
		this._coatingSystem = coatingSystem;
		this.initComponent();
	}

	get coatingSystem() {
		return this._coatingSystem;
	}

	@Input()
	public set isDisabled(isDisabled: boolean) {
		this._isDisabled = isDisabled;
		for (const validators of this.coatProductsValidators) {
			ValidatorHelper.setFormControlsEnabled(validators, !isDisabled, {});
		}
	}
	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	@Input()
	public isModalView: boolean = false;

	@Output()
	public listUpdated: EventEmitter<void> = new EventEmitter<void>();

	public applicationMethods: ProductApplicationMethod[][] = [];
	public brands: BrandModel[] = [];
	public brandsObservable: Observable<BrandModel[]>;
	public coatProductsValidators = [];
	public newApplicationMethods: ProductApplicationMethod[] = [];
	public newCoatProduct: EmbeddedCoatProduct;
	public newSelectedProductBrand: BrandModel;
	public newCoatProductValidators;
	public percentages: Number[] = Constants.COATING_SYSTEM_PERCENTAGES;
	public allProducts: Array<Product> = [];
	public selectAll: boolean = false;
	public selectedBrandProducts = {};
	public selectedProductBrand: BrandModel[] = [];
	public showAddRow: boolean = false;
	public textConstants: typeof TEXT = TEXT;
	public loadingProductsPromise: Promise<Product[]>;

	private _coatingSystem: CoatingSystem;
	private _isDisabled: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private brandService: BrandService,
		private productService: ProductService,
		private snack: SnackBarHelperComponent
	) {}

	public ngOnInit(): void {
		this.brandsObservable = this.brandService.postList({ isActive: true }).pipe(
			tap((brands: BrandModel[]) => {
				this.brands = brands;
				this.setupBrandFilters();
			})
		);

		this.loadingProductsPromise = this.productService.postList({ isActive: true }).toPromise();
		this.loadingProductsPromise.then(product => {
			this.allProducts = product;
		});
	}

	/**
	 * Inits fields and displays with the current coating system.
	 */
	private initComponent() {
		// Set spread and target rate for any existing coat products
		for (let i = 0; i < this.coatingSystem.coatProducts.length; i++) {
			if (!(this.coatingSystem.coatProducts[i].spreadRate || this.coatingSystem.coatProducts[i].targetRate)) {
				this.setApplicationRates(i);
			}
		}
		this.setupCoatProductsValidators(this._coatingSystem);
		this.setNewCoatProduct();
		this.setupBrandFilters();
	}

	/**
	 * Adds the new coat product to the coating system.
	 */
	public addCoatProduct(): void {
		const errors = ValidatorHelper.checkErrors(this.newCoatProductValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		this.coatProductsValidators.push(GetAddCoatingSystemProductValidation());
		this.coatingSystem.coatProducts.push(this.newCoatProduct);
		this.selectedProductBrand.push(this.newSelectedProductBrand);

		this.newCoatProductValidators = GetAddCoatingSystemProductValidation();
		this.setNewCoatProduct();
		// This is not the best solution, however because we are using ngModel and FormControl
		// together on the html this is the only possible solution without having to do much refactoring
		this.newCoatProductValidators.ApplicationMethod.setValue(this.newCoatProduct.applicationMethod);
		this.newCoatProductValidators.Percentage.setValue(this.newCoatProduct.percentage);
		this.snack.snackInformation(this.textConstants.coatProductAdded);

		this.listUpdated.emit();
	}

	public deleteSelectedCoatProducts(): void {
		let index = 0;
		const indicesToRemove = [];
		for (const coatProduct of this.coatingSystem.coatProducts) {
			if (coatProduct.isSelected) {
				indicesToRemove.push(index);
			}
			index++;
		}

		for (let i = indicesToRemove.length - 1; i >= 0; i--) {
			this.removeCoatProduct(indicesToRemove[i]);
		}

		this.listUpdated.emit();
	}

	/**
	 * Retrieves products for the selected brand
	 */
	public getBrandProducts(index?: number): void {
		const brand = index === undefined ? this.newSelectedProductBrand : this.selectedProductBrand[index];
		if (brand) {
			if (!this.selectedBrandProducts[brand.id]) {
				this.selectedBrandProducts[brand.id] = [];
				this.loadingProductsPromise = this.productService.postList({ brand: brand.id }).toPromise();

				this.loadingProductsPromise
					.then(products => {
						this.selectedBrandProducts[brand.id] = products;
					})
					.catch(() => {});
			}
		}
	}

	/**
	 * When product selected, populate application methods for the coatProduct.
	 * If product cleared, clear application methods and rates as well.
	 * @param product
	 * @param index   if no index, we're editing the new entry.
	 */
	public productSelected(product: Product, index?: number): void {
		if (index !== undefined) {
			// Not the product of the new coat product case.
			this.applicationMethods[index] = [];
			this._coatingSystem.coatProducts[index].applicationMethod = undefined;
			this.coatingSystem.coatProducts[index].spreadRate = 0;
			this.coatingSystem.coatProducts[index].targetRate = 0;

			if (product) {
				this.applicationMethods[index] = product.applicationMethods;
			}
		} else {
			// The product of new coat product case.
			this.newApplicationMethods = [];
			this.newCoatProduct.applicationMethod = undefined;
			this.newCoatProduct.spreadRate = 0;
			this.newCoatProduct.targetRate = 0;

			if (product) {
				this.newApplicationMethods = product.applicationMethods;
			}
		}
		for (const validation of this.coatProductsValidators) {
			const errors = ValidatorHelper.checkErrors(validation);
			if (errors) {
				return;
			}
		}
	}

	/**
	 * Removes the given coat product from the coating system.
	 * @param index number
	 */
	public removeCoatProduct(index: number): void {
		this._coatingSystem.coatProducts.splice(index, 1);
		this.selectedProductBrand.splice(index, 1);
		this.coatProductsValidators.splice(index, 1);
		this.snack.snackInformation(this.textConstants.coatProductRemoved);
	}

	public selectAllCoatProducts(): void {
		for (const coatProduct of this.coatingSystem.coatProducts) {
			coatProduct.isSelected = !this.selectAll;
		}
	}

	/**
	 * Sets the display values for Spread and Target rates on coat products.
	 */
	public setApplicationRates(i?: number): void {
		if (i !== undefined) {
			const coatProduct = this.coatingSystem.coatProducts[i];
			if (coatProduct.applicationMethod && coatProduct.product.applicationMethods) {
				const coatProductApplicationMethod = coatProduct.product.applicationMethods.find(appMethod => {
					return appMethod.type === coatProduct.applicationMethod;
				});
				coatProduct.spreadRate = coatProductApplicationMethod?.spreadRate || 0;
				coatProduct.targetRate = coatProductApplicationMethod?.applicationTargetRate || 0;
			}
		} else {
			const coatProduct = this.newCoatProduct;
			if (coatProduct.applicationMethod) {
				const newCoatProductApplicationMethod = coatProduct.product.applicationMethods.find(appMethod => {
					return appMethod.type === coatProduct.applicationMethod;
				});
				coatProduct.spreadRate = newCoatProductApplicationMethod.spreadRate;
				coatProduct.targetRate = newCoatProductApplicationMethod.applicationTargetRate;
			}
		}
	}

	public toggleAddRow(): void {
		this.showAddRow = !this.showAddRow;
	}

	/**
	 * Creates a blank coat product.
	 */
	private setNewCoatProduct(): void {
		// Keep previously assigned product + application method for new rows
		this.newCoatProduct = {
			product: this.newCoatProduct?.product ? Object.assign({}, this.newCoatProduct?.product) : undefined,
			applicationMethod: this.newCoatProduct?.applicationMethod,
			percentage: 100,
			targetRate: this.newCoatProduct?.targetRate,
			spreadRate: this.newCoatProduct?.spreadRate,
		};
		this.setApplicationRates();
	}

	/**
	 * Generates validators for each element in the coating systems products array.
	 */
	private setupCoatProductsValidators(coatingSystem: CoatingSystem): void {
		if (coatingSystem && coatingSystem.coatProducts) {
			for (let i = 0; i < coatingSystem.coatProducts.length; ++i) {
				if (!this.coatProductsValidators[i]) {
					this.coatProductsValidators[i] = GetCoatingSystemProductValidation();
				}
			}

			if (this.coatProductsValidators.length > coatingSystem.coatProducts.length) {
				// Removes the unused validators.
				this.coatProductsValidators.splice(coatingSystem.coatProducts.length);
			}
		}

		if (!this.newCoatProductValidators) {
			this.newCoatProductValidators = GetCoatingSystemProductValidation();
		}

		if (this.isDisabled) {
			for (const validators of this.coatProductsValidators) {
				ValidatorHelper.setFormControlsEnabled(validators, false, {});
			}
		}
	}

	/**
	 * Sets the initial brand filter to match the brand of the coat product
	 */
	private setupBrandFilters() {
		if (this.coatingSystem && this.coatingSystem.coatProducts) {
			for (let i = 0; i < this.coatingSystem.coatProducts.length; i++) {
				const coatProduct: any = this.coatingSystem.coatProducts[i];
				let brandId;
				// Handle both populated and unpopulated coatProduct.product
				if (coatProduct.product?.brand) {
					// Populated product
					brandId = coatProduct.product.brand;
				} else {
					// Unpopulated product
					const coatProductObject = this.allProducts.find(product => product.id === coatProduct.product);
					brandId = coatProductObject?.brand;
				}
				this.selectedProductBrand[i] = this.brands.find(brand => brand.id === brandId);
				this.getBrandProducts(i);
			}
		}
	}
}
