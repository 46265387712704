import { BaseModel } from './base.model';
import { UntypedFormGroup } from '@angular/forms';
import { EntityModel } from './entity.model';
import { Shift } from '../interfaces';
import { ERROR_MESSAGES } from '../helpers';

export class ShiftModel extends BaseModel implements Shift {
	public id: string = '';
	public user?: string;
	public project: string;
	public startTime: Date;
	public endTime: Date;
	public parent?: string;
	public isParent: boolean;
	public wasOrphaned?: boolean;
	public rateOverride?: number;
	public isPublished?: boolean;
	public acceptedAt?: Date;
	public rejectedAt?: Date;
	public entity: EntityModel;
	public breakMinutes?: number;
	public notes?: string;
	public isHoliday?: boolean;
	public workerApproved?: boolean;
	public adminApproved?: boolean;
	public employee?: any;
	public requiresApproval?: boolean;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
			if (json.entity) {
				this.entity = new EntityModel(json.entity);
			} else if (json.id) {
				console.error(ERROR_MESSAGES.missingEntity);
			}
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
