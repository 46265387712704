import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { PermissionsMap, PROJECT_STATUS, UserRoles } from '../helpers';
import { Permissions, Project } from '../interfaces';
import { AuthUserModel } from '../models';
import { ProjectRouteData } from '../pages/project/common/project.interfaces';
import { ProjectRouteService } from '../pages/project/common/project.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class RolePermissionsService {
	private userPermissionsSubject: BehaviorSubject<Permissions> = new BehaviorSubject<Permissions>(null);
	public permittedActions: Observable<Permissions> = this.userPermissionsSubject.asObservable();

	public isUserKeyRole: boolean;
	public doesUserShareCompanyWithProject: boolean;
	public permissions: typeof PermissionsMap = PermissionsMap;
	public rank: number;
	public role: string;

	private userRoles: typeof UserRoles = UserRoles;

	constructor(
		private auth: AuthService,
		private projectRouteService: ProjectRouteService
	) {
		this.auth.userDetailsObservable.subscribe((userDetails: AuthUserModel) => {
			if (userDetails) {
				this.role = userDetails.role;
				this.rank = this.getRoleRank(userDetails.role);
				this.userPermissionsSubject.next(this.permissions[userDetails.role]);
			}
		});

		this.projectRouteService.projectDataStream.subscribe((projectData: ProjectRouteData) => {
			this.isUserKeyRole = projectData.isUserKeyRole;
			this.doesUserShareCompanyWithProject = projectData.doesUserShareCompany;
		});
	}

	public canEditProject(isUserKeyRole: boolean): boolean {
		return this.isTeamLeaderAndAbove() || ((this.isUserAndAbove() || this.isTrainee()) && isUserKeyRole);
	}

	/**
	 * Can edit the project if it is not Active and not Target, OR the user is an admin.
	 */
	public canEditProjectByStatus(project: Project): boolean {
		return this.isAdmin() || project.status === PROJECT_STATUS.active.value || project.status === PROJECT_STATUS.target.value;
	}

	/**
	 * Returns true if user is Admin or above
	 */
	public isAdmin(): boolean {
		return this.rank ? this.rank <= this.userRoles.admin.rank : false;
	}

	/**
	 * Returns true if user is Contracts or above
	 */
	public isContractsAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.contracts.rank : false;
	}

	/**
	 * Returns true if user is SuperAdmin or God
	 */
	public isSuperAdmin(): boolean {
		return this.rank ? this.rank === this.userRoles.superAdmin.rank : false;
	}

	/**
	 * Returns true if user is TeamLead or above
	 */
	public isTeamLeaderAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.teamLeader.rank : false;
	}

	/**
	 * Returns true if user is Trainee
	 */
	public isTrainee(): boolean {
		return this.rank ? this.rank === this.userRoles.trainee.rank : false;
	}

	/**
	 * Returns true if user is User role
	 */
	public isUser(): boolean {
		return this.rank ? this.rank === this.userRoles.user.rank : false;
	}

	/**
	 * Returns true if user is Trainee or above
	 */
	public isTraineeAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.trainee.rank : false;
	}

	/**
	 * Returns true if user is User or above
	 */
	public isUserAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.user.rank : false;
	}

	/**
	 * Returns true if user is User or above
	 */
	public isUserAndBelow(): boolean {
		return this.rank ? this.rank >= this.userRoles.user.rank : false;
	}

	/**
	 * Returns true if user is Viewer or above
	 */
	public isViewerAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.viewer.rank : false;
	}

	/**
	 * Returns true if user is employee
	 */
	public isEmployee(): boolean {
		return this.rank ? this.rank === this.userRoles.employee.rank : false;
	}

	/**
	 * Returns true if user is employee or above
	 */
	public isEmployeeAndAbove(): boolean {
		return this.rank ? this.rank <= this.userRoles.employee.rank : false;
	}

	/**
	 * Returns Rank of the role provided
	 * @param role
	 * @private
	 */
	public getRoleRank(role: string): number {
		const rank = this.userRoles[role]?.rank;
		if (rank) {
			return rank;
		}
	}
}
