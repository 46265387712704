import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Project } from '../../../../interfaces';
import { InvoicesPageService } from '../../common/invoices-page.service';

@Component({
	selector: 'paint-invoice-list-totals-card',
	templateUrl: './invoice-list-totals-card.component.html',
})
export class InvoiceListTotalsCardComponent implements OnInit {
	@Input() public project?: Project;

	public invoices$: BehaviorSubject<any>;
	public projectValue: number = 0;
	public claimed: number = 0;
	public owing: number = 0;

	private destroyRef = inject(DestroyRef);

	constructor(private invoicesPageService: InvoicesPageService) {}

	public ngOnInit() {
		this.invoices$ = this.invoicesPageService.invoices;
		this.invoices$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap(res => {
					this.projectValue = res.projectValue;
					this.claimed = res.claimed;
					this.owing = res.projectValue - res.claimed;
				})
			)
			.subscribe();
	}
}
