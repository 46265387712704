// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-location-input {
  caret-color: black;
  line-height: normal;
  font-size: 13px !important;
  font-family: "Open Sans", sans-serif !important;
}

.close-icon {
  font-size: 14px;
}

::ng-deep .cdk-overlay-container {
  z-index: 9999;
}

:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: transparent !important;
  border: 0 !important;
}

mat-form-field {
  height: 36px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 2px;
}
mat-form-field .mat-mdc-form-field-infix {
  padding: 0 !important;
}
mat-form-field .ng-invalid:not(form):not(.disable-error).ng-touched,
mat-form-field .submitted .ng-invalid {
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/charge-sublocation-autocompletes/charge-sub-location-autocompletes.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,mBAAA;EACA,0BAAA;EACA,+CAAA;AACD;;AAEA;EACC,eAAA;AACD;;AAGA;EACC,aAAA;AAAD;;AA4BC;EACC,kCAAA;EACA,oBAAA;AAzBF;;AA8BA;EACC,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AA3BD;AA4BC;EACC,qBAAA;AA1BF;AA4BC;;EAEC,2BAAA;AA1BF","sourcesContent":[".sub-location-input {\n\tcaret-color: black;\n\tline-height: normal;\n\tfont-size: 13px !important;\n\tfont-family: 'Open Sans', sans-serif !important;\n}\n\n.close-icon {\n\tfont-size: 14px;\n}\n\n// This is required to ensure the mat-autocomplete dropdowns are visible over the modal\n::ng-deep .cdk-overlay-container {\n\tz-index: 9999;\n}\n\n// Styles to make mat autocomplete look like an ng-select\n:host ::ng-deep {\n\t//.mat-mdc-form-field-infix {\n\t//    padding: 0 !important;\n\t//}\n\t//.mat-mdc-text-field-wrapper {\n\t//    padding-bottom: 0 !important;\n\t//}\n\t//.mat-mdc-form-field-bottom-align {\n\t//  display: none;\n\t//}\n\n\t//mat-form-field,\n\t//mat-form-field.mat-mdc-form-field-invalid,\n\t//mat-form-field.mat-mdc-focused {\n\t//    .mat-mdc-form-field-underline,\n\t//    .mat-mdc-form-field-ripple {\n\t//        background-color: transparent;\n\t//    }\n\t//}\n\n\t.mat-mdc-icon-button {\n\t\t//height: 0;\n\t\t//line-height: 34px;\n\t}\n\t.mdc-text-field--filled:not(.mdc-text-field--disabled) {\n\t\tbackground: transparent !important;\n\t\tborder: 0 !important;\n\t}\n}\n\n// Removing the styles below generates a double red outlined line on input\nmat-form-field {\n\theight: 36px;\n\tborder: 1px solid #cccccc;\n\tborder-radius: 4px;\n\tpadding: 8px;\n\tmargin-bottom: 2px;\n\t.mat-mdc-form-field-infix {\n\t\tpadding: 0 !important;\n\t}\n\t.ng-invalid:not(form):not(.disable-error).ng-touched,\n\t.submitted .ng-invalid {\n\t\tbox-shadow: none !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
