import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[appDisableControl]',
})
export class DisableControlDirective {
	@Input() set appDisableControl(condition: boolean) {
		const action = condition ? 'disable' : 'enable';
		if (this.ngControl.control) {
			this.ngControl.control[action]();
		}
	}

	constructor(private ngControl: NgControl) {}
}
/* https://netbasal.com/disabling-form-controls-when-working-with-reactive-forms-in-angular-549dd7b42110 */
