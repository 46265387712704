import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import * as _ from 'lodash';

@Component({
	// tslint:disable-next-line:component-selector
	selector: '[checkbox-drag-drop]',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./multi-checkbox-drag-drop.directive.scss'],
	templateUrl: './multi-checkbox-drag-drop.directive.html',
})
export class MultiDragDropComponent {
	@ContentChild(TemplateRef, { static: false })
	public templateRef;

	@Input()
	public disabled: boolean = true;
	@Input()
	public items: any[];

	@Output()
	public itemsAdded = new EventEmitter<any[]>();
	@Output()
	public itemsUpdated = new EventEmitter<any[]>();
	@Output()
	public selectionChanged = new EventEmitter<any[]>();

	@HostListener('document:click', ['$event'])
	public clickout(event) {
		if (this.selections.length && !this.eRef.nativeElement.contains(event.target)) {
			this.clearSelection();
		}
	}
	@HostListener('mouseover') onMouseOver() {
		this.inDropZone = true;
	}
	@HostListener('mouseleave') onMouseLeave() {
		this.inDropZone = false;
	}

	// tslint:disable:member-ordering
	public dragging: DragRef = null;
	public selections: number[] = [];
	public inDropZone: boolean = false;

	private currentSelectionSpan: number[] = [];
	private lastSingleSelection: number;

	constructor(
		private eRef: ElementRef,
		private cdRef: ChangeDetectorRef
	) {}

	public dragEnded(): void {
		this.dragging = null;
		this.cdRef.detectChanges();
	}

	public dragStarted(ev: CdkDragStart, index: number): void {
		this.selections = this.getAllSelectedIndexes(this.items, true);
		if (this.selections.length) {
			this.items[index].isSelected = true;
			this.selections = this.getAllSelectedIndexes(this.items, true);
		}
		this.dragging = ev.source._dragRef;
		const indices = this.selections.length ? this.selections : [index];
		ev.source.data = {
			indices,
			values: indices.map(i => this.items[i]),
			source: this,
		};
		this.cdRef.detectChanges();
	}

	public dropped(ev: CdkDragDrop<any>): void {
		if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
			return;
		}
		const data = ev.item.data;

		if (data.source === this) {
			const removed = _.pullAt(this.items, data.indices);
			if (ev.previousContainer !== ev.container) {
				this.itemsUpdated.emit(this.items);
			}
		}
		this.dragging = null;
		setTimeout(() => this.clearSelection());
	}

	public droppedIntoList(ev: CdkDragDrop<any>): void {
		if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
			return;
		}
		const data = ev.item.data;
		let spliceIntoIndex = ev.currentIndex;
		if (ev.previousContainer === ev.container && this.selections.length > 1) {
			this.selections.splice(-1, 1);
			const sum = _.sumBy(this.selections, selectedIndex => (selectedIndex <= spliceIntoIndex ? 1 : 0));
			spliceIntoIndex -= sum;
		}
		this.items.splice(spliceIntoIndex, 0, ...data.values);

		if (ev.previousContainer !== ev.container) {
			this.itemsAdded.emit(data.values);
		}
		this.itemsUpdated.emit(this.items);
		setTimeout(() => this.cdRef.detectChanges());
	}

	public isSelected(i: number): boolean {
		return this.selections.indexOf(i) >= 0;
	}

	public select(event, index): void {
		setTimeout(() => {
			this.selections = this.getAllSelectedIndexes(this.items, true);
			this.selectionChanged.emit(this.selections.map(i => this.items[i]));
			this.cdRef.detectChanges();
		}, 0);
	}

	private clearSelection(): void {
		if (this.selections.length) {
			this.selections = [];
			this.currentSelectionSpan = [];
			this.deselectAllitems();
			this.lastSingleSelection = null;
			this.selectionChanged.emit(this.selections.map(i => this.items[i]));
			this.cdRef.detectChanges();
		}
	}

	private deselectAllitems(): void {
		for (let i = 0; i < this.items.length; i++) {
			this.items[i].isSelected = false;
		}
	}

	private getAllSelectedIndexes(arr, val): number[] {
		const indexes: number[] = [];
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].isSelected === val) {
				indexes.push(i);
			}
		}
		return indexes;
	}
}
