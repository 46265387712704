import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Constants, TEXT } from '../../../helpers';
import { Task } from '../../../interfaces';

@Component({
	selector: 'app-task-view-dialog',
	templateUrl: './task-view-dialog.component.html',
	styleUrls: ['./task-view-dialog.component.scss'],
})
export class TaskViewDialogComponent implements OnInit {
	@Input() public readonly task: Task;
	@Input() public readonly isEditDisabled: boolean;
	public readonly dateFormats = Constants.DATE_FORMATS;
	public readonly taskTypes = Constants.TASK_TYPES;
	public textConstants = TEXT;
	@Output() private toggleCompleteTask: EventEmitter<Task> = new EventEmitter<Task>();
	@Output() private followUpClick: EventEmitter<Task> = new EventEmitter<Task>();

	constructor() {}

	ngOnInit() {}

	toggleComplete() {
		this.toggleCompleteTask.emit(this.task);
	}

	onFollowUpClick() {
		this.followUpClick.emit(this.task);
	}
}
