import { Observable } from 'rxjs';

export interface SuccessRatesData {
	averageProjectTotalProfit: number;
	lostProjectsNetTotalChargeOut: number;
	lostProjectsTotalProfit: number;
	overallProjectWonRate: number;
	projectsSubmittedCount: number;
	projectsTotalProfit: number;
	projectsLostCount: number;
	projectsWonCount: number;
	strikeRate: number;
	submittedProjectsNetTotalChargeOut: number;
	submittedProjectsTotalProfit: number;
	wonProjectsNetTotalChargeOut: number;
	wonProjectsTotalProfit: number;
}

export interface Top5ClientsData {
	id: string;
	name: string;
	netTotalChargeOut: number;
	totalProfit: number;
}

export interface ProjectsStatusData {
	status: string;
	count: number;
}

export interface ProjectsBrandsData {
	brand: string;
	count: number;
	logo: string;
	logoObservable?: Observable<string>;
}

export interface ProjectsSchedulesData {
	clientName: string;
	clientWonLost: string;
	name: string;
	projectId: string;
	quoteStartDate: Date;
	quoteFinishDate: Date;
	reference: string;
	status: string;
	userDisplayPictureKey?: string;
	userName: string;
}

export interface WonAndSubmittedProjectProfitByMonthData {
	dates: Array<Date>;
	submittedProfit: Array<number>;
	wonProfit: Array<number>;
}
