import { TEXT } from '../../../../../../helpers';

export const TotalsText = {
	averageCostPerUnit: TEXT.averageCostPerUnit,
	averageRatePerUnit: TEXT.averageRatePerUnit,
	chargesCount: TEXT.chargesCount,
	totalCharge: TEXT.totalCharge,
	totalCost: TEXT.totalCost,
	totalDiscount: TEXT.totalDiscount,
	totalGPPercent: TEXT.totalGPPercent,
	totalLabourHours: TEXT.totalLabourHours,
	totalProfit: TEXT.totalProfit,
	totals: TEXT.totals,
	totalUnits: TEXT.totalUnits,
};
