import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { CoreModule } from '../../../../../app/core/core.module';
import { NavigationMenuOptionsModule } from '../components/navigation-menu-options/common/navigation-menu-options.module';
import { NavigationMenuComponent } from '../navigation-menu.component';

@NgModule({
	declarations: [NavigationMenuComponent],
	exports: [NavigationMenuComponent],
	imports: [CommonModule, MatTooltipModule, NavigationMenuOptionsModule, PopoverModule.forRoot(), RouterModule, TooltipModule, CoreModule],
	providers: [],
})
export class NavigationMenuModule {}
