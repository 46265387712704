// Framework imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// 3rd party imports
// @itc-Core imports
// project specific imports
import { NavigationMenuOptionsComponent } from '../navigation-menu-options.component';

@NgModule({
	declarations: [NavigationMenuOptionsComponent],
	exports: [NavigationMenuOptionsComponent],
	imports: [CommonModule, RouterModule],
	providers: [],
})
export class NavigationMenuOptionsModule {}
