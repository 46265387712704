import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';

export interface Brand {
	id?: string;
	name: string;
	isActive: boolean;
	logo?: string;
	imageUrl?: string; // after the request, the logo's url is resolved
}

export interface BrandResponse {
	status: boolean;
	brand: Brand;
}

export interface BrandsResponse {
	status: boolean;
	brands: Brand[];
}

export function GetBrandValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Logo',
			validators: [],
		},
	]);
}
