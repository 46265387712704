import { EmbeddedEstimatorUserTool } from '../../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.interfaces';
import { defaultBoqMapUserTool, layerViewTypes } from './measuring-tool.constants';
import { BoqsMeasuringToolTotals, BoqTool, MeasuringToolCoordinate, MeasuringToolMetresScale, MeasuringToolTask } from './measuring-tool.interfaces';
import { MeasuringToolLayer } from './models/measuring-tool-layer.model';

export class MeasuringToolPage {
	public boqs: string[] = []; // Boq Id array
	public boqTools: BoqTool[] = [];
	public boqToolsMap?: { [key: string]: EmbeddedEstimatorUserTool };
	public createdAt?: Date;
	public editedBy?: string;
	public id?: string;
	public isActive: boolean;
	public key: string;
	public layers: MeasuringToolLayer[];
	public layerViewType: string = layerViewTypes.all;
	public legendData: LegendData = new LegendData();
	public name?: string;
	public order: number;
	public projectId: string;
	public variationId?: string;
	public scaleData?: MeasuringToolMetresScale;
	public scaleLayer?: MeasuringToolLayer;
	public subLocation: string;
	public tasks: MeasuringToolTask[];
	public thumbnailKey: string;
	public thumbnailUrl?: string;
	public totalBoqsAreas?: BoqsMeasuringToolTotals;
	public totalCeilingArea: number;
	public totalPerimeter: number;
	public totalSurfaceArea: number;
	public totalWallsSurfaceArea: number;
	public updatedAt?: Date;
	public url?: string;

	constructor(json?: any) {
		if (json) {
			Object.assign(this, json);
		}
		if (this.layers && this.layers.length) {
			this.layers = this.layers.map((value: any) => new MeasuringToolLayer(value));
		}
		if (this.scaleLayer) {
			this.scaleLayer = new MeasuringToolLayer(this.scaleLayer);
		}
		if (this.boqTools && this.boqTools.length) {
			if (!this.boqToolsMap) {
				this.boqToolsMap = {};
			}
			for (const boqTool of this.boqTools) {
				this.boqToolsMap[boqTool.boqId] = boqTool.userTool;
			}
		}
		// If a boq has recently been added to a page, it will not have
		// a designated boqTool assigned. Check for any of these cases and
		// assign a default user tool.
		if (this.boqs && this.boqs.length) {
			for (const boq of this.boqs) {
				if (!this.boqToolsMap) {
					this.boqToolsMap = {};
				}
				if (!this.boqToolsMap[boq]) {
					this.boqTools.push({
						boqId: boq,
						userTool: defaultBoqMapUserTool,
					});
					this.boqToolsMap[boq] = defaultBoqMapUserTool;
				}
			}
		}
	}
}

export class LegendData {
	public legendDescriptionWidth: number;
	public legendPosition: MeasuringToolCoordinate;
	public legendSize: number;
	public showLegend: boolean;
	public showMeasurementCol: boolean = true;
}
