// Comment object
import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { UserModel } from '../models';
import { Attachment } from './attachment';

export interface Comment {
	id: string;
	message: string;
	sender: any;
	isInternal: boolean;
	updatedAt: Date;
}

export interface DialogComment {
	id: string;
	message: string;
	sender: UserModel;
	isInternal: boolean;
	updatedAt: Date;
}
// Response type given back when retrieving a comment
export interface CommentResponse {
	status: boolean;
	comment: Comment;
}

// Response type given back when retrieving a comment
export interface AttachmentResponse {
	status: boolean;
	attachment: Attachment;
}

// Response type given back when retrieving a list of comments
export interface CommentsResponse {
	status: boolean;
	comments: Comment[];
}

export interface CommentUpdate {
	id: string;
	isInternal: boolean;
	message: string;
	project: string;
	company: string;
	sender: string;
	updatedAt: Date;
}

export function GetProjectCommentValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Comment',
			validators: [Validators.required],
		},
	]);
}
