import { Pipe } from '@angular/core';

import { Project } from '../interfaces';

@Pipe({
	name: 'contactPerson',
})
export class ContactPersonPipe {
	transform(value: Array<Project>, contact: string): Array<Project> {
		//Guard
		if (!value || !contact || contact === '') {
			return value;
		}

		// filter by the contact names of the clients of a project.
		let filteredProjects: Array<Project> = value.filter(project => {
			let index = project.clients.findIndex(client => {
				return client.contact.name.match(new RegExp(contact, 'i')) !== null;
			});
			return index !== -1;
		});
		return filteredProjects;
	}
}
