import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Constants, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { Company, ContactClient, Email, GetExportEmailValidation, Project } from '../../../interfaces';
import { UserModel } from '../../../models';
import { EmailService } from '../../../services/email.service';

@Component({
	selector: 'app-export-email-dialog',
	templateUrl: './export-email-dialog.component.html',
	styleUrls: ['./export-email-dialog.component.css'],
})
export class ExportEmailDialogComponent implements OnInit {
	constructor(
		private emailService: EmailService,
		public snack: SnackBarHelperComponent,
		private activatedRoute: ActivatedRoute
	) {}

	@Input()
	public project: Project;
	@Input()
	public clients: Array<ContactClient>;

	public ccUsers: UserModel[] = [];
	public ccEmailsSelected: string[] = [];
	public client: Company;
	public emailPromise: Promise<void>;
	public replyToUser: UserModel;
	public users: UserModel[] = [];
	public validators;
	public email: Email;

	private currentUser: UserModel;

	public ngOnInit() {
		this.validators = GetExportEmailValidation();

		this.activatedRoute.data.subscribe(({ users, currentUser, project }) => {
			this.users = users;
			this.ccUsers = [...users, ...this.getClientCompanyContacts()];
			this.currentUser = currentUser;
		});

		this.replyToUser = this.currentUser;

		this.email = {
			body: undefined,
			subject: undefined,
			sentTo: undefined,
			replyTo: this.replyToUser.email,
			ccEmails: this.ccEmailsSelected,
		};
	}

	/**
	 * Updates the email object when user selects / removes from the multi dropdown
	 * @param {string} emails
	 */
	public onCCEmailChange(emails: string[]): void {
		if (emails.length > 0) {
			this.email.ccEmails = emails;
		} else {
			this.email.ccEmails = undefined;
		}
	}

	/**
	 * Autofills the subject and the message when a client is selected.
	 * @param client
	 */
	public onClientChange(client: ContactClient): void {
		if (client) {
			const user: UserModel = this.project.salesPerson;
			const userFullName: string = `${user.firstName} ${user.lastName}`;
			this.email.subject = Constants.getExportMailSubject(this.project.reference, this.project.name);
			this.email.body = Constants.getExportMailMessage(userFullName, user.position, user.phone);
			this.email.sentTo = client.email;
		} else {
			this.email.subject = '';
			this.email.body = '';
			this.email.sentTo = undefined;
		}
	}

	/**
	 * Updates replyTo field on email object
	 * @param {User} user
	 */
	public onReplyToUserChange(user: UserModel): void {
		if (user) {
			this.email.replyTo = user.email;
		} else {
			this.email.replyTo = undefined;
		}
	}

	/**
	 * Sends an email with the form data.
	 * @param {string} filesFolderKey Generated files/folder key
	 * @param {string} attachmentKey
	 * @param {string} projectId
	 */
	public async sendEmail(filesFolderKey: string, attachmentKey: string, projectId: string): Promise<any> {
		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return errors;
		} else {
			const htmlFormatted = this.email.body.replace(/(\r\n|\n|\r)/gm, '<br>');

			const body = {
				subject: this.email.subject,
				body: htmlFormatted,
				sentTo: this.email.sentTo,
				project: projectId,
				filesFolderKey: filesFolderKey,
				attachmentKey: attachmentKey,
				replyTo: this.email.replyTo,
				ccEmails: this.email.ccEmails,
			};
			this.emailPromise = this.emailService.sendExportEmail(body).toPromise();
			return this.emailPromise;
		}
	}

	// Custom search for email fields, the user items can be ContactClient or User type
	public dialogCustomSearch(term: string, user: ContactClient | UserModel) {
		term = term.toLocaleLowerCase();
		let name: string;
		if (user instanceof ContactClient) {
			name = user.contact.name.toLocaleLowerCase();
		} else {
			name = `${user.firstName} ${user.lastName}`.toLocaleLowerCase();
		}
		return name.indexOf(term) > -1 || user.email.toLocaleLowerCase().indexOf(term) > -1 || (name + ' - ' + user.email).toLocaleLowerCase().indexOf(term) > -1;
	}

	private getClientCompanyContacts(): Array<ContactClient | UserModel> {
		const contacts = [];
		this.project.clients.forEach(client => {
			contacts.push(...client.company.contacts);
		});
		return contacts;
	}
}
