import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CountResponse, ItemsResponse, Paginate, PostParams } from '../interfaces';
import { BaseModel } from '../models';

@Injectable()
export class BaseService<T extends BaseModel> {
	protected readonly modelObj: T;

	protected baseUrl = Constants.BASE_API_URL;

	constructor(
		protected httpClient: HttpClient,
		protected endPoint: string,
		protected modelType: new (json?) => T // private update: SwUpdate
	) {
		this.modelObj = new this.modelType();
	}

	/**
	 * Post to list action in endpoint and return array of modelType
	 * @param postObject
	 */
	public postList(postObject: any): Observable<Array<T>> {
		return this.httpClient.post<ItemsResponse>(`${this.baseUrl}/${this.endPoint}/list`, postObject).pipe(map(res => res.items.map(item => new this.modelType(item))));
	}

	/**
	 * Post to details action of endpoint and return an instance of modelType
	 * @param postObject
	 */
	public postDetails(postObject: any): Observable<T> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/details`, postObject).pipe(map((json: any) => new this.modelType(json.item)));
	}

	/**
	 * Post to create action of endpoint and return an instance of modelType
	 * @param postObject
	 */
	public postCreate(postObject: T): Observable<T> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/create`, postObject.getPostObject()).pipe(map((json: any) => new this.modelType(json.item)));
	}

	/**
	 * Post to edit action of endpoint and return an instance of modelType
	 * @param postObject
	 */
	public postEdit(postObject: T): Observable<T> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/edit`, postObject.getPostObject()).pipe(map((json: any) => new this.modelType(json.item)));
	}

	/**
	 * Post to paginate action of endpoint and return an instance of modelType
	 * @param paginate
	 */
	public postPaginate(paginate: Paginate): Observable<T[]> {
		return this.httpClient.post<ItemsResponse>(`${this.baseUrl}/${this.endPoint}/paginate`, paginate).pipe(map(res => res.items.map(item => new this.modelType(item))));
	}

	/**
	 * Post to order of the items list and return paginate items
	 * @param paginate
	 */
	public postOrder(paginate: Paginate): Observable<T[]> {
		return this.httpClient.post<ItemsResponse>(`${this.baseUrl}/${this.endPoint}/order`, paginate).pipe(map(res => res.items.map(item => new this.modelType(item))));
	}

	/**
	 * Post to pagin action of endpoint and return an instance of modelType
	 * @param {PostParams} params
	 * @returns {Observable<CountResponse>}
	 */
	public postCount(params?: PostParams) {
		return this.httpClient.post<CountResponse>(`${this.baseUrl}/${this.endPoint}/count`, { params }).pipe(map(res => res.total));
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param id
	 * @param isActive
	 */
	public postEditActive(id: string, isActive: boolean): Observable<T> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/edit-active`, { id, isActive }).pipe(map((json: any) => new this.modelType(json.item)));
	}

	/**
	 * Post to upload-file action of endpoint and return an instance of modelType
	 * @param formData FormData
	 */
	public postUploadFile(formData: FormData): Observable<T> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/upload-file`, formData).pipe(map((json: any) => new this.modelType(json.item)));
	}

	/**
	 * Post to bulk-activate action of endpoint
	 * @param items
	 */
	public postBulkActivate(items: Array<string>): Observable<void> {
		return this.httpClient.post<void>(`${Constants.BASE_API_URL}/${this.endPoint}/bulk-edit-active`, {
			active: true,
			items: items,
		});
	}

	/**
	 * Post to bulk-deactivate action of endpoint
	 * @param items
	 */
	public postBulkDeactivate(items: Array<string>): Observable<void> {
		return this.httpClient.post<void>(`${Constants.BASE_API_URL}/${this.endPoint}/bulk-edit-active`, {
			active: false,
			items: items,
		});
	}
}
