import { Constants } from '../../../../helpers';
import { TEXT } from '../../../../helpers/text.constants';
import { HeaderMappingItem } from './header.interfaces';

export const HeaderMappingItems: HeaderMappingItem[] = [
	{
		// Dashboard header
		routes: [Constants.ROUTE_LINKS.dashboard],
		displayText: '',
		showTitle: false,
	},
	{
		// Clients header
		routes: [Constants.ROUTE_LINKS.clients],
		displayText: TEXT.clients,
	},
	{
		// Library headers
		routes: [Constants.ROUTE_LINKS.admin, Constants.ROUTE_LINKS.libraries],
		displayText: TEXT.library,
	},
	{
		// Project header
		routes: [Constants.ROUTE_LINKS.project],
		displayText: TEXT.project,
	},
	{
		// Project search header
		routes: [Constants.ROUTE_LINKS.newProjects, Constants.ROUTE_LINKS.projectSearch],
		displayText: '',
		showTitle: false,
	},
	{
		// User profile header
		routes: [Constants.ROUTE_LINKS.user],
		displayText: TEXT.yourProfile,
	},
];

export const HeaderText = {
	editedBy: TEXT.editedBy,
	LastSaved: TEXT.lastSaved,
	measuringTool: TEXT.measuringToolUrl,
	overview: TEXT.overview,
};
