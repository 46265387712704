import { Injectable } from '@angular/core';
import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable({
	providedIn: 'root',
})
export class EmployeeGuard {
	constructor(private permissionsService: RolePermissionsService) {}

	public canActivate() {
		this.permissionsService.isEmployeeAndAbove()
	}
}
