export enum NavigationMenuIcons {
	add = './assets/icons/add.svg',
	clients = './assets/icons/silhouettes.svg',
	dashboard = './assets/icons/dashboard.svg',
	database = './assets/icons/database.svg',
	calendar = './assets/icons/calendar.svg',
	filledFolder = './assets/icons/filled-folder.svg',
	invoices = './assets/icons/invoices.svg',
	home = './assets/icons/home.svg',
	library = './assets/icons/bookshelf.svg',
	projects = './assets/icons/folder.svg',
	reporting = './assets/icons/project-description.svg',
	subscription = './assets/icons/bookshelf.svg', // TODO source icon
}
