import { UserModel } from '../models';

export class NgSelectCustomFunctionHelper {
	/**
	 * Custom ng select search function for user's name fields.
	 * Apply this to [searchFn] option.
	 * This matches any substring user's first and last name as displayed.
	 * @param searchTerm {string} - The search query
	 * @param user {UserModel} - The user to compare against
	 */
	public static userNameSearch(searchTerm: string, user: UserModel): boolean {
		// Convert term to lowercase for comparison
		searchTerm = searchTerm.toLocaleLowerCase();

		// Build full name and convert to lowercase for comparison
		const userName = (user.firstName + ' ' + user.lastName).toLocaleLowerCase();

		return userName.indexOf(searchTerm) > -1;
	}

	/**
	 * Custom ng select search function for user's name and email fields.
	 * Apply this to [searchFn] option.
	 * @param searchTerm {string} - The search query
	 * @param user {UserModel} - The user to compare against
	 */
	public static userNameEmailSearch(searchTerm: string, user: UserModel): boolean {
		// Convert term and user's email to lowercase for comparison
		searchTerm = searchTerm.toLocaleLowerCase();
		const userEmail = user.email.toLocaleLowerCase();
		const emailMatch = userEmail.indexOf(searchTerm) > -1;

		// Return true if either email or name search passes
		return emailMatch || this.userNameSearch(searchTerm, user);
	}
}
