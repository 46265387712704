import { ReferenceCode } from '../interfaces';

export class BoqDimensionsModel {
	public profile: ReferenceCode;
	public units: number = 0;
	public unitType: string;

	constructor(json?: any) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
