import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import { AttachmentsTabTreeNode } from '../../../pages/project/project-details/attachments-tab/common/attachments-tab.interface';

@Component({
	selector: 'paint-collapsable-list',
	templateUrl: './collapsable-list.component.html',
	styleUrls: ['./collapsable-list.component.scss'],
})
export class CollapsableListComponent implements OnInit {
	public isCollapsed: boolean = true;

	@Input()
	public collapsableItem: AttachmentsTabTreeNode;
	@Input()
	public rowItems: TemplateRef<any>;
	@Input()
	public rowHeading: string;
	public isGrandChild = false;

	public ngOnInit(): void {
		this.isCollapsed = !this.collapsableItem.isOpen;
	}
}
