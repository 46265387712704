import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable()
//TODO REMOVE THIS SERVICE
export class FileUploadService {
	private cancelSubject = new Subject<Array<string>>();
	public cancelObs: Observable<Array<string>> = this.cancelSubject.asObservable();

	constructor() {}

	/**
	 * Signals the file uploader to cancel the upload.
	 */
	public cancelUpload(): void {
		this.cancelSubject.next([]);
	}
}
