// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-radius-25px {
  border-radius: 25px;
}

.project-status-container {
  margin-bottom: 2rem;
}

.progress-circle {
  width: 13px;
  height: 13px;
}

.status-line {
  border-top: 3px solid gray;
  margin-top: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/project/project-status-bar/project-status-bar.component.scss"],"names":[],"mappings":"AAGA;EACC,mBAAA;AAFD;;AAKA;EAEC,mBAAA;AAHD;;AAMA;EAEC,WAba;EAcb,YAda;AAUd;;AAOA;EACC,0BAAA;EACA,iBAAA;AAJD","sourcesContent":["$bar-colour: gray;\n$circle-size: 13px;\n\n.border-radius-25px {\n\tborder-radius: 25px;\n}\n\n.project-status-container {\n\t// Counteract the negative margin from the hr / line element\n\tmargin-bottom: 2rem;\n}\n\n.progress-circle {\n\t//background: $bar-colour;\n\twidth: $circle-size;\n\theight: $circle-size;\n}\n\n.status-line {\n\tborder-top: 3px solid $bar-colour;\n\tmargin-top: -20px; // Display behind the progress circles\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
