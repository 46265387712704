import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NavigationWarningService } from '../../../../@itc-core/components/navigation-warning/common/navigation-warning.service';
import { InputNumberConfig } from '../../../core/components/display-input-number/common/display-input-number.models';
import { Constants, measurementTypes, SnackBarHelperComponent } from '../../../helpers';
import { Product, ReferenceCode } from '../../../interfaces';
import { BrandModel, CoatingSystemModel, ColourModel } from '../../../models';
import { DrawingToolType, LineStyles, measuringToolUnits } from '../../../pages/measure-tool/common/measuring-tool.constants';
import { MeasuringToolUnit } from '../../../pages/measure-tool/common/measuring-tool.interfaces';
import { BrandService } from '../../../services/brand.service';
import { CoatingSystemService } from '../../../services/coating-system.service';
import { ProductService } from '../../../services/product.service';
import { RefCodeService } from '../../../services/reference-code.service';
import { CalculationTypes, presetColours, presetColoursLabel, TextConstants, UserToolTypes } from './common/estimator-user-tool-form.constants';
import { EmbeddedEstimatorUserTool } from './common/estimator-user-tool-form.interfaces';
import { EstimatorUserTool, GetUserToolFormValidation } from './common/estimator-user-tool-form.models';

@Component({
	selector: 'paint-estimator-user-tool-form',
	templateUrl: './estimator-user-tool-form.component.html',
	styleUrls: ['./estimator-user-tool-form.component.scss'],
})
export class EstimatorUserToolFormComponent implements OnInit {
	@Input()
	public set userTool(userTool: EmbeddedEstimatorUserTool | EstimatorUserTool) {
		this._userTool = userTool;
		// Clear filters when new tool is set
		this.productIdFilter = undefined;
		this.substrateIdFilter = undefined;
		if (userTool) {
			this.surfaceAreaColour = this.userTool.canvasStyleDetails.fillColour;
			this.setProfiles();
			this.setValidators();
		}
	}

	public get userTool(): EmbeddedEstimatorUserTool | EstimatorUserTool {
		return this._userTool;
	}

	@Input()
	public displayUnits: MeasuringToolUnit = measuringToolUnits.metres;
	@Input()
	public isLayersMenu: boolean = false;
	@Input()
	public showBoqDetailsForm: boolean = true;
	@Input()
	public isBoqTool: boolean = false;
	@Input()
	public userToolTypesArray: string[] = Object.values(UserToolTypes);

	@Output()
	public saveToUserToolsLibraryClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	public valueChanged: EventEmitter<void> = new EventEmitter<void>();

	public brandIdFilter: string;
	public brands: BrandModel[] = [];
	public brandsObservable: Observable<BrandModel[]>;
	public calculationTypes: typeof CalculationTypes = CalculationTypes;
	public calculationTypesArray: string[] = Object.values(CalculationTypes);
	public coatingSystems: CoatingSystemModel[];
	public coatingSystemObservable: Observable<CoatingSystemModel[]>;
	public drawingToolTypes: typeof DrawingToolType = DrawingToolType;
	public environments: Array<string> = Constants.ENVIRONMENTS;
	public isCalculationTypeParameter: boolean;
	public lineStyle = LineStyles.solid;
	public lineStyles: string[] = Object.values(LineStyles);
	public measuringToolUnits: typeof measuringToolUnits = measuringToolUnits;
	public presetColours: typeof presetColours = presetColours;
	public presetColoursLabel: string = presetColoursLabel;
	public productIdFilter: string;
	public products: Product[] = [];
	public productsObservable: Observable<Product[]>;
	public profiles: ReferenceCode[] = [];
	public profilesObservable: Observable<ReferenceCode[]>;
	public ratePerUnitInputNumberConfig: InputNumberConfig;
	public substrateIdFilter: string;
	public substrates: ReferenceCode[] = [];
	public substratesObservable: Observable<ReferenceCode[]>;
	public surfaceAreaColour: string;
	public textConstants: typeof TextConstants = TextConstants;
	public userToolTypes: typeof UserToolTypes = UserToolTypes;
	public validators;

	private _userTool: EmbeddedEstimatorUserTool | EstimatorUserTool;

	constructor(
		private brandService: BrandService,
		private coatingSystemService: CoatingSystemService,
		private navWarningService: NavigationWarningService,
		private productService: ProductService,
		private referenceCodeService: RefCodeService,
		private snack: SnackBarHelperComponent
	) {}

	public ngOnInit(): void {
		this.setValidators();
		this.setDropdownData();
		this.setInputNumberConfigs();
	}

	public brandFilterChanged(): void {
		this.productIdFilter = undefined;
		this.setFilteredProducts();
		this.setFilteredCoatingSystems();
	}

	public calculationTypeChanged(): void {
		this.setProfiles();
		this.setValidators();
		this.valueChangeRegistered();
	}

	/**
	 * Saves the colour field to revert to if we swap back from the perimeter calculation type
	 */
	public colourChanged(): void {
		this.surfaceAreaColour = this.userTool.canvasStyleDetails.fillColour;
		this.valueChangeRegistered();
	}

	/**
	 * Save an existing entity tool to the user tools library
	 */
	public saveToUserToolsLibrary(): void {
		this.saveToUserToolsLibraryClicked.emit();
	}

	public setFilteredCoatingSystems(): void {
		const query: any = {
			isActive: true,
		};
		if (this.brandIdFilter) {
			query.brand = this.brandIdFilter;
		}
		if (this.productIdFilter) {
			query.product = this.productIdFilter;
		}
		if (this.substrateIdFilter) {
			query.substrate = this.substrateIdFilter;
		}
		this.coatingSystemObservable = this.coatingSystemService.postList(query).pipe(
			tap(coatingSystems => {
				this.coatingSystems = coatingSystems;
			})
		);
	}

	public setFilteredProducts(): void {
		const query: any = {
			isActive: true,
		};
		if (this.brandIdFilter) {
			query.brand = this.brandIdFilter;
		}

		this.productsObservable = this.productService.postList(query).pipe(
			tap(products => {
				this.products = products;
			})
		);
	}

	public setValidators(): void {
		this.validators = GetUserToolFormValidation(this.userTool);
	}

	/**
	 * Resets stroke weight to 1 if 0 was set
	 */
	public strokeWeightChanged(): void {
		if (this.userTool.canvasStyleDetails.strokeWeight < 1) {
			this.userTool.canvasStyleDetails.strokeWeight = 1;
			this.snack.snackError(this.textConstants.lineWidthError);
		}
		this.valueChangeRegistered();
	}

	public updateColour(colour: ColourModel): void {
		if (!colour) {
			// Prevent null value being set
			this.userTool['boqDetails'].colour = undefined;
		} else {
			this.userTool['boqDetails'].colour = colour;
		}
	}

	public userToolTypeChanged(): void {
		this.setValidators();
		this.setProfiles();
		this.valueChangeRegistered();
	}

	/**
	 * Emits the changes made in the boq tool tab placed in the left side.
	 */
	public valueChangeRegistered(): void {
		this.valueChanged.emit();
		this.navWarningService.setStatus(true);
	}

	private setDropdownData(): void {
		this.setFilteredCoatingSystems();
		this.setFilteredProducts();

		this.brandsObservable = this.brandService.postList({ isActive: true }).pipe(
			tap((brands: BrandModel[]) => {
				this.brands = brands;
			})
		);

		this.substratesObservable = this.referenceCodeService
			.postList({
				isActive: true,
				codeType: [Constants.REFERENCE_CODE_TYPES.substrate.key],
			})
			.pipe(
				tap(substrates => {
					this.substrates = substrates;
				})
			);
	}

	private setInputNumberConfigs(): void {
		this.ratePerUnitInputNumberConfig = new InputNumberConfig({
			min: 1,
		});
	}

	/**
	 * Sets the items in the profiles dropdown based on tool type selected
	 * @private
	 */
	private setProfiles(): void {
		let query;
		if (this.userTool.userToolType === this.userToolTypes.item) {
			query = {
				isActive: true,
				codeType: [Constants.REFERENCE_CODE_TYPES.profile.key],
				code2: measurementTypes.item,
			};
		} else if (this.userTool.userToolType === this.userToolTypes.measure && this.userTool.calculationType === this.calculationTypes.linealMetre) {
			query = {
				isActive: true,
				codeType: [Constants.REFERENCE_CODE_TYPES.profile.key],
				code2: measurementTypes.linealMetre,
			};
		}

		if (query) {
			this.profilesObservable = this.referenceCodeService.postList(query).pipe(
				tap(profiles => {
					this.profiles = profiles;
				})
			);
		} else {
			this.profiles = [];
		}
	}

	/**
	 * Show/hide boq information of a selected user tool
	 */
	public showBoqDetails(): void {
		this.showBoqDetailsForm = !this.showBoqDetailsForm;
	}
}
