import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ItemsResponseData, Project } from '../../../interfaces';
import { ProjectModel } from '../../../models';
import { DocumentGeneratorService } from '../../../services/document-generator.service';
import { SlideOverService } from '../../../services/slide-over.service';
import { InvoiceHttpService, InvoicePaginateResponse } from '../../project/invoice/common/invoice.http.service';
import { InvoiceModel } from '../../project/invoice/common/invoice.models';

@Injectable()
export class InvoicesPageService {
	// Cache filters used in the invoices table
	public filters: {
		page?: number;
		skip?: number;
		limit?: number;
		invoiceNo?: number;
		clientIds?: string[];
		dateFrom?: Date;
		dateTo?: Date;
		projectId?: string;
		status?: string[];
	} = { page: 0, limit: 25, invoiceNo: undefined, clientIds: [], dateFrom: undefined, dateTo: undefined, projectId: undefined, status: [] };

	public invoices: BehaviorSubject<InvoicePaginateResponse> = new BehaviorSubject<InvoicePaginateResponse>({
		items: [],
		total: 0,
		status: true,
		claimed: 0,
		projectValue: 0,
	});

	public projects: BehaviorSubject<ItemsResponseData<ProjectModel>> = new BehaviorSubject<ItemsResponseData<ProjectModel>>({
		items: [],
		total: 0,
		status: true,
	});

	constructor(
		private invoiceHttpService: InvoiceHttpService,
		private documentGeneratorService: DocumentGeneratorService,
		private slideOverService: SlideOverService
	) {}

	public paginate(skip: number, limit: number, invoiceNo?: number, clientIds?: string[], dateFrom?: Date, dateTo?: Date, projectId?: string, status?: string[]) {
		return this.invoiceHttpService.postPaginate(skip, limit, invoiceNo, clientIds, dateFrom, dateTo, projectId, status).pipe(
			tap(val => {
				val.items = val.items.map(i => new InvoiceModel(i));
				this.invoices.next(val);
			})
		);
	}

	public paginateWithCachedFilters() {
		return this.paginate(
			this.filters.page,
			this.filters.limit,
			this.filters.invoiceNo,
			this.filters.clientIds,
			this.filters.dateFrom,
			this.filters.dateTo,
			this.filters.projectId,
			this.filters.status
		);
	}

	public newInvoicePaginate(skip: number, limit: number, projectRef?: number, clientIds?: string[]) {
		return this.invoiceHttpService.postPaginateLiveProjects(skip, limit, projectRef, clientIds).pipe(
			tap(val => {
				this.projects.next(val);
			})
		);
	}
}
