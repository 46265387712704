import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { TierAccessService } from '../../../../app/services/tier-access.service';
import { NavigationWarningService } from './navigation-warning.service';

@Injectable()
export class UserCountGuard {
	constructor(private tierAccessService: TierAccessService) {}

	public canDeactivate(): Observable<boolean> {
		return of(this.tierAccessService.canCreateMoreUsers());
	}
}
