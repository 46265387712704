import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { LabourRate, ReferenceCode, State } from '../interfaces';
import { BaseModel } from './base.model';

export class LabourRateModel extends BaseModel implements LabourRate {
	// Properties
	public afterHoursRateEba: number;
	public afterHoursRateNonEba: number;
	public documentType: string;
	public isActive: boolean = true;
	public labourType: ReferenceCode;
	public rateEBA: number;
	public rateNonEBA: number;
	public state: State;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({
			labourType: new UntypedFormControl(this.labourType, [Validators.required]),
			rateEBA: new UntypedFormControl(this.rateEBA, [Validators.required]),
			rateNonEBA: new UntypedFormControl(this.rateNonEBA, [Validators.required]),
			state: new UntypedFormControl(this.state, [Validators.required]),
		});
	}

	getFormData(): FormData {
		return undefined;
	}
}

export function GetRateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'State',
			validators: [Validators.required],
		},
		{
			name: 'LabourType',
			validators: [Validators.required],
		},
		{
			name: 'RateEBA',
			validators: [Validators.required, Validators.min(1)],
		},
		{
			name: 'RateNonEBA',
			validators: [Validators.required, Validators.min(1)],
		},
		{
			name: 'AfterHoursRateEba',
			validators: [Validators.required, Validators.min(1)],
		},
		{
			name: 'AfterHoursRateNonEba',
			validators: [Validators.required, Validators.min(1)],
		},
	]);
}
