// tslint:disable:quotemark
export enum Text {
	access = 'access',
	active = 'active',
	alerts = 'alerts',
	all = 'all',
	averageProfit = 'average profit',
	brands = 'brands',
	cancel = 'cancel',
	clients = 'clients',
	coatingSystems = 'coating systems',
	colours = 'colours',
	companies = 'companies',
	competitors = 'competitors',
	completed = 'completed',
	confirm = 'confirm',
	createANewClient = 'create a new client',
	creatingAnAverage = 'creating an average',
	dashboard = 'dashboard',
	demoProjectId = 'HSK5S4',
	filesFolders = 'files folders',
	help = 'help',
	importData = 'import data',
	inactive = 'inactive',
	inclusionsExclusions = 'inclusions exclusions',
	incomplete = 'incomplete',
	labour = 'labour',
	labourRates = 'labour rates',
	leaveThePage = 'leave without saving',
	leaveWithoutSaving = 'do you want to leave without saving?',
	library = 'library',
	logOut = 'log out',
	loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ' +
		'labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ' +
		'ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum ' +
		'dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia ' +
		'deserunt mollit anim id est laborum.',
	material = 'material',
	noResults = 'no results',
	other = 'other',
	profit = 'profit',
	products = 'products',
	profitOf = 'profit of',
	profitLost = 'profit lost',
	profitSubmitted = 'profit submitted',
	profitWon = 'profit won',
	project = 'project',
	projectBudget = 'project budget',
	projects = 'projects',
	projectsLost = 'projects lost',
	projectsSubmitted = 'projects submitted',
	projectsWon = 'projects won',
	revenue = 'revenue',
	reports = 'reports',
	scheduleMeeting = 'schedule meeting',
	settings = 'settings',
	stayOnThisPage = 'stay on this page',
	saveAndLeave = 'save and leave',
	strikeRateNumber = 'strike rate number',
	strikeRateAmount = 'strike rate amount',
	subscription = 'subscription',
	summary = 'summary',
	suppliers = 'suppliers',
	support = 'support',
	target = 'target',
	tasks = 'tasks',
	tenAM = '10:00am',
	testButton = 'test button',
	titleGoesHere = 'title goes here',
	totalProfit = 'total profit',
	marketingItems = 'marketing items',
	turnOffNavWarning = 'turn off nav warning',
	turnOnNavWarning = 'turn on nav warning',
	unknown = 'unknown',
	userGroups = 'user groups',
	userProfile = 'user profile',
	users = 'users',
	createProjectError = 'Please complete the missing fields before saving.',
}
