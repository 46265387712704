// Framework imports
import { Injectable } from '@angular/core';

// 3rd party imports

// @itc-Core imports

// project specific imports
@Injectable({
	providedIn: 'root',
})
export class ChargesService {
	private _subGroupsExpanded: string[];
	private _currentChargeId: string;

	// tslint:disable-next-line:no-empty
	constructor() {}

	public get currentChargeId(): string {
		return this._currentChargeId;
	}

	public get subGroupsExpanded(): string[] {
		return this._subGroupsExpanded;
	}

	public set currentChargeId(id: string) {
		this._currentChargeId = id;
	}

	public set subGroupsExpanded(values: string[]) {
		this._subGroupsExpanded = values;
	}

	/**
	 * Sets the state of the service.
	 * @param {string[]} subGroupArray
	 * @param {string} id
	 */
	public setState(subGroupArray: string[], id: string): void {
		this.currentChargeId = id;
		this.subGroupsExpanded = subGroupArray;
	}
}
