import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';
import { AddressDetail } from './address-detail';
import { ContactDetail } from './contact-detail';

// Interface

export interface Competitor {
	id: string;
	name: string;
	isActive: boolean;
	addressDetail: AddressDetail;
	contactDetail: ContactDetail;
}

// Responses

export interface CompetitorsResponse {
	status: boolean;
	competitors?: Competitor[];
}

export interface CompetitorResponse {
	status: boolean;
	competitor?: Competitor;
}

// Validators

export function GetCompetitorValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Email',
			validators: [Validators.email],
		},
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'State',
			validators: [Validators.required],
		},
	]);
}
