import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';

import { interval, Subscription } from 'rxjs';

@Component({
	selector: 'app-loader',
	templateUrl: './app-loader.component.html',
	styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent {
	public loader: Subscription | undefined;

	constructor(private router: Router) {
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loader = interval(50).subscribe(() => {});
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.removeLoader();
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	private removeLoader() {
		this.loader.unsubscribe();
		this.loader = undefined;
	}
}
