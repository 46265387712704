export interface ItemsResponseData<T> {
	items: T[];
	status: boolean;
	total?: number;
}

export interface ItemResponseData<T> {
	item: T;
	status: boolean;
}
