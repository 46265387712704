import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectSearchDoughnutChartComponent } from './project-search-doughnut-chart.component';

@NgModule({
	exports: [ProjectSearchDoughnutChartComponent],
	providers: [CurrencyPipe],
	declarations: [ProjectSearchDoughnutChartComponent],
})
export class ProjectSearchDoughnutChartModule {}
