import { UntypedFormGroup } from '@angular/forms';

import { IncExcCommentTemplate } from '../interfaces';
import { BaseModel } from './base.model';

export class InclusionExclusionTemplateModel extends BaseModel implements IncExcCommentTemplate {
	// Properties
	company: string;
	isActive: boolean;
	message: string;
	title: string;
	isInclusion: boolean;
	order: number;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
