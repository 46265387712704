import { Product } from './product';

export class EmbeddedCoatProduct {
	public applicationMethod: string;
	public applicationRate?: number;
	public isSelected?: boolean;
	public percentage: number;
	public product: Product;
	public spreadRate?: number;
	public targetRate?: number;
}
