import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';

export interface ProjectTime {
	id?: string;
	user: any;
	project: string;
	startDate: Date;
	finishDate?: Date;
	isManual?: boolean;
}

export interface ProjectTimeData {
	_id: string;
	clientName?: string;
	projectName: string;
	projectTotalHr: number;
	status: string;
}

// Response type given back when retrieving a projectTime
export interface ProjectTimeResponse {
	status: boolean;
	projectTime: ProjectTime;
}

// Response type given back when retrieving a list of projectTimes
export interface ProjectTimesResponse {
	status: boolean;
	projectTimes: ProjectTime[];
}

export function GetProjectTimeValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'StartDate',
			validators: [Validators.required],
		},
		{
			name: 'Duration',
			validators: [Validators.required, Validators.min(0.1), Validators.max(9999999)],
		},
	]);
}
