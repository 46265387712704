import { UntypedFormGroup } from '@angular/forms';

import { Product, ProductApplicationMethod, ProductSize, ReferenceCode } from '../interfaces';
import { BaseModel } from './base.model';

export class ProductModel extends BaseModel implements Product {
	// Properties
	public applicationMethods: ProductApplicationMethod[];
	public base: string;
	public brand: string;
	public displayPictureUrl: string;
	public documentType: string;
	public entity;
	public finish: ReferenceCode;
	public imageUrl: string;
	public isActive: boolean;
	public msdsSheetKey: string;
	public msdsSheetUrl: string;
	public pictureKey: string;
	public pictureUrl: string;
	public productCode: string;
	public productGroup: ReferenceCode;
	public productType: ReferenceCode;
	public quality: ReferenceCode;
	public sizes: ProductSize[] = [];
	public specSheetKey: string;
	public specSheetUrl: string;
	public title: string;
	public vocLevel: string;

	//non db property
	public isSelected?: boolean;
	public msdsSheetFile: File;
	public pictureFile: File;
	public specSheetFile: File;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		if (this.msdsSheetFile || this.pictureFile || this.specSheetFile) {
			let formData = new FormData();

			if (this.msdsSheetFile) {
				formData.append('msdsSheet', this.msdsSheetFile);
			}
			if (this.specSheetFile) {
				formData.append('specSheet', this.specSheetFile);
			}
			if (this.pictureFile) {
				formData.append('picture', this.pictureFile);
			}

			return formData;
		}
		return undefined;
	}
}
