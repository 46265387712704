import {Injectable} from "@angular/core";
import {PopoverComponent} from "../../@mos-core/components/popover/popover.component";

@Injectable({ providedIn: 'root' })
export class PopoverService {
    private openPopover: PopoverComponent | null = null;

    public register(openPopover: PopoverComponent) {
        if (this.openPopover && this.openPopover !== openPopover) {
            this.openPopover.close();
        }
        this.openPopover = openPopover;
    }

    public unregister(popover: PopoverComponent) {
        if (this.openPopover === popover) {
            this.openPopover = null;
        }
    }
}