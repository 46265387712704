export interface PermissionsActions {
	admin: Permissions;
	contracts: Permissions;
	field: Permissions;
	operations: Permissions;
	superAdmin: Permissions;
	teamLeader: Permissions;
	trainee: Permissions;
	user: Permissions;
	viewer: Permissions;
}

export interface Permissions {
	client?: ClientActions;
	contact?: ContactActions;
	export?: ExportActions;
	library?: LibraryActions;
	project?: ProjectActions;
	settings?: SettingsActions;
}

export interface ClientActions {
	edit: boolean;
	view: boolean;
}

export interface ContactActions {
	assign: boolean;
	create: boolean;
	delete: boolean;
	edit: boolean;
	view: boolean;
}

export interface ProjectActions {
	addVariations: boolean;
	approvals: boolean;
	assign: boolean;
	assignTask: boolean;
	create: boolean;
	createCharges: boolean;
	createChargesRelated: boolean;
	edit: boolean;
	editRelated: boolean;
	view: boolean;
	viewRelated: boolean;
}

export interface ExportActions {
	access: boolean;
	create: boolean;
	share: boolean;
	view: boolean;
}

export interface LibraryActions {
	addRemoveBrands: boolean;
	addRemoveCoatingSystem: boolean;
	addRemoveColour: boolean;
	addRemoveCompetitor: boolean;
	addRemoveFiles: boolean;
	addRemoveIncExc: boolean;
	addRemoveLabourRates: boolean;
	addRemoveProduct: boolean;
	addRemoveSuppliers: boolean;
	addRemoveMarketing: boolean;
	editBrands: boolean;
	editCoatingSystem: boolean;
	editColour: boolean;
	editIncExc: boolean;
	editLabourRates: boolean;
	editProduct: boolean;
	editSuppliers: boolean;
	view: boolean;
}

export interface SettingsActions {
	editCompanies: boolean;
	editRefCodes: boolean;
	editRoles: boolean;
	editStates: boolean;
	editUsers: boolean;
	importData: boolean;
	view: boolean;
}
