import { Text } from '../../../../../../../@itc-core/common/text.constants';

export const HeaderMenuText = {
	alerts: Text.alerts,
	help: Text.help,
	logOut: Text.logOut,
	settings: Text.settings,
	subscription: Text.subscription,
	userProfile: Text.userProfile,
};
