/**
 * Created by Mohammad Karimi from In The Code on 26/3/18.
 */

export class ObjectHelper {
	/**
	 * helper that check if the object has the
	 * @param {Object} map
	 * @returns {boolean}
	 */
	public static isEmpty(map: Object) {
		for (const key in map) {
			return !map.hasOwnProperty(key);
		}
		return true;
	}

	/**
	 * Gets the field value of an object following the given path with this format : object.subPath1.subPath2
	 * If it doesn't find the field, then return null.
	 * @param object
	 * @param path
	 * @return string
	 */
	public static getFieldWithPath = (object: Object, path: string): string => {
		const subPaths: string[] = path.split('.');
		if (!subPaths || subPaths.length < 1) {
			return '';
		}
		let lastField: Object = object;
		subPaths.forEach(subPath => (lastField = lastField[subPath] !== undefined && lastField[subPath] !== null ? lastField[subPath] : ''));
		return lastField.toString();
	};
}
