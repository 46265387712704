import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ItemResponseData, ItemsResponseData, Project } from '../interfaces';
import { ProjectModel } from '../models';
import { VCProjectInterface } from '../pages/project/version-control/common/version-control.interfaces';
import { VCProjectModel } from '../pages/project/version-control/common/version-control.models';

@Injectable()
export class VCProjectService {
	constructor(private http: HttpClient) {}

	/**
	 * Creates a vcProject
	 */
	public postCreate(projectId: string, message: string): Observable<VCProjectModel[]> {
		return this.http.post<ItemsResponseData<VCProjectInterface>>(`${Constants.BASE_API_URL}/version-control/create`, { projectId, message }).pipe(
			map(res => {
				return res.items.map(item => {
					return new VCProjectModel(item);
				});
			})
		);
	}

	/**
	 * Edits the active state the given vcProject
	 */
	public postEditActive(vcProject: VCProjectModel, isActive: boolean): Observable<VCProjectModel[]> {
		return this.http
			.post<ItemsResponseData<VCProjectInterface>>(`${Constants.BASE_API_URL}/version-control/edit-active`, {
				id: vcProject.id,
				isActive,
				projectId: vcProject.originalProjectId,
			})
			.pipe(
				map(res => {
					return res.items.map(item => {
						return new VCProjectModel(item);
					});
				})
			);
	}

	/**
	 * Return the list of VCProjects for the given project
	 */
	public postList(projectId: string, showInactive: boolean = false): Observable<VCProjectModel[]> {
		return this.http.post<ItemsResponseData<VCProjectInterface>>(`${Constants.BASE_API_URL}/version-control/list`, { projectId, showInactive }).pipe(
			map(res => {
				return res.items.map(item => {
					return new VCProjectModel(item);
				});
			})
		);
	}

	/**
	 * Restores a project to the state it had in a given VCProject
	 */
	public postRestoreFromVersion(vcProject: VCProjectModel): Observable<ProjectModel> {
		return this.http
			.post<ItemResponseData<Project>>(`${Constants.BASE_API_URL}/version-control/restore`, {
				vcProjectId: vcProject.id,
				projectId: vcProject.originalProjectId,
			})
			.pipe(
				map(res => {
					return new ProjectModel(res.item);
				})
			);
	}
}
