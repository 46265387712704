// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-loader-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: 1040;
}
.app-loader-container ::ng-deep .ng-busy-backdrop {
  pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/app-loader/app-loader.component.scss"],"names":[],"mappings":"AAEA;EACC,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,oBAAA;EACA,aAAA;AADD;AAGC;EACC,mBAAA;AADF","sourcesContent":["//@import '~bootstrap/scss/bootstrap-grid';\n\n.app-loader-container {\n\tposition: fixed;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tpointer-events: none;\n\tz-index: 1040;\n\n\t::ng-deep .ng-busy-backdrop {\n\t\tpointer-events: all;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
