import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EstimatorUserTool } from '../../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.models';
import { Constants } from '../../../helpers';
import { BaseService } from '../../../services/base.service';
import { EstimatorToolResponse } from './estimator-user-tool-library.interfaces';

@Injectable({
	providedIn: 'root',
})
export class EstimatorUserToolLibraryService extends BaseService<EstimatorUserTool> {
	constructor(private http: HttpClient) {
		super(http, Constants.END_POINTS.userTools, EstimatorUserTool);
	}

	/**
	 * Update Estimator User Tool usageCount
	 * @param userToolId
	 */
	public incrementUsageCount(userToolId: string): Observable<boolean> {
		return this.http.post<EstimatorToolResponse>(`${Constants.BASE_API_URL}/estimator-user-tool/increment-usage-count`, { userToolId }).pipe(map(res => res.status));
	}
}
