import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

@Injectable()
export class BlockRootAccessGuard {
	private disallowedRootPaths: string[] = ['admin'];

	constructor() {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const url: string[] = state.url.split('/');
		return of(url.length > 2 || (url.length === 2 && !this.disallowedRootPaths.includes(url[1])));
	}

	public canDeactivate(
		component: boolean,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url: string[] = nextState.url.split('/');
		return of(!(url.length === 2 && this.disallowedRootPaths.includes(url[1])));
	}
}
