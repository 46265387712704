import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
	transition('closed => opened', [animate('250ms', keyframes([style({ opacity: '0' }), style({ opacity: '1' }), style({ opacity: '1' })]))]),
	transition('opened => closed', [animate('250ms', keyframes([style({ opacity: '0' }), style({ opacity: '0' }), style({ opacity: '1' })]))]),
]);

export const slideInOut = trigger('slideInOut', [
	state(
		'closed',
		style({
			width: '0',
			opacity: '0.5',
		})
	),
	state(
		'opened',
		style({
			transform: '100%',
			opacity: '1',
		})
	),
	transition('closed <=> opened', [animate('300ms ease-out')]),
]);
