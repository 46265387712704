// Framework imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ItcColouredDotDirective } from '../itc-coloured-dot.component';

@NgModule({
	declarations: [ItcColouredDotDirective],
	exports: [ItcColouredDotDirective],
	imports: [CommonModule],
	providers: [],
})
export class ItcColouredDotModule {}
