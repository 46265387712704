// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-status-modal {
  min-height: 100px;
  min-width: 300px;
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/edit-project-status-dialog/edit-project-status-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,gBAAA;EACA,gBAAA;AACD","sourcesContent":[".edit-status-modal {\n\tmin-height: 100px;\n\tmin-width: 300px;\n\tmax-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
