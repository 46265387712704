import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import {
	ArrowLeftToLine,
	ArrowRightFromLine, Calendar, ChartColumn,
	ChevronDown, FolderClosed, Home,
	LucideAngularModule,
	Table, Users, File, Bell, LogOut,
	User,
	Settings,
	LifeBuoy,
	BadgeDollarSign, CircleDollarSign,
	Gem
} from 'lucide-angular';
import { PopoverComponent } from '../../../@mos-core/components/popover/popover.component';
import { CoreModule } from '../../core/core.module';
import { ButtonComponent } from '../../../@mos-core/components/button/button.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, TitleCasePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SkeletonLoader } from '../../../@mos-core/components/skeleton-loader/skeleton-loader.component';
import { ProductTourModule } from '../product-tour/common/product-tour.module';

@NgModule({
	declarations: [SidebarComponent],
	imports: [
		PopoverComponent,
		CoreModule,
		LucideAngularModule.pick({
			ArrowLeftToLine,
			ArrowRightFromLine,
			ChevronDown,
			Home,
			FolderClosed,
			Users,
			ChartColumn,
			File,
			Calendar,
			Table,
			Bell,
			User,
			Settings,
			LifeBuoy,
			BadgeDollarSign,
			LogOut,
			CircleDollarSign,
		}),
		ButtonComponent,
		RouterLink,
		RouterLinkActive,
		NgClass,
		TitleCasePipe,
		MatTooltipModule,
		SkeletonLoader,
		ProductTourModule,
	],
	exports: [SidebarComponent],
})
export class SidebarModule {}
