import { Injectable, Injector, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalOptions } from './modal.service';

interface SlideOverOptions {
	title: string;
	subtitle: string;
	component: Type<any>;
	module: Type<any>;
	inputs?: Record<string, unknown>;
}

@Injectable({
	providedIn: 'root',
})
export class SlideOverService {
	public config: BehaviorSubject<SlideOverOptions> = new BehaviorSubject<SlideOverOptions>(undefined);
	public closeEvent: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);


	public open(options: SlideOverOptions): void {
		this.config.next(options);
	}

	public close(): void {
		this.config.next(undefined);
		this.closeEvent.next();
	}
}
