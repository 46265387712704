export class NumberHelper {
	/**
	 * Returns true if the given value is a valid decimal number
	 * @param value
	 */
	static isNumber(value) {
		// https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
		return !isNaN(parseFloat(value)) && isFinite(value);
	}

	/**
	 * Rounds a number to the amount of decimals
	 * @param value
	 * @returns {number}
	 */
	static round(value, places?) {
		return Math.round((value + Number.EPSILON) * Math.pow(10, places)) / Math.pow(10, places);
	}

	/**
	 * Ceils a number to the amount of decimals
	 * @param value
	 * @returns {number}
	 */
	static ceil(value) {
		if (isNaN(value)) {
			return 0;
		}
		const float = parseFloat(parseFloat(value + 'e' + 2).toPrecision(6));

		const result = Number(Math.ceil(float) + 'e-' + 2);
		if (isNaN(result)) {
			return 0;
		}
		return result;
	}

	/**
	 * Returns a number whose value is limited to the given range
	 * @param number
	 * @param min
	 * @param max
	 * @returns {number}
	 */
	static clamp(number, min, max) {
		return Math.min(Math.max(number, min), max);
	}
}
