import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Constants } from '../helpers';
import { DivisionModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class DivisionService extends BaseService<DivisionModel> {
	constructor(public httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.division, DivisionModel);
	}

	public getUserDivisions(userId: string): Observable<DivisionModel[]> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/list-user`, { user: userId }).pipe(map((res: { status: boolean; items: DivisionModel[] }) => res.items));
	}

	public setUserDivisions(userId: string, divisions: string[]): Observable<any> {
		return this.httpClient.post(`${this.baseUrl}/${this.endPoint}/set-user`, {
			user: userId,
			divisions: divisions,
		});
	}
}

@Injectable()
export class GetDivisionsResolver {
	constructor(private divisionService: DivisionService) {}

	public resolve(): Observable<any> {
		return this.divisionService.postList({ isActive: true });
	}
}
