import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../../../helpers';
import { ItemResponseData, ItemsResponseData } from '../../../interfaces';
import { AlertModel } from './alert.models';

@Injectable()
export class AlertHttpService {
	constructor(private http: HttpClient) {}

	/**
	 * Returns all the alerts of a specific user
	 * @param userId
	 * @returns
	 */
	public getAlerts(userId: String) {
		return this.http.post<ItemsResponseData<AlertModel>>(`${Constants.BASE_API_URL}/alerts/list`, { userId }).pipe(map(res => res.items));
	}

	/**
	 * Returns the total of unread alerts
	 * @param userId
	 * @returns
	 */
	public getTotalUnread(userId: String) {
		return this.http.post<ItemResponseData<number>>(`${Constants.BASE_API_URL}/alerts/total-unread`, { userId }).pipe(map(res => res.item));
	}

	/**
	 * Returns an alert coming from saas admin
	 * @param adminMessageId
	 * @returns
	 */
	public getDetails(adminMessageId: string) {
		return this.http.post<ItemResponseData<AlertModel>>(`${Constants.BASE_API_URL}/alerts/details`, { adminMessageId }).pipe(map(res => res.item));
	}

	/**
	 * Create a new alert
	 * @param alert
	 * @returns
	 */
	public createAlert(alert: AlertModel) {
		return this.http.post<ItemResponseData<AlertModel>>(`${Constants.BASE_API_URL}/alerts/create`, { alert }).pipe(map(res => res.item));
	}

	/**
	 * update the status of an alert
	 * @param alertId
	 * @param status
	 * @returns
	 */
	public update(alertId: string, status: string): Observable<AlertModel> {
		return this.http.post<ItemResponseData<AlertModel>>(`${Constants.BASE_API_URL}/alerts/update`, { alertId, status }).pipe(map(res => res.item));
	}
}
