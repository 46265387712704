import { TEXT } from '../../../../../../helpers';

export const MeasuringToolLayoutTextConst = {
	addNewBoq: TEXT.addNewBoq,
	addBoqToStart: TEXT.addBoqToStart,
	addPage: TEXT.addPage,
	calculationWillBeAffected: TEXT.calculationWillBeAffected,
	clonePage: TEXT.clonePage,
	confirm: TEXT.confirm,
	confirmAction: TEXT.confirmAction,
	confirmingMarginOfError: TEXT.confirmingMarginOfError,
	contingencyMargin: TEXT.contingencyMargin,
	convertPDF: TEXT.convertPDF,
	createBoq: TEXT.createBoq,
	deactivatePage: TEXT.deactivatePage,
	drawScaleLine: TEXT.drawScaleLine,
	editPage: TEXT.editPage,
	export: TEXT.export,
	exportAllPagesAsPdf: TEXT.exportAllPagesAsPDF,
	exportAllPagesAsImages: TEXT.exportAllPagesAsImages,
	addCurrentPageToTender: TEXT.addCurrentPageToTender,
	addAllPagesToTender: TEXT.addAllPagesToTender,
	exportCanvasAsImage: TEXT.exportCanvasAsImage,
	exportCoordinates: TEXT.exportCoordinates,
	exportPageAsPdf: TEXT.exportPageAsPdf,
	exportPageInfo: TEXT.exportPageInfo,
	fromAttachments: TEXT.fromAttachments,
	includeAnnotations: TEXT.includeAnnotations,
	compressPDF: TEXT.compressPDF,
	doesNotApplyToImageExports: TEXT.doesNotApplyToImageExports,
	items: TEXT.items,
	layers: TEXT.layers,
	noPageExistsForBoq: TEXT.noPageExistsForBoq,
	pageName: TEXT.pageName,
	pages: TEXT.pages,
	pageTotals: TEXT.pageTotals,
	pdfUploader: TEXT.pdfUploader,
	precision: TEXT.precision,
	recalibrateScale: TEXT.recalibrateScale,
	savePage: TEXT.savePage,
	scaleConfirmationInstructions: TEXT.scaleConfirmationInstructions,
	setScaleValue: TEXT.setScaleValue,
	select: TEXT.select,
	selectPointToolToDrawScaleLine: TEXT.selectPointToolToDrawScaleLine,
	settingScale: TEXT.settingScale,
	subLocation: TEXT.subLocation,
	submit: TEXT.submit,
	totalCeilingArea: TEXT.totalCeilingArea,
	totalItems: TEXT.totalItems,
	totalPerimeter: TEXT.totalPerimeter,
	totals: TEXT.totals,
	totalSurfaceArea: TEXT.totalSurfaceArea,
	totalWallsArea: TEXT.totalWallsArea,
	units: TEXT.units,
	updatePage: TEXT.updatePage,
	uploadImage: TEXT.uploadImage,
	uploadPage: TEXT.uploadPage,
};

export const MeasuringToolHelpText = {
	hotkeysInfo:
		'\nCanvas Info:\n' +
		'\n\u2022 Left-click to draw a point\n' +
		'\n\u2022 Middle-click to pan\n' +
		'\n\u2022 Scroll to zoom\n' +
		'\n\u2022 Press enter to close a shape\n' +
		'\n\u2022 Press backspace to remove a point\n' +
		'\n\u2022 Press plus to add a new layer\n' +
		'\n\u2022 Hold shift to draw in a straight line\n' +
		'\n\u2022 Before measuring, a scale must be set',
};

export const MeasuringToolPageFormStates = {
	add: 'Add',
	clone: 'Clone',
	edit: 'Edit',
};

export enum AddPageOptions {
	convertPDF,
	uploadImage,
	fromAttachments,
}
