import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { CompanyModel, UserModel } from '../models';

// Interface

export interface Division {
	description?: string;
	entity: string;
	company: CompanyModel;
	users: UserModel[];
	teamLeader?: UserModel;
	id?: string;
	isActive: boolean;
	name: string;
	order: number;
}

// Validators

export function GetDivisionValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Description',
			validators: [],
		},
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
	]);
}
