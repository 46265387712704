import { DrawingToolType, measuringToolUnits } from '../measuring-tool.constants';
import { MeasuringToolCoordinate, MeasuringToolHeightInput } from '../measuring-tool.interfaces';
import { MeasuringToolCutout } from './measuring-tool-cutout.model';
import { MeasuringToolStyle } from './measuring-tool-style.model';

export class MeasuringToolLayer {
	public activeLayerIndex: number;
	public annotation?: string;
	public boqId?: string;
	public ceilingArea: number = 0;
	public cutoutShapes: MeasuringToolCutout[] = [];
	public heightInput: MeasuringToolHeightInput = {
		displayUnit: measuringToolUnits.metres,
		value: 0,
	};
	public isAnnotation: boolean = false;
	public isScaleLayer?: boolean = false;
	public legendDescription: string;
	public perimeter: number = 0;
	public points: MeasuringToolCoordinate[] = [];
	public style?: MeasuringToolStyle = new MeasuringToolStyle();
	public toolType: DrawingToolType = DrawingToolType.point;
	public totalSurfaceArea: number = 0;
	public wallsSurfaceArea: number = 0;

	constructor(model?: Partial<MeasuringToolLayer>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
