export class TradingHourModel {
	public day: string;
	public open: string;
	public close: string;
	public isOpen: boolean;

	constructor(json?: Partial<TradingHourModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
