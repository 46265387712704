import { Project } from '../../../../interfaces';
import { UserModel } from '../../../../models';
import { VCProjectInterface } from './version-control.interfaces';

export class VCProjectModel implements VCProjectInterface {
	public author: UserModel;
	public chargeCount?: number;
	public createdAt?: Date;
	public id?: string;
	public isActive: boolean;
	public message: string;
	public originalProjectId: string;
	public project?: Project;
	public projectValue?: number;
	public updatedAt: Date;
	public versionNumber: number;

	constructor(model?: Partial<VCProjectModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
