import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ItcModalConfig } from '../../../@itc-core/directives/modal/common/modal.models';
import { EstimatorUserTool } from '../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.models';
import { EstimatorUserToolFormComponent } from '../../components/forms/estimator-user-tool-form/estimator-user-tool-form.component';
import { ButtonIcons } from '../../components/icon-button/common/icon-button.constants';
import { IconButtonConfig } from '../../components/icon-button/common/icon-button.interfaces';
import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../helpers';
import { GenericLibraryService } from '../../services/generic-library.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { GetCurrentUserResolver } from '../../services/user.service';
import { defaultLineDash } from '../measure-tool/common/measuring-tool.constants';
import { EstimatorUserToolLibraryService } from './common/estimator-user-tool-library.service';

@Component({
	templateUrl: './estimator-user-tool-library.component.html',
	styleUrls: ['./estimator-user-tool-library.component.scss'],
})
export class EstimatorUserToolLibraryComponent implements OnInit {
	@ViewChild(EstimatorUserToolFormComponent, { static: false })
	public estimatorUserToolFormRef: EstimatorUserToolFormComponent;
	@ViewChild(EstimatorUserToolFormComponent, { static: false })
	private userToolFormComponent: EstimatorUserToolFormComponent;

	/**
	 * Router config resolvers
	 * @type {}
	 */
	public static routerResolvers = {
		currentUser: GetCurrentUserResolver,
	};

	public addEditUserToolObservable: Observable<EstimatorUserTool>;
	public addUserToolButtonConfig: IconButtonConfig;
	public currentUserId: string;
	public currentUserTool: EstimatorUserTool;
	public fetchUserToolsObservable: Observable<EstimatorUserTool[]>;
	public formState: string;
	public libraryTypesConst = Constants.LIBRARY_TYPES;
	public modalConfig: ItcModalConfig;
	public projectId: string;
	public showModal: boolean = false;
	public textConstants: typeof TEXT = TEXT;

	constructor(
		public userToolService: EstimatorUserToolLibraryService,
		private libraryService: GenericLibraryService,
		private localStorageService: LocalStorageService,
		private route: ActivatedRoute,
		private snack: SnackBarHelperComponent
	) {
		this.route.queryParams
			.pipe(
				tap((params: Params) => {
					if (params.projectId) {
						this.projectId = params.projectId;
					}
				})
			)
			.subscribe();
	}

	public ngOnInit(): void {
		this.setButtonConfig();
		this.currentUserId = this.localStorageService.getUserDetails().id;
	}

	public addEditUserTool(): void {
		const error = ValidatorHelper.checkErrors(this.estimatorUserToolFormRef.validators);
		if (error) {
			this.snack.snackError(error);
			return;
		}
		if (this.currentUserTool.id) {
			this.addEditUserToolObservable = this.userToolService.postEdit(this.currentUserTool).pipe(
				tap(() => {
					this.libraryService.refreshList();
					this.showModal = false;
				})
			);
		} else {
			this.addEditUserToolObservable = this.userToolService.postCreate(this.currentUserTool).pipe(
				tap(() => {
					this.libraryService.refreshList();
					this.showModal = false;
				})
			);
		}
	}

	/**
	 * Deactivate the tool by the isActive toggle
	 * @private
	 */
	public toggleIsActive(): void {
		this.addEditUserToolObservable = this.userToolService.postEditActive(this.currentUserTool.id, !this.currentUserTool.isActive).pipe(
			tap((userTool: EstimatorUserTool) => {
				this.currentUserTool = userTool;
				this.libraryService.refreshList();
			})
		);
	}

	public userToolSelected(id?: string): void {
		if (id) {
			this.formState = Constants.FORM_STATES.EDIT;
			this.addEditUserToolObservable = this.userToolService.postDetails({ id }).pipe(
				tap(tool => {
					this.currentUserTool = tool;
				})
			);
		} else {
			this.formState = Constants.FORM_STATES.ADD;
			this.currentUserTool = this.getNewUserTool();
		}
		this.modalConfig = new ItcModalConfig({
			confirmButtonText: id ? this.textConstants.update : this.textConstants.create,
			showCancelButton: false,
			showActiveToggle: !!id,
			showCloseModalButton: true,
			title: this.formState + ' ' + this.libraryTypesConst[this.libraryTypesConst.userTool.key].itemName,
		});
		this.showModal = true;
	}

	private getNewUserTool(): EstimatorUserTool {
		return new EstimatorUserTool({
			canvasStyleDetails: {
				fillColour: 'rgba(0,0,0,1)',
				fontSize: undefined,
				lineStyle: undefined,
				lineDash: defaultLineDash, // Set it as the same value every time for now
				highlight: false,
				itemSize: undefined,
				strokeWeight: undefined,
				strokeColour: 'rgba(0,0,0,1)',
				textColour: 'rgba(0,0,0,1)',
			},
			userId: this.currentUserId,
		});
	}

	private setButtonConfig(): void {
		this.addUserToolButtonConfig = {
			icon: ButtonIcons.userTool,
			label: this.textConstants.createNewUserTool,
			isAddSuffix: true,
		};
	}
}
