import { TEXT } from '../../../../helpers/text.constants';

export const textConstants = {
	abn: TEXT.abn,
	actions: TEXT.actions,
	activate: TEXT.activate,
	add: TEXT.add,
	addContact: TEXT.addContact,
	addContactPerson: TEXT.addContactPerson,
	addressLine1: TEXT.addressLine1,
	addressLine2: TEXT.addressLine2,
	addressLine3: TEXT.addressLine3,
	childrenClients: TEXT.childrenClients,
	childrenCompanies: TEXT.childrenCompanies,
	city: TEXT.city,
	client: TEXT.client,
	company: TEXT.company,
	companyName: TEXT.companyName,
	contactPerson: TEXT.contactPerson,
	createClient: TEXT.createClient,
	createCompany: TEXT.createCompany,
	country: TEXT.country,
	deactivate: TEXT.deactivate,
	details: TEXT.details,
	email: TEXT.email,
	emptyRegionsDropdown: TEXT.emptyRegionsDropdown,
	fax: TEXT.fax,
	location: TEXT.location,
	mergeClient: TEXT.mergeClient,
	name: TEXT.name,
	parentClient: TEXT.parentClient,
	parentCompany: TEXT.parentCompany,
	phone: TEXT.phone,
	postCode: TEXT.postCode,
	projectList: TEXT.projectList,
	industryLicenseNumber: TEXT.industryLicenseNumber,
	region: TEXT.region,
	remove: TEXT.remove,
	sameAsAccount: TEXT.sameAsAccount,
	saveUpdate: TEXT.saveUpdate,
	saveContact: TEXT.saveContact,
	state: TEXT.state,
	viewAdditionalDetails: TEXT.viewAdditionalDetails,
	website: TEXT.website,
};
