// Interfaces for autocomplete dropdown arrays. We only need a subset of
// their respective original types when displaying them.

export interface BrandAutocompleteItemInterface {
	id?: string;
	name: string;
}

export interface CompanyAutocompleteItemInterface {
	id?: string;
	name: string;
}

export interface DivisionAutocompleteItemInterface {
	id?: string;
	name: string;
}

export interface StateAutocompleteItemInterface {
	id: string;
	name: string;
}

export interface UserAutocompleteItemInterface {
	id?: string;
	firstName: string;
	lastName: string;
}
