import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { Colour, ItemsResponseData } from '../interfaces';
import { ColourModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ColourService extends BaseService<ColourModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.color, ColourModel);
	}

	/**
	 * Converts color number Red/Green/Blue to Hex string
	 */
	public colorNumberToHex(colour: number): string {
		if (colour) {
			const hex = colour.toString(16);
			return hex.length === 1 ? '0' + hex : hex;
		}
		return '00';
	}

	public hexToRgb(hex: string) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
				}
			: null;
	}

	/**
	 * Post to list action in endpoint and return array of modelType
	 * @param postObject
	 * @param populateFields
	 */
	public postPopulatedList(postObject: Partial<ColourModel>, populateFields: string[]): Observable<Array<ColourModel>> {
		return this.httpClient
			.post<ItemsResponseData<ColourModel>>(`${this.baseUrl}/${this.endPoint}/populated-list`, {
				params: postObject,
				populateFields: populateFields,
			})
			.pipe(map(res => res.items.map(colour => new ColourModel(colour))));
	}
}

@Injectable()
export class GetActiveColoursResolver {
	constructor(private colourService: ColourService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.colourService.postList({ isActive: true });
	}
}
