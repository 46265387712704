export class ContactDetailModel {
	public email: string;
	public phone: string;
	public fax: string;
	public website: string;

	constructor(json?: Partial<ContactDetailModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
