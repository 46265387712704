import { NgClass, NgComponentOutlet } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { LucideAngularModule } from 'lucide-angular';

export class ButtonConfig {
	public label: string;
	public class?: string;
	public type?: 'normal' | 'primary' | 'danger' | 'caution' | 'accept' | 'soft';
	public width?: 'auto' | 'full';
}

const ButtonStyles: { [key: string]: string } = {
	accept: 'accept tw-inline-flex tw-justify-center tw-rounded-md tw-bg-green-600 tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-white tw-shadow-sm hover:tw-bg-green-500 tw-cursor-pointer',
	normal: 'normal tw-inline-flex tw-justify-center tw-rounded-md tw-bg-white tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-1 hover:tw-bg-gray-50 sm:tw-mt-0 tw-cursor-pointer',
	primary: 'primary tw-inline-flex tw-justify-center tw-rounded-md tw-bg-primary tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-white tw-shadow-sm hover:tw-bg-primary2 tw-cursor-pointer',
	soft: 'soft tw-inline-flex tw-justify-center tw-rounded-md tw-bg-lightPrimary2 tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-primary tw-cursor-pointer',
	danger: 'danger tw-inline-flex tw-justify-center tw-rounded-md tw-bg-red-500 tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-white tw-shadow-sm hover:tw-bg-red-600 tw-cursor-pointer',
	caution: 'caution tw-inline-flex tw-justify-center tw-rounded-md tw-bg-amber-400 tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-white tw-shadow-sm hover:tw-bg-amber-500 tw-cursor-pointer',
	disabled: 'tw-inline-flex tw-justify-center tw-rounded-md tw-bg-gray-300 tw-px-2.5 tw-py-2 tw-text-sm tw-font-semibold tw-text-nowrap tw-text-gray-500 tw-cursor-not-allowed tw-opacity-50',
};

const ButtonWidth: { [key: string]: string } = {
	auto: 'tw-w-auto',
	full: 'tw-w-full',
};

@Component({
	selector: 'mos-button',
	templateUrl: './button.component.html',
	standalone: true,
	imports: [NgClass, NgComponentOutlet, LucideAngularModule],
})
export class ButtonComponent implements OnInit, OnDestroy {
	@Input() public type: 'normal' | 'primary' | 'danger' | 'caution' | 'accept' | 'soft';
	@Input() public width: 'auto' | 'full';
	@Input() public label: string;
	@Input() public button: ButtonConfig[];
	@Input() public keybind: string;
	@Input() public disabled: boolean;
	@Input() public icon: string;
	@Input() public iconAlignment: 'left' | 'right' = 'left';
	@Output() public hotkey: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	private keybindListener: (event: KeyboardEvent) => void;

	constructor(private renderer: Renderer2) {}

	ngOnInit() {
		// Set default type and width
		this.type = this.type || 'normal';
		this.width = this.width || 'auto';

		// create keybind listener
		if (this.keybind) {
			this.keybindListener = this.renderer.listen('window', this.keybind, (event) => {
				const target = event.target as HTMLElement;
				if (['INPUT', 'TEXTAREA', 'SELECT'].includes(target.tagName) || target.isContentEditable) {
					return; // Don't fire the event if the target is an input, textarea, select, or contenteditable element
				}

				this.hotkey.emit(event);
			});
		}
	}

	ngOnDestroy() {
		if (this.keybind && this.keybindListener) {
			this.keybindListener = null;
		}
	}

	getKeybindLabel(): string {
		const keys = this.keybind?.split('.') ?? [];

		// shorten certain key names
		const keyNames = {
			'control': 'ctrl',
			'delete': 'del',
			'escape': 'esc',
		};

		return keys.splice(1, keys.length).map(k => {
			return keyNames[k] ?? k;
		}).join(' + ').toUpperCase();
	}

	protected readonly ButtonStyles = ButtonStyles;
	protected readonly ButtonWidth = ButtonWidth;
}
