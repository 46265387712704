import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CommentResponse, CommentsResponse, CommentUpdate } from '../interfaces';
import { ClientCommentPreviewComponent } from '../components/client/components/client-comment-preview/client-comment-preview.component';

@Injectable()
export class CommentService {
	private activeDialog = new Subject<any>();
	public activeDialog$ = this.activeDialog.asObservable();

	constructor(private http: HttpClient) {}

	/**
	 * Gets a list of comments for the provided project
	 * @param projectId
	 */
	public postList(projectId) {
		return this.http.post<CommentsResponse>(`${Constants.BASE_API_URL}/project/comment/list`, { projectId }).pipe(map(res => res.comments));
	}

	/**
	 * Adds a new comment
	 * @param comment
	 * @param projectId
	 */
	public addComment(comment, projectId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/project/comment/create`, { comment, projectId }).pipe(map(res => res.comment));
	}

	/**
	 * Updates comment
	 * @param commentUpdate: CommentUpdate
	 * @param projectId: string
	 */
	public editComment(commentUpdate: CommentUpdate, projectId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/project/comment/update`, { commentUpdate, projectId }).pipe(map(res => res.comment));
	}

	/**
	 * Delete a comment
	 * @param commentId
	 * @param projectId
	 */
	public deleteComment(commentId, projectId: string) {
		return this.http.post<CommentResponse>(`${Constants.BASE_API_URL}/project/comment/delete`, { commentId, projectId }).pipe(map(res => res.comment));
	}

	public setActiveDialog(component: any) {
		this.activeDialog.next(component);
	}
}
