import { UntypedFormGroup } from '@angular/forms';

import { Constants } from '../helpers';
import { AddressDetail, Competitor, ContactDetail } from '../interfaces';
import { BaseModel } from './base.model';

export class CompetitorModel extends BaseModel implements Competitor {
	// Properties
	public addressDetail: AddressDetail = Constants.createEmptyAddressDetail();
	public contactDetail: ContactDetail = Constants.createEmptyContactDetail();
	public isActive: boolean = true;
	public name: string;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		// this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
