export class SupplierLocationFavouriteModel {
	public locationId: string;
	public supplierId: string;

	constructor(json?: Partial<SupplierLocationFavouriteModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
