import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Contact } from '../../../interfaces';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-contact-view-dialog',
	templateUrl: './contact-view-dialog.component.html',
})
export class ContactViewDialogComponent implements OnInit {
	@Input() public readonly contact: Contact;

	constructor(
		private s3Service: S3Service,
		private sanitizer: DomSanitizer
	) {}

	public ngOnInit(): void {
		if (this.contact.imageFile) {
			const url = window.URL.createObjectURL(this.contact.imageFile);
			this.contact.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		} else if (this.contact.imageFile !== null) {
			if (this.contact.displayPictureUrl) {
				this.contact.imageUrl = this.contact.displayPictureUrl;
			} else if (this.contact.displayPictureKey) {
				this.s3Service.getSignedUrl(this.contact.displayPictureKey).subscribe(url => {
					this.contact.imageUrl = url;
				});
			}
		} else {
			this.contact.imageUrl = undefined;
		}
	}
}
