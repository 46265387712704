import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import { AdditionalCharge } from '../../../interfaces';
import { ChargeModel, GetAdditionalChargeLocationValidation, GetBoqLocationValidation } from '../../../models';
import { AdditionalChargeService } from '../../../services/additional-charge.service';
import { BoqService } from '../../../services/boq.service';
import { MeasuringToolLayoutDataService } from '../../../pages/measure-tool/common/components/measuring-tool-layout/common/measuring-tool-layout.data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-location-edit-dialog',
	templateUrl: './location-edit-dialog.component.html',
	styleUrls: ['./location-edit-dialog.component.scss'],
})
export class LocationEditDialogComponent implements OnInit {
	@Input() public charge: ChargeModel;
	@Input() public projectId: string;

	public environments: Array<string> = Constants.ENVIRONMENTS;
	public validators;
	public isBoqType: boolean;
	public textConstants: typeof TEXT = TEXT;
	private destroyRef = inject(DestroyRef);

	constructor(
		private snack: SnackBarHelperComponent,
		private boqService: BoqService,
		private additionalChargeService: AdditionalChargeService,
		private measuringToolLayoutDataService: MeasuringToolLayoutDataService
	) {}

	public ngOnInit(): void {
		this.isBoqType = this.charge.chargeType === Constants.CHARGE_TYPES.Boq;
		this.validators = this.isBoqType ? GetBoqLocationValidation(this.charge) : GetAdditionalChargeLocationValidation(this.charge);
	}

	public onSave(): Observable<ChargeModel | AdditionalCharge> {
		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return null;
		}
		// We must manually set these as the sublocation autocomplete component does not use the ngModel approach
		this.charge.subLocation1 = this.validators.SubLocation1.value;
		if (this.validators.SubLocation2) {
			this.charge.subLocation2 = this.validators.SubLocation2.value;
		}
		if (this.validators.SubLocation3) {
			this.charge.subLocation3 = this.validators.SubLocation3.value;
		}
		if (this.isBoqType) {
			// Don't need the result from updating the MT page user tool name
			this.measuringToolLayoutDataService
				.updateUserToolName(this.projectId, [this.charge.id], this.charge.subLocation2)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe();

			return this.boqService.editLocationBoq(
				this.charge.id,
				this.projectId,
				this.charge.environment,
				this.charge.subLocation1,
				this.charge.subLocation2,
				this.charge.subLocation3
			);
		} else {
			return this.additionalChargeService.editAdditionalChargeLocation(this.charge.id, this.charge.subLocation1, this.charge.environment, this.projectId);
		}
	}
}
