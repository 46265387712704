import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChargeListRequest, ChargePaginateResponse, ChargesBulkEditData, ChargesBulkEditResponse } from '../dialog/components/charges/common/charges.interfaces';
import { Constants } from '../helpers';
import { BulkCharge, ItemsResponse, Paginate, Project, ProjectResponse } from '../interfaces';
import { BaseModel, ChargeModel, CoatingSystemModel } from '../models';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class ChargeService extends BaseService<ChargeModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.Charge, ChargeModel);
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param ids
	 * @param bulkCharge
	 * @param projectId
	 */
	public postBulkEdit(ids: Array<string>, bulkCharge: BulkCharge, projectId: string): Observable<ChargesBulkEditData> {
		return this.httpClient
			.post<ChargesBulkEditResponse>(`${this.baseUrl}/${Constants.END_POINTS.Charge}/bulk-edit`, { ids, bulkCharge, projectId })
			.pipe(map(res => res.bulkEditData));
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param ids
	 * @param isActive
	 * @param projectId
	 */
	public postEditBulkActive(ids: Array<string>, isActive: boolean, projectId: string): Observable<Project> {
		return this.httpClient.post<ProjectResponse>(`${this.baseUrl}/${Constants.END_POINTS.Charge}/edit-active`, { ids, isActive, projectId }).pipe(map(res => res.project));
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param ids
	 * @param bulkCharge
	 * @param projectId
	 */
	public postBulkClone(ids: Array<string>, bulkCharge: BulkCharge, projectId: string): Observable<ChargesBulkEditData> {
		return this.httpClient
			.post<ChargesBulkEditResponse>(`${this.baseUrl}/${Constants.END_POINTS.Charge}/bulk-clone`, { ids, bulkCharge, projectId })
			.pipe(map(res => res.bulkEditData));
	}

	/**
	 * Post to list-action of endpoint and return array of Charges
	 * @param chargeListRequest
	 * @param projectId
	 * @param hideVariationCharges
	 */
	public postChargeList(chargeListRequest: ChargeListRequest, projectId: string, hideVariationCharges: boolean = true): Observable<ChargeModel[]> {
		return this.httpClient
			.post<ItemsResponse>(`${this.baseUrl}/${Constants.END_POINTS.Charge}/list`, {
				chargeListRequest,
				projectId,
				hideVariationCharges,
			})
			.pipe(map(res => res.items.map(item => new ChargeModel(item))));
	}

	/**
	 * Post to update-many action of endpoint and return an instance of modelType
	 * @param charges
	 * @param projectId
	 * @param isFromMeasuringTool
	 */
	public postUpdateMany(charges: Array<ChargeModel>, projectId: string, isFromMeasuringTool: boolean = false): Observable<Project> {
		//TODO remove this when the route is using JoiHelper and unknown(true) is removed
		const chargeItems = [];
		charges.forEach(charge => {
			// If we are editing the boq from a measuring tool component, automatically set useEstimatedValues to true
			if (isFromMeasuringTool && charge.chargeType === Constants.CHARGE_TYPES.Boq) {
				charge.useEstimatedValues = true;
			}
			const postObject: BaseModel = JSON.parse(JSON.stringify(charge, BaseModel.removeBulkBoqReplacer));
			chargeItems.push(postObject);
		});

		return this.httpClient
			.post<ProjectResponse>(`${this.baseUrl}/${Constants.END_POINTS.Charge}/update-many`, {
				charges: chargeItems,
				projectId: projectId,
			})
			.pipe(map(res => res.project));
	}

	/**
	 * Post to paginate action of endpoint and return an instance of modelType
	 * @param paginate
	 * @param projectId
	 */
	public postPaginateCharges(paginate: Paginate, projectId: string): Observable<any> {
		return this.httpClient.post<ChargePaginateResponse>(`${this.baseUrl}/${this.endPoint}/paginate`, { paginate, projectId }).pipe(
			map(res => {
				const result = {
					charges: [],
					isCustom: undefined,
					totals: {
						totalCount: 0,
						totalLabourHours: 0,
						totalUnits: 0,
						averageCostPerUnits: 0,
						totalCosts: 0,
						averageRatePerUnits: 0,
						totalChargeOut: 0,
						totalDiscounts: 0,
						totalProfit: 0,
					},
				};

				result.charges = res.charges.map(item => new ChargeModel(item));
				result.isCustom = res.isCustom;
				if (res.totals) {
					result.totals = res.totals;
				}

				return result;
			})
		);
	}

	/**
	 * Get all the BOQs belonging to the project
	 */
	public listBoqs(projectId: string): Observable<ChargeModel[]> {
		return this.httpClient.post<{ items: ChargeModel[] }>(`${this.baseUrl}/${this.endPoint}/list-boqs`, { projectId }).pipe(
			map(res => {
				const boqs = res.items.map(charge => new ChargeModel(charge));

				return boqs;
			})
		);
	}

	/**
	 * Post to order of the chargeOrder list and return the current page of charges
	 * @param charges
	 * @param projectId
	 * @param variationId
	 */
	public postChargeOrder(charges, projectId: string, variationId?: string): Observable<any> {
		return this.httpClient
			.post<ItemsResponse>(`${this.baseUrl}/${this.endPoint}/order`, {
				charges,
				projectId,
				variationId,
			})
			.pipe(map(res => res.items.map(item => new ChargeModel(item))));
	}

	public getCommonCoatingSystem(ids, projectId): Observable<CoatingSystemModel> {
		return this.httpClient.post<{ item: CoatingSystemModel }>(`${this.baseUrl}/${this.endPoint}/common-coating-system`, { ids, projectId }).pipe(map(res => res.item));
	}
}
