import { TEXT } from '../../../../../../helpers/text.constants';
import { SentenceCasePipe } from '../../../../../../pipes/sentence-case-pipe';

const sentenceCasePipe: SentenceCasePipe = new SentenceCasePipe();

export const AddContactTextConst = {
	addressLine1: TEXT.addressLine1,
	addressLine2: TEXT.addressLine2,
	addressLine3: TEXT.addressLine3,
	cityLocality: `${TEXT.city}/${TEXT.locality}`,
	email: TEXT.email,
	mobile: TEXT.mobile,
	name: TEXT.name,
	phoneNumber: TEXT.phoneNumber,
	position: TEXT.position,
	postCode: TEXT.postCode,
	region: TEXT.region,
	relationshipHolder: TEXT.relationshipHolder,
	select: sentenceCasePipe.transform(TEXT.select + '...'),
	state: TEXT.state,
	typeHere: sentenceCasePipe.transform(TEXT.typeHere),
};
