// Framework imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
// 3rd party imports
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

// @itc-Core imports
// project specific imports
import { ContentCardModule } from '../../../../../../@itc-core/components/components/content-card/common/content-card.module';
import { ItcBusyModule } from '../../../../../../@itc-core/directives/itc-busy/component/itc-busy.module';
import { ItcColouredDotModule } from '../../../../../../@itc-core/directives/itc-coloured-dot/common/itc-coloured-dot.module';
import { ItcModalDirectiveModule } from '../../../../../../@itc-core/directives/modal/common/modal.module';
import { CoreModule } from '../../../../../core/core.module';
import { InvoiceListTotalsCardComponent } from '../invoice-list-totals-card.component';

@NgModule({
	declarations: [InvoiceListTotalsCardComponent],
	imports: [
		CommonModule,
		ContentCardModule,
		ItcColouredDotModule,
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTooltipModule,
		ItcModalDirectiveModule,
		ItcBusyModule,
		CoreModule,
	],
	exports: [InvoiceListTotalsCardComponent],
})
export class InvoiceListTotalsCardModule {}
