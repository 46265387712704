import { RGB } from '../interfaces';

export class ColourHelper {
	/**
	 * Converts color number Red/Green/Blue to Hex string
	 */
	public static colorNumberToHex(colour: number): string {
		if (colour) {
			const hex = colour.toString(16);
			return hex.length === 1 ? '0' + hex : hex;
		}
		return '00';
	}

	/**
	 * Convert a hex string to an object contain r,g,b properties
	 */
	public static hexToRgb(hex: string): RGB {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
				}
			: null;
	}

	/**
	 * Convert an array with [red, green, blue] to a hex string
	 */
	public static convertRGBArrayToHex(rgbArray: number[]): string {
		const rHex = this.colorNumberToHex(rgbArray[0]);
		const gHex = this.colorNumberToHex(rgbArray[1]);
		const bHex = this.colorNumberToHex(rgbArray[2]);

		return `#${rHex}${gHex}${bHex}`;
	}
}
