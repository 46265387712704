import { ReferenceCode } from './reference-code';

export interface Attachment {
	_id?: string;
	id?: string;
	key?: string;
	fileKey?: string;
	name: string;
	category: ReferenceCode;
	children?: any[];
	uploadedAt: Date;
	createdAt?: Date;
	updatedAt?: Date;
	revision: number;
	isGenerated: boolean;
	project: string;
	isActive: boolean;
}
