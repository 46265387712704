export interface PostParams {
	brand?: string;
	chargeType?: string;
	coatingSystem?: string;
	codeType?: string;
	company?: string;
	environment?: string;
	folder?: string;
	firstName?: string;
	isActive?: boolean;
	lastName?: string;
	name?: string;
	project?: string;
	role?: string;
	subLocation1?: string;
	subLocation2?: string;
	subLocation3?: string;
	substrate?: string;
	type?: string;
	userId?: string;
	userIds?: string[];
	variation?: string;
}
