/**
 * Created by Tom Broadwood from In The Code on 29/3/18.
 */
import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';

export interface Email {
	body?: string;
	estimator?: string;
	commentsToEstimator?: string;
	subject?: string;
	sentTo?: string;
	sentFrom?: string;
	project?: string;
	user?: any;
	createdAt?: Date;
	attachmentKey?: string;
	icalEvent?: string;
	fileUrl?: string;
	sendTo?: string;
	replyTo?: string;
	ccEmails?: string[];
	filesFolderKey?: string;
}

export interface EmailResponse {
	email: string;
	status: boolean;
}

export interface EmailsResponse {
	emails: [Email];
	status: boolean;
}

export function GetExportEmailValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Body',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Subject',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Client',
			validators: [Validators.required],
		},
	]);
}
