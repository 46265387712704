export class ClaimModel {
	public amount: number;
	public charge: string;

	constructor(model?: Partial<ClaimModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
