// Framework imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { IconButtonConfig } from '../../../../app/components/icon-button/common/icon-button.interfaces';
import { ContentCardDropdownItem } from './common/content-card.interfaces';
import { ContentCardModel } from './common/content-card.models';

@Component({
	selector: 'itc-content-card',
	templateUrl: './content-card.component.html',
	styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements OnInit {
	@Output()
	public buttonPressed: EventEmitter<void> = new EventEmitter();
	@Output()
	public dropdownItemSelected: EventEmitter<ContentCardDropdownItem> = new EventEmitter();

	@Input()
	public set inputConfig(inputConfig: ContentCardModel) {
		this._inputConfig = new ContentCardModel(inputConfig);
		if (this._inputConfig.link) {
			this.iconButtonConfig();
		}
	}
	public get inputConfig(): ContentCardModel {
		return this._inputConfig;
	}

	@Input()
	public isPreviousSelected: boolean = false;

	@Input()
	public isSelected: boolean = false;

	public currentDropdownItem: ContentCardDropdownItem;
	public dropDownChevron: IconDefinition = faChevronDown;
	public iconConfig: IconButtonConfig;

	private _inputConfig: ContentCardModel = new ContentCardModel();

	constructor() {}

	public ngOnInit(): void {
		if (this.inputConfig.dropdownItems.length) {
			this.currentDropdownItem = this.inputConfig.dropdownItems[0];
		}
	}

	public clicked(): void {
		this.buttonPressed.emit();
	}

	public itemSelected(item: ContentCardDropdownItem): void {
		this.currentDropdownItem = item;
		this.dropdownItemSelected.emit(item);
	}

	private iconButtonConfig(): void {
		this.iconConfig = {
			icon: this.inputConfig.link.icon,
			label: this.inputConfig.link.text,
			isAddSuffix: this.inputConfig.isAddSuffix,
			hasBorder: false,
		};
	}
}
