export class DashboardStrikeRateModel {
	public imageUrl?: string;
	public profitAverage: number = 0;
	public profitLost: number = 0;
	public profitSubmitted: number;
	public profitTotal: number = 0;
	public profitWon: number = 0;
	public projectsSubmitted: number = 0;
	public projectsLost: number = 0;
	public projectsWon: number = 0;
	public revenueLost: number = 0;
	public revenueSubmitted: number = 0;
	public revenueWon: number = 0;
	public strikeRate: number = 0;

	constructor(model?: Partial<DashboardStrikeRateModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
