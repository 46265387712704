import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Injectable()
export class EntityOwnerGuard {
	constructor(private authService: AuthService) {}

	public canActivate(): boolean {
		return this.authService.isEntityOwner();
	}

	public canActivateChild(): boolean {
		return this.authService.isEntityOwner();
	}
}
