import { UntypedFormGroup, Validators } from '@angular/forms';

import { ValidatorHelper } from '../../../../helpers';
import { BaseModel } from '../../../../models';
import { ReferenceCodeModel } from '../../../../models';
import { MeasuringToolStyle } from '../../../../pages/measure-tool/common/models/measuring-tool-style.model';
import { CalculationTypes, UserToolTypes } from './estimator-user-tool-form.constants';
import { EmbeddedEstimatorUserTool, UserToolBoqDetails } from './estimator-user-tool-form.interfaces';

export class EstimatorUserTool extends BaseModel {
	public boqDetails: UserToolBoqDetails = {};
	public calculationType: string = CalculationTypes.surfaceArea;
	public canvasStyleDetails: MeasuringToolStyle;
	public defaultHeight: number = 0;
	public entity: string;
	public isActive: boolean = true;
	public name: string;
	public profile: ReferenceCodeModel;
	public updatedAt: Date;
	public usageCount: number;
	public userId: string;
	public userToolType: string = UserToolTypes.measure;

	constructor(model?: Partial<EstimatorUserTool>) {
		super();
		if (model) {
			Object.assign(this, model);
		}
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}

	/**
	 * Get copy of a estimator user tool without the unneeded fields when setting a measuring tool page's boq user tool
	 */
	public getEmbeddedUserToolObject(subLocation2): EmbeddedEstimatorUserTool {
		const userToolCopy = <EstimatorUserTool>JSON.parse(JSON.stringify(this, BaseModel.removeKeysReplacer));

		return {
			calculationType: userToolCopy.calculationType,
			canvasStyleDetails: userToolCopy.canvasStyleDetails,
			defaultHeight: userToolCopy.defaultHeight,
			name: subLocation2 ? subLocation2 : userToolCopy.name,
			profile: userToolCopy.profile,
			totalCeilingArea: 0,
			totalPerimeter: 0,
			totalSurfaceArea: 0,
			totalWallsSurfaceArea: 0,
			userId: userToolCopy.userId,
			userToolType: userToolCopy.userToolType,
		};
	}
}

export function GetUserToolFormValidation(userTool: EmbeddedEstimatorUserTool | EstimatorUserTool) {
	const formValidators: any[] = [
		{
			name: 'Name',
			validators: [Validators.required, Validators.maxLength(300)],
			value: userTool.name,
		},
		{
			name: 'LineStyle',
			validators: [Validators.required],
			value: userTool.canvasStyleDetails.lineStyle,
		},
		{
			name: 'StrokeWeight',
			validators: [Validators.required, Validators.min(1)],
			value: userTool.canvasStyleDetails.strokeWeight,
		},
	];
	if (userTool.userToolType === UserToolTypes.annotation) {
		formValidators.push({
			name: 'FontSize',
			validators: [Validators.required, Validators.min(1)],
			value: userTool.canvasStyleDetails.fontSize,
		});
	} else if ((userTool.userToolType === UserToolTypes.measure && userTool.calculationType === CalculationTypes.linealMetre) || userTool.userToolType === UserToolTypes.item) {
		formValidators.push({
			name: 'Profile',
			validators: [Validators.required],
			value: userTool.profile,
		});
		if (userTool.userToolType === UserToolTypes.item) {
			formValidators.push({
				name: 'ItemSize',
				validators: [Validators.required, Validators.min(1)],
				value: userTool.canvasStyleDetails.itemSize,
			});
		}
	} else if (
		userTool.userToolType === UserToolTypes.measure &&
		(userTool.calculationType === CalculationTypes.surfaceArea || userTool.calculationType === CalculationTypes.wallsArea)
	) {
		formValidators.push({
			name: 'DefaultHeight',
			validators: [Validators.required, Validators.min(0)],
			value: userTool.defaultHeight,
		});
	}

	return ValidatorHelper.formBuilder(formValidators);
}
