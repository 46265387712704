import { UntypedFormGroup } from '@angular/forms';

import { SupplierProduct } from '../interfaces';
import { BaseModel } from './base.model';

export class SupplierProductModel extends BaseModel implements SupplierProduct {
	// Properties
	public name: string;
	public dailyHireRate: number;
	public thumbnailFile: File;
	public thumbnailKey: string;
	public thumbnailUrl: string;
	public transportCost: number;
	public weeklyHireRate: number;
	public supplierId: string;

	constructor(json?: any, supplierId?: string) {
		super();
		if (json) {
			if (json._id) {
				this.id = json._id;
			}
			delete json._id;
			this.deserialize(json);
		}

		this.supplierId = supplierId;
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(supplierId?: string): FormData {
		if (this.thumbnailFile) {
			const formData = new FormData();
			formData.append('supplierId', supplierId);
			formData.append('thumbnail', this.thumbnailFile);
			return formData;
		}
		return undefined;
	}
}
