import { SupplierModel } from './supplier.model';

export class EmbeddedSupplierRateModel {
	public dailyHireRate: number;
	public duration: number;
	public imageUrl: string;
	public isDaily: boolean;
	public name: string;
	public supplier: SupplierModel;
	public thumbnailKey: string;
	public transportCost: number;
	public weeklyHireRate: number;

	constructor(json?: Partial<EmbeddedSupplierRateModel>) {
		if (json) {
			Object.assign(this, json);
			if (json.supplier) {
				this.supplier = new SupplierModel(json.supplier);
			}
		}
	}
}
