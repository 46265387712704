import { Region } from '../interfaces';
import { StateModel } from './state.model';

export class AddressDetailModel {
	public addressLine1: string;
	public addressLine2?: string;
	public addressLine3?: string;
	public city?: string;
	public country?: string;
	public postCode?: string;
	public region?: Region;
	public state?: StateModel = new StateModel();

	constructor(json?: Partial<AddressDetailModel>) {
		if (json) {
			Object.assign(this, json);
			if (json.state) {
				this.state = new StateModel(json.state);
			}
		}
	}

	/**
	 * Method to return a full address string
	 */
	public get fullAddress(): string {
		let fullAddressString = '';
		fullAddressString += this.addressLine1 ?? '';
		fullAddressString += this.addressLine2 ? ' ' + this.addressLine2 : '';
		fullAddressString += this.addressLine3 ? ' ' + this.addressLine3 : '';
		fullAddressString += this.postCode ? ' ' + this.postCode : '';
		fullAddressString += this.city ? ' ' + this.city : '';
		fullAddressString += this.state ? ' ' + this.state.name : '';
		fullAddressString += this.country ? ' ' + this.country : '';
		return fullAddressString;
	}
}
