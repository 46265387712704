/**
 * Created by Johan Brunet from In The Code on 6/8/18.
 */
import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { ProjectModel, UserModel } from '../models';
import { Email } from './email';

class BaseTask {
	public action: string;
	public assignee: UserModel;
	public author: UserModel;
	public dueDate: Date;
	public createdAt?: string;
	public id?: string;
	public notifications?: Array<TaskNotification>;
	public isComplete: boolean;
	public completeDate?: Date;
	public taskType: string;
}

export interface NewTask extends BaseTask {
	project: string;
}

export class Task extends BaseTask {
	public project: ProjectModel;

	constructor(obj?: Partial<Task>) {
		super();
		if (obj.project) {
			obj.project = new ProjectModel(obj.project);
		}
		Object.assign(this, obj);
	}
}

export interface TaskNotification {
	periodType: string;
	periodNumber: number;
}

// Response type given back when retrieving a task
export class TaskResponse {
	public status: boolean;
	public task: Task;

	constructor(obj?: Partial<TaskResponse>) {
		if (obj.task) {
			obj.task = new Task(obj.task);
		}
		Object.assign(this, obj);
	}
}

// Response type given back when retrieving a list of tasks
export class TasksResponse {
	public status: boolean;
	public total: number;
	public tasks: Task[];

	constructor(obj?: Partial<TasksResponse>) {
		if (obj.tasks) {
			obj.tasks = obj.tasks.map(item => new Task(item));
		}
		Object.assign(this, obj);
	}
}

export class TaskWithEmailResponse {
	public status: boolean;
	public data: {
		task: Task;
		email: Email;
	};

	constructor(obj?: Partial<TaskWithEmailResponse>) {
		if (obj.data) {
			if (obj.data.task) {
				obj.data.task = new Task(obj.data.task);
			}
		}
		Object.assign(this, obj);
	}
}

export function GetTaskValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'TaskAction',
			validators: [Validators.required, Validators.maxLength(3000)],
		},
		{
			name: 'TaskDueDate',
			validators: [Validators.required],
		},
		{
			name: 'TaskAssignee',
			validators: [Validators.required],
		},
	]);
}

export function GetNotificationValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'PeriodNumber',
			validators: [Validators.required, Validators.min(1)],
		},
		{
			name: 'PeriodType',
			validators: [Validators.required],
		},
	]);
}
