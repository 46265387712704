export interface S3Credentials {
	accessKeyId: string;
	basePath: string;
	s3Policy: string;
	s3Signature: string;
	uploadUrl: string;
}

export interface S3PolicyResponse {
	credentials: S3Credentials;
	status: boolean;
}

export interface S3SignedUrlResponse {
	status: boolean;
	url: string;
}

export interface S3File {
	extension?: string;
	fileName?: string;
	s3Key: string;
	size?: number;
	url?: string;
}
