import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';

export interface RGB {
	r: number;
	g: number;
	b: number;
}

export interface Colour {
	id?: string;
	name: string;
	brand: string;
	code: string;
	base: string;
	hexColour: string;
	red: number;
	green: number;
	blue: number;
	isActive: boolean;
	isSelected?: boolean;
	order?: number;
}

export function GetColourValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [CustomValidators.noWhiteSpaceValidator, Validators.required, Validators.maxLength(255)],
		},
		{
			name: 'Code',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Base',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Blue',
			validators: [Validators.required, Validators.maxLength(3), Validators.max(255), Validators.min(0)],
		},
		{
			name: 'Red',
			validators: [Validators.required, Validators.maxLength(3), Validators.max(255), Validators.min(0)],
		},
		{
			name: 'Green',
			validators: [Validators.required, Validators.maxLength(3), Validators.max(255), Validators.min(0)],
		},
	]);
}
