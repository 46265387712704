import { TEXT } from '../../../helpers';

export const textConstants = {
	createdFromTo: TEXT.createdFromTo,
	defaultDivision: TEXT.defaultDivision,
	filterProjects: TEXT.filterProjects,
	noItems: TEXT.noItems,
	selectStatus: TEXT.selectStatus,
	state: TEXT.state,
	status: TEXT.status,
	typeHere: TEXT.typeHere,
	userStatistics: TEXT.userStatistics,
};
