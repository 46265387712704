// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-dropdown-panel {
	position: absolute;
}

.ng-select {
	position: initial;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.centerLabel {
	text-align: center;
}

label {
	font-weight: bold;
}

.mergeTable td {
	border-bottom: 1px solid rgba(150, 150, 150, 0.3);
}

.mergePicture {
	height: 131px;
	width: 131px;
	margin-left: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}

@media (min-width: 350px) and (max-width: 575px) {
	.client-merge-dialog {
		width: 300px;
	}
}
@media (min-width: 576px) {
	.client-merge-dialog {
		width: 500px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/merge-client-dialog/merge-client-dialog.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,cAAc;CACd,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iDAAiD;AAClD;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,gBAAgB;CAChB,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC;EACC,YAAY;CACb;AACD;AACA;CACC;EACC,YAAY;CACb;AACD","sourcesContent":[".ng-dropdown-panel {\n\tposition: absolute;\n}\n\n.ng-select {\n\tposition: initial;\n}\n\n.left {\n\ttext-align: left;\n}\n\n.right {\n\ttext-align: right;\n}\n\n.center {\n\tdisplay: block;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n\n.centerLabel {\n\ttext-align: center;\n}\n\nlabel {\n\tfont-weight: bold;\n}\n\n.mergeTable td {\n\tborder-bottom: 1px solid rgba(150, 150, 150, 0.3);\n}\n\n.mergePicture {\n\theight: 131px;\n\twidth: 131px;\n\tmargin-left: 0px;\n\tmargin-top: 0px;\n\tmargin-bottom: 0px;\n}\n\n@media (min-width: 350px) and (max-width: 575px) {\n\t.client-merge-dialog {\n\t\twidth: 300px;\n\t}\n}\n@media (min-width: 576px) {\n\t.client-merge-dialog {\n\t\twidth: 500px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
