import { Component, Input } from '@angular/core';

import { ProjectStatus } from '../../common/project.interfaces';

@Component({
	selector: 'paint-project-status-pill',
	templateUrl: './project-status-pill.component.html',
	styleUrls: ['./project-status-pill.component.scss'],
})
export class ProjectStatusPillComponent {
	@Input() public status: ProjectStatus;
}
