import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

const durationNumber = 3000;
const verticalPos: MatSnackBarVerticalPosition = 'top';
const failureConfig: MatSnackBarConfig = {
	duration: durationNumber,
	panelClass: ['bg-danger'],
	verticalPosition: verticalPos,
};
const successConfig: MatSnackBarConfig = {
	duration: durationNumber,
	panelClass: ['bg-success'],
	verticalPosition: verticalPos,
};
const warningConfig: MatSnackBarConfig = {
	duration: durationNumber,
	panelClass: ['bg-warning'],
	verticalPosition: verticalPos,
};
const informationConfig: MatSnackBarConfig = {
	duration: durationNumber,
	panelClass: ['bg-info'],
	verticalPosition: verticalPos,
};

/**
 * @title Snack-bar with a custom component
 * Use:
 * import {SnackBarHelperComponent} from "../../helpers/snack-bar-helper";
 **/
// TODO Lilit (removed message)
@Component({
	selector: 'app-snack-bar-helper-component',
	template: '<span></span>',
})
export class SnackBarHelperComponent {
	constructor(public snackBar: MatSnackBar) {}

	public snackError(message) {
		this.snackBar.open(message, '', failureConfig);
	}

	public snackSuccess(message) {
		this.snackBar.open(message, '', successConfig);
	}

	public snackWarning(message) {
		this.snackBar.open(message, '', warningConfig);
	}

	public snackInformation(message) {
		this.snackBar.open(message, '', informationConfig);
	}
}
