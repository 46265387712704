import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class LibrariesDataService {
	private librariesRouteDataSubject: Subject<string> = new BehaviorSubject<string>(undefined);
	public librariesRouteData: Observable<string> = this.librariesRouteDataSubject.asObservable().pipe(shareReplay(1));

	constructor() {}

	public setCompanyById(companyId: string): void {
		this.librariesRouteDataSubject.next(companyId);
	}
}
