import { Component, Input, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BoqModel, BrandModel, ChargeModel, ColourModel } from '../../../models';
import { BoqService } from '../../../services/boq.service';
import { BrandService } from '../../../services/brand.service';
import { ColourService } from '../../../services/colour.service';
import { ColourEditDialogConstants } from './common/colour-edit-dialog.constants';

@Component({
	selector: 'app-colour-edit-dialog',
	templateUrl: './colour-edit-dialog.component.html',
	styleUrls: ['./colour-edit-dialog.component.scss'],
})
export class ColourEditDialogComponent implements OnInit {
	@Input() public charge: ChargeModel;
	@Input() public isDisabled = false;
	@Input() public projectId: string;

	public brands = [];
	public colour: ColourModel;
	public colourBrandId: string;
	public colours: ColourModel[] = [];
	public filteredColours = [];
	public isAdmin = false;
	public loadingBrandsSubscription: Subscription;
	public loadingColoursSubscription: Subscription;
	public textConstants: typeof ColourEditDialogConstants = ColourEditDialogConstants;

	constructor(
		private boqService: BoqService,
		private brandService: BrandService,
		private colourService: ColourService
	) {}

	public ngOnInit() {
		this.loadingBrandsSubscription = this.brandService
			.postList({ isActive: true })
			.pipe(
				finalize(() => {
					this.loadingBrandsSubscription.unsubscribe();
				})
			)
			.subscribe((brands: BrandModel[]) => {
				this.brands = brands;
			});

		this.loadingColoursSubscription = this.colourService
			.postList({ isActive: true })
			.pipe(
				finalize(() => {
					this.loadingColoursSubscription.unsubscribe();
				})
			)
			.subscribe((colours: ColourModel[]) => {
				this.colours = colours;
				if (this.charge.colour) {
					this.filterColourByBrand(this.charge.colour.brand);
				}
			});

		if (this.charge && this.charge.colour) {
			this.colourBrandId = this.charge.colour.brand;
			this.colour = new ColourModel(this.charge.colour);
		}
	}

	public onSave(): Observable<BoqModel> {
		let colourId;
		if (this.colour?.id) {
			colourId = this.colour.id;
		}

		return this.boqService.editColourBoq(this.charge.id, this.projectId, colourId);
	}

	/**
	 * Load Colour from the selected Brand
	 * @param {string} brandId
	 */
	public selectColourBrand(brandId: string): void {
		this.filterColourByBrand(brandId);
		this.colour = null;
	}

	private filterColourByBrand(brandId: string): void {
		this.filteredColours = this.colours.filter(colour => {
			return colour.brand === brandId;
		});
	}
}
