import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../../../../app/services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(router: Router, service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {
	const navItems = {
		Account: 0,
		Companies: 1,
		Users: 3,
		Divisions: 2,
	};

	const navParams = {
		Account: '/admin/account-details',
		Users: '/admin/users',
		Divisions: '/admin/divisions',
	};

	const selector = `.side-nav-item-${navItems[productTourService.getCurrentTour()]}`;
	const handler = () => {
		const navItem = document.querySelector(selector);
		if (navItem) {
			navItem.removeEventListener('click', handler);
		}
		service.next();
	};

	const backHandler = () => {
		service.back();
		service.tourObject.back();
	};

	return [
		{
			id: 'step3',
			attachTo: {
				element: selector,
				on: 'right',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			buttons: [
				// {
				// 	text: 'Back',
				// 	classes: 'back-button',
				// 	action: function () {
				// 		router.navigateByUrl('/');
				// 		backHandler();
				// 	}
				// },
				{
					text: 'Next',
					action: function () {
						router.navigateByUrl(`${navParams[productTourService.getCurrentTour()]}`);
						handler();
					},
				},
			],
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: `Navigate to ${productTourService.getCurrentTour()}`,
			text: [`Click on ${productTourService.getCurrentTour()}`],
			when: {
				show: () => {
					const navItem = document.querySelector(selector);

					if (navItem) {
						navItem.addEventListener('click', handler);
					}
				},
				hide: () => {
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}
				},
				cancel: () => {
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}
				},
			},
		},
	];
}

export function getScheduleSteps(router: Router, service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {

	let currentTour = productTourService.getCurrentTour();

	const navParams = {
		'My Shifts': '/shifts/my-schedule',
		'Project/Location': '/schedule/projects',
		'Resources': '/schedule/resources',
		'Schedule': '/schedule/schedule-view',
		'Timesheets': 'schedule/timesheets',
	};

	const navItems = {
		'My Shifts': 0,
		'Project/Location': 1,
		'Resources': 2,
		'Schedule': 3,
		'Timesheets': 4,
	};

	const selector = `.side-nav-item-${navItems[currentTour]}`;

	function handler() {
		const navItem = document.querySelector(selector);
		if (navItem) {
			navItem.removeEventListener('click', handler);
		}
		service.next();
	}

	return [
		{
			id: 'step2',
			title: `Navigate to ${currentTour}`,
			text: `Click on ${currentTour}`,
			attachTo: {
				element: selector,
				on: 'right',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			when: {
				show: () => {
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.addEventListener('click', handler);
					}
				},
				hide: () => {
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}
				},
				cancel: () => {
					const navItem = document.querySelector(selector);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}

				},
			},
			buttons: [
				{
					text: 'Next',
					action: function() {
						router.navigateByUrl(`${navParams[currentTour]}`);
						handler();
					},
				},
			],
		},
	];
}

