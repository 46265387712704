import { NgModule } from '@angular/core';
import { SentenceCasePipe } from '../pipes/sentence-case-pipe';

import { ActivePipe } from './active-pipe';
import { ContactPersonPipe } from './contact-person-pipe';
import { TruncateStringPipe } from './truncate-string.pipe';

@NgModule({
	declarations: [ActivePipe, ContactPersonPipe, SentenceCasePipe, TruncateStringPipe],
	exports: [ActivePipe, ContactPersonPipe, SentenceCasePipe, TruncateStringPipe],
})
export class PipeExportModule {}
