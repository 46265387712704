import { Region } from './region';
import { State } from './state';

export interface AddressDetail {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	country?: string;
	postCode?: string;
	region?: Region;
	state?: State;
}
