import { Injectable } from '@angular/core';

import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable()
export class AdminGuard {
	constructor(private permissionsService: RolePermissionsService) {}

	public canActivate(): boolean {
		return this.permissionsService.isAdmin();
	}

	public canActivateChild(): boolean {
		return this.permissionsService.isAdmin();
	}
}
