import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs';
import { ItemResponseData } from '../interfaces';
import { QuoteApproval } from '../interfaces/quote-approval';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Constants } from '../helpers';

@Injectable()
export class QuoteApprovalGuard {
	constructor(
		private http: HttpClient,
		private router: Router
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const approvalId = route.paramMap.get('id');

		return this.http.get<ItemResponseData<QuoteApproval>>(`${Constants.BASE_API_URL}/approve/${approvalId}`).pipe(
			map(res => {
				if (!res.status) {
					return this.router.parseUrl('/login');
				} else {
					return true;
				}
			}),
			catchError(err => this.router.navigateByUrl('/login'))
		);
	}
}
