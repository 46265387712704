export class AlertModel {
	public id: string;
	public status: string;
	public user: string;
	public task: string;
	public adminMessage: string;
	public isUnread?: boolean;
	public createdAt?: Date;

	constructor(model?: Partial<AlertModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
