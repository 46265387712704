import { UntypedFormGroup } from '@angular/forms';

import { EmbeddedBOQCoatProduct, EmbeddedCoatingSystem, EmbeddedRate } from '../interfaces';
import { BaseModel } from './base.model';

export class EmbeddedCoatingSystemModel extends BaseModel implements EmbeddedCoatingSystem {
	title: string = undefined;
	coatProducts?: EmbeddedBOQCoatProduct[] = [];
	chargeOutRates?: EmbeddedRate[] = [];
	currentChargeOutState?: string = undefined;

	/**
	 * Default constructor
	 */
	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(JSON.parse(JSON.stringify(json)));
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
