import { FormGroup, Validators } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';

import { CustomValidators, ValidatorHelper } from '../helpers';
import { UserModel } from '../models';
import { Region } from './region';
import { State } from './state';

class CompanyBase {
	public _id?: string;
	public abn?: string;
	public addressLine1?: string;
	public addressLine2?: string;
	public addressLine3?: string;
	public city?: string;
	public country?: string;
	public contacts?: Contact[] = [];
	public displayPictureKey?: string;
	public displayPictureUrl?: string;
	public email?: string;
	public fax?: string;
	public id?: string;
	public imageUrl?: string;
	public isActive?: boolean;
	public isClient?: boolean;
	public isParent: boolean;
	public isProvider?: boolean;
	public isSelected?: boolean;
	public name: string = '';
	public parentClient?: Company;
	public phone?: string;
	public postalCode?: string;
	public providerChildren?: Company[];
	public industryLicenseNumber?: string;
	public state?: State;
	public website?: string;
}

// Class for receiving model from the DB
export class Company extends CompanyBase {
	constructor(json?: any) {
		super();
		Object.assign(this, json);

		if (json && json._id) {
			this.id = json._id;
		}
	}
}

//template object
export interface Template {
	key: string;
	type: string;
}

//Contact object
export interface Contact {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	displayPictureKey?: string;
	displayPictureUrl?: string;
	email?: string;
	estimators?: UserModel[];
	fax?: string;
	id?: string;
	imageFile?: File | Blob;
	imageUrl?: string | SafeResourceUrl;
	mobile?: string;
	name: string;
	newEstimator?: UserModel;
	phone?: string;
	position?: string;
	postalCode?: string;
	state?: State;
}

// Company object
export interface Company {
	_id?: string;
	abn?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	contacts?: Contact[];
	displayPictureKey?: string;
	displayPictureUrl?: string;
	email?: string;
	fax?: string;
	id?: string;
	imageUrl?: string;
	isActive?: boolean;
	isClient?: boolean;
	isParent: boolean;
	isProvider?: boolean;
	isSelected?: boolean;
	name: string;
	parentClient?: Company;
	phone?: string;
	postalCode?: string;
	providerChildren?: Company[];
	industryLicenseNumber?: string;
	region?: Region;
	state?: State;
	website?: string;
}

// Dashboard Company Display Object
export interface DashboardCompany {
	cost: number;
	id: string;
	name: string;
}

//Response type given back when retrieving a company
export interface CompanyResponse {
	company: Company;
	status: boolean;
}

// Response type given back when retrieving a list of companies
export interface CompaniesResponse {
	companies: Company[];
	status: boolean;
}

export interface ClientSearch {
	city?: string;
	contact?: string;
	email?: string;
	fax?: string;
	isActive?: boolean;
	name?: string;
	phone?: string;
	state?: State;
}

export interface ClientResponse {
	clientNames?: string[];
	clientContactNames?: string[];
}

export interface ClientProject {
	_id: string;
	reference: number;
	name: string;
	status: string;
	updatedAt: string;
}

export interface ClientProjectSummary {
	projects: ClientProject[];
	won: number;
	lost: number;
	profit: number;
	strikeRate: number;
}

export function GetTemplateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Category',
			validators: [Validators.required],
		},
		{
			name: 'File',
			validators: [Validators.required],
		},
	]);
}

export function GetCompanyValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'TemplateKey',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'TemplateType',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Name',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Email',
			validators: [Validators.maxLength(255), Validators.required, Validators.email],
		},
		{
			name: 'Phone',
			validators: [Validators.maxLength(255), Validators.required],
		},
		{
			name: 'Fax',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Address1',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Address2',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Address3',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'State',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'PostalCode',
			validators: [Validators.maxLength(255), Validators.minLength(2)],
		},
		{
			name: 'ABN',
			validators: [
				Validators.maxLength(255),
				// Validators.minLength(9)
			],
		},
		{
			name: 'IndustryLicenseNumber',
			validators: [Validators.maxLength(255), Validators.minLength(1)],
		},
		{
			name: 'Website',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'State',
			validators: [Validators.maxLength(255), Validators.minLength(2)],
		},
		{
			name: 'Country',
			validators: [Validators.maxLength(255), Validators.minLength(2)],
		},
		{
			name: 'City',
			validators: [
				Validators.maxLength(255),
				Validators.minLength(2),
				// Validators.required
			],
		},
	]);
}

export function GetAddContactValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'ContactEmail',
			validators: [Validators.required, Validators.maxLength(300), Validators.email],
		},
		{
			name: 'ContactName',
			validators: [Validators.required, Validators.maxLength(300), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'ContactPosition',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactAddress1',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactAddress2',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactAddress3',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactCity',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactState',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactPostalCode',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactPhone',
			validators: [Validators.maxLength(200)],
		},
		{
			name: 'ContactFax',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'ContactMobile',
			validators: [Validators.maxLength(255)],
		},
	]);
}

export function GetClientSearchValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Contact',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'Name',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'Email',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'Phone',
			validators: [Validators.maxLength(30)],
		},
		{
			name: 'Fax',
			validators: [Validators.maxLength(50)],
		},
		{
			name: 'City',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'State',
			validators: [Validators.maxLength(300)],
		},
	]);
}

export function GetCompanyProjectSearchValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'From',
			validators: [Validators.required],
		},
		{
			name: 'To',
			validators: [Validators.required],
		},
	]);
}

export function GetMergeClientValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Client',
			validators: [Validators.required],
		},
	]);
}
