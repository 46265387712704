export interface UserTime {
	id?: string;
	user: string;
	startDate: Date;
	finishDate?: Date;
}

// Response type given back when retrieving a projectTime
export interface UserTimeResponse {
	status: boolean;
	userTime: UserTime;
}
