import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faLock, faLockOpen, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { Text } from '../../common/text.constants';
import { ButtonTextOptions, LayoutOptions, StyleOptions } from './common/split-button-dropdown.constants';
import { OptionsInterface } from './common/split-button-dropdown.interface';
import { InputButtonConfig } from './common/split-button-dropdown.model';

@Component({
	selector: 'itc-split-button-dropdown',
	templateUrl: './split-button-dropdown.component.html',
	styleUrls: ['./split-button-dropdown.component.scss'],
})
export class ItcSplitButtonDropdownComponent {
	@Input()
	public buttonText: string = '';
	@Input()
	public isButtonDisabled: boolean = false;
	@Input()
	public isDropDownOptionsDisabled: boolean = false;
	@Input()
	public isLocked: boolean = false;
	@Input()
	public set config(config: InputButtonConfig) {
		this._config = new InputButtonConfig(config);
	}
	public get config(): InputButtonConfig {
		return this._config;
	}

	@Output()
	public buttonPressed: EventEmitter<void> = new EventEmitter();
	@Output()
	public dropdownSelected: EventEmitter<OptionsInterface> = new EventEmitter();
	@Output()
	public isLockedChange: EventEmitter<boolean> = new EventEmitter();

	public layoutOptions: typeof LayoutOptions = LayoutOptions;
	public lockIcon: IconDefinition = faLock;
	public lockOpenIcon: IconDefinition = faLockOpen;
	public noDataPlaceholder: string = Text.noResults;
	public styleOptions: typeof StyleOptions = StyleOptions;
	public textOptions: typeof ButtonTextOptions = ButtonTextOptions;

	private _config: InputButtonConfig = new InputButtonConfig();

	constructor() {}

	public clicked(): void {
		this.buttonPressed.emit();
	}

	public optionSelected(option): void {
		if (!option.disabled) {
			this.dropdownSelected.emit(option);
		}
	}

	public toggleLock(): void {
		this.isLockedChange.emit(this.isLocked);
	}
}
