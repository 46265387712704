import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';

export interface FileFolder {
	id?: string;
	folder: string;
	company: string;
	templateTitle?: string;
	fileKey?: string;
	isActive: boolean;
	isProductMsds?: boolean;
}

export interface FilesFolderResponse {
	status: boolean;
	filesFolder: FileFolder;
}

export interface FilesFoldersResponse {
	status: boolean;
	filesFolders: FileFolder[];
	items?: FileFolder[];
}

export function GetFilesFolderValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Folder',
			validators: [],
		},
		{
			name: 'TemplateTitle',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
	]);
}
