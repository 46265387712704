import { NgModule } from '@angular/core';

import { ItcBusyDirective } from '../itc-busy.directive';
import { ItcBusyComponent } from './itc-busy.component';

@NgModule({
	imports: [],
	declarations: [ItcBusyComponent, ItcBusyDirective],
	exports: [ItcBusyComponent, ItcBusyDirective],
	providers: [],
})
export class ItcBusyModule {}
