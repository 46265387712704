import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Comment, DialogComment } from '../../../interfaces';
import { UserModel } from '../../../models';

@Component({
	selector: 'app-comment-view-dialog',
	templateUrl: './comment-view-dialog.component.html',
})
export class CommentViewDialogComponent implements OnInit {
	@Input() public readonly comment: DialogComment;
	@Input() public readonly users: UserModel[];
	@Input() public readonly userCanEditOrDeleteComment: boolean;
	@Input() public readonly isEditDisabled: boolean;
	@Output() public remove: EventEmitter<Comment> = new EventEmitter<Comment>();
	@Output() public save: EventEmitter<Comment> = new EventEmitter<Comment>();

	constructor() {}

	ngOnInit() {}

	public onSaveClick(comment: Comment) {
		this.save.emit(comment);
	}

	public onRemoveClick(comment: Comment) {
		this.remove.emit(comment);
	}
}
