import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ItemsResponse, ReferenceCode } from '../interfaces';
import { ReferenceCodeModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class RefCodeService extends BaseService<ReferenceCodeModel> {
	//this will keep the observable for caching
	public referenceCodesObservable: Observable<any> = null;
	//lists of referenceCodes that we have recieved
	public referenceCodes: ReferenceCode[];

	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.referenceCode, ReferenceCodeModel);
		this.referenceCodes = [];
	}

	//Get the reference codes from database
	//when we do not have in the cache.
	public getRefCodesWithTypesFromDatabase = (codeTypes: string[], isActive: boolean) => {
		this.referenceCodesObservable = this.httpClient
			.post<ItemsResponse>(`${Constants.BASE_API_URL}/referencecode/list`, {
				codeType: codeTypes,
				isActive,
			})
			.pipe(
				map(req => {
					const result = req.items.reduce((prev, code) => {
						if (prev[code.codeType] === undefined) {
							prev[code.codeType] = [];
						}

						prev[code.codeType].push(code);

						return prev;
					}, {});

					// We want substrate to always return alphabetically sorted
					if (result.substrate) {
						result.substrate.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
					}

					return result;
				}),
				tap(referenceCodes => (this.referenceCodes = referenceCodes ? referenceCodes : [])),
				shareReplay(1)
			);
	};

	/**
	 * Gets all referenceCodes with the given comma seperated types
	 * @param {string[]} codeTypes
	 * @param {boolean} isActive
	 * @returns {Observable<any>}
	 */
	public getRefCodesWithTypesAndStatus(codeTypes: string[] = [], isActive: boolean) {
		//Check if we do not have the reference code
		//in the list we should make the api request.
		if (!this.referenceCodesObservable) {
			this.getRefCodesWithTypesFromDatabase(codeTypes, isActive);
		} else {
			//we should check if we have the specific code in our list or not
			const cachedCodeTypes = Object.keys(this.referenceCodes);
			codeTypes.map(codeType => {
				if (cachedCodeTypes.indexOf(codeType) === -1) {
					cachedCodeTypes.push(codeType);
				}
			});
			//we should make an api call for getting
			//this list from database and save in the cache.
			if (cachedCodeTypes.length > 0) {
				this.getRefCodesWithTypesFromDatabase(cachedCodeTypes, isActive);
			}
		}
		return this.referenceCodesObservable;
	}

	/**
	 * Gets all ReferenceCode types from json file in server side
	 * @returns {Observable<any>}
	 */
	public getRefCodeTypes() {
		return this.httpClient.post<ItemsResponse>(`${Constants.BASE_API_URL}/referencecode/types`, {}).pipe(map(res => res.items));
	}
}

@Injectable()
export class RefCodeTypesResolver {
	constructor(private refCodeService: RefCodeService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.refCodeService.getRefCodeTypes();
	}
}

@Injectable()
export class RefCodeWithTypesResolver {
	constructor(private refCodeService: RefCodeService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.refCodeService.getRefCodesWithTypesFromDatabase(route.data.resolverCodeTypes, true);
	}
}

@Injectable()
export class ActiveRefCodeWithTypesResolver {
	constructor(private refCodeService: RefCodeService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.refCodeService.getRefCodesWithTypesAndStatus(route.data.resolverCodeTypes, true);
	}
}
