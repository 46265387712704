import { Component, Input } from '@angular/core';

@Component({
	selector: 'mos-card',
	standalone: true,
	templateUrl: './card.component.html',
})
export class CardComponent {
	@Input() public padding?: string = '4';
	@Input() public borderRadius?: string = 'tw-rounded-md';

}
