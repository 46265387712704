import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CompaniesResponse, Company, CompanyResponse, PaginateResponse, ReferenceCode } from '../interfaces';

@Injectable()
export class ExternalAccountingService {

    constructor(private http: HttpClient) {}

    public getXeroAuthorizationRedirect() {
		return this.http
			.get<any>(`${Constants.BASE_API_URL}/external-accounting/authorize-app`, {
				params: { service: 'xero' },
			})
			.pipe(
				map(res => {
					return !!res.redirect ? res.redirect : undefined;
				})
			);
    }

    public updateXeroAuthorization(code, scope, state, session_state) {
        return this.http
            .post<any>(`${Constants.BASE_API_URL}/external-accounting/update-authorization`, {
				params: {
                    service: 'xero',
                    code,
                    scope,
                    state,
                    session_state,
                },
			})
			.pipe(
				map(res => {return res;})
			);
    }

    public getConfig() {
		return this.http
			.get<any>(`${Constants.BASE_API_URL}/external-accounting/config`)
			.pipe(
				map(res => {
					return res;
				})
			);
    }

	public disconnectService() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/disconnect-authorization`, {})
			.pipe(
				map(res => {return res;})
			);
	}

	public syncClients() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/sync-clients`, {})
			.pipe(
				map(res => {return res;})
			);
	}

	public syncInvoices() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/external-accounting/sync-invoices`, {})
			.pipe(
				map(res => {return res;})
			);
	}
}
