import { Injectable, signal } from '@angular/core';
import { Feature, DEFAULT_FEATURES } from '../helpers/feature';
import { AuthService } from './auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
	public features = signal<Record<Feature, boolean>>(DEFAULT_FEATURES);

	constructor(private auth: AuthService) {
		this.auth.userDetailsObservable.pipe(takeUntilDestroyed()).subscribe(auth => {
			this.features.set(auth?.entity?.features ?? DEFAULT_FEATURES);
		});
	}

	public has(feature: Feature): boolean {
		return this.features()[feature] ?? false;
	}
}
