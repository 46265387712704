import { Component, Input } from '@angular/core';

import { BoxedListItemModel } from './common/boxed-list-item.models';

@Component({
	selector: 'paint-boxed-list-item',
	templateUrl: './boxed-list-item.component.html',
	styleUrls: ['./boxed-list-item.component.scss'],
})
export class BoxedListItemComponent {
	@Input()
	public inputConfig: BoxedListItemModel;

	constructor() {}
}
