// Response type given back when retrieving the project splits
import { Validators } from '@angular/forms';
import * as moment from 'moment';

import { ValidatorHelper } from '../helpers';

export interface PieChartData {
	labels: string[];
	colors: string[];
	data: number[];
}

export interface GanttChartData {
	clientNames: string[];
	disablePointer?: boolean;
	endDates: moment.Moment[];
	labels: string[];
	names: string[];
	startDates: moment.Moment[];
	statusColours: string[];
}

export interface BarChartData {
	labels?: string[];
	datasets: any[];
}

export function GetDashboardDateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'DateFrom',
			validators: [Validators.required],
		},
		{
			name: 'DateTo',
			validators: [Validators.required],
		},
	]);
}
