import { Injectable } from '@angular/core';

import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable()
export class TraineeAndAboveGuard {
	constructor(private permissionsService: RolePermissionsService) {}

	public canActivate(): boolean {
		return this.permissionsService.isTraineeAndAbove();
	}
}
