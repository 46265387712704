import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../helpers';
import { SignUpUser } from '../../../../interfaces';

@Injectable({
	providedIn: 'root',
})
export class SignupFormService {
	constructor(private http: HttpClient) {}

	public createEntity(signUpUser: SignUpUser) {
		return this.http.post(`${Constants.BASE_API_URL}/external-entity/signup`, signUpUser);
	}
}
