import { Injectable } from '@angular/core';
import * as Moment from 'moment';

import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { Task, TasksResponse } from '../../../../interfaces';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { S3Service } from '../../../../services/s3.service';
import { TaskService } from '../../../../services/task.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationBellService {
	private listEvent: ReplaySubject<Array<Task>> = new ReplaySubject();
	public list: Observable<Array<Task>> = this.listEvent.asObservable();

	constructor(
		private taskService: TaskService,
		private userService: LocalStorageService,
		private s3Service: S3Service
	) {}

	public getNotifications(): Observable<Array<Task>> {
		return this.taskService.postPaginate(0, 100, [this.userService.getUserId()]).pipe(
			map((tasksResponse: TasksResponse) => {
				/*
                    FR.4 Section 1 Part a
                    Incomplete tasks that are assigned to the user will show up in the notifications section
                    if they are due on that day.
                 */
				return tasksResponse.tasks.filter((task: Task) => {
					const startOfDueDate: Moment.Moment = Moment(task.dueDate).startOf('day');
					const startOfToday: Moment.Moment = Moment().startOf('day');
					const isDueDateToday: boolean = Math.abs(startOfDueDate.diff(startOfToday, 'day')) === 0;
					return !task.isComplete && isDueDateToday;
				});
			}),
			switchMap((tasks: Array<Task>) => {
				// Retrieve author's display picture
				// Loop through all tasks to get the assignee picture
				// If no key, just return the task
				const calls = tasks.map((task: Task) => {
					if (task.assignee && task.assignee.displayPictureKey) {
						return this.s3Service.getSignedUrl(task.assignee.displayPictureKey).pipe(
							map((pictureUrl: string) => {
								// Add the picture url to the assignee
								task.assignee.displayPictureUrl = pictureUrl;
								return task;
							})
						);
					} else {
						return of(task);
					}
				});
				if (calls.length) {
					return forkJoin(calls);
				} else {
					return of(tasks);
				}
			}),
			tap((tasks: Array<Task>) => {
				// Pass the finished list to the notification-panel component
				this.listEvent.next(tasks);
			})
		);
	}
}
