import { Injectable } from '@angular/core';

import { RolePermissionsService } from '../services/role-permissions.service';

@Injectable({
	providedIn: 'root',
})
export class ContractsAndAboveGuard {
	constructor(private permissionsService: RolePermissionsService) {}

	public canActivate(): boolean {
		return this.permissionsService.isContractsAndAbove();
	}
}
