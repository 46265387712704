import { Component, Input } from '@angular/core';

import { variables } from '../../../../_variables';
import { ProjectStatus } from '../common/project.interfaces';
import { statusBarItems } from './common/project-status-bar.constants';

@Component({
	selector: 'paint-project-status-bar',
	templateUrl: './project-status-bar.component.html',
	styleUrls: ['./project-status-bar.component.scss'],
})
export class ProjectStatusBarComponent {
	@Input() public status: ProjectStatus;

	public statusBarItems: typeof statusBarItems = statusBarItems;
	protected readonly variables = variables;
	public hover: string[] = null;
}
