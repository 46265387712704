import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[type=number]',
})
export class InputNumberDirective {
	@Input() isInteger: boolean;

	constructor(
		private el: ElementRef,
		private ngControl: NgControl
	) {}

	@HostListener('focus') onFocus() {
		this.el.nativeElement.select();
	}

	@HostListener('click') onClick() {
		this.el.nativeElement.select();
	}

	@HostListener('change') onChange() {
		this.updateValue();
	}

	updateValue() {
		const value: number = this.ngControl.value;
		if (value) {
			const fixedNumber: number = this.isInteger ? 0 : 2;
			const roundedValue: string = value.toFixed(fixedNumber);
			if (value.toString() !== roundedValue) {
				this.ngControl.control.setValue(Number(roundedValue));
			}
		}
	}
}
