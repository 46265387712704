import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Constants } from '../../../helpers';
import { ChargeModel } from '../../../models';
import { AdditionalChargeService } from '../../../services/additional-charge.service';
import { BoqService } from '../../../services/boq.service';
import { AddContactTextConst } from '../contact-dialog/components/add-contact-person/common/add-contact-person.constants';

@Component({
	selector: 'app-comments-edit-dialog',
	templateUrl: './comments-edit-dialog.component.html',
})
export class CommentsEditDialogComponent {
	@Input()
	public set charge(charge: ChargeModel) {
		this._charge = charge;

		if (!this._charge) {
			return;
		}

		this.isBoq = this._charge.chargeType === Constants.CHARGE_TYPES.Boq;

		if (this.isBoq) {
			this.boqComment = this._charge.chargeOutAmounts.comment;
			this.boqInternalComment = this._charge.chargeOutAmounts.internalComment;
		} else if (!this.isBoq) {
			this.additionalChargeComment = this._charge.comment;
		}
	}

	@Input()
	public isDisabled: boolean = false;

	@Input()
	public projectId: string;

	@Output()
	public chargeUpdated: EventEmitter<void> = new EventEmitter<void>();

	public additionalChargeComment: string;
	public boqComment: string;
	public boqInternalComment: string;
	public editAdditionalChargeSubscription: Subscription;
	public editBoqSubscription: Subscription;
	public isBoq: boolean;
	public text: typeof AddContactTextConst = AddContactTextConst;

	private _charge;

	constructor(
		private additionalChargeService: AdditionalChargeService,
		private boqService: BoqService
	) {}

	public onSave(): void {
		if (this.isBoq) {
			this._charge.chargeOutAmounts.comment = this.boqComment;
			this._charge.chargeOutAmounts.internalComment = this.boqInternalComment;

			this.editBoqSubscription = this.boqService
				.editBoq(this._charge, this._charge.project)
				.pipe(
					finalize(() => {
						this.chargeUpdated.emit();
						this.editBoqSubscription.unsubscribe();
					})
				)
				.subscribe();
		} else if (!this.isBoq) {
			this._charge.comment = this.additionalChargeComment;

			this.editAdditionalChargeSubscription = this.additionalChargeService
				.editAdditionalCharge(this._charge._id, this._charge, this.projectId)
				.pipe(
					finalize(() => {
						this.chargeUpdated.emit();
						this.editAdditionalChargeSubscription.unsubscribe();
					})
				)
				.subscribe();
		}
	}
}
