import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { UserModel } from '../models';

export interface UserGroup {
	id: string;
	name: string;
	teamLeader?: UserModel;
	users: Array<UserModel>;
	isActive: boolean;
}

export interface UserGroupResponse {
	status: boolean;
	userGroup: UserGroup;
}

export interface UserGroupsResponse {
	status: boolean;
	userGroups: Array<UserGroup>;
}

export function GetUserGroupValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'TeamLeader',
			validators: [Validators.required],
		},
	]);
}
