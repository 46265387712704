import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent } from '../helpers';
import { ItemResponseData, ItemsResponse, Paginate, PartialUsersResponse, PostParams, ReferenceCode, UserResponse, UsersResponse } from '../interfaces';
import { UserModel } from '../models';
import { ClientService } from './client.service';
import { LocalStorageService } from './local-storage.service';
import { ProviderService } from './provider.service';
import { QuoteApproval } from '../interfaces/quote-approval';
import { QuoteApprovalModel } from '../models/quote-approval.model';

@Injectable()
export class GetActiveAdminsResolver {
	constructor(private userService: UserService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		const params = {
			role: Constants.USER_ROLES.admin.title,
			isActive: true,
		};
		return this.userService.getUsers(params);
	}
}

@Injectable()
export class GetClientsForUsersResolver {
	constructor(private clientService: ClientService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.clientService.getClients();
	}
}

@Injectable()
export class GetCurrentUserResolver {
	constructor(
		private localStorage: LocalStorageService,
		private userService: UserService
	) {}

	public resolve(): Observable<any> | Promise<any> | any {
		const user = this.localStorage.getUserDetails();

		return user && user.id ? this.userService.getUser(user.id) : undefined;
	}
}

@Injectable()
export class GetProvidersForUsersResolver {
	constructor(private providerService: ProviderService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.providerService.getProviders();
	}
}

@Injectable()
export class GetUsersResolver {
	constructor(private userService: UserService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.userService.getUsers({ isActive: true });
	}
}

@Injectable()
export class UserService {
	constructor(
		private http: HttpClient,
		public snack: SnackBarHelperComponent
	) {}

	/**
	 * Gets all users
	 * @returns {Observable<any>}
	 */
	public updatePicture(userId: string, awsKey: string) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/updatepicture`, {
				id: userId,
				key: awsKey,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Gets all users
	 * @returns {Observable<any>}
	 */
	public getUsers(query?: PostParams) {
		return this.http.post<UsersResponse>(`${Constants.BASE_API_URL}/user/list`, { query }).pipe(
			map(res => {
				return res.users.map(user => {
					return new UserModel(user);
				});
			})
		);
	}

	/**
	 * Gets all users
	 * @returns {Observable<any>}
	 */
	public getUnassignedUsers(assignedUsers: String[] = []) {
		return this.http
			.post<UsersResponse>(`${Constants.BASE_API_URL}/user/list/unassigned`, {
				assignedUsers,
			})
			.pipe(map(res => res.users));
	}

	/**
	 * Adds a new user
	 * @param {User} user
	 * @param {User?}currentUser
	 * @returns {Observable<any>}
	 */
	public addUser(user: UserModel, currentUser?) {
		const body = { user: user, currentUser: {} };
		if (currentUser) {
			body.currentUser = { id: currentUser.id, email: currentUser.email };
		}
		return this.http.post<UserResponse>(`${Constants.BASE_API_URL}/user/`, body).pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Gets the user matching id
	 * @param {String} id
	 * @returns {Observable<Object>}
	 */
	public getUser(id: String) {
		return this.http.get<UserResponse>(`${Constants.BASE_API_URL}/user/${id}`).pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Updates the current users password with the supplied string
	 * @param {String} currentPassword
	 * @param {String} newPassword
	 * @returns {Observable<UserResponse>}
	 */
	public updatePassword(currentPassword: string, newPassword: String) {
		return this.http.post<UserResponse>(`${Constants.BASE_API_URL}/user/changepassword`, {
			currentPassword: currentPassword,
			password: newPassword,
		});
	}

	public updateUser(id: String, user: UserModel) {
		return this.http.put<UserResponse>(`${Constants.BASE_API_URL}/user/${id}`, new UserModel(user).getPostObject()).pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Resend the confirm email to the use manually
	 * @param {User} user
	 */
	public resendInviteEmail(user: UserModel, currentUser?: UserModel) {
		const body = { user: user, currentUser: {} };
		if (currentUser) {
			body.currentUser = { id: currentUser.id, email: currentUser.email };
		}
		return this.http.post<UserResponse>(`${Constants.BASE_API_URL}/user/sendinviteemail`, body).pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Gets Users for pagination
	 * @returns {Observable<any>}
	 */
	public postPaginate(pagin: Paginate) {
		return this.http.post<UsersResponse>(`${Constants.BASE_API_URL}/user/paginate`, pagin).pipe(map(res => res.users));
	}

	/**
	 * Gets totalCount
	 * @returns {Observable<any>}
	 */
	public postCount(params?: PostParams) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/user/count`, { params }).pipe(map(res => res.total));
	}

	public getCompanyUsers(providers: Array<string>) {
		return this.http
			.post<ItemsResponse>(`${Constants.BASE_API_URL}/user/company-users`, {
				providers,
			})
			.pipe(map(res => res.items));
	}

	/**
	 * Adds a favourite template on the current User.
	 * @param {string} templateId
	 * @returns {Observable<UserResponse>}
	 */
	public addTemplate(templateId: string) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/add-template`, {
				templateId,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Removes a favourite template on the current User.
	 * @param {string} templateId
	 * @returns {Observable<UserResponse>}
	 */
	public removeTemplate(templateId: string) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/remove-template`, {
				templateId,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Changes the active state of an existing user.
	 * @param id
	 * @param isActive
	 * @param newId
	 * @returns {Observable<User>}
	 */
	public postEditIsActive(id: string, isActive: boolean, newId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/user/edit-active`, {
				id,
				newId,
				isActive,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	public updateUserTour(tourId: string, complete: boolean) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/update-tour`, {
				tourId,
				complete,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	public getUserTours(userId: string) {
		return this.http
			.get<UserResponse>(`${Constants.BASE_API_URL}/user/${userId}/tours`) // Adjust the URL as necessary
			.pipe(map(response => new UserModel(response)));
	}

	/**
	 * Update the default pay rate information for a group of users
	 * @param userIds 
	 * @param interval 
	 * @param weekday 
	 * @param saturday 
	 * @param sunday 
	 * @param holiday 
	 * @returns 
	 */
	public updateUsersPayRates(userIds: string[], interval: string, weekday: number, saturday: number, sunday: number, holiday: number) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/edit-pay-rates`, {
				ids: userIds,
				interval,
				weekday,
				saturday,
				sunday,
				holiday,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Add leave hours to a group of users
	 * @param userIds
	 * @param addedHours
	 * @param leaveType
	 * @returns 
	 */
	public addLeave(userIds: string[], addedHours: number, leaveType: string) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/add-leave`, {
				ids: userIds,
				hours: addedHours,
				leaveType: leaveType,
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Update the user details for a shift worker
	 * @param userId
	 * @param updatedWorker 
	 * @returns 
	 */
	public updateWorkerDetails(userId: string, updatedWorker: UserModel) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/edit-worker-details`, {
				id: userId,
				updatedWorker: new UserModel(updatedWorker).getPostObject(),
			})
			.pipe(map(res => new UserModel(res.user)));
	}

	/**
	 * Add a new shift worker
	 * @param newWorker
	 * @returns 
	 */
	public addWorker(newWorker: UserModel) {
		return this.http
			.post<UserResponse>(`${Constants.BASE_API_URL}/user/add-shift-worker`, {
				user: newWorker
			})
			.pipe(map(res => new UserModel(res.user)));
	}
}
