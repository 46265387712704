import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ModalService } from '../../../app/services/modal.service';
import { ModalText } from './common/modal.constants';
import { ItcModalConfig } from './common/modal.models';

@Component({
	selector: '[itc-modal]',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalDirectiveComponent {
	@Input()
	public isActive: boolean = true;
	@Input()
	public isBusy: boolean = false;
	@Output()
	public isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input()
	public set config(config: ItcModalConfig) {
		this._config = new ItcModalConfig(config);
	}

	public get config(): ItcModalConfig {
		return this._config;
	}

	@Input()
	private set show(show: boolean) {
		if (this._show !== show) {
			show ? this.showModal() : this.hideModal();
		}
		this._show = show;
	}
	private get show(): boolean {
		return this._show;
	}

	@Output()
	public additionalButton: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	public cancel: EventEmitter<null> = new EventEmitter<null>();
	@Output()
	public close: EventEmitter<null> = new EventEmitter<null>();
	@Output()
	public confirm: EventEmitter<null> = new EventEmitter<null>();
	@Output()
	private showChange: EventEmitter<boolean> = new EventEmitter();
	@Output()
	private toggleActive: EventEmitter<boolean> = new EventEmitter();

	@ViewChild('modalTemplate')
	private modalTemplate: any;

	public modalText: typeof ModalText = ModalText;

	private _config: ItcModalConfig;
	private _show: boolean = false;

	constructor(
		private modalService: BsModalService,
		private appModalService: ModalService
	) {}

	public additionalButtonAction(): void {
		this.additionalButton.emit();
	}

	public cancelAction(): void {
		this.cancel.emit();
		this.closeModal();
	}

	public confirmAction(): void {
		this.confirm.emit();
	}

	public onToggleActive(): void {
		this.isActive = !this.isActive;
		this.toggleActive.emit(this.isActive);
	}

	private closeModal(): void {
		this.showChange.emit(false);
		this.close.emit();
		this.hideModal();
	}

	private hideModal(): any {
		this.modalService.hide();
		this.appModalService.setIsOpen(false);
	}

	private showModal(): any {
		this.appModalService.setIsOpen(true);
		this.modalService.show(this.modalTemplate, {
			class: 'modal-dialog-centered',
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}
}
