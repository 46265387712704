import { Component, Input } from '@angular/core';

import { Constants, TEXT } from '../../../helpers';
import { AlertModel } from '../../../pages/alert/common/alert.models';

@Component({
	selector: 'alert-view-dialog',
	templateUrl: './alert-view-dialog.component.html',
	styleUrls: ['./alert-view-dialog.component.scss'],
})
export class AlertViewDialogComponent {
	@Input()
	public set alert(alert: AlertModel) {
		this._alert = alert;
	}
	public get alert(): AlertModel {
		return this._alert;
	}

	private _alert: AlertModel;
	public readonly dateFormats = Constants.DATE_FORMATS;
	public readonly alertStatus = Constants.ALERT_STATUS;
	public textConstants = TEXT;
	public alertPromise: Promise<AlertModel>;

	constructor() {}
}
