import { Component, Input } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Constants } from '../../../helpers';
import { ProjectService } from '../../../services/project.service';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-suppliers-tab',
	templateUrl: './suppliers-tab.component.html',
})
export class SuppliersTabComponent {
	@Input() totalCount: number = 0;

	@Input()
	set projectId(value: string) {
		if (value) {
			this._projectId = value;
		}
	}
	get projectId() {
		return this._projectId;
	}

	public headers = [Constants.LIBRARY_HEADER_TYPES.productName, Constants.LIBRARY_HEADER_TYPES.supplierName];
	public embeddedSuppliers: any[] = [];
	public libraryTypesConst = Constants.LIBRARY_TYPES;
	public selectedTab: boolean = false;
	public tabIconPath: string = Constants.SVG_ICON_PATHS.localOffer;

	private _projectId: string;

	constructor(
		private projectService: ProjectService,
		private s3Service: S3Service
	) {}

	public load(): void {
		this.projectService.postSuppliers(this.projectId).subscribe((suppliers: any[]) => {
			this.embeddedSuppliers = suppliers;
			this.totalCount = this.embeddedSuppliers.length;
			this.getSuppliersImages();
		});
	}

	public onDeselectTab(): void {
		this.selectedTab = false;
	}

	public onSelectTab(tabEvent: TabDirective): void {
		if (tabEvent.active && this.projectId) {
			this.load();
			this.selectedTab = true;
		}
	}

	/**
	 * Download images for each product to display.
	 */
	private getSuppliersImages(): void {
		for (const supplier of this.embeddedSuppliers) {
			if (supplier.thumbnailKey) {
				this.s3Service.getSignedUrl(supplier.thumbnailKey).subscribe((url: string) => {
					supplier.imageUrl = url;
				});
			}
		}
	}
}
