import { Validators } from '@angular/forms';

import { Constants, CustomValidators, measurementTypes, ValidatorHelper } from '../helpers';
import { Boq, Colour, EmbeddedCoatingSystem, LabourCost, LabourRate, Product, ReferenceCode } from '../interfaces';
import { BoqDimensionsModel } from './boq-dimensions.model';
import { ChargeModel } from './charge.model';

export class BoqModel extends ChargeModel implements Boq {
	public areaDimensionsUnits: number = 0;
	public chargeCategory: ReferenceCode = undefined;
	public chargeOutAmounts: {
		chargeCategory: string;
		ratePerUnit: number;
		margin: number;
		comment: string;
		internalComment: string;
	} = {
		chargeCategory: undefined,
		ratePerUnit: 1,
		margin: 0,
		comment: undefined,
		internalComment: undefined,
	};
	public chargeOutHeightFeeTotal: number = 0;
	public chargeOutLabourCostPerUnit: number = 0;
	public chargeOutLabourCostTotal: number = 0;
	public chargeOutMaterialCostPerUnit: number = 0;
	public chargeOutMaterialCostTotal: number = 0;
	public chargeType: string = Constants.CHARGE_TYPES.Boq;
	public coatingSystem: EmbeddedCoatingSystem = undefined;
	public colour: Colour = undefined;
	public difficulty: number = 0;
	public dimensions: BoqDimensionsModel = new BoqDimensionsModel();
	public discount: number = 0;
	public discountPercentage: number = 0;
	public distribution: [
		{
			product: string;
			totalRequiredLitre: number;
			totalLabourHour: number;
			distribution: {
				totalUnits: number;
				size: number;
				cost: number;
			};
			totalWasteCost: number;
		},
	] = undefined;
	public division: string = undefined;
	public environment: string = undefined;
	public estimatedDimensions: BoqDimensionsModel = new BoqDimensionsModel({
		units: 0,
		unitType: measurementTypes.m2,
	});
	public grossTotalChargeOut: number = 0;
	public heightRange: string = undefined;
	public id: string = undefined;
	public isActive: boolean = true;
	public isAfterHours: boolean = undefined;
	public isBasedOnMargin: boolean = undefined;
	public isDiscountPercentage: boolean = undefined;
	public isEbaRate: boolean = undefined;
	public isSelected: boolean = false;
	public labourCost?: LabourRate | LabourCost = null;
	public labourCostPerHour: number = 0;
	public labourCostPerUnit: number = 0;
	public labourCostTotal: number = 0;
	public labourHoursTotal: number = 0;
	public location: string;
	public materialCostPerUnit: number = 0;
	public materialCostTotal: number = 0;
	public product: Product = undefined;
	public project: string = undefined;
	public subLocation1: string = undefined;
	public subLocation2: string = undefined;
	public subLocation3: string = undefined;
	public substrate: ReferenceCode = undefined;
	public totalCharge: number = 0;
	public totalCost: number = 0;
	public totalCostPerUnit: number = 0;
	public totalPrice: number = 0;
	public totalProfit: number = 0;
	public totalTargetRate: number = 0;
	public useEstimatedValues: boolean = false;
	public wasteCostPerUnit: number = 0;
	public wasteCostTotal: number = 0;
	public order: number = 0;
	public variation?: string = undefined;

	/**
	 * Default constructor
	 */
	constructor(json?: any) {
		super();
		if (json) {
			Object.assign(this, JSON.parse(JSON.stringify(json)));
		}
	}

	/**
	 * Static function to create a new object of this class
	 *
	 * @param {object} json
	 * @returns {BoqModel}
	 */
	public static createFromJson(json: object) {
		const newObject = new this(); // this is the class because it's in a static function
		newObject.merge(json);
		return newObject;
	}

	public GetChargeOutAmountsValidation(isDisabled: boolean) {
		return ValidatorHelper.formBuilder(
			[
				{
					name: 'ChargeCategory',
					validators: [],
				},
				{
					name: 'RatePerUnit',
					validators: [Validators.min(0)],
				},
				{
					name: 'GP',
					validators: [Validators.min(0)],
				},
				{
					name: 'Comment',
					validators: [],
				},
				{
					name: 'InternalComment',
					validators: [],
				},
			],
			isDisabled
		);
	}

	public GetAreaDimensionsValidation(isDisabled: boolean, boq: BoqModel) {
		const validators: any[] = [
			{
				name: 'Profile',
				validators: [],
				value: boq.useEstimatedValues ? boq.estimatedDimensions.profile : boq.dimensions.profile,
			},
			{
				name: 'UnitType',
				validators: [],
				value: boq.useEstimatedValues ? boq.estimatedDimensions.unitType : boq.dimensions.unitType,
			},
		];

		if (!boq.useEstimatedValues) {
			validators.push({
				name: 'Units',
				validators: [Validators.required],
				value: boq.dimensions.units,
			});
		}
		return ValidatorHelper.formBuilder(validators, isDisabled);
	}

	public getFormData(): FormData {
		return undefined;
	}

	public getPostObject(): BoqModel {
		return JSON.parse(JSON.stringify(this, BoqModel.removeKeysReplacer));
	}
}

export function GetBoqValidation(isDisabled: boolean, boq?: BoqModel) {
	return ValidatorHelper.formBuilder(
		[
			{
				name: 'ChargeCategory',
				validators: [Validators.required, Validators.maxLength(300)],
				value: boq.chargeCategory ?? undefined,
			},
			{
				name: 'Discount',
				validators: [Validators.min(0)],
				value: boq.discount ?? undefined,
			},
			{
				name: 'Environment',
				validators: [Validators.required],
				value: boq.environment ?? undefined,
			},
			{
				name: 'LabourType', // Used to be LabourCost, changed to match form label when used in snackbar message
				validators: [Validators.required],
				value: boq.labourCost ?? undefined,
			},
			{
				name: 'SubLocation1',
				validators: [Validators.maxLength(255), Validators.required],
				value: boq.subLocation1 ?? undefined,
			},
			{
				name: 'SubLocation2',
				validators: [Validators.maxLength(255)],
				value: boq.subLocation2 ?? undefined,
			},
			{
				name: 'SubLocation3',
				validators: [Validators.maxLength(255)],
				value: boq.subLocation3 ?? undefined,
			},
			{
				name: 'Division',
				validators: [Validators.required],
				value: boq.division ?? undefined,
			},
			{
				name: 'HeightRange',
				validators: [Validators.required],
				value: boq.heightRange ?? undefined,
			},
			{
				name: 'Product',
				validators: [Validators.required],
				value: boq.product ?? undefined,
			},
			{
				name: 'DiscountType',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CompletedMeasurement',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CompletedLabourType',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CompletedProfileCode1',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CompletedHeightRangeCode1',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CoatingSystemTitle',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CoatingSystemApplication',
				validators: [Validators.maxLength(255)],
			},
			{
				name: 'CoatingSystem',
				validators: [Validators.required],
				value: boq.coatingSystem ?? undefined,
			},
			{
				name: 'CoatingSystemProducts',
				validators: [CustomValidators.minLengthArray(1)],
				value: boq.coatingSystem?.coatProducts ?? undefined,
			},
			{
				name: 'Substrate',
				validators: [Validators.required],
				value: boq.substrate,
			},
			{
				name: 'Difficulty',
				validators: [Validators.min(0), Validators.max(100), Validators.required],
				value: boq.difficulty,
			},
		],
		isDisabled
	);
}

export function GetSubstrateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Substrate',
			validators: [Validators.required],
		},
	]);
}
