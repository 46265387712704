import { Component, Input, OnInit } from '@angular/core';

import { Constants, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { GetPasswordValidation } from '../../../interfaces';
import { UserModel } from '../../../models';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-update-password-dialog',
	templateUrl: './update-password-dialog.component.html',
	styleUrls: ['./update-password-dialog.component.scss'],
})
export class UpdatePasswordDialogComponent implements OnInit {
	@Input() private currentUser: UserModel;

	public currentPassword: string;
	public newPassword: string;
	public verifyPassword: string;
	public passwordValidators;

	constructor(
		private snack: SnackBarHelperComponent,
		private userService: UserService
	) {}

	public ngOnInit() {
		this.passwordValidators = GetPasswordValidation();
	}

	/**
	 * Validates and checks the input fields for password, then sends a request to compare to the current one.
	 */
	public async changePassword() {
		return new Promise((resolve, reject) => {
			const errors = ValidatorHelper.checkErrors(this.passwordValidators);
			if (errors) {
				this.snack.snackError(errors);
				return reject();
			}
			if (!this.isPasswordVerified()) {
				return reject();
			}

			this.userService.updatePassword(this.currentPassword, this.newPassword).subscribe(resolve, reject);
		});
	}

	public resetForm(): void {
		this.passwordValidators = GetPasswordValidation();
	}

	/**
	 * Returns whether password is verified.
	 */
	private isPasswordVerified(): boolean {
		if (this.newPassword && this.verifyPassword) {
			if (this.newPassword !== this.verifyPassword) {
				this.snack.snackError(Constants.PASSWORD_MATCH_MESSAGE);
			}
			return this.newPassword === this.verifyPassword;
		} else {
			return false;
		}
	}
}
