import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { Paginate, PostParams, ProjectTime, ProjectTimeResponse, ProjectTimesResponse } from '../interfaces';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ProjectTimeService {
	private endPoint: string = Constants.END_POINTS.projectTime;
	private projectTime: ProjectTime;
	private updateActiveInterval;

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private localStorageService: LocalStorageService
	) {}

	/**
	 * Get the total time spent by all the users on the specified project.
	 * @param {string} projectId
	 * @returns {Observable<any>}
	 */
	public getTotalTimeSpentOnProject(projectId: string) {
		const params = {
			project: projectId,
		};
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/${this.endPoint}/count-time`, {
				params,
				projectId,
			})
			.pipe(map(res => res.time));
	}

	/**
	 * Gets Project Times for pagination
	 * @returns {Observable<*>}
	 */
	public postPaginate(pagin: Paginate) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/${this.endPoint}/paginate`, pagin).pipe(map(res => res.projectTimes));
	}

	/**
	 * Gets totalCount
	 * @returns {Observable<any>}
	 */
	public postCount(params: PostParams = {}) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/${this.endPoint}/count`, { params }).pipe(map(res => res.total));
	}

	/**
	 * Get the time spent on the project after the quote was submitted.
	 * @param {string} projectId
	 * @param {Date} submittedDate
	 * @returns {Observable<any>}
	 */
	public getAfterSubmittedTimeSpentOnProject(projectId: string, submittedDate: Date) {
		const params = {
			project: projectId,
			finishDate: {
				after: submittedDate,
			},
		};
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/${this.endPoint}/count-time`, {
				params,
				projectId,
			})
			.pipe(map(res => res.time));
	}

	/**
	 * Get the time spent on the project before the quote was submitted.
	 * @param {string} projectId
	 * @param {Date} submittedDate
	 * @returns {Observable<any>}
	 */
	public getBeforeSubmittedTimeSpentOnProject(projectId: string, submittedDate: Date) {
		const params = {
			project: projectId,
			finishDate: {
				before: submittedDate,
			},
		};
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/${this.endPoint}/count-time`, {
				params,
				projectId,
			})
			.pipe(map(res => res.time));
	}

	/**
	 * Adds a new project time
	 * @param {String} projectId
	 * @param {ProjectTime} projectTime
	 * @returns {Observable<BrandsResponse>}
	 */
	public addProjectTime(projectId: string, projectTime: ProjectTime) {
		return this.http
			.post<ProjectTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/create`, {
				projectId,
				projectTime,
			})
			.pipe(map(res => res.projectTime));
	}

	/**
	 * Get a list of project Time, matching the project
	 * @param {string} projectId
	 */
	public getManualProjectTimesOnProject(projectId: string) {
		const params = {
			project: projectId,
			isManual: true,
		};
		return this.http.post<ProjectTimesResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/list`, { params, projectId }).pipe(map(res => res.projectTimes));
	}

	/**
	 * Delete a projectTime.
	 * @param projectTimeId
	 * @param projectId
	 */
	public delete(projectTimeId: string, projectId: string) {
		return this.http.post<ProjectTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/delete`, { projectTimeId, projectId }).pipe(map(res => res.projectTime));
	}

	/**
	 * Starts a new project time session
	 */
	public startNew(projectId: string): void {
		const userId = this.localStorageService.getUserId();
		if (userId) {
			this.http.post<ProjectTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/start`, { userId, projectId }).subscribe(res => {
				// If the request was successful, update the active time periodically
				if (res.status) {
					this.projectTime = res.projectTime;
					// Clear any previous interval
					if (this.updateActiveInterval) {
						clearInterval(this.updateActiveInterval);
					}
					this.updateActiveInterval = setInterval(() => {
						this.updateActiveTime(projectId);
					}, Constants.ACTIVE_TIME_UPDATE_RATE);
				}
			});
		}
	}

	/**
	 * Completes a project time session
	 */
	public complete(projectId: string): void {
		if (this.projectTime && this.authService.isAuthenticated()) {
			this.http
				.post<ProjectTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/complete`, {
					projectTimeId: this.projectTime.id,
					projectId,
				})
				.subscribe();
			this.projectTime = null;
		}
	}

	/**
	 * Updates the last active date of a project time session
	 */
	public updateActiveTime(projectId: string): void {
		if (this.projectTime && this.authService.isAuthenticated()) {
			this.http
				.post<ProjectTimeResponse>(`${Constants.BASE_API_URL}/${this.endPoint}/last-active`, {
					projectTimeId: this.projectTime.id,
					projectId,
				})
				.subscribe();
		}
	}

	/**
	 * Returns the project time session id
	 */
	public getCurrentProjectTimeSession(): string {
		return this.projectTime ? this.projectTime.id : undefined;
	}
}

@Injectable()
export class GetTotalTimeSpentOnProject {
	constructor(private projectTimeService: ProjectTimeService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> {
		if (!route.params.projectId) {
			return of(0);
		}
		return this.projectTimeService.getTotalTimeSpentOnProject(route.params.projectId).pipe(map(res => res.totalTimeSpentOnProject));
	}
}
