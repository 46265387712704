import { BoqDimensionsModel } from '../models';
import { Colour } from './colour';
import { EmbeddedCoatingSystem } from './embedded-coating-system';
import { LabourRate } from './labourRate';
import { Product } from './product';
import { LabourCost } from './project';
import { ReferenceCode } from './reference-code';

// Charge object
export interface Charge {
	id?: string;
	_id?: string;
	isActive: boolean;
	totalCost: number;
	discount: number;
	order: number;
	discountPercentage: number;
	isDiscountPercentage: boolean;
	labourHoursTotal?: number;
	totalCharge: number;
	grossTotalChargeOut: number;
	chargeType: string;
	isSelected: boolean;
	project: string;
	product?: Product;
	colour?: Colour;

	// Additional Charges
	chargeCategory: ReferenceCode;
	environment: string;
	description: string;
	markUp: number;
	rate?: number;
	comment: string;
	isExportHidden: boolean;
	boq?: string;
	overtimeRate?: number;
	embeddedSupplierRate: {
		dailyHireRate?: number;
		weeklyHireRate?: number;
		transportCost?: number;
		duration?: number;
		isDaily?: boolean;
	};
	fileKeys: string[];
	requiredPaintUnit?: number;

	// BOQ
	division: string;
	location?: string;
	subLocation1?: string;
	subLocation2?: string;
	subLocation3?: string;
	substrate: ReferenceCode;
	heightRange: string;
	difficulty: number;
	dimensions: {
		unitType: string;
		profile?: ReferenceCode;
		units: number;
	};
	coatingSystem: EmbeddedCoatingSystem;
	labourCost?: LabourRate | LabourCost;
	chargeOutAmounts: {
		chargeCategory: string;
		ratePerUnit: number;
		margin: number;
		comment: string;
	};
	totalRate?: number;
	areaDimensionsUnits: number;
	labourCostPerUnit: number;
	labourCostTotal: number;
	materialCostPerUnit: number;
	materialCostTotal: number;
	wasteCostPerUnit: number;
	wasteCostTotal: number;
	totalCostPerUnit: number;
	chargeOutLabourCostPerUnit: number;
	chargeOutLabourCostTotal: number;
	chargeOutMaterialCostPerUnit: number;
	chargeOutMaterialCostTotal: number;
	chargeOutHeightFeeTotal: number;
	distribution: [
		{
			product: string;
			totalRequiredLitre: number;
			totalLabourHour: number;
			distribution: {
				totalUnits: number;
				size: number;
				cost: number;
			};
			totalWasteCost: number;
		},
	];
}

export interface BulkCharge {
	bulkBoq: {
		coatingSystem?: EmbeddedCoatingSystem;
		discount?: number;
		division?: string;
		environment?: string;
		useEstimatedValues?: boolean;
		subLocation1?: string;
		subLocation2?: string;
		subLocation3?: string;
		substrate?: string;
		product?: string;
		targetProjectId?: string;
		heightRange?: string;
		difficulty?: number;
		colour?: string;
		dimensions?: BoqDimensionsModel;
		estimatedDimensions?: BoqDimensionsModel;
		chargeOutAmounts?: {
			comment: string;
			internalComment: string;
			ratePerUnit: number;
		};
		areaDimensionsUnits?: number;
	};
	bulkAdditionalCharge: {
		environment?: string;
		description?: string;
		discount?: number;
		comment?: string;
		subLocation1?: string;
		targetProjectId?: string;
	};
	variation?: string;
}
