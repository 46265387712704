/*
 * FR.4 Section 1 Part b
 * The system will check for new notifications every 5 minutes.
 */
export const MinutesToRefresh = 5;
export const MinutesToMilliseconds = 60000;
export const TextConstants = {
	noNotifications: 'You have no Notifications',
	notifications: 'Notifications',
};
