import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Constants, LOGO_UPLOADER_TITLES, MAX_FILE_SIZE, SnackBarHelperComponent, uploadAllowedTypes } from '../../../helpers';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { ConfirmationDialogService } from '../../../services/confirm-dialog.service';
import { DialogComponent } from '../../../../@mos-core/components/dialog/dialog.component';

@Component({
	selector: 'app-logo-uploader',
	templateUrl: './logo-uploader.component.html',
	styleUrls: ['./logo-uploader.component.scss'],
})
export class LogoUploaderComponent implements OnInit {
	@ViewChild(FileUploaderComponent, { static: false })
	@ViewChild('confirmDialog') confirmDialog: DialogComponent;

	private fileUploaderComponent: FileUploaderComponent;

	@Input()
	public disabled: boolean = false;
	@Input()
	public imageUrl: string | SafeResourceUrl;
	@Input()
	public loadingPromise: Promise<any>;
	@Input()
	public removeLogo: boolean = true;
	@Input()
	public title: string = LOGO_UPLOADER_TITLES.picture;

	@Output()
	public deleteLogo: EventEmitter<void> = new EventEmitter();
	@Output()
	public filesChange: EventEmitter<File> = new EventEmitter();

	@Input()
	public set file(file: File) {
		this._file = file;
		// this.onFilesChanged([file]);
	}
	public get file() {
		return this._file;
	}

	public uploadAllowedTypes: any = uploadAllowedTypes;

	private _file: File;

	constructor(
		public snack: SnackBarHelperComponent,
		private sanitizer: DomSanitizer,
		private confirmationDialogService: ConfirmationDialogService
	) {}

	public ngOnInit(): void {
		this.onFilesChanged([this.file]);
	}

	public onDeleteLogo(): void {
		this.confirmationDialogService.setDialog(this.confirmDialog);
		this.confirmationDialogService.confirm('Are you sure you want to delete this photo?', 'Delete', () => {
			this.deleteLogo.emit();
			//this.fileUploaderComponent.cancelUpload();
			this.imageUrl = undefined;
			this.filesChange.emit(null);
		});
	}

	public onFilesChanged(files: File[]): void {
		const file = files ? files[0] : undefined;
		if (!file) {
			return;
		}
		// File size limit is 1GB
		if (file.size > MAX_FILE_SIZE) {
			this.snack.snackError(Constants.FILE_TOO_BIG_MESSAGE);
			return;
		}

		this.file = file;
		const url = window.URL.createObjectURL(file);
		this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		this.filesChange.emit(this.file);
	}

	public removeImageUrl(): void {
		this.imageUrl = undefined;
	}
}
