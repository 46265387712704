import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../helpers';
import { CompetitorModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class CompetitorService extends BaseService<CompetitorModel> {
	constructor(public httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.competitor, CompetitorModel);
	}
}
