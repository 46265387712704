import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../helpers';
import { Marketing } from '../interfaces';
import { MarketingModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class MarketingService extends BaseService<MarketingModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.marketing, MarketingModel);
	}
}

@Injectable()
export class GetMarketingItemsResolver {
	constructor(private marketingService: MarketingService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.marketingService.postList({ isActive: true });
	}
}
