import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'mos-skeleton-loader',
	standalone: true,
	templateUrl: './skeleton-loader.component.html',
	imports: [
		NgClass,
	],
})
export class SkeletonLoader {
	@Input() public dark?: boolean = false;
}
