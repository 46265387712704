import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';

import { Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { ItcModalConfig } from '../../../@itc-core/directives/modal/common/modal.models';
import { AppComponent } from '../../app.component';
import { LogoUploaderDialogComponent, UpdatePasswordDialogComponent, UserDetailsDialogComponent } from '../../dialog/components';
import { Constants, LOCAL_STORAGE_KEYS, LOGO_UPLOADER_TITLES, SnackBarHelperComponent } from '../../helpers';
import { Company, Project, ProjectSearch, ProjectSearchSummary, ProjectTimeData, UserTime } from '../../interfaces';
import { UserModel } from '../../models';
import { ExportService } from '../../services/export.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ModalService } from '../../services/modal.service';
import { ProjectTimeService } from '../../services/project-time.service';
import { ProjectService } from '../../services/project.service';
import { S3Service } from '../../services/s3.service';
import { UserTimeService } from '../../services/user-time-service';
import { GetCurrentUserResolver, UserService } from '../../services/user.service';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
	/**
	 * Router config resolvers
	 * @type {}
	 */
	public static routerResolvers = {
		user: GetCurrentUserResolver,
	};

	@ViewChild(LogoUploaderDialogComponent, { static: false })
	public logoUploaderDialogComponent: LogoUploaderDialogComponent;
	@ViewChild('logoUploaderDialog', { static: true })
	public logoUploaderDialogRef: ElementRef;
	@ViewChild(UserDetailsDialogComponent, { static: false })
	public userDetailsDialogComponent: UserDetailsDialogComponent;
	@ViewChild('userDetailsDialog', { static: true })
	public userDetailsDialogRef: ElementRef;
	@ViewChild(UpdatePasswordDialogComponent, { static: false })
	public updatePasswordDialogComponent: UpdatePasswordDialogComponent;
	@ViewChild('updatePassword', { static: true })
	public updatePasswordRef: TemplateRef<any>;

	public canShowTimeEntries: boolean = false;
	public countProjectsPromise: Promise<number>;
	public currentUser: UserModel;
	public displayedCompanies: Company[];
	public loadingProjectsPromise: Promise<ProjectSearchSummary>;
	public loadingSessions: Subscription;
	public logoUploaderTitles: typeof LOGO_UPLOADER_TITLES = LOGO_UPLOADER_TITLES;
	public paginateItemsPerPage = Constants.PAGINATE_ITEMS_10PER_PAGE;
	public projectLink: string = '/' + Constants.ROUTE_LINKS.project + '/';
	public projectsArray: Project[] = [];
	public projectsMaxPage: number = 1;
	public projectsPageNo: number = 1;
	public projectsSearch: string;
	public projectStatus = Constants.PROJECT_STATUS;
	public projectsTotalCount: number = 0;
	public projectTimesArray: ProjectTimeData[] = [];
	public projectTimesMaxPage: number = 1;
	public projectTimesPageNo: number = 1;
	public projectTimesSearch: string;
	public projectTimesTotalCount: number = 0;
	public sessions: UserTime[] = [];
	public sessionsMaxPage: number = 1;
	public sessionsPageNo: number = 1;
	public showChangePasswordModal: boolean = false;
	public showUpdatePictureModal: boolean = false;
	public showUserDetailsModal: boolean = false;
	public totalUserTime: number;
	public updateCurrentUser: Observable<UserModel>;
	public updatePasswordModalConfig: ItcModalConfig = new ItcModalConfig({
		confirmButtonText: Constants.MODAL_TEXT.submit,
		title: Constants.MODAL_TITLE.changePassword,
		showCloseModalButton: true,
	});
	public updatePictureModalConfig: ItcModalConfig = new ItcModalConfig({
		confirmButtonText: Constants.MODAL_TEXT.submit,
		title: Constants.MODAL_TITLE.updatePicture,
		showCloseModalButton: true,
	});
	public uploadingFilePromise: Promise<any>;
	public userDetailsModalConfig: ItcModalConfig = new ItcModalConfig({
		confirmButtonText: Constants.MODAL_TEXT.submit,
		title: Constants.MODAL_TITLE.userDetails,
		showCloseModalButton: true,
	});
	public userPictureUrl = Constants.PROFILE_DEFAULT_PICTURE;

	private searchProject: ProjectSearch = {
		name: '',
		estimators: [],
	};

	constructor(
		public appComponent: AppComponent,
		public snack: SnackBarHelperComponent,
		private exportService: ExportService,
		private localStorageService: LocalStorageService,
		private modalService: ModalService,
		private projectService: ProjectService,
		private projectTimeService: ProjectTimeService,
		private route: ActivatedRoute,
		private router: Router,
		private s3Service: S3Service,
		private userService: UserService,
		private userTimeService: UserTimeService
	) {}

	public ngOnInit(): void {
		this.currentUser = new UserModel();

		this.route.data.subscribe(({ user }) => {
			this.currentUser = user;
			if (this.currentUser.displayPictureKey) {
				this.s3Service.getSignedUrl(this.currentUser.displayPictureKey).subscribe(url => {
					this.userPictureUrl = url;
				});
			}
		});

		this.searchProject.estimators = [];
		this.searchProject.estimators.push(this.currentUser.id);
		// Only display first 3 companies
		this.displayedCompanies = this.currentUser ? this.currentUser.providers.filter((company: Company) => company.isActive === true).slice(0, 3) : [];

		this.getTimeEntries();
		this.getProjectPage(1);
		this.getProjectTimesPage(1);
	}

	/**
	 * Closes the change password modal
	 */
	public resetPasswordModalForm(): void {
		this.updatePasswordDialogComponent.resetForm();
	}

	/**
	 * Closes the update picture modal
	 */
	public closeUpdatePictureModal(): void {
		this.showUpdatePictureModal = false;
	}

	/**
	 * Closes the user details modal
	 */
	public closeUserDetailsModal(): void {
		this.showUserDetailsModal = false;
	}

	/**
	 * Opens the Change Password Modal
	 */
	public openChangePasswordModal(): void {
		this.showChangePasswordModal = true;
	}

	/**
	 * Opens the Update Picture Modal
	 */
	public openUpdatePictureModal(): void {
		this.showUpdatePictureModal = true;
	}

	/**
	 * Opens the User Details Form Modal.
	 */
	public openUserDetailsModal(): void {
		this.showUserDetailsModal = true;
	}

	/**
	 * Submit change password modal and close modal on success
	 */
	public submitChangePasswordModal(): void {
		// TODO convert promises to observables
		this.updatePasswordDialogComponent.changePassword().then(() => {
			this.showChangePasswordModal = false;
		});
	}

	/**
	 * Submit update picture modal and close modal on success
	 */
	public submitUpdatePictureModal(): void {
		// TODO convert promises to observables
		this.logoUploaderDialogComponent.getImageFile().then((file: File | Blob) => {
			if (file !== undefined) {
				this.uploadingFilePromise = this.uploadAndSaveImage(file);
			}
			// Close the modal
			this.showUpdatePictureModal = false;
		});
	}

	/**
	 * Submit user details modal changes, update the current user and close modal on success
	 */
	public submitUserDetailsModal(): void {
		const updateUser = this.userDetailsDialogComponent.updateUser();
		if (updateUser) {
			this.updateCurrentUser = updateUser.pipe(
				tap((user: UserModel) => {
					this.currentUser = user;
					this.showUserDetailsModal = false;
				})
			);
		}

	}

	/**
	 * Retrieves paginated list of projects.
	 * @param {number} pageSelected
	 */
	public getProjectPage(pageSelected: number): void {
		this.projectsPageNo = pageSelected;

		// Get a total count of the projects with the params.
		this.countProjectsPromise = this.projectService.postCount({ name: this.projectsSearch }).toPromise();

		this.countProjectsPromise.then(total => {
			this.projectsTotalCount = total;
			this.projectsMaxPage = Math.ceil(this.projectsTotalCount / this.paginateItemsPerPage);
		});

		// Get a page of projects.
		this.loadingProjectsPromise = this.projectService
			.postPaginate({
				search: { name: this.projectsSearch },
				skip: (this.projectsPageNo - 1) * this.paginateItemsPerPage,
				limit: this.paginateItemsPerPage,
			})
			.toPromise();

		this.loadingProjectsPromise.then(res => {
			this.projectsArray = res.items;
		});
	}

	/**
	 * Retrieves paginated list of project times.
	 * @param {number} pageSelected
	 */
	public getProjectTimesPage(pageSelected: number): void {
		this.projectTimesPageNo = pageSelected;
		const params = {
			name: this.projectTimesSearch,
			userId: this.currentUser.id,
		};

		// Get a page of project times.
		this.projectTimeService
			.postPaginate({
				params: params,
				skip: (this.projectTimesPageNo - 1) * this.paginateItemsPerPage,
				limit: this.paginateItemsPerPage,
			})
			.subscribe(res => {
				this.projectTimesTotalCount = res[0].totalCount;
				this.projectTimesMaxPage = Math.ceil(this.projectTimesTotalCount / this.paginateItemsPerPage);
				this.projectTimesArray = res[0].items;
			});
	}

	/**
	 * Retrieves time entries from the API.
	 * @private
	 */
	private getTimeEntries(): void {
		this.loadingSessions = this.userTimeService
			.getTimeEntries(this.localStorageService.getUserId())
			.pipe(
				finalize(() => {
					this.loadingSessions.unsubscribe();
				})
			)
			.subscribe(result => {
				this.sessions = result.sessions;
				this.totalUserTime = result.total;
			});
	}

	/**
	 * Uploads on amazon and saves the profile picture of the current user.
	 * @param blob
	 */
	// TODO convert Promises to observables
	private uploadAndSaveImage(blob: File | Blob): Promise<any> {
		return new Promise((resolve, reject) => {
			if (blob === null) {
				// Remove picture.
				this.currentUser.displayPictureKey = null;
				this.userPictureUrl = Constants.PROFILE_DEFAULT_PICTURE;
				this.localStorageService.setUserKey(LOCAL_STORAGE_KEYS.displayPictureKey, this.currentUser.displayPictureKey);
				this.appComponent.userPicture = this.userPictureUrl;
				return this.userService.updatePicture(this.currentUser.id, null).subscribe(resolve, reject);
			}
			// Update picture.
			const path = `user/${this.currentUser.id}/picture_${new Date().toISOString()}_${uuid()}`;
			this.s3Service.uploadFile(blob, path, blob.type).subscribe(returnKey => {
				this.userService.updatePicture(this.currentUser.id, returnKey).subscribe(user => {
					this.currentUser.displayPictureKey = user.displayPictureKey;
					this.localStorageService.setUserKey(LOCAL_STORAGE_KEYS.displayPictureKey, this.currentUser.displayPictureKey);
					this.s3Service.getSignedUrl(this.currentUser.displayPictureKey).subscribe(url => {
						this.userPictureUrl = url;
						this.appComponent.userPicture = url;
						resolve(null);
					}, reject);
				}, reject);
			}, reject);
		});
	}
}
