/* WARNING: This file must be updated if _variables.scss is changed */
const $target = '#FCB800';
const $declined = '#D11F4B';
const $active = '#F77F21';
const $quoteReady = '#D299C4';
const $quoteApproved = '#7C4795';
const $submitted = '#662066';
const $won = '#3E9937';
const $lost = '#00245E';
const $live = '#00B0F0';
const $completed = '#A9D18E';

export const variables = {
	activeColour: $active,
	declinedColour: $declined,
	lostColour: $lost,
	quoteApprovedColour: $quoteApproved,
	quoteReadyColour: $quoteReady,
	submittedColour: $submitted,
	targetColour: $target,
	wonColour: $won,
	liveColour: $live,
	completedColour: $completed,
};
