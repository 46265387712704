import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Subscription } from 'rxjs';

import { ModalChangeEvent, ModalOptions, ModalService } from '../../../services/modal.service';
import { ModalTextConst } from './common/modal.constants';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
	@ViewChild('modal', { static: true })
	private modalTemplate: TemplateRef<any>;
	@ViewChild('notification', { static: true })
	private notificationTemplate: TemplateRef<any>;

	public modalOptions: ModalOptions;
	public text: typeof ModalTextConst = ModalTextConst;

	private modalReference: BsModalRef;
	private subscription: Subscription = new Subscription();

	constructor(
		private modalService: ModalService,
		private bsModalService: BsModalService
	) {}

	public ngOnInit(): void {
		this.subscription.add(
			this.modalService.modalChange.subscribe((event: ModalChangeEvent) => {
				if (event) {
					this.modalOptions = event.options;
					this.modalReference = event.isNotification
						? this.bsModalService.show(this.notificationTemplate, event.config)
						: this.bsModalService.show(this.modalTemplate, event.config);
				} else {
					if (this.modalReference) {
						this.modalReference.hide();
					}
				}
			})
		);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public submitButtonClick(): void {
		this.modalOptions.submitButton.click();
	}

	public deleteButtonClick(): void {
		this.modalOptions.deleteButton.click();
	}

	public cancelButtonClick(): void {
		this.modalOptions.cancelButton.click();
	}

	public actionLinkClick(): void {
		this.modalOptions.actionLink.click();
	}
}
