/**
 * Created by Mohammad Karimi from In The Code on 13/3/18.
 *
 * Filter the array on active objects only is showInactive is true
 *
 * Note: This is an impure pipe and will only be triggered on input change.
 * As pipes cannot detect changes in the content of an array or object
 * (eg. an array affectation will be detected, but not a push),
 * an additional parameter triggerPipe as been added to trigger the pipe manually.
 * This parameter value only needs to be modified when an update is needed.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'activeFilter',
})
export class ActivePipe implements PipeTransform {
	//Note: triggerPipe is only used to manually trigger the pipe (see comment at top of file)

	public transform(items: any[], showInactive: boolean): any[] {
		if (items === undefined || items.length === 0 || showInactive === true) {
			return items;
		}

		//check if the showActive flag is true filter the items
		//otherwise return back the list of items without change.
		return items.filter(item => {
			return item.isActive;
		});
	}
}
