// tslint:disable:quotemark
export const TEXT = {
	import: 'import',
	importPage: 'import page',
	abbreviatedVariation: 'var.',
	abn: 'ABN',
	acceptance: 'acceptance',
	acceptanceEmail: 'acceptance email',
	action: 'action',
	actions: 'actions',
	activate: 'activate',
	activateClient: 'activate client',
	activateConfirmation: 'are you sure you want to activate this project?',
	activeTool: 'active tool',
	active: 'active',
	actual: 'actual',
	actualGP: 'actual GP $',
	actualQuotedAmount: 'actual quoted $',
	actualQuotedNumber: 'actual quoted No.',
	actualSalesAmount: 'actual sales $',
	actualSalesNumber: 'actual sales No.',
	actualStrikeRateAmount: 'actual strike rate $',
	actualStrikeRateNumber: 'actual strike rate no.',
	add: 'add',
	addAllPagesToTenderDocuments: 'add all pages to tender documents',
	addBoqToStart: 'add page + BOQ to start using the canvas',
	addCharge: 'add charge',
	addChargeOutRate: 'add additional charge-out rate',
	addClient: 'add additional client',
	addCoatsProducts: 'add additional coats and products',
	addComment: 'add comment',
	addContact: 'add contact',
	addContactPerson: 'add contact person',
	addCurrentPageToTenderDocuments: 'add current page to tender documents',
	addFile: 'add file',
	additionalCharge: 'additional charge',
	additionalCharges: 'additional charges',
	additionalDetails: 'additional details',
	additionalUsers: 'additional users',
	addMembers: 'add members',
	addNewBoq: 'Add New BOQ',
	addNewBrand: 'add new brand',
	addNewClient: 'add new client',
	addNewPage: 'add new page',
	addPage: 'add page',
	addProductDistribution: 'add product distribution',
	address: 'address',
	addressLine1: 'address line 1',
	addressLine2: 'address line 2',
	addressLine3: 'address line 3',
	addSelectedAnnotationsToTask: 'add selected annotations to task',
	addSize: 'add size',
	addSupplierProduct: 'add supplier product',
	addTask: 'add task',
	adminEmail: 'admin email',
	adminName: 'admin name',
	advanced: 'advanced',
	salesReporting: 'sales reporting',
	amountDollar: 'amount $',
	advancedSearch: 'advanced',
	afterHours: 'after hours',
	afterHoursEbaRate: 'after hours EBA $ rate',
	afterHoursNonEbaRate: 'after hours non-EBA $ rate',
	all: 'all',
	allCompanies: 'all companies',
	allDivisions: 'all divisions',
	allLayers: 'all layers',
	allUsers: 'all users',
	amount: 'amount',
	amountOwing: 'amount owing',
	annotation: 'annotation',
	annotationTool: 'annotation tool',
	annotationText: 'annotation text',
	application: 'application',
	applicationMethod: 'application method',
	applicationMethods: 'application methods',
	applicationRate: 'application rate',
	applicationRates: 'application rates',
	applicationTargets: 'application targets',
	applyCurrentScaleToAllPages: 'apply current scale to all pages',
	applyDiscountEvenlyToBoqsAndAdditionalCharges: 'apply discount evenly to all charges',
	applyDiscountFromBoqsAndAdditionalCharges: 'apply discount to selected charges (apply discounts in the charges table)',
	applyDiscountOnProjectTotal: 'apply discount to project total',
	applyLegendSettingsToAllPages: 'apply legend settings to all pages',
	applyTool: 'apply tool',
	applyToolOverrideBoq: 'apply tool and override BOQ data',
	archive: 'archive',
	area: 'area',
	areaDimension: 'Area Dimension',
	areYouSureYouWantToContinue: 'are you sure you want to continue?',
	assignee: 'assignee',
	averageSale: 'average sale',
	assignEstimator: 'assign estimator',
	assignUser: 'assign user access levels',
	attachments: 'attachments',
	author: 'author',
	averageApplicationRate: 'total application rate',
	averageCostPerUnit: 'average $ cost/unit',
	averageRatePerUnit: 'average $ rate/unit',
	back: 'back',
	backToLogin: 'back to login',
	base: 'base',
	basicSearch: 'basic search',
	billingAddress: 'billing address',
	billingDeliveryAddress: 'billing / delivery address',
	billTo: 'bill to',
	blue: 'blue',
	boq: 'BOQ',
	boqs: 'BOQs',
	boqCharge: 'BOQ charge',
	boqDetails: 'bill of quantity details',
	boqRef: 'BOQ ref',
	boqTool: 'BOQ Tool',
	boqView: 'BOQ View',
	boqUserToolOverrideConfirmation: 'Do you want to override BOQ fields',
	boqUserToolOverrideConfirmationWarning: 'This will immediately save the updated BOQ and cannot be undone.',
	borderColour: 'border colour',
	boqTotalCeilingArea: 'BOQ total ceiling area',
	boqTotalPerimeter: 'BOQ total perimeter',
	boqTotalSurfaceArea: 'BOQ total surface area',
	boqTotalWallsArea: 'BOQ total walls area',
	breakOutLine: 'break out line (B)',
	brand: 'brand',
	brandFilter: 'brand filter',
	brands: 'brands',
	brandsProvided: 'brands provided',
	brandsSelected: 'brands selected',
	budget: 'budget',
	budgets: 'budgets',
	budgetQuoted: 'budget quoted $',
	actualQuoted: 'actual quoted $ ',
	budgetGP: 'budget GP $',
	budgetQuotedAmount: 'budget quoted $',
	budgetQuotedNumber: 'budget quoted No.',
	budgetSalesAmount: 'budget sales $',
	budgetSalesNumber: 'budget sales No.',
	budgetStrikeRateAmount: 'budget strike rate $',
	budgetStrikeRateNumber: 'budget strike rate no.',
	budgetStrikeRate: 'budget strike rate $',
	actualStrikeRate: 'actual strike rate $',
	actualQuotedNum: 'actual quoted no.',
	budgetQuotedNum: 'budget quoted no.',
	bulkData: 'bulk data input',
	bulkEdit: 'bulk edit',
	budgetSales: 'budget sales',
	actualSales: 'actual sales',
	calculateCost: 'calculate cost',
	calculationOverrideWarning1: "this project's calculated values uses a legacy calculation method.",
	calculationOverrideWarning2: 'saving will recalculate with the current calculation method which may alter your values.',
	calculationType: 'calculation type',
	calculationWillBeAffected: 'this will affect all the calculations for this page',
	callOut: 'call out',
	cancel: 'cancel',
	cancelAtEndOfPeriod: 'cancel at the end of this period',
	cancelNow: 'cancel now',
	cancelOrder: 'cancel order',
	category: 'category',
	cc: 'CC',
	centimetreAbbreviation: 'cm',
	centimetres: 'centimetres',
	changeOrder: 'change order',
	changePassword: 'change password',
	characters: 'characters',
	charge: 'charge',
	chargeCategory: 'charge category',
	chargeOut: 'charge out',
	chargeOutRates: 'charge-out $ rates',
	chargeOuts: 'charge-outs',
	charges: 'charges',
	chargesCount: 'charges count',
	checkout: 'checkout',
	childrenClients: 'children clients',
	childrenCompanies: 'children companies',
	chooseBrand: 'choose brand',
	chooseChargeType: 'choose charge type',
	chooseColour: 'choose colour',
	city: 'city',
	cityLocality: 'city/Locality',
	claim: 'claim',
	claimAmount: 'claim amount',
	claimAmountOver100Percent: 'one or more claims will exceed 100% of the total charge amount',
	claimedToDate: 'claimed to date',
	clear: 'clear',
	clearAllFields: 'clear all fields',
	clearFields: 'clear fields',
	clearFilters: 'clear filters',
	client: 'client',
	clientContact: 'client contact',
	clientContactAddress: 'client contact address',
	clientContactEmail: 'client contact email',
	clientContactName: 'client contact name',
	clientName: 'client name',
	clients: 'clients',
	clone: 'clone',
	cloneBoq: 'Clone BOQ',
	cloneCharges: 'clone charges',
	clonePage: 'clone page',
	cloneSubLocation: 'clone sub location',
	close: 'close',
	closed: 'closed',
	coat: 'coat',
	coatingPercentage: 'coating percentage',
	coatingPercentageTooltip: 'Adjust percentage of coat being applied e.g. Full coat 100%, Spot coat 20%.',
	coatingSystem: 'coating system',
	coatingSystemProducts: 'coating system products',
	coatingSystems: 'coating systems',
	coatProductAdded: 'Coat Product Added',
	coatProductRemoved: 'Coat Product Removed',
	coatsAndProducts: 'coats and products',
	code: 'code',
	collapseAll: 'collapse all',
	colorCreate: 'create new colour',
	colour: 'colour',
	colourBrand: 'colour brand',
	colourCode: 'colour code',
	colourName: 'colour name',
	colours: 'colours',
	colourDisclaimer: 'screen images are intended as a guide only and should not be regarded as absolutely correct',
	colourSample: 'colour sample',
	column: 'column',
	combine: 'combine',
	comment: 'comment',
	comments: 'comments',
	commentsToEstimator: 'comments to estimator',
	companies: 'companies',
	company: 'company',
	companyDivisions: 'company divisions',
	companyName: 'company name',
	companyResult: 'companies listed on this account',
	competitor: 'competitor',
	competitors: 'competitors',
	complete: 'complete',
	completed: 'completed',
	completeDate: 'completed date',
	completeEstimate: 'complete estimate',
	completeProposal: 'complete proposal',
	compressPdfs: 'compress PDF(s)',
	concurrent: 'concurrent',
	confirm: 'confirm',
	confirmAction: 'are you sure you want to proceed',
	confirmBoqUpdate: 'Confirm BOQ Update',
	confirmCalculationOverride: 'confirm calculation override',
	confirmingMarginOfError: 'confirming margin of error',
	confirmPassword: 'confirm password',
	confirmScale: 'confirm scale',
	consumables: 'consumables',
	contact: 'contact',
	contactAddress: 'contact address',
	contactEmail: 'contact email',
	contactNumber: 'contact number',
	contactPerson: 'contact person',
	contingencyMargin: 'contingency margin (%)',
	contractAdministrator: 'contract administrator',
	contractClaim: 'contract claim',
	contractTotal: 'contract total',
	convertPDF: 'convert PDF',
	copy: 'copy',
	correctMarkedFields: 'Correct Marked Fields',
	cost: 'cost',
	costOverview: 'cost Overview',
	costToDate: 'cost to date',
	couldNotFindLocation: 'could not find location',
	country: 'country',
	create: 'create',
	createAccount: 'create account',
	createBoq: 'create BOQ',
	createClient: 'create client',
	createCompany: 'create company',
	created: 'created',
	createdAt: 'created at',
	createDivision: 'create division',
	createdDocuments: 'created documents',
	createdFromTo: 'created from - to',
	createDocument: 'create document',
	createInvoice: 'create invoice',
	createNew: 'create new',
	createNewBrand: 'create new brand',
	createNewCharge: 'create new charge',
	createNewClient: 'create new client',
	createNewCoatingSystem: 'create new coating system',
	createNewColour: 'create new colour',
	createNewCompany: 'create new company',
	createNewCompetitor: 'create new competitor',
	createNewDivision: 'create new division',
	createNewEntityTool: 'create new entity tool',
	createNewFile: 'create new file',
	createNewFilesFolders: 'create new files/folders',
	createNewLabourRate: 'create new labour rate',
	createNewMarketingItem: 'create new marketing item',
	createNewPassword: 'create new password',
	createNewProduct: 'create new product',
	createNewProject: 'create new project',
	createNewRefCode: 'create new reference code',
	createNewState: 'create new state',
	createNewSupplier: 'create new supplier',
	createNewTemplate: 'create new template',
	createNewTime: 'create new time entry',
	createNewUser: 'create new user',
	createNewUserGroup: 'create new user group',
	createNewUserTool: 'create new user tool',
	createOrder: 'create order',
	createProjectVersion: 'create project version',
	createReport: 'create report',
	createTool: 'create tool',
	createUserTool: 'create user tool',
	createVariation: 'create variation',
	continue: 'continue',
	currentSession: 'current session',
	currentStatus: 'current status',
	customDocument: 'custom document',
	customTemplate: 'custom templates',
	cutOut: 'cut out (X)',
	daily: 'daily',
	dailyHireRate: 'daily hire $ rate',
	dashboard: 'dashboard',
	dataType: 'data type',
	date: 'date',
	dateDue: 'date due',
	dateSent: 'date sent',
	day: 'day',
	dayMonthYear: 'dd/mm/yyyy',
	days: 'days',
	deactivate: 'deactivate',
	deactivateClient: 'deactivate client',
	deactivateConfirmation: 'are you sure you want to deactivate this project?',
	deactivatedOrInvalidBoq: 'Deactivated or Invalid BOQ',
	deactivatePage: 'deactivate page',
	deactivatePageConfirmation: 'Please select whether you also want to remove all BOQs associated with this page, or just deactivate the page.',
	deactivatePageMessage: 'Deactivate all BOQs associated with this page',
	deactivateSubLocation: 'deactivate sub location',
	decision: 'decision',
	declineEmail: 'decline email',
	declineOrAcceptTargetProject: 'Review and select projects that have the most potential and decline projects that are less likely or unlikely to win',
	deconstruct: 'deconstruct (D)',
	default: 'default',
	defaultChargeCategory: 'default charge category',
	defaultDivision: 'default division',
	defaultHeight: 'default height',
	defaultLabourRate: 'default labour $ rate',
	defaultUserToolName: 'My Tool',
	delete: 'delete',
	deleteSelectedShapes: 'delete selected shapes',
	deliverDate: 'deliver date',
	deliverTo: 'deliver to',
	deliveryAddress: 'delivery address',
	description: 'description',
	deselect: 'deselect',
	deselectAll: 'deselect All',
	details: 'details',
	difficulty: 'difficulty',
	disableArea: 'disable area',
	discount: 'discount',
	discountOption: 'discount options',
	discountPercentage: 'discount percentage',
	discounts: 'discounts',
	discountType: 'discount type',
	discountTypePercentage: 'percentage',
	discountTypeAmount: '$ amount',
	discountValue: 'discount value',
	division: 'division',
	divisionMembers: 'division members',
	divisions: 'divisions',
	divisionUserGroup: 'division (user group)',
	documentTitle: 'document title',
	doesNotApplyToImageExports: 'does not apply to image exports',
	download: 'download',
	downloadAllFiles: 'download all files',
	downloadAsPDF: 'download as PDF',
	downloadAttachments: 'download attachments',
	downloadExpiryWarning: 'download link expires in 1 week',
	downloadFiles: 'download files',
	downloadTemplate: 'download template',
	drawScale: 'draw scale',
	drawScaleLine: 'draw scale line',
	due: 'due',
	dueDate: 'due date',
	duplicate: 'duplicate',
	duration: 'duration',
	eba: 'EBA',
	ebaRate: 'EBA $ rate',
	ebaType: 'EBA type',
	edit: 'edit',
	editBoq: 'edit BOQ',
	editedBy: 'edited by',
	editCoatingSystem: 'edit coating system',
	editComment: 'edit comment',
	editOrder: 'edit order',
	editPage: 'edit page',
	editPageName: 'edit page name',
	editProfile: 'edit profile',
	editSubLocation: 'edit sub location',
	ellipse: 'ellipse',
	email: 'email',
	emailAttachmentWarning: 'files larger than 25MB will only be available via download link when emailing',
	emailDocument: 'email document',
	emailMessage: 'email message',
	emails: 'emails',
	emailSubject: 'email subject',
	emailTheProducedFile: 'email the produced file',
	emptyRegionsDropdown: 'no regions provided in the selected state',
	enableArea: 'enable area',
	enableAutoRenewal: 'enable auto renewal',
	endDate: 'end date',
	endsOn: 'ends on',
	enterEmail: 'enter your email and we will send',
	enterYourEmailAndWeWillSend: "enter your email and we'll send you a password reset link",
	entityAddress: 'entity address',
	entityAdmin: 'entity admin',
	entityConfiguration: 'entity configuration',
	entityDetails: 'entity details',
	environment: 'environment',
	errorAddPointCompletedShape: "can't add a point to a completed shape",
	errorBreakOutLine: 'cannot break out line on an invalid shape',
	errorCantCombineLessThenTwo: 'select at least 2 shapes to combine',
	errorCantDrawCutoutForIncompleteShape: "can't draw cutout on an incomplete shape",
	errorCantPasteToThisLayer: "Can't paste to this layer - Selected BOQ's tool type does not match copied data",
	cantPasteToThisLayerMissingBoq: "Can't paste to this layer - Missing target BOQ or BOQ Tool",
	errorCantDrawCutoutForShape: "can't draw cutout on this shape",
	errorChangeLayersNoScale: "can't change layers until a scale has been set",
	errorCloseShapeForItems: "can't close a shape for items tool",
	errorCloseShapeForLessThenThree: "can't close a shape with less than 3 points",
	errorCombinePointAndShape: 'only point and shape tools can be combined',
	errorConfirmScaleBeforeContinuing: 'confirm scale value before continuing',
	errorContingencyPositive: 'contingency input value must be positive',
	errorCutoutOuterIntersection: "can't intersect with outer shape",
	errorCutoutOutOfBounds: 'moving point will cause cutout to be out of bounds',
	errorCutoutOutOfBoundsIfRemove: 'removing point will cause cutout to be out of bounds',
	errorCutoutOutsideShape: "can't add a cutout point outside of a shape",
	errorLocation: 'Supplier needs at least one location',
	errorNegativeLayerIndex: 'layer must be greater than 0',
	errorScaleFirst: 'scale must be set first',
	errorScaleNoUnits: 'a unit type must be selected when setting the scale',
	errorScalePoints: 'scale must have a start and end point',
	errorScalePositiveNum: 'scale must be greater than 0',
	errorShapeIsCompleted: 'shape is already completed',
	errorShapesMustBeConnected: 'all shapes selected must be connected',
	estimator: 'estimator',
	estimatorName: 'estimator name',
	estimators: 'estimators',
	excComments: 'exclusion comments',
	excLibrary: 'exclusion library',
	excessDistributionWarning: 'you have now exceeded the total units specified by the project distribution',
	excessSupplierProductDurationWarning: 'you have exceeded the required duration for this supplier product',
	exclusions: 'exclusions',
	expandAll: 'expand all',
	export: 'export',
	exportAll: 'export all',
	exportAllDocuments: 'export all documents',
	exportAllPagesAsImages: 'export all pages as images',
	exportAllPagesAsPDF: 'export all pages as pdf',
	exportPageInfo: 'Note: Exporting will automatically upload the generated file to Tender Documents for this project.',
	exportCanvasAsImage: 'export canvas as image',
	exportCoordinates: 'export coordinates',
	exportDocuments: 'export documents',
	exportPageAsPdf: 'export page as pdf',
	exportSummary: 'export summary',
	fax: 'fax',
	feet: 'feet',
	file: 'file',
	filesFolders: 'files folders',
	fillColour: 'fill colour',
	financialYear: 'financial year',
	filter: 'filter',
	filterByDivision: 'filter by division',
	filterCharges: 'filter charges',
	filterProjects: 'filter projects',
	finish: 'finish',
	finishDate: 'finish date',
	firstName: 'first Name',
	flipPageShapes: 'flip page shapes',
	flipSelectedShapes: 'flip selected shapes',
	floor: 'floor',
	followUp: 'follow up',
	followUpDate: 'follow up date',
	fontColour: 'font colour',
	footAbbreviation: 'ft',
	forgot: 'forgot',
	forgotPassword: 'forgot password',
	forgotYourPassword: 'forgot your password',
	formErrorMessage: 'this form has not been completed',
	freeText: 'free text',
	frequentlyUsedTools: 'frequently used tools',
	from: 'from',
	fromAttachments: 'from attachments',
	fromCharge: 'from charge',
	fromCost: 'from cost',
	fontSize: 'font size',
	forecastingReport: 'forecasting report',
	forecastingReportFileNameProjectView: 'forecasting-report-project-view',
	forecastingReportFileName: 'forecasting-report-summary-view',
	generate: 'generate',
	generateTitle: 'generate title',
	generatedDocuments: 'generated documents',
	gp: 'gp',
	gpBudget: 'GP $ budget',
	grandTotal: 'grand total',
	grandTotals: 'grand totals',
	green: 'green',
	greyBackgroundLayers: 'grey background layers',
	grossProfit: 'gross profit',
	grossProfitPercent: 'gross profit %',
	grossProfitTarget: 'gross profit target',
	grossProfitDollarBudget: 'Budget $',
	grossProfitDollarActual: 'Actual $',
	grossTotals: 'gross totals',
	groupByDivision: 'group by division',
	group: 'group',
	gst: 'GST',
	gotIt: 'got it',
	height: 'height',
	heightRange: 'height range',
	heightAllowance: 'height allowance',
	heightRangeRateWithUnits: 'height range rate (m2 / hr)',
	hello: 'hello',
	help: 'FAQ/help section',
	hexColourCode: 'hex colour code',
	hide: 'hide',
	hideAnnotation: 'hide annotation',
	hideBackgroundLayers: 'hide background layers',
	hideChargeFromQuote: 'hide charge from quote',
	hideInactive: 'hide inactive',
	hideUnselectedLayers: 'hide unselected layers',
	high: 'high',
	highlight: 'highlight',
	highToLow: 'high to low',
	hours: 'hours',
	hoursSpentTotal: 'hours spent total',
	hoursSpent: 'hours spent',
	hourTotals: 'hour totals',
	id: 'id',
	importData: 'import data',
	inactive: 'inactive',
	inAppChat: 'in app chat',
	incComments: 'inclusion comments',
	inchAbbreviation: 'in',
	inches: 'inches',
	compressPDF: 'compress PDF(s)',
	incLibrary: 'inclusion library',
	includeAnnotations: 'include annotations',
	includesFreeTrial: 'includes a 14 day free trial!',
	inclusions: 'inclusions',
	inclusionsExclusions: 'inclusions exclusions',
	incomplete: 'incomplete',
	industry: 'industry',
	industryLicenseNumber: 'Industry license number',
	invoice: 'invoice',
	invoiceDate: 'invoice date',
	invoiceDeactivateConfirmation1: 'are you sure you want to deactivate this invoice?',
	invoiceDeactivateConfirmation2: "doing this will affect the project's claimed to date value, but will not inform the client you may have originally invoiced (via email).",
	invoiceEmail: 'invoice email',
	invoiceHistory: 'invoice history',
	invoiceId: 'invoice id',
	invoiceNumber: 'invoice number',
	internalComment: 'internal comment',
	internalCommentsForEstimator: 'internal comments for estimator',
	itemCalculationType: 'item calculation type',
	items: 'items',
	itemsTooltip: 'items (I)',
	itemSize: 'item size',
	joined: 'joined',
	keyDates: 'key dates',
	labelSuffix: ':',
	labour: 'labour',
	labourCosts: 'labour costs',
	labourHours: 'labour hours',
	labourRate: 'labour $ rate',
	labourRates: 'labour $ rates',
	labourType: 'labour type',
	language: 'language',
	lastEdited: 'last edited',
	lastMonth: 'last month',
	lastName: 'last Name',
	lastSaved: 'last saved',
	lastWeekSessions: 'last week sessions',
	latestVersion: 'latest version',
	layers: 'layers',
	leadSource: 'lead source',
	learnMore: 'learn more',
	leftUntil: 'left until',
	legend: 'legend',
	library: 'library',
	line: 'line',
	listOrTileView: 'list or tile view',
	lineColour: 'line colour',
	lineDash: 'line dash',
	lineStyle: 'line style',
	lineType: 'line type',
	lineWeight: 'line weight',
	lineWidth: 'line width',
	lineWidthError: 'line width can not be less then 1',
	litres: 'litres',
	litresOfWaste: 'L of waste',
	liveOpportunities: 'live opportunities',
	locality: 'locality',
	location: 'location',
	login: 'login',
	loginTo: 'login to',
	loginWithDifferent: 'log in with a different account',
	lost: 'lost',
	low: 'low',
	lowToHigh: 'low to high',
	lumpSumCharge: 'lump sum charge',
	lumpSumCost: 'lump sum cost',
	mainContactEmail: 'main contact email',
	mainContactFirstName: 'main contact first name',
	mainContactLastName: 'main contact last name',
	mainContactPhone: 'main contact phone',
	manageSubscription: 'manage subscription',
	managementReview: 'management review',
	mark: 'mark',
	marketing: 'marketing',
	marketingItems: 'marketing items',
	markup: 'markup',
	masterDataUpdateWarning: 'master data cannot be updated. Use clone function instead.',
	matchingLibraryCoatingSystemWarning: 'Could not find matching library coating system',
	materials: 'materials',
	materialsAndWaste: 'materials & waste',
	materialsCanBeAdded: 'materials can be added or edited within the',
	materialsMarkUp: 'materials mark-up',
	measuringDistance: 'measuring distance',
	measuringTool: 'measuring tool',
	measuringToolUrl: 'measuring-tool',
	measuredDistance: 'measured distance',
	members: 'members',
	mergeClient: 'merge client',
	message: 'message',
	metreAbbreviation: 'm',
	metres: 'metres',
	millimetreAbbreviation: 'mm',
	millimetres: 'millimetres',
	mobile: 'mobile',
	month: 'month',
	mostRecentUsersWillBeDeactivated: 'most recent users will be deactivated',
	moveLegend: 'move legend',
	moveShapeToBack: 'move shape(s) to back',
	moveShapeToFront: 'move shape(s) to front',
	msdsSheet: 'Material Safety Data sheet',
	name: 'name',
	navigateButton: 'navigate to',
	needAnAccount: 'need an account',
	negative: 'negative',
	negotiationOption: 'negotiation option',
	netTotals: 'net totals',
	newCharge: 'new charge',
	newPassword: 'new password',
	newProduct: 'new product',
	newProject: 'new project',
	newProjectVersionMessage: 'new project version message',
	newSupplierLocation: 'new supplier location',
	newToOld: 'new to old',
	no: 'no',
	noUsers: 'no users',
	noData: 'no data',
	noItems: 'no items',
	noLocation: 'no location',
	none: 'none',
	nonPrefix: 'non',
	noPageExistsForBoq: 'No page exists for the selected BOQ',
	noQuote: 'no quote due date found',
	noRates: 'there are no labour rates for current state',
	nonEba: 'non-EBA',
	nonEbaRate: 'non-EBA $ rate',
	noProducts: 'no products',
	notAllBrandsBelongToSupplier: "not all products you are ordering belong to the selected supplier's brands.",
	notification: 'notification',
	notYou: 'not you',
	number: 'number',
	numberHash: 'number #',
	numberOf: 'number of',
	of: 'of',
	oldToNew: 'old to new',
	open: 'open',
	openMsdsSheet: 'open MSDS sheet',
	openSpecSheet: 'open spec sheet',
	operatingStates: 'operating states',
	operationHours: 'operation hours',
	or: 'or',
	order: 'order',
	orderedToDate: 'ordered to date',
	otherReason: 'other reason',
	outstanding: 'outstanding',
	override: 'override',
	overtimeHours: 'overtime hours',
	overtimeRate: 'overtime $ rate',
	overview: 'overview',
	paid: 'paid',
	pageDescriptionApplicationRates:
		'These are the application rates based on individual coats or as a total. You can adjust your rates higher or lower and the changes will be reflected in the charges table.',
	pageDescriptionDashboard: "here's a dashboard of what you have been working on recently.",
	pageDescriptionSearchProjects:
		'to find a project you can perform a specific search using one or a combination of the fields below or select the status of the project you are looking for.',
	pageDescriptionSearchClients: 'to find a client you can perform a specific search using one or a combination of the fields below.',
	pageDescriptionBrandsLibrary: 'add a brand to your library.',
	pageDescriptionCoatingSystem: 'create a new coating system based on your paint brand.',
	pageDescriptionColourLibrary: 'add a new colour to your library.',
	pageDescriptionMeasureToolLibrary: 'build your own measure tools\' coating systems',
	pageDescriptionCompetitorsLibrary: 'list your competitors and monitor who wins projects.',
	pageDescriptionIncExcLibrary: 'build your own standard inclusions and exclusions you can use when quoting.',
	pageDescriptionIncExcProject: 'Type and create your own Inclusions & exclusions and save them for later use or build them using the PaintProjex Library.',
	pageDescriptionLabourRatesLibrary: 'use our industry standard labour rates or make your own.',
	pageDescriptionLabourRatesProject1: 'Adjust labour costs specific to each labour type.',
	pageDescriptionLabourRatesProject2: 'These will apply to the entire project.',
	pageDescriptionCompaniesSettings: 'if your organisation has an number of companies you can add them here.',
	pageDescriptionEntitySettings: "update your account details. Your account's users must log out and log back in to see any changes made.",
	pageDescriptionImportDataSettings: 'if you have a data in a file  you can import a .CSV file or drag a file here for a quick import.',
	pageDescriptionUsersSettings: 'these are all the users in your company subscribed to PaintProjex.',
	pageDescriptionUserGroupsSettings: 'if you have multiple users subscribed to PaintProjex you can allocate them to special groups.',
	pageAddingModalTitle: 'Page Adding Wizard 🧙',
	pageName: 'page name',
	pageResolution: 'page resolution',
	pages: 'pages',
	pageSubLocationUpdateWarning: 'Updating the sub location will automatically update any BOQs assigned to the page with the new sub location.',
	pageToAssign: 'page to assign',
	pageTotals: 'page totals',
	pan: 'pan (SPACE)',
	parentClient: 'parent client',
	parentCompanies: 'parent companies',
	parentCompany: 'parent company',
	password: 'password',
	paidInAdvance: 'paid in advance',
	paintInformation: 'Paint Information',
	paymentComment: 'payment comment/note',
	paymentDue: 'payment due',
	pdfUploader: 'pdf uploader',
	pdfUploadReUploadWarning: 'Uploading the original PDF and changing scale resolution or uploading a new PDF/image can cause the existing measurement scale to be inaccurate.',
	percentage: 'percentage',
	perimeter: 'perimeter',
	phone: 'phone',
	phoneNumber: 'phone number',
	placeOrder: 'place order',
	pleaseCreateAccount: 'please create your account',
	pleaseLogInToAccount: 'please login to your account',
	pleaseSelectACountry: 'please select a country',
	pleaseSelectARegion: 'please select a region',
	pleaseSelectAState: 'please select a state',
	pleaseSelectOperatingStates: 'please select operating states',
	pleaseSubmitTask: 'you cannot submit an empty task',
	poDate: 'PO date',
	pointToPoint: 'point to point (P)',
	poNumber: 'PO number',
	position: 'position',
	postCode: 'post code',
	precision: 'precision',
	preview: 'preview',
	previewAndSend: 'preview and send',
	previewInvoice: 'preview invoice',
	previouslyPainted: 'previously painted',
	products: 'products',
	privacyPolicy: 'privacy policy',
	probability: 'probability',
	product: 'product',
	productCode: 'product code',
	productFilter: 'product filter',
	productImage: 'product image',
	productionRate: 'production rate',
	productMSDS: 'add product MSDS',
	productName: 'product name',
	productPurchasingSummary: 'product purchasing summary',
	productRequired: 'This requires a product and substrate be selected',
	profile: 'profile',
	profit: 'profit',
	project: 'project',
	projectDetails: 'project details',
	projectDueDate: 'project due date',
	projectFinish: 'project finish',
	projectList: 'project list',
	projectManager: 'project manager',
	projectManagement: 'project management',
	projectName: 'project name',
	projectQuantity: 'project quantity',
	projectRating: 'project rating',
	projectID: 'project ID#',
	projectRestoreConfirmation1: 'would you like to create a project version before restoring?',
	projectRestoreConfirmation2: 'if you do not, any existing changes to the current project version will be lost.',
	projectRestoreConfirmation3: 'create a new project version before restoring.',
	projectRestoreMaxVersionsMessage1: 'maximum number of project versions reached.',
	projectRestoreMaxVersionsMessage2: 'please deactivate an existing version if you wish to create a new one.',
	projectRestoreWithoutCreateWarning: 'project will restore without creating a new version.',
	projects: 'projects',
	projectSchedule: 'project schedule',
	projectsScheduled: 'projects scheduled',
	projectSessionModalLine1: 'the user below currently has an active session on this project',
	projectSessionModalLine2: 'would you like to end their session and start your own?',
	projectSessionModalLine3: 'your user role must be admin or above to override project sessions',
	projectSessionModalTitle: 'confirm project session override',
	projectSlashClient: 'Project/Client',
	projectStartAndFinish: 'project start & finish date',
	projectStart: 'project start',
	projectStartDate: 'project start date',
	projectsSearch: 'search projects',
	projectTotals: 'project totals',
	projectValue: 'project value',
	projectVersions: 'project versions',
	projectView: 'project view',
	projectsSplitByStatus: 'projects split by status',
	purchaseNumber: 'purchase order number',
	purchaseOrder: 'purchase order',
	purchaseOrderDeactivateConfirmation1: 'are you sure you want to deactivate this purchase order?',
	purchaseOrderDeactivateConfirmation2:
		'doing this will affect the purchase order total amount, but will not inform the supplier you may have originally sent an order to (via email).',
	purchaseOrderEmail: 'purchase order email',
	purchasingSummary: 'purchasing summary',
	quality: 'quality',
	quicksearch: 'quick search',
	quote: 'quote',
	quoteBudget: 'quote budget',
	quoted: 'quoted',
	quoteDepth: 'quote depth',
	quoteDue: 'quote due',
	quoteDueDate: 'quote due date',
	quoteFinish: 'quote finish',
	quoteIsDue: 'quote is due',
	quoteStart: 'quote start',
	quoteStartAndFinishDate: 'quote start & finish date',
	quoteSubmittedDate: 'quote submitted date',
	quoteTarget: 'quote target',
	quoteWasDue: 'quote was due',
	quoteWasSent: 'quote was sent',
	rate: '$ rate',
	ratePerUnit: '$ rate per unit',
	reason: 'reason',
	received: 'received',
	recentInvoices: 'recent invoices',
	recalibrateScale: 'recalibrate scale',
	recentProjects: 'recent projects',
	recipient: 'recipient',
	rectangle: 'rectangle (R)',
	rectangleCutOut: 'rectangle cut out (T)',
	red: 'red',
	redo: 'redo (Ctrl + Shift + Z)',
	refund: 'refund',
	region: 'region',
	reference: 'reference',
	referenceCodes: 'reference codes',
	ref: 'ref',
	relationshipHolder: 'relationship holder',
	rememberMe: 'remember me',
	remove: 'remove',
	removeMsdsSheet: 'remove MSDS sheet',
	removeSpecSheet: 'remove spec sheet',
	renewsOn: 'renews on',
	replyTo: 'reply to',
	reporting: 'reporting',
	reports: 'reports',
	requestARefund: 'i would like to request a refund.',
	requestToSave: 'request to save',
	reset: 'reset',
	resendEmail: 'resend email',
	resendInvoice: 'resend invoice',
	resetHere: 'reset here',
	resetLink: 'you a password reset link',
	restoreProject: 'restore project',
	results: 'results',
	resultsAmount: 'results $',
	resultsGP: 'results GP $',
	resultsNumber: 'results no.',
	revenue: 'revenue',
	salesResAmount: 'sales result based on $amount',
	salesResNum: 'sales result based on number',
	saveBudgets: 'save budgets',
	revision: 'revision',
	role: 'role',
	rulerToolTip: 'ruler (M)',
	salesDashboard: 'sales dashboard',
	salesDashboardNoUsers: 'sales dashboard (no users)',
	salesBudget: 'sales budget',
	salesDetails: 'sales details',
	salesInformation: 'sales information',
	salesPerson: 'sales person',
	salesReport: 'sales report',
	salesResultsBasedOnAmount: 'sales results based on $ amount',
	salesResultsBasedOnNumber: 'sales results based on number',
	salesTarget: 'sales target',
	salesWon: 'sales won',
	sameAsAccount: 'same as account',
	sameAsBillingAddress: 'same as billing address',
	save: 'save',
	saveAndContinue: 'save and continue',
	saveAndReturn: 'save and return',
	saveAs: 'save As',
	saveAsTemplate: 'save as template',
	saveChanges: 'save changes',
	saveContact: 'save contact',
	saveCharge: 'save charge',
	saveCharges: 'save charges',
	saveComment: 'save comment',
	saveComments: 'save comments',
	saveEntity: 'save account',
	saveLabourCosts: 'save labour costs',
	saveOrder: 'save order',
	savePage: 'save page',
	saveProject: 'save project',
	saveToUserTools: 'save to user tools',
	saveUpdate: 'save update',
	scale: 'scale',
	scaleConfirmationInstructions: 'please measure a second distance to confirm that the scale is within an acceptable margin of error.',
	scaleInstructions: 'please select two points on the drawing and input the corresponding distance to set the scale.',
	scaleResolutionWarning1: 'this will only affect pages with no drawings',
	scaleResolutionWarning2: 'please note that if other pages have a different resolution/scale, the copied scale may be inaccurate',
	scope: 'scope',
	scopeOfWorks: 'scope of works',
	search: 'search',
	searchClients: 'search clients',
	searchPage: 'search page...',
	searchProject: 'search project...',
	searchResults: 'search results',
	searchSummary: 'search summary',
	searchTool: 'search tool',
	section: 'section',
	select: 'select (S)',
	selectAll: 'select all',
	selectAShape: 'select a shape',
	selectBrand: 'select brand',
	selectCodeType: 'select code type',
	selectColourByBrand: 'select colour by brand',
	selectCompany: 'select company',
	selectDivision: 'select division',
	selectedShapes: 'selected shape(s)',
	selectFile: 'select file',
	selectPlaceholder: 'select...',
	selectPointToolToDrawScaleLine: 'select point tool to draw scale line',
	selectPresetTemplate: 'select a preset template',
	selectProductByBrand: 'select product by brand',
	selectState: 'select state',
	selectStatus: 'select status...',
	send: 'send',
	sender: 'sender',
	sendTo: 'send to',
	sendRequest: 'send request',
	sent: 'sent',
	set: 'set',
	setBoqDetails: 'set BOQ details',
	setScaleBeforeContinuing: 'set scale before continuing',
	setScaleValueBeforeContinuing: 'set scale value before continuing',
	setScaleValue: 'set scale value',
	setUserTool: 'set a user tool for the selected BOQ',
	settings: 'settings',
	settingScale: 'setting scale',
	sheet: 'sheet',
	show: 'show',
	showAutofilledFields: 'show auto-filled fields',
	showAll: 'show all',
	showBoqs: 'show BOQs',
	showAnnotation: 'show annotation',
	showFilters: 'show filters',
	showInactive: 'show inactive',
	showMeasurementColumn: 'show measurement column',
	showMore: 'show more',
	signUpNow: 'sign up now',
	since: 'since',
	site: 'site',
	siteAddress: 'site address',
	siteAllowance: 'site allowance',
	siteContact: 'site contact',
	siteEmail: 'site email',
	siteLocation: 'site location',
	sitePhone: 'site phone',
	size: 'size',
	sizeInLitres: 'size (L)',
	sizes: 'sizes',
	specialInstructions: 'special instructions',
	specifyProductCode: 'specify product code',
	specSheet: 'technical data sheet',
	splitByDivision: 'split by division',
	spreadRate: 'spread rate',
	spreadRateWithUnits: 'spread rate (m2 / litre)',
	squareCentimetreAbbreviation: 'cm²',
	squareFootAbbreviation: 'ft²',
	squareInchAbbreviation: 'in²',
	squareMetreAbbreviation: 'm²',
	squareMillimetreAbbreviation: 'mm²',
	standardView: 'standard view',
	start: 'start',
	startAndFinishDates: 'start & finish dates',
	startTime: 'start time',
	startDate: 'start date',
	state: 'state',
	status: 'status',
	statusDate: 'status date',
	strikeRate: 'strike rate',
	strikeRateAmount: 'strike rate $',
	strikeRateNumber: 'strike rate #',
	strokeColour: 'stroke colour',
	subLocation: 'sub location',
	subLocation1: 'sub location 1',
	subLocation2: 'sub location 2',
	subLocation3: 'sub location 3',
	subject: 'subject',
	submit: 'submit',
	submitToClient: 'submit to client',
	submitted: 'submitted',
	submittedDate: 'submitted date',
	subscribe: 'subscribe',
	subscription: 'subscription',
	subscriptionChange: 'Subscription Change',
	subscriptionInvoiceHistory: 'subscription invoice history',
	subscriptionSuccessMessage: 'subscription successfully updated',
	subscriptionTime: 'hrs per month',
	subIndustry: 'sub industry',
	substrate: 'substrate',
	substrateFilter: 'substrate filter',
	summary: 'summary',
	summaryByMonth: 'summary by month',
	superseded: 'superseded',
	supersededVersions: 'superseded versions',
	supplierProduct: 'supplier product',
	supplierProductCost: 'supplier product cost',
	supplier: 'supplier',
	supplierDetails: 'supplier details',
	supplierLocation: 'supplier location',
	supplierLocations: 'supplier locations',
	supplierName: 'supplier name',
	supplierProducts: 'supplier products',
	suppliers: 'suppliers',
	supplierType: 'supplier type',
	support: 'support',
	surfaceArea: 'surface area',
	target: 'target',
	targetMet: 'Target Met',
	targetProjectName: 'target project name',
	targetProjects: 'target projects',
	targetRemaining: 'Target Remaining',
	taskDeleteWarning: 'Note: Deleting a task will remove associated annotation too.',
	tasks: 'tasks',
	teamLeader: 'team leader',
	template: 'template',
	templateTitle: 'template title',
	terms: 'terms',
	termsOfUse: 'terms of use',
	the: 'the',
	thisClaim: 'this claim',
	thisMonth: 'this month',
	thisOrder: 'this order',
	thisTemplateRepresentsProductMSDS: 'this template represents product MSDS for all products within the project',
	textareaPlaceholder: 'type here (max 3000 characters)',
	textColour: 'text colour',
	time: 'time',
	timePeriod: 'time period',
	timezone: 'timezone',
	title: 'title',
	to: 'to',
	toggleEditAnnotation: 'toggle edit annotation',
	toggleFlipAxis: 'toggle flip axis',
	toggleSubgroups: 'toggle subgroups',
	toLibrary: 'to library',
	toolName: 'tool name',
	tools: 'tools',
	toolType: 'tool type',
	top5Brands: 'top 5 brands',
	top5Clients: 'top 5 clients by',
	total: 'total',
	totalAfterSubmission: 'total after submission',
	totalAmount: 'total amount',
	totalCeilingArea: 'total ceiling area',
	totalCharge: 'total charge',
	totalChargeUsageDuration: 'total charge usage duration',
	totalCost: 'total cost',
	totalCount: 'total count',
	totalDiscount: 'total discount',
	totalGPPercent: 'total GP %',
	totalGrossCharge: 'total gross charge',
	totalHours: 'total hours',
	totalIncTax: 'total inc tax',
	totalItems: 'total items',
	totalLabourCost: 'total labour cost',
	totalLabourHours: 'total labour hours',
	totalLitres: 'total litres',
	totalMaterialsCost: 'total materials cost',
	totalMeasureUnits: 'total measure/units',
	totalPerimeter: 'total perimeter',
	totalProfit: 'total profit',
	totalProject: 'total project',
	totalProjectBuildValue: 'total project build value',
	totalSurfaceArea: 'total surface area',
	totalThisClaim: 'total this claim',
	totalTimeSpendOnQuote: 'total time spend on quote',
	totalTimeSpent: 'total time spent',
	totalUnits: 'total units',
	totalValue: 'total value',
	totalVariations: 'total variations',
	totals: 'totals',
	totalsView: 'totals view',
	totalWallsArea: 'total walls area',
	trainingAndSupport: 'training and support',
	transportCost: 'transport cost',
	transportCostPaid: 'transport cost paid',
	type: 'type',
	divisionName: 'division name',
	typeHere: 'type here',
	unallocated: 'unallocated',
	undo: 'undo (Ctrl + Z)',
	unit: 'unit',
	units: 'units',
	unitsM2: 'units (M2)',
	unitType: 'unit type',
	unlimited: 'unlimited',
	addCurrentPageToTender: 'add current page to tender',
	addAllPagesToTender: 'add all pages to tender',
	up: 'up',
	updateBoq: 'Update BOQ',
	url: 'link',
	useEstimatedValues: 'use estimated values',
	upcomingDeadline: 'upcoming deadline',
	upcomingDeadlines: 'upcoming deadlines',
	update: 'update',
	updatePage: 'update page',
	updateProjectBoqsToEba: 'update project boqs to EBA',
	updateProjectBoqsToNonEba: 'update project boqs to non-EBA',
	updateTool: 'update tool',
	userName: 'user name',
	uploadCustomDoc: 'upload custom document',
	uploadedFiles: 'uploaded files',
	uploadFile: 'upload file',
	uploadImage: 'upload image',
	uploadPage: 'upload page',
	uploadTemplate: 'upload template',
	user: 'user',
	userCount: 'user count',
	userDeactivateWarning1: 'This user is an estimator on 1 or more projects.',
	userDeactivateWarning2: 'Deactivating them without a transfer target will deactivate their associated projects.',
	userGroups: 'user groups',
	userLimit: 'user limit',
	users: 'users',
	usersAnd: 'users and',
	userStatistics: 'user statistics',
	userTool: 'user tool',
	userToolsLibrary: 'user tools library',
	userToolDropdownLabel: 'select existing user tool',
	userToolPopulateDropdownTitle: 'populate fields from user tool',
	userToolType: 'user tool type',
	userToTransferProjectsTo: 'user to transfer projects to',
	validate: 'validate',
	value: 'value',
	variation: 'variation',
	variationAmount: 'variation amount',
	variationClaimedToDate: 'variation claimed to date',
	variationClaims: 'variation claims',
	variationOutstanding: 'variation outstanding',
	variations: 'variations',
	version: 'version',
	versions: 'versions',
	view: 'view',
	viewAdditionalDetails: 'view additional details',
	viewAll: 'view all',
	viewAttachments: 'view attachments',
	viewInGoogle: 'view in google maps',
	viewInvoiceHistory: 'view invoice history',
	viewMore: 'view more',
	viewOnlyMode: 'view only mode',
	viewUserTool: 'view user tools',
	vocLevel: 'VOC Level',
	wallsSurfaceArea: 'walls surface area',
	warning: 'warning',
	waste: 'waste',
	website: 'website',
	weekly: 'weekly',
	weeklyHireRate: 'weekly hire $ rate',
	weeks: 'weeks',
	welcome: 'welcome',
	willApplyToAllSelectedShapes: 'will apply to all selected shapes',
	winLoss: 'win loss',
	winLossDetails: 'win/Loss details',
	winProbability: 'win probability',
	with: 'with',
	won: 'won',
	writeAComment: 'write a comment',
	yearToDate: 'year to date',
	yes: 'yes',
	youAPasswordResetLink: 'you a password reset link',
	youCanFindFiles: 'you can find your files which have been generated in the',
	youCurrentlyHave: 'you currently have',
	yourAccount: 'your account',
	yourProfile: 'your profile',
	yourProjects: 'your projects',
	yourTasks: 'your tasks',
	zoomIn: "zoom in (Ctrl + '+')",
	zoomOut: "zoom out (Ctrl + '-')",
	zoomToPage: "zoom to page (Shift + '+')",
	zoomToWidth: "zoom to width (Shift + '-')",
};
