import { UserModel } from './user.model';

export class ProjectSessionModel {
	public endedAt?: Date;
	public isActive: boolean = true;
	public project: string;
	public user: UserModel;

	constructor(model?: Partial<ProjectSessionModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
