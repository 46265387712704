export class StripeInvoice {
	public date: Date;
	public invoiceUrl: string;
	public stripeId: string;
	public total: number;

	constructor(json: Partial<StripeInvoice>) {
		Object.assign(this, json);
		if (this.date) {
			this.date = new Date(this.date);
		}

		this.total = json.total / 100;
	}
}
