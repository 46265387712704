import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef, EventEmitter,
	HostListener,
	inject,
	OnInit,
	Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { ProductTourService } from '../../services/product-tour.service';
import { UserModel } from '../../models';

@Component({
	selector: 'paint-product-tour',
	templateUrl: './product-tour.component.html',
})
export class ProductTourComponent implements OnInit {
	@Output() checklistToggle = new EventEmitter<boolean>();

	public isChecklistOpen = false;
	public memoized: { [key: string]: string[] } = {};
	public productTour: any[] = [];
	public relatedTours: { [key: string]: string[] } = {
		'Getting Started': ['Account', 'Companies', 'Users', 'Divisions', 'Clients', 'Help'],
		'Scheduling Tool': ['My Shifts', 'Project/Location', 'Resources', 'Schedule', 'Timesheets']
	};
	public sectionTitles: string[] = ['Getting Started', 'Scheduling Tool'];
	public availableSections: string[];
	public userRole: string;
	public userFirstName: string;
	public userId: string;
	public user: UserModel;
	public completedTourCount: number;
	private destroyRef = inject(DestroyRef);

	constructor(
		private productTourService: ProductTourService,
		private router: Router,
		private localStorageService: LocalStorageService,
		private elementRef: ElementRef
	) {}

	@HostListener('document:click', ['$event'])
	public clickOutside(event) {
		if (!this.elementRef.nativeElement.contains(event.target) && this.isChecklistOpen) {
			this.isChecklistOpen = false;
			this.checklistToggle.emit(false)
		}
	}

	public ngOnInit() {
		this.user = this.localStorageService.getUserDetails();
		this.userRole = this.localStorageService.getUserDetails().role;
		this.userFirstName = this.localStorageService.getUserDetails().firstName;
		if (this.user && this.user.entity) {
			this.productTour = this.productTourService.getTours(this.userRole);
			this.availableSections = this.filterAvailableSections(this.productTour, this.relatedTours, this.sectionTitles);
			this.availableSections.forEach(section => (this.memoized[section] = this.getRelatedTours(section)));
			this.setCompletedTours();
			this.productTourService.tourCompleted.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
				this.setCompletedTours();
			});
			this.productTourService.completedTourCount.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
				this.completedTourCount = count;
			});
		}
	}

	public navigateToTour(tourId: string): void {
		if (!this.productTourService.getCurrentTour()) {
			this.router.navigateByUrl('/').then(() => {
				this.productTourService.setTour(tourId);
			});
		}
		this.isChecklistOpen = false;
	}

	public openSetupChecklist() {
		this.isChecklistOpen = !this.isChecklistOpen;
		this.checklistToggle.emit(this.isChecklistOpen);
	}

	public setCompletedTours() {
		this.productTourService.setUserTours();
	}

	public filterAvailableSections(productTours: any[], relatedTours: { [key: string]: string[] }, sectionTitles: string[]) {
		const availableTourIds: Set<any> = new Set(productTours.map(tour => tour.id));
		return sectionTitles.filter(section => {
			return relatedTours[section].some(tourId => availableTourIds.has(tourId));
		})
	}

	public getRelatedTours(sectionTitle: string): any[] {
		return this.productTour.filter(tour => this.relatedTours[sectionTitle].includes(tour.id));
	}
}
