import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectStatusPillComponent } from '../project-status-pill.component';

@NgModule({
	declarations: [ProjectStatusPillComponent],
	exports: [ProjectStatusPillComponent],
	imports: [CommonModule],
	providers: [],
})
export class ProjectStatusPillModule {}
