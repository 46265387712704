import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SnackBarHelperComponent } from '../helpers';
import { Project } from '../interfaces';
import { ProjectSessionModel, UserModel } from '../models';
import { EstimatorUserService } from '../services/estimator-user/estimator-user.service';
import { ProjectSessionService } from '../services/project-session.service';
import { ProjectService } from '../services/project.service';
import { RolePermissionsService } from '../services/role-permissions.service';

/**
 * Guard to deactivate a user's project session if they navigate away from the project or measuring-tool routes
 */
@Injectable()
export class ProjectSessionCreateGuard {
	private currentUserId: string;
	private project: any;

	constructor(
		private estimatorUserService: EstimatorUserService,
		private projectService: ProjectService,
		private projectSessionService: ProjectSessionService,
		private rolesPermissions: RolePermissionsService,
		private snack: SnackBarHelperComponent
	) {}

	/**
	 * Auto-create project sessions when accessing any project/:projectId route
	 * If a session already exists and belongs to another user, notify the current user
	 * @param route
	 * @param state
	 */
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// Fetch the current user
		return this.projectService.getProject(route.params.projectId).pipe(
			switchMap(project => {
				this.project = project;
				return this.estimatorUserService.userDataStream;
			}),
			switchMap((currentUser: UserModel) => {
				this.currentUserId = currentUser.id;
				if (this.rolesPermissions.canEditProject(this.getIsUserKeyRole(this.project, currentUser)) && this.rolesPermissions.canEditProjectByStatus(this.project)) {
					return this.projectSessionService.startProjectSession(route.params.projectId, this.currentUserId);
				} else {
					return of(null);
				}
			}),
			map((projectSession: ProjectSessionModel) => {
				if (projectSession === null) {
					// If we lacked project edit permissions in the above switchMap
					this.snack.snackInformation('Current user is view-only');
				} else if (projectSession?.user.id !== this.currentUserId) {
					// startProjectSession will return an existing project session instead if it exists
					// Check if this (potential existing) project session belongs to our own user
					// If there was an existing project session that does not belong to the current user,
					// notify via a snack as the project will not have auto-created a session.
					this.snack.snackInformation('Another user has already started a session for this project.');
				}
				return true;
			})
		);
	}

	/**
	 * returns true if User Id is a value for one of the project keys (estimator, salesPerson, projectManager)
	 * @param project
	 * @param user
	 */
	private getIsUserKeyRole(project: Project, user: UserModel): boolean {
		return project && user ? project.estimator?.id === user.id || project.salesPerson?.id === user.id || project.projectManager?.id === user.id : false;
	}
}
