// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-text {
  white-space: pre-line;
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/email-view-dialog/email-view-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,qBAAA;AACD","sourcesContent":[".wrap-text {\n\twhite-space: pre-line;\n\tword-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
