import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectStatusPillModule } from '../../components/project-status-pill/common/project-status-pill.module';
import { ProjectStatusBarComponent } from '../project-status-bar.component';

@NgModule({
	declarations: [ProjectStatusBarComponent],
	exports: [ProjectStatusBarComponent],
	imports: [CommonModule, ProjectStatusPillModule],
})
export class ProjectStatusBarModule {}
