import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { finalize } from 'rxjs/operators';

import { Constants, NgSelectCustomFunctionHelper, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { GetNotificationValidation, GetTaskValidation, NewTask, Task, TaskNotification } from '../../../interfaces';
import { UserModel } from '../../../models';
import { TaskService } from '../../../services/task.service';

@Component({
	selector: 'app-add-task-dialog',
	templateUrl: './add-task-dialog.component.html',
})
export class AddTaskDialogComponent implements OnInit {
	@Input() public users: UserModel[];
	public taskValidator;
	public notificationValidator;
	public isEditingTask: boolean;
	public currentNotification: TaskNotification;
	public readonly periodTypes: Array<string> = Object.values(Constants.PERIOD_TYPES);
	public addEditTaskPromise: Promise<Task>;
	public currentTask: NewTask;
	public dateString: string;
	public time: Date;

	constructor(
		private snack: SnackBarHelperComponent,
		private taskService: TaskService
	) {}

	public ngOnInit() {
		this.notificationValidator = GetNotificationValidation();
		this.taskValidator = GetTaskValidation();
		this.initCurrentNotification();
		this.initTask();
		this.time = new Date();
		this.dateString = this.time.toISOString().split('T')[0];
	}

	/**
	 * Adds a new notification to the notification list of te task.
	 */
	public addNotification(): void {
		const errors = ValidatorHelper.checkErrors(this.notificationValidator);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		const newNotification: TaskNotification = Object.assign({}, this.currentNotification);
		this.currentTask.notifications.unshift(newNotification);
		this.initCurrentNotification();
	}

	public removeNotification(index: number): void {
		this.currentTask.notifications.splice(index, 1);
	}

	/**
	 * Adds or edits a task.
	 */
	public addTask(projectId: string, currentUser: UserModel): Promise<Task> {
		this.addEditTaskPromise = new Promise((resolve, reject) => {
			const errors = ValidatorHelper.checkErrors(this.taskValidator);
			if (errors) {
				this.snack.snackError(errors);
				return reject();
			}

			this.currentTask.project = projectId;
			this.currentTask.author = currentUser;

			const createTask = this.taskService
				.createTask(this.currentTask, projectId)
				.pipe(finalize(() => createTask.unsubscribe()))
				.subscribe(resolve, reject);
		});
		return this.addEditTaskPromise;
	}

	/**
	 * Initializes a new task.
	 */
	private initTask() {
		this.currentTask = {
			action: undefined,
			dueDate: new Date(),
			assignee: undefined,
			author: undefined,
			id: undefined,
			project: undefined,
			notifications: [],
			isComplete: false,
			taskType: Constants.TASK_TYPES.user,
		};
		this.isEditingTask = false;
	}

	/**
	 * Initializes the add notification form.
	 */
	private initCurrentNotification(): void {
		this.currentNotification = {
			periodType: this.periodTypes[0],
			periodNumber: 1,
		};
	}

	public changeDate() {
		const DateTimeCombinedMoment = moment(this.dateString);
		const timeMoment = moment(this.time);

		DateTimeCombinedMoment.set({
			hour: timeMoment.get('hours'),
			minute: timeMoment.get('minutes'),
			second: 0,
			millisecond: 0,
		});

		this.currentTask.dueDate = DateTimeCombinedMoment.toDate();
	}

	public customSearch(term: string, user: UserModel) {
		return NgSelectCustomFunctionHelper.userNameEmailSearch(term, user);
	}
}
