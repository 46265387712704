export class RouteNavigationMenuConfig {
	public buttonLabel: string = '';
	public exactRoute: boolean = true;
	public items: RouteNavigationMenuItem[] = [];
	public title: string = '';

	constructor(model?: Partial<RouteNavigationMenuConfig>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}

export class RouteNavigationMenuItem {
	public children?: RouteNavigationMenuItem[] = [];
	public divider?: boolean = false;
	public isExpanded?: boolean = false;
	public isDisabled?: boolean = false;
	public name: string;
	public route?: string;
	public external?: boolean;

	constructor(model?: Partial<RouteNavigationMenuItem>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
