// Framework imports
import { Injectable } from '@angular/core';

// 3rd party imports
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

// @itc-Core imports
// project specific imports
import { Project } from '../../../interfaces';
import { ProjectSessionModel, UserModel } from '../../../models';
import { LocalStorageService } from '../../../services/local-storage.service';
import { NavigationService } from '../../../services/navigation.service';
import { ProjectSessionService } from '../../../services/project-session.service';
import { ProjectService } from '../../../services/project.service';
import { UserService } from '../../../services/user.service';
import { ProjectRouteData } from './project.interfaces';

@Injectable({
	providedIn: 'root',
})
export class ProjectRouteService {
	private projectSubject: Subject<ProjectRouteData> = new Subject<ProjectRouteData | null>();
	public projectDataStream: Observable<ProjectRouteData> = this.projectSubject.asObservable().pipe(shareReplay(1));

	private returnResults: ProjectRouteData = {
		project: new Project(),
		currentUser: new UserModel(),
		isUserKeyRole: false,
		isUserLocked: false,
		doesUserShareCompany: false,
		variationId: undefined,
	};

	constructor(
		private localStorage: LocalStorageService,
		private navigationService: NavigationService,
		private projectService: ProjectService,
		private projectSessionService: ProjectSessionService,
		private userService: UserService
	) {}

	public getProject(projectId?: string): Observable<ProjectRouteData> {
		return this.projectService.getProject(projectId).pipe(
			catchError(() => {
				this.navigationService.setRoute(['']);
				return EMPTY;
			}),
			switchMap((project: Project) => {
				const user = this.localStorage.getUserDetails();
				this.returnResults.project = project;

				return this.userService.getUser(user.id);
			}),
			switchMap((user: UserModel) => {
				this.returnResults.currentUser = user;
				this.returnResults.isUserKeyRole = this.getIsUserKeyRole(this.returnResults.project, user);
				this.returnResults.doesUserShareCompany = this.getDoesUserShareCompany(this.returnResults.project, user);

				return this.projectSessionService.fetchProjectSession(this.returnResults.project.id);
			}),
			map((projectSession: ProjectSessionModel): ProjectRouteData => {
				this.returnResults.isUserLocked = projectSession?.user.id !== this.returnResults.currentUser.id;
				this.projectSubject.next(this.returnResults);

				return this.returnResults;
			})
		);
	}

	/**
	 * Called when navigating away from a project to reset it so that old data is not loaded because of shareReplay()
	 */
	public resetProject(): void {
		// Emit undefined on this observable in order to clear the project data.
		// This prevents shareReplay(1) from firing the previous project and showing old data briefly before loading a new project
		this.projectSubject.next(null);
	}

	public setLocked(isUserLocked: boolean): void {
		this.returnResults.isUserLocked = isUserLocked;
		this.projectSubject.next(this.returnResults);
	}

	public setProject(project: Project, user?: UserModel): void {
		this.returnResults.project = new Project(project);
		if (user) {
			this.returnResults.currentUser = JSON.parse(JSON.stringify(user));
		}
		this.projectSubject.next(this.returnResults);
	}

	/**
	 * Keeps track of a variation id if we are add/editing charges for a variation. Should be set to
	 * undefined when add/editing project charges
	 * @param variationId
	 */
	public setVariationId(variationId: string): void {
		// The .next() is in an if statement to fix a timing issue with getProject() running concurrently in
		// certain situations. If we set the returnResults.variationId, it will still update from the .next()
		// elsewhere.
		if (this.returnResults.variationId === variationId) {
			return;
		}
		this.returnResults.variationId = variationId;
		if (this.returnResults.project.id) {
			this.projectSubject.next(this.returnResults);
		}
	}

	/**
	 * returns true if Project.company is in User.providers
	 * @param project
	 * @param user
	 */
	private getDoesUserShareCompany(project: Project, user: UserModel): boolean {
		const doesShareCompany = false;

		for (const provider of user.providers) {
			if (provider.id === project.company.id) {
				return true;
			}
		}
		return doesShareCompany;
	}

	/**
	 * returns true if User Id is a value for one of the project keys (estimator, salesPerson, projectManager)
	 * @param project
	 * @param user
	 */
	private getIsUserKeyRole(project: Project, user: UserModel): boolean {
		return project && user ? project.estimator?.id === user.id || project.salesPerson?.id === user.id || project.projectManager?.id === user.id : false;
	}
}
