import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../helpers';
import { ReferenceCode } from '../interfaces';
import { LabourRateModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class LabourRateService extends BaseService<LabourRateModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.labourRate, LabourRateModel);
	}
}

@Injectable()
export class GetRatesCountResolver {
	constructor(private labourRateService: LabourRateService) {}

	resolve(): Observable<any> | Promise<any> | any {
		return this.labourRateService.postCount();
	}
}
