import { AfterViewInit, Component, DestroyRef, HostListener, inject, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ShepherdService } from 'angular-shepherd';
import { AlertService } from '../../../../../pages/alert/common/alert.service';
import { AuthService } from '../../../../../services/auth.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { ProductTourService } from '../../../../../services/product-tour.service';
import { RolePermissionsService } from '../../../../../services/role-permissions.service';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { DashBoardHeaderConstants } from './common/dashboard-header-menu.constants';
import { getSteps as defaultSteps } from './common/dashboard-header-menu.tour.config';
import { getSteps as defaultScheduleSteps } from './common/schedule.tour.config';
import Step from 'shepherd.js/src/types/step';
import { filter } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { TextConstants } from '../../../../../pages/alert/common/alert.constants';

@Component({
	selector: 'paint-dashboard-header-menu',
	templateUrl: './dashboard-header-menu.component.html',
	styleUrls: ['../header-menu/header-menu.component.scss', './dashboard-header-menu.component.scss'],
})
export class DashboardHeaderMenuComponent extends HeaderMenuComponent implements AfterViewInit, OnDestroy {
	@Input()
	public userImageUrl: string;

	public defaultUserImage: IconDefinition = faUser;
	public textConstants = DashBoardHeaderConstants;
	public userRole: string;
	private destroyRef = inject(DestroyRef);
	private timeout: any;

	constructor(
		authService: AuthService,
		navigationService: NavigationService,
		localStorageService: LocalStorageService,
		rolePermissionsService: RolePermissionsService,
		alertService: AlertService,
		private router: Router,
		private productTourService: ProductTourService,
		private shepherdService: ShepherdService,
	) {
		super(authService, navigationService, localStorageService, rolePermissionsService, alertService);
		this.userRole = localStorageService.getUserDetails().role;
	}

	public ngAfterViewInit() {
		super.ngAfterViewInit();

		this.timeout = setTimeout(() => {
			this.productTourService.activeTourSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(tourId => {
				if (tourId) {
					this.shepherdService.defaultStepOptions = {
						scrollTo: true,
						cancelIcon: {
							enabled: true,
						},
					};
					this.shepherdService.modal = true;
					const relatedTours = ['Account', 'Companies', 'Users', 'Divisions', 'Help'];
					const scheduleToolTours: string[] = ['My Shifts', 'Project/Location', 'Resources', 'Schedule', 'Timesheets'];
					if (!this.shepherdService.isActive) {
						if (relatedTours.includes(tourId)) {
							this.productTourService.showSteps(defaultSteps(this.router, this.shepherdService, this.productTourService), false);
						} else if (scheduleToolTours.includes(tourId)) {
							this.productTourService.showSteps(defaultScheduleSteps(this.shepherdService), false);
						}
					}
				}
			});
		}, 25);

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === 'popstate') {
					if (this.shepherdService.isActive) {
						this.shepherdService.cancel();
					}
				}
			});
	}

	public ngOnDestroy() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	protected readonly environment = environment;
	protected readonly TextConstants = TextConstants;
}
