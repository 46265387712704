import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { Constants } from '../../helpers';
import { ModalService } from '../../services/modal.service';

@Directive({
	selector: '[appConfirmClick]',
})
export class ConfirmClickDirective {
	constructor(private modalService: ModalService) {}

	@Output() appConfirmClick: EventEmitter<any> = new EventEmitter();

	@Input() isRemove: boolean = false;
	@Input() isActivate: boolean = false;

	@HostListener('click', ['$event'])
	public onClick(event): void {
		event.stopPropagation();

		let message = '';
		if (this.isRemove) {
			message = Constants.confirmModalMessage(Constants.CONFIRM_ACTIONS.remove);
		} else if (this.isActivate) {
			message = Constants.confirmModalMessage(Constants.CONFIRM_ACTIONS.activate);
		} else {
			message = Constants.confirmModalMessage(Constants.CONFIRM_ACTIONS.deactivate);
		}

		this.modalService.showConfirmation({
			title: Constants.MODAL_TITLE.confirm,
			message: message,
			submitButton: {
				click: () => {
					this.appConfirmClick.emit(event);
					this.modalService.closeModal();
				},
				style: Constants.MODAL_STYLE.primary,
				text: Constants.MODAL_TEXT.confirm,
			},
			cancelButton: {
				click: () => this.modalService.closeModal(),
				style: Constants.MODAL_STYLE.outline,
			},
		});
	}
}
