import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { IconButtonModule } from '../../../../../app/components/icon-button/common/icon-button.module';
import { IconButtonComponent } from '../../../../../app/components/icon-button/icon-button.component';
import { CoreModule } from '../../../../../app/core/core.module';
import { ItcColouredDotModule } from '../../../../directives/itc-coloured-dot/common/itc-coloured-dot.module';
import { ContentCardComponent } from '../content-card.component';

@NgModule({
	declarations: [ContentCardComponent],
	exports: [BsDropdownModule, ContentCardComponent],
	imports: [BsDropdownModule.forRoot(), CommonModule, FontAwesomeModule, ItcColouredDotModule, IconButtonModule],
	providers: [],
})
export class ContentCardModule {}
