// Framework imports
import { Component, Input } from '@angular/core';

// 3rd party imports
// @itc-Core imports
// project specific imports
import { FormErrorText } from './common/form-error.constants';

@Component({
	selector: 'paint-form-error',
	templateUrl: './form-error.component.html',
	styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
	@Input()
	public isError: boolean;

	public text: typeof FormErrorText = FormErrorText;

	constructor() {}
}
