import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Constants } from '../../../../helpers';
import { AuthService } from '../../../../services/auth.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { RolePermissionsService } from '../../../../services/role-permissions.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProductTourModule } from '../../../../components/product-tour/common/product-tour.module';
import { DropdownOptions } from '../../header/components/header-menu/common/header-menu.interfaces';
import { AlertService } from '../../../../pages/alert/common/alert.service';
import { faChevronDown, faSignOutAlt, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { UserModel } from '../../../../models';
import { HeaderMenuText } from '../../header/components/header-menu/common/header-menu.constants';
import { environment } from '../../../../../environments/environment';
import { NgClass } from '@angular/common';
import { NavigationService } from '../../../../services/navigation.service';
import { S3Service } from '../../../../services/s3.service';

@Component({
	selector: 'shifts-navigation-menu',
	templateUrl: './shifts-navigation-menu.component.html',
	styleUrls: ['./shifts-navigation-menu.component.scss'],
	standalone: true,
	imports: [
		RouterLink,
		RouterLinkActive,
		MatTooltipModule,
		BsDropdownModule,
		FaIconComponent,
		NgClass,
		ProductTourModule,
	],
})
export class ShiftsNavigationMenuComponent implements OnInit, AfterViewInit {
	public isMobileMenuOpen: boolean = false;
	public isTeamLeaderAndAbove = false;
	public isUserAndBelow = false;
	public isEntityActive: boolean = true;
	public isEntityOwner: boolean = false;
	public totalAlerts: number = 0;
	public isSuperAdmin: boolean = false;
	public dropdownOptions: Array<DropdownOptions> = [];
	public logOutIcon: IconDefinition = faSignOutAlt;
	public chevronDown: IconDefinition = faChevronDown;
	public currentUser: UserModel;
	public userPictureUrl: string;

	constructor(
		public router: Router,
		private elementRef: ElementRef,
		private localStorageService: LocalStorageService,
		private authService: AuthService,
		private rolePermissionsService: RolePermissionsService,
		private alertService: AlertService,
		private navigationService: NavigationService,
		private s3Service: S3Service,
	){}

	public ngOnInit(): void {
		this.currentUser = this.localStorageService.getUserDetails();
		this.isTeamLeaderAndAbove = this.rolePermissionsService.isTeamLeaderAndAbove();
		this.isUserAndBelow = this.rolePermissionsService.isUserAndBelow();
		this.isEntityActive = this.authService.isEntityActive();
		this.isSuperAdmin = this.rolePermissionsService.isSuperAdmin();
		this.isEntityOwner = this.authService.isEntityOwner();
		this.setDropdownOptions();
		this.getPictureUrl();
	}

	public ngAfterViewInit(): void {
		this.alertService.alerts.subscribe(total => {
			this.totalAlerts = total;
		});
	}

	public toggleMobileMenu(): void {
		this.isMobileMenuOpen = !this.isMobileMenuOpen;
	}

	private setDropdownOptions(): void {
		// Do not display user profile and setting options for entity owners with an inactive plan
		if (this.isEntityActive) {
			this.dropdownOptions.push({
				displayText: HeaderMenuText.alerts,
				route: 'shifts/alerts',
			});
		}
		this.dropdownOptions.push({
			displayText: HeaderMenuText.help,
			route: Constants.ROUTE_LINKS.help,
		});
		// Only show subscription page dropdown option if we are on the public SaaS environment
		if (this.isEntityOwner && (environment.env === 'production' || environment.env === 'local' || environment.env === 'development')) {
			this.dropdownOptions.push({
				displayText: HeaderMenuText.subscription,
				route: Constants.ROUTE_LINKS.subscription,
			});
		}
		this.dropdownOptions.push({
			displayText: HeaderMenuText.logOut,
			route: Constants.ROUTE_LINKS.login,
		});
	}

	public alertsSelected(): void {
		this.navigationService.setRoute(['shifts/alerts']);
	}

	public optionSelected(option: DropdownOptions) {
		if (option.route.startsWith('https')) {
			window.open(option.route, '_blank');
		} else {
			this.navigationService.setRoute([option.route]);
		}
	}

	public getPictureUrl() {
		if (this.currentUser && this.currentUser.displayPictureKey) {
			this.s3Service.getSignedUrl(this.currentUser.displayPictureKey).subscribe(pictureUrl => {
				this.userPictureUrl = pictureUrl;
			})}
	}

	@HostListener('document:click', ['$event'])
	public documentClick(event: Event): void {
		if (this.isMobileMenuOpen && !this.elementRef.nativeElement.contains(event.target)) {
			this.isMobileMenuOpen = false;
		}
	}

	protected readonly defaultUserImage = faUser;
}
