import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ArrayHelper, Constants, SnackBarHelperComponent } from '../../../../../helpers';
import { CoatingSystem, GetPaintInformationValidation, Product, ReferenceCode } from '../../../../../interfaces';
import { BrandModel } from '../../../../../models';
import { AuthService } from '../../../../../services/auth.service';
import { BrandService } from '../../../../../services/brand.service';
import { CoatingSystemService } from '../../../../../services/coating-system.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { ProductService } from '../../../../../services/product.service';

@Component({
	selector: 'app-paint-information-tile',
	templateUrl: './paint-information.component.html',
})
export class PaintInformationComponent implements OnInit {
	@Input() public product: Product;
	@Input() public isDisabled: Boolean = false;
	@Input() public substrate: ReferenceCode;
	@Input() public validators;
	@Input() public isBoqForm: boolean = false;
	@Output() public productChanged: EventEmitter<Product> = new EventEmitter<Product>();
	@Output() public selectedCoatingSystem: EventEmitter<any> = new EventEmitter<any>();

	public products: Product[] = [];
	public filteredProducts: Product[] = [];
	public recentProducts: Product[] = [];
	public finishTypes: ReferenceCode[] = [];
	public filterBrandId: string;
	public filterFinish: ReferenceCode;
	public boqMatchingCoatingSystems: CoatingSystem[] = undefined;
	public isAdmin: boolean = false;
	public brands: BrandModel[] = [];
	public searchingCoatingSystemsPromise: Promise<CoatingSystem[]>;
	public loadingProductsPromise: Promise<Product[]>;

	constructor(
		public snack: SnackBarHelperComponent,
		public authService: AuthService,
		public localStorageService: LocalStorageService,
		public coatingSystemService: CoatingSystemService,
		private brandService: BrandService,
		private route: ActivatedRoute,
		private productService: ProductService
	) {}

	ngOnInit() {
		this.route.data.subscribe(({ referenceCodeTypes }) => {
			this.finishTypes = referenceCodeTypes.finish_type;
		});

		// Validator for product since boq requires it
		if (!this.validators) {
			this.validators = GetPaintInformationValidation();
		}

		if (this.isDisabled) {
			this.validators.Product.disable();
		}

		this.isAdmin = this.authService.isAdmin();

		if (this.product) {
			this.filterBrandId = this.product.brand;
			this.filterFinish = this.product.finish;
		}

		this.brandService.postList({ isActive: true }).subscribe(brands => {
			this.brands = brands;
		});

		this.loadingProductsPromise = this.productService.postList({ isActive: true }).toPromise();

		this.loadingProductsPromise
			.then(products => {
				this.products = products;
				this.loadRecentProducts();
				this.filterProduct();
			})
			.catch(() => {});
	}

	/**
	 * Add the selected product to the LocalStorage
	 */
	selectProduct() {
		if (this.product) {
			this.localStorageService.addRecentProduct(this.product.id);
			this.filterBrandId = this.product.brand;
			this.filterFinish = this.product.finish;
		}
		this.productChanged.emit(this.product);
		this.resetCoatingSystemFields();
	}

	/**
	 * Load All the recent Project to the recent Projects
	 */
	loadRecentProducts() {
		this.recentProducts = [];
		const recentProductIds = this.localStorageService.getRecentProducts();
		if (recentProductIds) {
			for (const item of recentProductIds) {
				const result = ArrayHelper.findObjectInArrayWithId(this.products, item);
				if (result) {
					this.recentProducts.push(result);
				}
			}
		}
	}

	/**
	 * Filter the product List by the selected brand or finish
	 */
	filterProduct() {
		if (this.products && this.filterBrandId && this.filterFinish) {
			this.filteredProducts = this.products.filter(product => product.brand === this.filterBrandId && product.finish.id === this.filterFinish.id);
		} else if (this.products && this.filterBrandId) {
			this.filteredProducts = this.products.filter(product => product.brand === this.filterBrandId);
		} else if (this.products && this.filterFinish) {
			this.filteredProducts = this.products.filter(product => product.finish.id === this.filterFinish.id);
		} else {
			this.filteredProducts = this.products;
		}
	}

	/**
	 * Reset the coating system and found coating systems
	 */
	resetCoatingSystemFields() {
		const coatingSystem = {
			title: '',
			coatProducts: [],
			chargeOutRates: [],
		};
		this.boqMatchingCoatingSystems = undefined;
		this.selectedCoatingSystem.emit(coatingSystem);
	}

	/**
	 * Load the title for matching Finish Type
	 * @param {string} finishId
	 */
	getFinishTitle(finishId: string) {
		if (finishId) {
			const finishObj = ArrayHelper.findObjectInArrayWithId(this.finishTypes, finishId);
			if (finishObj) {
				return finishObj.title;
			}
			return '';
		}
	}

	/**
	 * Load the title for matching Brand
	 * @param {string} brandId
	 */
	getBrandTitle(brandId: string) {
		if (brandId) {
			const brand = ArrayHelper.findObjectInArrayWithId(this.brands, brandId);
			return brand ? brand.name : '';
		}
	}

	/**
	 * When Recent Product is selected to load related product finish and brand
	 * @param {string} product
	 */
	selectRecentProduct(product: Product) {
		this.filterFinish = product.finish;
		this.filterBrandId = product.brand;
		this.product = product;
		this.productChanged.emit(product);
		this.filterProduct();
		this.resetCoatingSystemFields();
	}

	/**
	 * Search for matching Coating Systems where the brand, product and substrate match
	 * @param {Product} product
	 * @param {ReferenceCode} substrate
	 */
	searchCoatingSystem(product: Product, substrate: ReferenceCode) {
		if (!product || !substrate) {
			this.snack.snackError(Constants.BOQ_COMPONENT_FIELD_MESSAGE);
			return;
		}
		const postObject = { brand: product.brand, product: product.id, substrate: substrate.id, isActive: true };
		this.searchingCoatingSystemsPromise = this.coatingSystemService.postList(postObject).toPromise();
		this.searchingCoatingSystemsPromise.then(coatingSystems => (this.boqMatchingCoatingSystems = coatingSystems));
	}

	/**
	 * Called when coating system is selected. Emits the coating system selected event
	 * @param coatingSystem
	 */
	selectCoatingSystem(coatingSystem) {
		this.selectedCoatingSystem.emit(coatingSystem);
	}

	/**
	 * Called when brand or finish is selected. Resets the product and coating system, filters the list of products and emits the product changed event
	 */
	onChangeBrandOrFinish() {
		this.product = undefined;
		this.productChanged.emit(this.product);
		this.filterProduct();
		this.resetCoatingSystemFields();
	}
}
