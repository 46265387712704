import { Component, Input, OnInit } from '@angular/core';

import { SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { Company, GetMergeClientValidation } from '../../../interfaces';
import { ClientService } from '../../../services/client.service';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-merge-client-dialog',
	templateUrl: './merge-client-dialog.component.html',
	styleUrls: ['./merge-client-dialog.component.css'],
})
export class MergeClientDialogComponent implements OnInit {
	@Input() public client: Company;
	public mergedClient: Company;
	public clients: Company[];
	public newClient: Company = undefined;
	public clientPicture = undefined;
	public newClientPicture = undefined;
	public validators;
	public mergeClientsPromise: Promise<Company>;
	public loadingClientsPromise: Promise<Company[]>;

	constructor(
		public clientService: ClientService,
		public s3Service: S3Service,
		public snack: SnackBarHelperComponent
	) {}

	ngOnInit() {
		this.validators = GetMergeClientValidation();
		this.loadingClientsPromise = this.clientService.getClients().toPromise();
		this.loadingClientsPromise.then(clients => {
			this.clients = clients.filter(client => client.id !== this.client.id);
		});
		if (this.client) {
			if (this.client.displayPictureUrl) {
				this.clientPicture = this.client.displayPictureUrl;
			} else if (this.client.displayPictureKey) {
				this.s3Service.getSignedUrl(this.client.displayPictureKey).subscribe(signedUrl => {
					this.clientPicture = signedUrl;
				});
			}
		}
		this.initMergedClient();
	}

	updatePicture() {
		if (this.newClient) {
			if (this.newClient.displayPictureUrl) {
				this.newClientPicture = this.newClient.displayPictureUrl;
			} else if (this.newClient.displayPictureKey) {
				this.s3Service.getSignedUrl(this.newClient.displayPictureKey).subscribe(signedUrl => {
					this.newClientPicture = signedUrl;
				});
			} else {
				this.newClientPicture = undefined;
			}
		} else {
			this.newClientPicture = undefined;
		}
	}

	mergeClients(): Promise<Company> {
		this.mergeClientsPromise = new Promise((resolve, reject) => {
			const errors = ValidatorHelper.checkErrors(this.validators);
			if (errors) {
				this.snack.snackError(errors);
				return reject(errors);
			}
			this.clientService.mergeClient(this.client.id, this.newClient.id, this.mergedClient).subscribe(resolve, reject);
		});
		return this.mergeClientsPromise;
	}

	initMergedClient(): void {
		this.mergedClient = {
			name: this.client.name,
			email: this.client.email,
			phone: this.client.phone,
			fax: this.client.fax,
			addressLine1: this.client.addressLine1,
			addressLine2: this.client.addressLine2,
			addressLine3: this.client.addressLine3,
			city: this.client.city,
			state: this.client.state,
			postalCode: this.client.postalCode,
			website: this.client.website,
			abn: this.client.abn,
			industryLicenseNumber: this.client.industryLicenseNumber,
			isClient: this.client.isClient,
			isProvider: this.client.isProvider,
			isActive: this.client.isActive,
			displayPictureKey: this.client.displayPictureKey,
			displayPictureUrl: this.client.displayPictureUrl,
			imageUrl: this.client.imageUrl,
			isParent: false,
		};
	}

	setMergedClientPicture(client: Company): void {
		this.mergedClient.displayPictureKey = client.displayPictureKey;
		this.mergedClient.displayPictureUrl = client.displayPictureUrl;
	}
}
