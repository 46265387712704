import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class CustomValidators {
	public static minLengthArray(min: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value && c.value.length >= min) {
				return null;
			}

			return { minLengthArray: { valid: false, requiredLength: min } };
		};
	}

	/**
	 * Throws an error if an arrays items are not unique
	 * @param name Name of the field that must be unique (used in error message)
	 */
	public static arrayItemsUnique(name: string) {
		return (c: AbstractControl): { [key: string]: any } => {
			const map = {};
			for (const item of c.value) {
				if (map[item]) {
					return { arrayItemsUnique: { valid: false, name } };
				} else {
					map[item] = true;
				}
			}

			return null;
		};
	}

	/**
	 * The project charge's claims should only be required if there are no variation claims.
	 */
	public static invoiceClaimArray() {
		return (c: UntypedFormGroup): { [key: string]: any } => {
			if (!(c.value.variationClaims.length || c.value.claims.length)) {
				c.controls.claims.setErrors({ required: true });
			} else {
				c.controls.claims.setErrors(undefined);
			}
			return null;
		};
	}

	/**
	 * Custom validation to check if the field is empty after trim
	 * @param control
	 */
	public static noWhiteSpaceValidator(control: UntypedFormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	}
}
