import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import { CoatingSystem, EmbeddedRate, GetChargeOutRateValidation, State } from '../../../interfaces';

@Component({
	selector: 'app-charge-out-rates-table',
	templateUrl: 'charge-out-rates-table.component.html',
	styleUrls: ['./charge-out-rates-table.component.scss'],
})
export class ChargeOutRatesTableComponent implements OnInit {
	public _coatingSystem: CoatingSystem;

	@Input()
	public set isDisabled(isDisabled: boolean) {
		this._isDisabled = isDisabled;
		for (const validators of this.chargeOutRatesValidators) {
			ValidatorHelper.setFormControlsEnabled(validators, !isDisabled, {});
		}
	}
	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	@Input() set coatingSystem(value: CoatingSystem) {
		this._coatingSystem = value;
		this.setNewChargeOutRate();
		this.setupChargeOutRatesFormGroup();
		this.newChargeOutRateValidators = GetChargeOutRateValidation();
		if (this.isDisabled) {
			for (const validators of this.chargeOutRatesValidators) {
				ValidatorHelper.setFormControlsEnabled(validators, false, {});
			}
		}
	}

	@Input() public readonly isModalView: boolean = false;

	public chargeOutRatesFormGroup: UntypedFormGroup;
	public chargeOutRatesValidators = [];
	public newChargeOutRate: EmbeddedRate;
	public newChargeOutRateValidators;
	public showAddRow: boolean = false;
	public states: State[] = [];
	public textConstants: typeof TEXT = TEXT;

	private _isDisabled: boolean = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private snack: SnackBarHelperComponent
	) {
		//
	}

	public ngOnInit(): void {
		this.activatedRoute.data.subscribe(({ states }) => {
			this.states = states;
		});
	}

	/**
	 * Removes the given charge out rate from the coating system.
	 * @param embeddedRate
	 */
	public removeChargeOutRate(embeddedRate): void {
		const index = this._coatingSystem.chargeOutRates.indexOf(embeddedRate);
		if (index !== -1) {
			this._coatingSystem.chargeOutRates.splice(index, 1);
			this.chargeOutRatesValidators.splice(index, 1);
		}
		this.setupChargeOutRatesFormGroup();
	}

	/**
	 * Adds the new charge out rate to the coating system.
	 */
	public addChargeOutRate(): void {
		const errors = ValidatorHelper.checkErrors(this.newChargeOutRateValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		if (!this.validateChargeOutRateStatesAreUnique(this.newChargeOutRate.state)) {
			return;
		}

		this._coatingSystem.chargeOutRates.push(this.newChargeOutRate);
		this.setNewChargeOutRate();
		this.setupChargeOutRatesFormGroup();
		this.newChargeOutRateValidators = GetChargeOutRateValidation();
	}

	public toggleAddRow(): void {
		this.showAddRow = !this.showAddRow;
	}

	/**
	 * Validates that the state of the charge out rate being added is not already present in the array
	 * @param state
	 */
	public validateChargeOutRateStatesAreUnique(state) {
		if (this._coatingSystem.chargeOutRates && this._coatingSystem.chargeOutRates.findIndex(rate => (rate.state ? rate.state.id === state.id : false)) !== -1) {
			this.snack.snackError(Constants.VALIDATION_ERROR_UNIQUE_CHARGE_OUT_RATE);
			return false;
		}
		return true;
	}

	/**
	 * Creates a blank charge out rate.
	 */
	private setNewChargeOutRate(): void {
		this.newChargeOutRate = {
			state: undefined,
			rateEBA: 0,
			rateNonEBA: 0,
		};
	}

	/**
	 * Generates a form control for each element in the coating systems products array.
	 */
	private setupChargeOutRatesFormGroup(): void {
		if (!this.chargeOutRatesFormGroup) {
			this.chargeOutRatesFormGroup = new UntypedFormGroup({});
		}

		if (!this._coatingSystem || !this._coatingSystem.chargeOutRates) {
			return;
		}

		for (let i = 0; i < this._coatingSystem.chargeOutRates.length; ++i) {
			if (!this.chargeOutRatesValidators[i]) {
				ValidatorHelper.getDynamicValidationControl(GetChargeOutRateValidation, this.chargeOutRatesValidators, i, this.chargeOutRatesFormGroup);
			}
		}
		this.chargeOutRatesValidators.length = this._coatingSystem.chargeOutRates.length;
	}
}
