/**
 * Check clients are valid before saving
 */
import { ContactClient, Project } from '../interfaces';

export class ClientHelper {
	/**
	 * Sets a clients contact to the first in the list
	 * @param selectedClient
	 * @constructor
	 */
	public selectedClient(selectedClient: ContactClient) {
		this.clearClientContact(selectedClient);
		if (selectedClient.company && selectedClient.company.contacts.length > 0) {
			selectedClient.contact = selectedClient.company.contacts[0];
			this.selectedContact(selectedClient);
		}
	}

	/**
	 * Update the contact email and address fields when the user
	 * selects or updates a client contact
	 */
	public selectedContact(client: ContactClient) {
		if (!client) {
			return;
		}
		if (!client.contact) {
			this.clearClientContact(client);
		} else {
			client.email = client.contact.email;
			client.address = this.getFormattedAddress(client.contact);
		}
	}

	public getFormattedAddress(contact) {
		let address = '';
		address += !contact.addressLine1 ? '' : contact.addressLine1 + ', ';
		address += !contact.addressLine2 ? '' : contact.addressLine2 + ', ';
		address += !contact.addressLine3 ? '' : contact.addressLine3 + ', ';
		address += !contact.city ? '' : contact.city + ', ';
		address += !contact.state ? '' : contact.state.name + ', ';
		address += !contact.postalCode ? '' : contact.postalCode;
		return address;
	}

	/**
	 * Add an new client to the list of clients
	 */
	public addNewClient(client: ContactClient, projectClients: ContactClient[]): ContactClient[] {
		if (client.contact && client.company) {
			const clientCopy = Object.assign({}, client);
			if (projectClients) {
				projectClients.push(clientCopy);
			} else {
				projectClients = [clientCopy];
			}
		}
		return projectClients;
	}

	/**
	 * Resets a clients fields
	 * @param client
	 */
	public clearClient(client: ContactClient) {
		delete client.company;
		this.clearClientContact(client);
	}

	public clearClientContact(client: ContactClient) {
		client.contact = undefined;
		client.email = '';
		client.address = '';
	}

	public getEmptyClient() {
		return { company: undefined, contact: undefined, email: '', address: '' };
	}

	/**
	 * Delete the client from the list of clients while adding or editing the clients
	 */
	public deleteClient(client: ContactClient, projectClients: ContactClient[]) {
		const index = projectClients.indexOf(client);
		if (index !== -1) {
			projectClients.splice(index, 1);
		}
	}

	/**
	 * Throws an error and returns false if a projects client list is not valid
	 * @param project
	 */
	public areClientsValid(project: Partial<Project>) {
		if (!project.clients || project.clients.length === 0) {
			return false;
		}
		for (const client of project.clients) {
			if (!client.email || !client.contact || !client.company) {
				return false;
			}
		}
		return true;
	}
}
