import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ProjectSessionResponse } from '../interfaces';

@Injectable()
export class ProjectSessionService {
	constructor(private http: HttpClient) {}

	/**
	 * Creates a new projectSession
	 * @param projectId
	 * @param userId
	 * @returns {Observable<*>}
	 */
	public startProjectSession(projectId: string, userId: string) {
		return this.http.post<ProjectSessionResponse>(`${Constants.BASE_API_URL}/project/project-session/create`, { projectId, userId }).pipe(
			map(res => {
				return res.projectSession;
			})
		);
	}

	/**
	 * Fetches a projectSession
	 * @param projectId
	 */
	public fetchProjectSession(projectId: string) {
		return this.http.post<ProjectSessionResponse>(`${Constants.BASE_API_URL}/project/project-session/details`, { projectId }).pipe(
			map(res => {
				return res.projectSession;
			})
		);
	}

	/**
	 * Completes a projectSession
	 * @param {string} projectId
	 * @param userId
	 * @param shouldOverride
	 * @returns {Observable<*>}
	 */
	public completeProjectSession(projectId: string, userId: string, shouldOverride: boolean = false) {
		return this.http.post<void>(`${Constants.BASE_API_URL}/project/project-session/complete`, { projectId, userId, shouldOverride });
	}
}
