import { Validators } from '@angular/forms';

import { CustomValidators, ValidatorHelper } from '../helpers';
import { UserModel } from '../models';
import { Company } from './company';

export interface PartialUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	role?: string;
	isActive?: boolean;
}

export interface UserResponse {
	status: boolean;
	user: UserModel;
}

export interface UsersResponse {
	status: boolean;
	users: UserModel[];
}

export interface PartialUsersResponse {
	status: boolean;
	users: PartialUser[];
}

export interface UserSearch {
	firstName: string;
	lastName: string;
	isActive?: boolean;
}

//interface for the user while login
export class LoginUser {
	public email: string;
	public password: string;
	public rememberMe: boolean = false;
}

export class SignUpUser {
	public firstName: string;
	public lastName: string;
	public company: string;
	public email: string;
	public phone: string;
	public countryCode: string;
	public plan: string;
}

export function GetSignUpValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'FirstName',
			validators: [Validators.required],
		},
		{
			name: 'LastName',
			validators: [Validators.required],
		},
		{
			name: 'Email',
			validators: [Validators.email, Validators.required],
		},
		{
			name: 'Phone',
			validators: [Validators.required],
		},
		{
			name: 'CountryCode',
			validators: [Validators.required],
		},
		{
			name: 'Company',
			validators: [Validators.required],
		},
	]);
}

export function GetLoginValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Email',
			validators: [Validators.email, Validators.required],
		},
		{
			name: 'Password',
			validators: [Validators.required],
		},
	]);
}

export function GetUserSearchValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'FirstName',
			validators: [Validators.maxLength(300)],
		},
		{
			name: 'LastName',
			validators: [Validators.maxLength(300)],
		},
	]);
}

export function GetUserValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'FirstName',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'LastName',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Email',
			validators: [Validators.maxLength(255), Validators.required, Validators.email, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Phone',
			validators: [],
		},
		{
			name: 'Position',
			validators: [],
		},
		{
			name: 'Divisions',
			validators: [],
		},
		{
			name: 'Companies',
			validators: [],
		},
	]);
}

export function GetRoleValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Role',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
	]);
}

export function GetPasswordValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'CurrentPassword',
			validators: [Validators.minLength(6), Validators.required, Validators.maxLength(300)],
		},
		{
			name: 'NewPassword',
			validators: [Validators.minLength(6), Validators.required, Validators.maxLength(20)],
		},
		{
			name: 'VerifyPassword',
			validators: [Validators.minLength(6), Validators.required, Validators.maxLength(20)],
		},
	]);
}

export function GetUserClientValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Client',
			validators: [Validators.minLength(1), Validators.required],
		},
	]);
}

export function GetUserProviderValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Provider',
			validators: [Validators.minLength(1), Validators.required],
		},
	]);
}

export function GetUserDivisionValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Divisions',
			validators: [Validators.minLength(1), Validators.required],
		},
	]);
}
