import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(router: Router, service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {
	const handler = () => {
		service.next();
	};

	return [
		{
			id: 'step3',
			attachTo: {
				element: '.create-new-company-button',
				on: 'left',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			buttons: [
				{
					text: 'Next',
					action: function () {
						const element: HTMLElement = document.getElementById('new-company-btn');
						element.click();
					},
				},
			],
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Step 3: Navigate to Create New Company',
			text: ['Click on Create New Company'],
			when: {
				show: () => {
					const navItem = document.querySelector(`.create-new-company-button`);

					if (navItem) {
						navItem.addEventListener('click', handler);
					}
				},
				hide: () => {
					const navItem = document.querySelector(`.create-new-company-button`);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}
				},
				cancel: () => {
					const navItem = document.querySelector(`.create-new-company-button`);
					if (navItem) {
						navItem.removeEventListener('click', handler);
					}
				},
			},
		},
	];
}
