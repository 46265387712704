import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Constants } from '../helpers';
import { CompaniesResponse, ReferenceCode } from '../interfaces';

@Injectable()
export class ProviderService {
	//this is can keep the cached data for
	//providers and so if we have can use
	//instead of making the api call.
	providersObservable: Observable<any> = null;

	constructor(private http: HttpClient) {}

	/**
	 * Gets all companies
	 * @returns {Observable<any>}
	 */
	getProviders() {
		this.providersObservable = this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/provider/list`, {
				params: { isProvider: true },
			})
			.pipe(
				map(res => res.companies),
				shareReplay(1)
			);
		return this.providersObservable;
	}

	/**
	 * Gets all companies
	 * @returns {Observable<any>}
	 */
	getAllProviders() {
		this.providersObservable = this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/provider/list-all`, {
				params: {
					isProvider: true,
					isParent: false,
					isActive: true,
				},
			})
			.pipe(
				map(res => res.companies),
				shareReplay(1)
			);
		return this.providersObservable;
	}
}

@Injectable()
export class GetAllProvidersForUsersResolver {
	constructor(private providerService: ProviderService) {}

	resolve(): Observable<any> | Promise<any> | any {
		return this.providerService.getAllProviders();
	}
}
