import { Injectable } from '@angular/core';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class UploadCustomDocumentsGuard {
	constructor(private localStorageService: LocalStorageService) {}

	public canActivate(): boolean {
		const currentUser = this.localStorageService.getUserDetails();
		return currentUser.entity.canUploadCustomDocuments;
	}
}
