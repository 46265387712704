import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonIcons } from '../../../../app/components/icon-button/common/icon-button.constants';
import { IconButtonConfig } from '../../../../app/components/icon-button/common/icon-button.interfaces';
import { NavigationMenuItem } from './common/navigation-menu.interfaces';
import { NavigationMenuConfig } from './common/navigation-menu.models';

@Component({
	selector: 'paint-navigation-menu',
	templateUrl: './navigation-menu.component.html',
	styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent {
	@Input()
	public config: NavigationMenuConfig = new NavigationMenuConfig();
	@Input()
	public logoURL: string;
	@Input()
	public navigationItems: NavigationMenuItem[];

	@Output()
	public navigationItemClicked: EventEmitter<NavigationMenuItem> = new EventEmitter();

	public firstIconConfig: IconButtonConfig = {
		icon: ButtonIcons.folder,
		isAddSuffix: true,
		hasBorder: false,
	};
	public sideMinimized: boolean = true;

	constructor() {}

	// Method executed when non-popover nav button is pushed. If a popover menu option is selected, it will use its itemType instead (library / settings)
	public onItemClicked(item?: NavigationMenuItem): void {
		this.navigationItemClicked.emit(item);
	}
}
