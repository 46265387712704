import { Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { HeaderService } from '../../../../app/core/components/header/common/header.service';
import { slideInOut } from '../../../common/itc-core.animations';
import { RouteNavigationMenuConfig, RouteNavigationMenuItem } from './common/route-navigation-menu.models';

@Component({
	selector: 'itc-route-navigation-menu',
	templateUrl: './route-navigation-menu.component.html',
	styleUrls: ['./route-navigation-menu.component.scss'],
	animations: [slideInOut],
})
export class RouteNavigationMenuComponent implements OnDestroy {
	@Output()
	public buttonPressed: EventEmitter<string> = new EventEmitter();
	@Output()
	public isSidebarCollapsed: EventEmitter<boolean> = new EventEmitter();
	@Output()
	public itemClicked: EventEmitter<string> = new EventEmitter();
	public isMinimized: boolean = false;
	public items: RouteNavigationMenuItem[] = [];
	public selectedParent: RouteNavigationMenuItem;
	private destroyRef = inject(DestroyRef);

	constructor(
		private headerService: HeaderService,
	) {}

	private _config: RouteNavigationMenuConfig = new RouteNavigationMenuConfig();

	public get config(): RouteNavigationMenuConfig {
		return this._config;
	}

	@Input()
	public set config(navigationMenuConfig: RouteNavigationMenuConfig) {
		if (navigationMenuConfig) {
			this._config = navigationMenuConfig;
			this.setItems(navigationMenuConfig.items);
			this.isMinimized ? this.headerService.contract() : this.headerService.expand();
		}
	}

	private _selectedItem: RouteNavigationMenuItem;

	public get selectedItem(): RouteNavigationMenuItem {
		return this._selectedItem;
	}

	public set selectedItem(item: RouteNavigationMenuItem) {
		if (item.route) {
			this.itemClicked.emit(item.route);
		}
		this._selectedItem = item;
	}

	public onButtonClicked(): void {
		this.buttonPressed.emit();
	}

	public onItemClicked(item: RouteNavigationMenuItem, parent?: RouteNavigationMenuItem): void {
		if (item.external) {
			window.location.href = item.route;
		} else {
			if (item.children && item.children.length) {
				this.onParentClicked(item);
			} else {
				this.onChildClicked(item, parent);
			}
		}
	}


	public toggleMinimize(): void {
		this.isMinimized = !this.isMinimized;
		this.isSidebarCollapsed.emit(this.isMinimized);
		if (this.isMinimized) {
			this.headerService.contract();
		} else {
			this.headerService.expand();
		}
	}

	public ngOnDestroy() {
		this.headerService.contract();
	}

	/**
	 * set isSelected (needed for styling) property depending on conditions
	 * parent items with isSelected set to true show the children
	 * @param item: RouteNavigationMenuItem
	 */
	private onParentClicked(item: RouteNavigationMenuItem): void {
		//  if menu parent item is already selected
		if (this.selectedParent) {
			//  If the user is clicking the current selected parent - toggle the state of it and exit
			if (item.name === this.selectedParent.name) {
				this.selectedParent.isExpanded = !this.selectedParent.isExpanded;
				return;
			}
			//  if an item other than the current selected parent is clicked - deselect the current item
			this.selectedParent.isExpanded = false;
		}
		// set the selected parent to the clicked item and set it as selected
		this.selectedParent = item;
		this.selectedParent.isExpanded = !this.selectedParent.isExpanded;
	}

	/**
	 * set isSelected (needed for styling) property depending on conditions
	 * @param item: RouteNavigationMenuItem
	 * @param parent: RouteNavigationMenuItem (parent of clicked item)
	 */
	private onChildClicked(item: RouteNavigationMenuItem, parent: RouteNavigationMenuItem): void {
		//  if clicked item does not have children and it's id is matching selectedItem.route - don't do anything
		if (this.selectedItem && this.selectedItem.route === item.route) {
			return;
		}
		if (this.selectedItem) {
			this.selectedItem.isExpanded = false;
		}
		this.selectedItem = item;
		this.selectedItem.isExpanded = true;
		this.selectedParent = parent;
		if (this.selectedParent) {
			this.selectedParent.isExpanded = true;
		}
	}

	private setItems(items: Partial<RouteNavigationMenuItem>[]): void {
		const menuItems: RouteNavigationMenuItem[] = [];
		if (items) {
			for (const item of items) {
				menuItems.push(new RouteNavigationMenuItem(item));
			}
		}
		this.items = menuItems;
	}

}
