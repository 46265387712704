import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../helpers';
import { Email, EmailResponse, ProjectTargetingUpdate } from '../interfaces';

@Injectable()
export class EmailService {
	constructor(private http: HttpClient) {}

	/**
	 * Sends an email to a client with a file url attachment.
	 * @param email
	 */
	public sendExportEmail(email) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/email/export`, email);
	}

	public sendTargetProjectEmail(email: Email, projectTargetingUpdate: ProjectTargetingUpdate, isAcceptance: boolean): Observable<EmailResponse> {
		return this.http.post<EmailResponse>(`${Constants.BASE_API_URL}/email/target-project`, {
			email,
			projectTargetingUpdate,
			isAcceptance,
		});
	}
}
