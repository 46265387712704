// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog,
dialog::backdrop {
	background: rgba(0, 0, 0, 0.4);
}

dialog[open] {
	animation: dialog-in 125ms ease-out forwards;
}

dialog[open]::backdrop {
	animation: backdrop-in 125ms ease-out forwards;
}

dialog.closing[open] {
	animation: dialog-out 125ms ease-in forwards;
}

dialog.closing[open]::backdrop {
	animation: backdrop-out 125ms ease-in forwards;
}

@keyframes backdrop-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes backdrop-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes dialog-in {
	0% {
		opacity: 0;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes dialog-out {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.95);
	}
}

@media (prefers-reduced-motion) {
	.drawer {
		dialog[open] {
			animation: none;
		}
		dialog[open]::backdrop {
			animation: none;
		}

		dialog.closing[open] {
			animation: none;
		}
		dialog.closing[open]::backdrop {
			animation: none;
		}
	}
}
`, "",{"version":3,"sources":["webpack://./src/@mos-core/components/dialog/dialog.component.css"],"names":[],"mappings":"AAAA;;CAEC,8BAA8B;AAC/B;;AAEA;CACC,4CAA4C;AAC7C;;AAEA;CACC,8CAA8C;AAC/C;;AAEA;CACC,4CAA4C;AAC7C;;AAEA;CACC,8CAA8C;AAC/C;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;CACC;EACC,UAAU;EACV,sBAAsB;CACvB;CACA;EACC,UAAU;EACV,mBAAmB;CACpB;AACD;;AAEA;CACC;EACC,UAAU;EACV,mBAAmB;CACpB;CACA;EACC,UAAU;EACV,sBAAsB;CACvB;AACD;;AAEA;CACC;EACC;GACC,eAAe;EAChB;EACA;GACC,eAAe;EAChB;;EAEA;GACC,eAAe;EAChB;EACA;GACC,eAAe;EAChB;CACD;AACD","sourcesContent":["dialog,\ndialog::backdrop {\n\tbackground: rgba(0, 0, 0, 0.4);\n}\n\ndialog[open] {\n\tanimation: dialog-in 125ms ease-out forwards;\n}\n\ndialog[open]::backdrop {\n\tanimation: backdrop-in 125ms ease-out forwards;\n}\n\ndialog.closing[open] {\n\tanimation: dialog-out 125ms ease-in forwards;\n}\n\ndialog.closing[open]::backdrop {\n\tanimation: backdrop-out 125ms ease-in forwards;\n}\n\n@keyframes backdrop-in {\n\t0% {\n\t\topacity: 0;\n\t}\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n@keyframes backdrop-out {\n\t0% {\n\t\topacity: 1;\n\t}\n\t100% {\n\t\topacity: 0;\n\t}\n}\n\n@keyframes dialog-in {\n\t0% {\n\t\topacity: 0;\n\t\ttransform: scale(0.95);\n\t}\n\t100% {\n\t\topacity: 1;\n\t\ttransform: scale(1);\n\t}\n}\n\n@keyframes dialog-out {\n\t0% {\n\t\topacity: 1;\n\t\ttransform: scale(1);\n\t}\n\t100% {\n\t\topacity: 0;\n\t\ttransform: scale(0.95);\n\t}\n}\n\n@media (prefers-reduced-motion) {\n\t.drawer {\n\t\tdialog[open] {\n\t\t\tanimation: none;\n\t\t}\n\t\tdialog[open]::backdrop {\n\t\t\tanimation: none;\n\t\t}\n\n\t\tdialog.closing[open] {\n\t\t\tanimation: none;\n\t\t}\n\t\tdialog.closing[open]::backdrop {\n\t\t\tanimation: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
