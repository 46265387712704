// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companies-list {
  height: 70%;
}

.component-offset {
  height: 500px;
  margin-top: -50px;
}
.component-offset.fill-vertical-height::ng-deep .content-card-wrapper {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user-profile/user-profile.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;;AAEA;EACC,aAAA;EACA,iBAAA;AACD;AACC;EACC,YAAA;AACF","sourcesContent":[".companies-list {\n\theight: 70%;\n}\n\n.component-offset {\n\theight: 500px;\n\tmargin-top: -50px;\n\n\t&.fill-vertical-height::ng-deep .content-card-wrapper {\n\t\theight: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
