// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
	width: 1000px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/contact-dialog/add-contact-person-dialog.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd","sourcesContent":[".dialog-container {\n\twidth: 1000px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
