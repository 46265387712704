import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Mime from 'mime';
import * as moment from 'moment';

import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { Constants } from '../helpers';
import { S3Credentials, S3PolicyResponse, S3SignedUrlResponse } from '../interfaces';

@Injectable()
export class S3Service {
	// Cached image url observable and data
	public imageUrlsObservables: { [key: string]: Observable<string> } = {};
	public imageUrls: { [key: string]: string } = {};

	constructor(private http: HttpClient) {}

	/**
	 * Uploads a file or blob to s3.
	 */
	public uploadFile(file: File | Blob, path: string, contentType: string): Observable<string> {
		if (!file) {
			return;
		}
		// If there is no mime type, use the extension of the file name as the file type.
		let fileName = '';
		if (file.type) {
			contentType = file.type;
		}
		if (file instanceof File && file.name) {
			if (!contentType) {
				contentType = Mime.getType(file.name);
			}
			fileName = file.name;
		}

		return this.requestUploadPolicy(path, contentType).pipe(
			switchMap(credentials => {
				const formData: FormData = new FormData();
				formData.append('key', credentials.basePath + '/' + path);
				formData.append('AWSAccessKeyId', credentials.accessKeyId);
				formData.append('policy', credentials.s3Policy);
				formData.append('signature', credentials.s3Signature);
				formData.append('Content-Type', contentType);
				formData.append('file', file, fileName);

				return this.http.post<any>(credentials.uploadUrl, formData).pipe(
					// This returns an Observable because functions using uploadFile are subscribing to it afterwards.
					switchMap(() => {
						return of(credentials.basePath + '/' + path);
					})
				);
			})
		);
	}

	/**
	 * Gets the signed url of a file stored in s3
	 */
	public getSignedUrl(key: string): Observable<string> {
		if (this.imageUrls[key]) {
			// If we have a key and it is not expired, return it
			if (!this.isExpiredS3Url(this.imageUrls[key])) {
				return of(this.imageUrls[key]);
			} else {
				// URL is expired, delete the previous one and fetch a new one
				delete this.imageUrls[key];

				return this.setS3Observable(key);
			}
		} else if (!this.imageUrlsObservables[key]) {
			// Create a new observable to get the signed url
			return this.setS3Observable(key);
		}

		return this.imageUrlsObservables[key];
	}

	/**
	 * Returns true if an S3 url is expired or will expire in less than a minute
	 */
	private isExpiredS3Url(url: string): boolean {
		// The url should contain 'Expires='
		let parts = url.split('Expires=');
		if (parts[1]) {
			// After 'Expires=' and before the next '&' will be the unix time in seconds that the URL will expire
			parts = parts[1].split('&');
		}
		const expirySeconds = Number(parts[0]);
		// Could not parse expiry time, need to fetch a new url
		if (isNaN(expirySeconds)) {
			return true;
		}
		// If the URL expires in less than a minute from now, we consider this URL expired
		return moment()
			.add(1, 'minutes')
			.isSameOrAfter(moment(expirySeconds * 1000));
	}

	/**
	 * Requests an upload policy to upload a file to s3 from the client.
	 */
	private requestUploadPolicy(path: string, contentType: string): Observable<S3Credentials> {
		return this.http
			.post<S3PolicyResponse>(`${Constants.BASE_API_URL}/s3/policy`, {
				path: path,
				contentType: contentType,
			})
			.pipe(map(res => res.credentials));
	}

	/**
	 * Sets and returns the observable for getting the signed url
	 */
	private setS3Observable(key: string): Observable<string> {
		return (this.imageUrlsObservables[key] = this.http
			.post<S3SignedUrlResponse>(`${Constants.BASE_API_URL}/s3/signedurl`, {
				key: key,
			})
			.pipe(
				shareReplay(1),
				map(res => {
					this.imageUrls[key] = res.url;
					return res.url;
				})
			));
	}
}
