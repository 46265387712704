import { Component, Input, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';

import { SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { Product, ReferenceCode } from '../../../interfaces';
import { CoatingSystemModel } from '../../../models';
import { CoatingSystemFormComponent } from '../../../pages/coating-system/coating-system-form/coating-system-form.component';
import { CoatingSystemService } from '../../../services/coating-system.service';

@Component({
	selector: 'app-add-coating-system-dialog',
	templateUrl: './add-coating-system-dialog.component.html',
})
export class AddCoatingSystemDialogComponent {
	@ViewChild(CoatingSystemFormComponent, { static: false })
	public coatingSystemFormRef: CoatingSystemFormComponent;

	@Input()
	public brand: string;
	@Input()
	public coatingSystem: CoatingSystemModel;
	@Input()
	public isAdmin: boolean = false;
	@Input()
	public selectedBrandProducts: Product[];
	@Input()
	public substrates: ReferenceCode[];

	constructor(
		private coatingSystemService: CoatingSystemService,
		private snack: SnackBarHelperComponent
	) {}

	/**
	 * Saves the new coating system on the backend.
	 */
	public saveNewCoatingSystem(): Observable<CoatingSystemModel> {
		const castChargeOutRates = this.coatingSystem.chargeOutRates.map(rate => (rate.state ? rate.state.id : undefined));
		this.coatingSystemFormRef.validators.ChargeOutRates.setValue(castChargeOutRates);
		this.coatingSystemFormRef.validators.CoatProducts.setValue(this.coatingSystem.coatProducts);
		// Concatenate error strings and trim in case whitespace is returned
		const errors = `${ValidatorHelper.checkErrors(this.coatingSystemFormRef.validators) || ''}
      ${ValidatorHelper.checkErrorsInControlGroupArray(this.coatingSystemFormRef.coatProductsRef.coatProductsValidators) || ''}
      ${ValidatorHelper.checkErrorsInControlGroupArray(this.coatingSystemFormRef.chargeOutRatesRef.chargeOutRatesValidators) || ''}`.trim();
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		// sets isActive to true and saves Coating System to Library
		this.coatingSystem.isActive = true;
		return this.coatingSystemService.postCreate(this.coatingSystem);
	}
}
