import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { AddressDetail } from './address-detail';
import { ContactDetail } from './contact-detail';

// Interface

export interface Marketing {
	id: string;
	name: string;
	isActive: boolean;
	addressDetail: AddressDetail;
	contactDetail: ContactDetail;
}

// Validators

export function GetMarketingValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Email',
			validators: [Validators.email],
		},
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'State',
			validators: [Validators.required],
		},
	]);
}
