import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { Constants } from '../helpers';

export class ModalChangeEvent {
	public options: ModalOptions;
	public config: any;
	public isNotification: boolean;
}

export class ModalActionHandler {
	public click: Function;
	public text?: string;
	public style?: string;
	public disabled?: boolean;
}

export enum Alignment {
	HORIZONTAL_LEFT = 'horizontal-left spacing',
	HORIZONTAL_CENTER = 'horizontal-center spacing',
	HORIZONTAL_RIGHT = 'horizontal-right spacing',
	VERTICAL = 'vertical',
}

export class ModalOptions {
	public title?: string;
	public content?: any;
	public message?: String;
	public buttonAlignment?: Alignment;
	public isScrollable?: boolean;
	public submitButton?: ModalActionHandler;
	public deleteButton?: ModalActionHandler;
	public cancelButton?: ModalActionHandler;
	public actionLink?: ModalActionHandler;
}

export class ConfirmationModalOptions {
	public title: string;
	public message: string;
	public submitButton: ModalActionHandler;
	public cancelButton: ModalActionHandler;
}

export class PromptModalOptions {
	public title?: string;
	public content?: TemplateRef<any>;
	public message?: string;
	public submitButton?: ModalActionHandler;
	public cancelButton: ModalActionHandler;
	public actionLink?: ModalActionHandler;
}

@Injectable()
export class ModalService {
	public readonly modalChange: EventEmitter<ModalChangeEvent> = new EventEmitter<ModalChangeEvent>();

	private open: boolean;

	/**
	 * Shows a notification modal
	 * @param {String} message Message to display
	 */
	public showNotification(message: String): void {
		const options = {
			message,
			submitButton: {
				click: () => this.closeModal(),
				text: Constants.MODAL_TEXT.gotIt,
				style: Constants.MODAL_STYLE.warning,
			},
		};

		const config = {
			ignoreBackdropClick: true,
			keyboard: false,
		};

		this.openModal(options, true, config);
	}

	/**
	 * Shows a confirmation modal
	 * @param {ConfirmationModalOptions} options Modal options
	 */
	public showConfirmation(options: ConfirmationModalOptions): void {
		this.openModal(
			{
				title: options.title,
				message: options.message,
				buttonAlignment: Alignment.HORIZONTAL_RIGHT,

				// These are reversed to display the 'submit' button on the right
				submitButton: options.submitButton,
				cancelButton: options.cancelButton,
			},
			false
		);
	}

	/**
	 * Shows a prompt modal
	 * @param {PromptModalOptions} options Modal options
	 */
	public showPrompt(options: PromptModalOptions): void {
		this.openModal(
			{
				title: options.title,
				content: options.content,
				message: options.message,
				submitButton: options.submitButton,
				cancelButton: options.cancelButton,
				actionLink: options.actionLink,
			},
			false
		);
	}

	/**
	 * Shows a modal
	 * @param {ModalOptions} options Modal options
	 * @param {Any} config Configuration object. See {@link https://valor-software.com/ngx-bootstrap/#/modals#modal-options}
	 */
	public showModal(options: ModalOptions, config?: any): void {
		this.openModal(options, false, config);
	}

	/**
	 * Closes the modal
	 */
	public closeModal(): void {
		this.modalChange.next(undefined);
		this.open = false;
	}

	/**
	 * Checks if a modal is open
	 * @returns {Boolean} True if a open is open, false if not
	 */
	public isOpen(): boolean {
		return this.open;
	}

	public setIsOpen(isOpen: boolean) {
		this.open = isOpen;
	}

	/**
	 * Opens a modal
	 * @param {ModalOptions} options Modal options
	 * @param {Boolean} isNotification Flag denoting if modal is a notification
	 * @param {any} config Modal configuration. See {@link https://valor-software.com/ngx-bootstrap/#/modals#modal-options}
	 */
	private openModal(options: ModalOptions, isNotification: boolean, config: any = {}): void {
		config.ignoreBackdropClick = true;
		this.modalChange.next({
			options,
			config,
			isNotification,
		});
		this.open = true;
	}
}
