import { TEXT } from '../../../../helpers';

export const ProjectDetailsTextConst = {
	budget: TEXT.budget,
	cancel: TEXT.cancel,
	charges: TEXT.charges,
	client: TEXT.client,
	createProject: `${TEXT.create} ${TEXT.project}`,
	grossProfit: TEXT.grossProfit,
	gst: TEXT.gst,
	keyDates: TEXT.keyDates,
	materials: TEXT.materials,
	materialsCanBeAdded: TEXT.materialsCanBeAdded,
	netTotals: TEXT.netTotals,
	projectDetails: TEXT.projectDetails,
	revenue: TEXT.revenue,
	salesDetails: TEXT.salesDetails,
	saveProject: `${TEXT.save} ${TEXT.project}`,
	scope: TEXT.scope,
	section: TEXT.section,
	siteAddress: `${TEXT.site}/${TEXT.address.slice(0, 1).toUpperCase() + TEXT.address.slice(1)}`,
	summary: TEXT.summary,
	totalIncTax: TEXT.totalIncTax,
	totalHours: TEXT.totalHours,
	totalProfit: TEXT.totalProfit,
	upcomingDeadline: TEXT.upcomingDeadline,
	winProbability: TEXT.winProbability,
};
