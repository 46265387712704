import { Chart, registerables } from 'chart.js';

// The following code is modified from https://stackoverflow.com/questions/43254153/how-to-create-rounded-bars-for-bar-chart-js-v2
// Method to draw a rectangle with a rounded top
Chart.register(...registerables);

if (Chart['helpers']) {
	// Chart['helpers'].drawRoundedTopRectangle = function (ctx, x, y, width, height, radius) {
	//   ctx.beginPath();
	//   ctx.moveTo(x + radius, y);
	//   // top right corner
	//   ctx.lineTo(x + width - radius, y);
	//   ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	//   // bottom right	corner
	//   ctx.lineTo(x + width, y + height);
	//   // bottom left corner
	//   ctx.lineTo(x, y + height);
	//   // top left
	//   ctx.lineTo(x, y + radius);
	//   ctx.quadraticCurveTo(x, y, x + radius, y);
	//   ctx.closePath();
	// };
}

// Create an element "rounded top rectangle" that extends the default rectangle
// Chart.defaults.elements = Chart['elements'].Rectangle.extend({
//     draw: function (): void {
//         const ctx = this._chart.ctx;
//         const vm = this._view;
//         let left, right, top, bottom, signX, signY, borderSkipped;
//         let borderWidth = vm.borderWidth;
//
//         if (!vm.horizontal) {
//             // bar
//             left = vm.x - vm.width / 2;
//             right = vm.x + vm.width / 2;
//             top = vm.y;
//             bottom = vm.base;
//             signX = 1;
//             signY = bottom > top ? 1 : -1;
//             borderSkipped = vm.borderSkipped || 'bottom';
//         } else {
//             // horizontal bar
//             left = vm.base;
//             right = vm.x;
//             top = vm.y - vm.height / 2;
//             bottom = vm.y + vm.height / 2;
//             signX = right > left ? 1 : -1;
//             signY = 1;
//             borderSkipped = vm.borderSkipped || 'left';
//         }
//
//         // Canvas doesn't allow us to stroke inside the width so we can
//         // adjust the sizes to fit if we're setting a stroke on the line
//         if (borderWidth) {
//             // borderWidth should be less than bar width and bar height.
//             const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
//             borderWidth = borderWidth > barSize ? barSize : borderWidth;
//             const halfStroke = borderWidth / 2;
//             // Adjust borderWidth when bar top position is near vm.base(zero).
//             const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
//             const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
//             const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
//             const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
//             // not become a vertical line?
//             if (borderLeft !== borderRight) {
//                 top = borderTop;
//                 bottom = borderBottom;
//             }
//             // not become a horizontal line?
//             if (borderTop !== borderBottom) {
//                 left = borderLeft;
//                 right = borderRight;
//             }
//         }
//
//         // calculate the bar width and roundness
//         const barWidth = Math.abs(left - right);
//         const roundness = this._chart.config.options.barRoundness || 0.5;
//
//         let radius = 0;
//         if (signY === 1) {
//             radius = barWidth * roundness * 0.5;
//         }
//
//         ctx.beginPath();
//         ctx.fillStyle = vm.backgroundColor;
//         ctx.strokeStyle = vm.borderColor;
//         ctx.lineWidth = borderWidth;
//
//         // draw the rounded top rectangle
//       // TODO Lilit
//
//       // Chart.helpers.drawRoundedTopRectangle(ctx, left, top + 1, barWidth, bottom - top, radius);
//
//         ctx.fill();
//         if (borderWidth) {
//             ctx.stroke();
//         }
//     },
// });

// Create a new type of bar "rounded bar" from the existing default bar
// TODO Lilit
// Chart.defaults[CUSTOM_CHART_TYPES.roundedBar] = Chart['helpers'].clone(Chart.defaults['bar']);

// Give the "rounded bar" the rounded top property
// TODO Lilit
// Chart.controllers[CUSTOM_CHART_TYPES.roundedBar] = Chart.controllers.bar.extend({
//     dataElementType: Chart.elements.RoundedTopRectangle
// });

export { Chart };
