import { TEXT } from '../../../../helpers/text.constants';
import { SentenceCasePipe } from '../../../../pipes/sentence-case-pipe';

const sentenceCasePipe: SentenceCasePipe = new SentenceCasePipe();

export const ColourEditDialogConstants = {
	chooseBrand: TEXT.chooseBrand,
	chooseColour: TEXT.chooseColour,
	colourSample: TEXT.colourSample,
	colourDisclaimer: TEXT.colourDisclaimer,
	selectPlaceholder: sentenceCasePipe.transform(TEXT.selectPlaceholder),
};
