import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SnackBarHelperComponent, Constants } from '../helpers';
import { ProjectSessionModel, UserModel } from '../models';
import { MeasuringToolLayoutService } from '../pages/measure-tool/common/components/measuring-tool-layout/common/measuring-tool-layout.service';
import { AuthService } from '../services/auth.service';
import { EstimatorUserService } from '../services/estimator-user/estimator-user.service';
import { ProjectSessionService } from '../services/project-session.service';

/**
 * Guard to deactivate a user's project session if they navigate away from the project or measuring-tool routes
 */
@Injectable()
export class ProjectSessionGuard {
	private currentUserId: string;
	public hasMeasuringToolAccess: boolean;

	constructor(
		private authSerivce: AuthService,
		private estimatorUserService: EstimatorUserService,
		private measuringToolLayoutService: MeasuringToolLayoutService,
		private projectSessionService: ProjectSessionService,
		private router: Router,
		private snack: SnackBarHelperComponent
	) {}

	/**
	 * Block access to route if we have no active project session
	 * @param route
	 * @param state
	 */
	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// Fetch the current user
		return this.estimatorUserService.userDataStream.pipe(
			switchMap((currentUser: UserModel) => {
				this.currentUserId = currentUser.id;
				this.hasMeasuringToolAccess = currentUser.entity?.hasMeasuringToolAccess;
				return this.projectSessionService.fetchProjectSession(route.params.projectId);
			}),
			map((projectSession: ProjectSessionModel) => {
				if (!this.hasMeasuringToolAccess) {
					// Navigate back to project
					this.router.navigate([`${Constants.ROUTE_LINKS.project}/${route.params.projectId}`]);
					this.snack.snackError('Your entity does not have access to the measuring tool');
					return false;
				} else {
					// Return true if we have an active project session for the current user
					if (projectSession?.user.id === this.currentUserId) {
						return true;
					} else {
						// Navigate back to project
						this.router.navigate([`${Constants.ROUTE_LINKS.project}/${route.params.projectId}`]);
						this.snack.snackInformation('Cannot access measuring tool without an active project session');
						return false;
					}
				}
			})
		);
	}

	/**
	 * If a user has an active project session when navigating away from a project page or the measuring-tool, complete
	 * their project session.
	 * @param component
	 * @param currentRoute
	 * @param currentState
	 * @param nextState
	 */
	public canDeactivate(component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
		let projectId;
		// Clear all project info from the measuring tool layout observables so they do
		// not appear when loading the measuring tool for a new project
		this.measuringToolLayoutService.clearProjectObservables();
		// Bypass checks if we are getting kicked out to /login due to an expired session
		// queryParam is set in authentication-interceptor.ts
		if (nextState.url === '/login?is401InterceptLogout=true') {
			return of(true);
		}
		// Fetch the current user
		return this.estimatorUserService.userDataStream.pipe(
			switchMap((currentUser: UserModel) => {
				this.currentUserId = currentUser.id;
				projectId = currentRoute.params.projectId ? currentRoute.params.projectId : currentRoute.queryParams.projectId;
				if (projectId) {
					return this.projectSessionService.fetchProjectSession(projectId);
				} else {
					return of(null);
				}
			}),
			switchMap((projectSession: ProjectSessionModel) => {
				// If we have project session and it is for the current user, we will complete it before navigating away
				// Do not complete session if we are just navigating to and from the project <-> measuring-tool pages
				// Do not complete session if we are just navigating to and from the user tools library <-> measuring-tool pages
				if (
					projectSession?.user.id === this.currentUserId &&
					nextState.url.indexOf('/measuring-tool') === -1 &&
					nextState.url.indexOf('/project') === -1 &&
					nextState.url.indexOf('/user-tools?projectId=') === -1
				) {
					return this.projectSessionService.completeProjectSession(projectId, this.currentUserId);
				} else {
					return of(null);
				}
			}),
			map(() => {
				return true;
			})
		);
	}
}
