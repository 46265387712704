import { UntypedFormGroup } from '@angular/forms';

import { ERROR_MESSAGES } from '../helpers';
import { AdditionalCharge, AddressDetail, Attachment, Comment, Company, Competitor, ContactClient, LabourRate, Project, ReferenceCode, Task } from '../interfaces';
import { InvoiceModel } from '../pages/project/invoice/common/invoice.models';
import { VariationModel } from '../pages/project/variation/common/variation.models';
import { BaseModel } from './base.model';
import { BoqModel } from './boq.model';
import { ChargeModel } from './charge.model';
import { CompanyModel } from './company.model';
import { EntityModel } from './entity.model';
import { UserModel } from './user.model';
import { Timezone } from '../interfaces/timezones';

export class ProjectModel extends BaseModel implements Project {
	// Properties
	public additionalCharges: AdditionalCharge[];
	public additionalChargesChargeOut: number;
	public additionalChargesCost: number;
	public addressDetail: AddressDetail;
	public attachments: Attachment[];
	public averageTargetRate: number;
	public charges: Array<BoqModel | AdditionalCharge>;
	public clients: ContactClient[];
	public comments: Comment[];
	public commentsToEstimator: string;
	public company: Company;
	public completeCustomerProposal: Date;
	public completeEstimate: Date;
	public contractAdministrator: UserModel;
	public decisionDate: Date;
	public defaultChargeCategory: ReferenceCode;
	public division: string;
	public discount: number;
	public discountOption: string;
	public discountPercentage: number;
	public discountType: string;
	public entity: EntityModel;
	public estimator: UserModel;
	public exclusions: string;
	public grossTotalChargeOut: number;
	public grossTotalCost: number;
	public gst: number;
	public gstChargeOut: number;
	public heightFeeChargeOut: number;
	public includeComments: boolean;
	public inclusions: string;
	public isActive: boolean;
	public isDiscountDisabled: boolean;
	public isEbaRates: boolean;
	public isLegacyCalculation: boolean;
	public labourChargeOut: number;
	public labourCost: number;
	public labourHoursTotal: number;
	public labourType: LabourRate;
	public leadReference: string;
	public lostCompetitor: Competitor;
	public lostDate: Date;
	public managementToReviewQuote: Date;
	public marketing: string;
	public materialMarkUp: number;
	public materialWasteChargeOut: number;
	public materialWasteCost: number;
	public materialsBasedOn: string;
	public mealBreakLength: number;
	public name: string;
	public netTotalChargeOut: number;
	public operatingHours: any;
	public paymentTerms: string;
	public probability: number;
	public projectFinishDate: Date;
	public projectManager: UserModel;
	public projectStartDate: Date;
	public purchaseOrderNumber: string;
	public quoteClientList: ContactClient[];
	public quoteData: any;
	public quoteDueDate: Date;
	public quoteFinishDate: Date;
	public quoteStartDate: Date;
	public quoteTitle: string;
	public salesPerson: UserModel;
	public industry?: string;
	public shiftDuration: number;
	public subIndustry?: string;
	public shiftWorkers: any;
	public siteContact: string;
	public siteEmail: string;
	public sitePhone: string;
	public status: string;
	public statusDate: Date; // The effective date of the current project status
	public statusOtherReason: string;
	public statusReason: string;
	public showSubGroups: boolean;
	public submitToCustomer: Date;
	public submittedDate: Date;
	public tasks: Task[];
	public totalIncTax: number;
	public totalLitre: number;
	public totalMarginPercent: number;
	public totalProfit: number;
	public totalProjectBuildValue: number;
	public totalMaterial: number;
	public totalUnit: number;
	public totalUnits: number;
	public variations: VariationModel[];
	public wasteToConsumables: number;
	public wonDate: Date;
	public isDiscountPercentage: boolean;
	public chargeOrder: Array<ChargeModel | BoqModel | AdditionalCharge>;
	public reference: number;
	public totalAccess: number;
	public totalOther: number;
	public invoices?: InvoiceModel[];
	public timezone: Timezone;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
			if (json.entity) {
				this.entity = new EntityModel(json.entity);
			} else if (json.id) {
				console.error(ERROR_MESSAGES.missingEntity);
			}
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
