import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-full-screen-layout',
	templateUrl: './full-screen-layout.component.html',
	standalone: true,
	imports: [
		RouterOutlet,
	],
})
export class FullScreenLayoutComponent {}
