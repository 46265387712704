import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Decimal } from 'decimal.js';

import { projectStatuses } from '../../../../components/project/common/project.constants';
import { ProjectTimelineComponent } from '../../../../components/project/components/project-timeline/project-timeline.component';
import { CHART_COLOURS, Constants, getStatuses, PROJECT_STATUS } from '../../../../helpers';
import { AcceptanceEmail, ContactClient, Project } from '../../../../interfaces';
import { ProjectModel } from '../../../../models';
import { ClientService } from '../../../../services/client.service';
import { textConstants } from './project-card.constants';

@Component({
	selector: 'app-project-card',
	templateUrl: './project-card.component.html',
	styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit, OnDestroy {
	@ViewChild('progressBar', { static: true })
	@ViewChild('comments')
	public comments: ElementRef;
	private progressBar: ElementRef;

	@Input() public project: ProjectModel;
	@Input() public targetProject: boolean = false;
	@Input() set selectedClient(val) {
		this._selectedClient = val;
		this.updateSelectedClientSuccessRate();
	}
	get selectedClient() {
		return this._selectedClient;
	}

	@Output() public selectedClientChange = new EventEmitter<string>();
	@Output() public selectedAcceptanceEmail = new EventEmitter<AcceptanceEmail>();

	public chartGreen: CHART_COLOURS = CHART_COLOURS.green;
	public chartLightGreen: CHART_COLOURS = CHART_COLOURS.revenue;
	public chartRed: CHART_COLOURS = CHART_COLOURS.red;
	public chartYellow: CHART_COLOURS = CHART_COLOURS.profit;
	public clientSuccessRates: any = {};
	public gpChartColour: CHART_COLOURS;
	public incompleteColour: CHART_COLOURS = CHART_COLOURS.grey;
	public marginPercent: number;
	public progressBarPercentage: number = 69;
	public PROJECT_INDUSTRIES = Constants.PROJECT_INDUSTRIES;
	public PROJECT_SUB_INDUSTRIES = Constants.PROJECT_SUB_INDUSTRIES;
	public projectProfit: number;
	public projectRevenue: number;
	public projectStatuses: typeof projectStatuses = projectStatuses;
	public projectValueLabels: string[] = ['Profit', 'Revenue'];
	public selectedClientSuccessRate = null;
	public strikeRateChartColour: CHART_COLOURS;
	public subscription;
	public successRateLabels: string[] = ['Won', 'Quoted'];
	public textConstants: typeof textConstants = textConstants;

	private _selectedClient: ContactClient;

	constructor(private clientService: ClientService) {}

	public ngOnInit() {
		//this.progressBar.nativeElement.style.width = this.progressBarPercentage.toString() + '%';
		this.selectedClient = this.project.clients[0];
		this.projectProfit = this.project.totalProfit && this.project.totalProfit > 0 ? this.project.totalProfit : 0;
		this.projectRevenue = this.project.netTotalChargeOut && this.project.netTotalChargeOut > 0 ? this.project.netTotalChargeOut : 0;

		const gpPercentage = this.project.totalMarginPercent / 100;

		// Colour logic for GP%
		if (gpPercentage >= 0.5) {
			this.gpChartColour = this.chartGreen;
		}
		if (gpPercentage >= 0.25 && gpPercentage < 0.5) {
			this.gpChartColour = this.chartLightGreen;
		}
		if (gpPercentage >= 0.15 && gpPercentage < 0.25) {
			this.gpChartColour = this.chartYellow;
		}
		if (gpPercentage >= 0 && gpPercentage < 0.15) {
			this.gpChartColour = this.chartRed;
		}

		this.marginPercent = gpPercentage;

		this.subscription = this.clientService.clientSuccessRatesObservable.subscribe(updatedSuccessRates => {
			this.clientSuccessRates = updatedSuccessRates;
			this.updateSelectedClientSuccessRate();
		});
	}

	public ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.selectedClientChange.complete();
		this.selectedAcceptanceEmail.complete();
	}

	public consumeClick(event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	public customSearch(term: string, item: any) {
		term = term.toLocaleLowerCase();
		return (
			item.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
			item.lastName.toLocaleLowerCase().indexOf(term) > -1 ||
			(item.firstName + ' - ' + item.lastName).toLocaleLowerCase().indexOf(term) > -1
		);
	}

	public showEmailDialog(isAcceptance: boolean, project: Project): void {
		this.selectedAcceptanceEmail.emit({
			isAcceptance,
			project: new ProjectModel(project),
		});
	}

	public scrollToBottom(): void {
		setTimeout(() => (this.comments.nativeElement.scrollTop = this.comments.nativeElement.scrollHeight), 10);
	}

	private updateSelectedClientSuccessRate() {
		if (!this.selectedClient) {
			return;
		}

		const rate = this.clientSuccessRates[this.selectedClient.company?._id];

		if (rate) {
			this.selectedClientSuccessRate = {
				valueComplete: rate.wonProfit && rate.totalProfit ? rate.wonProfit / rate.totalProfit : 0,
				valueIncomplete: rate.submittedProfit / rate.totalProfit,
				strikeRate: rate.wonProfit ? rate.wonProfit / rate.totalProfit : 0,
			};

			const strikeRate = this.selectedClientSuccessRate.strikeRate;
			// Colour logic for strikeRate%
			if (strikeRate >= 0.5) {
				this.strikeRateChartColour = this.chartGreen;
			}
			if (strikeRate >= 0.25 && strikeRate < 0.5) {
				this.strikeRateChartColour = this.chartLightGreen;
			}
			if (strikeRate >= 0.15 && strikeRate < 0.25) {
				this.strikeRateChartColour = this.chartYellow;
			}
			if (strikeRate >= 0.0 && strikeRate < 0.15) {
				this.strikeRateChartColour = this.chartRed;
			}
		} else {
			this.selectedClientSuccessRate = null;
		}
	}

	protected readonly PROJECT_STATUS = PROJECT_STATUS;
	protected readonly Object = Object;
	protected readonly getStatuses = getStatuses;
}
