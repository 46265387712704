import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import { CustomFormGroup, GetIncExcCommentTemplateValidation } from '../../../interfaces';
import { InclusionExclusionTemplateModel } from '../../../models';
import { IncExcCommentTemplateService } from '../../../services/inc-exc-comment-template.service';

@Component({
	selector: 'app-edit-inc-exc-template-dialog',
	templateUrl: './edit-inc-exc-template-dialog.component.html',
})
export class EditIncExcTemplateDialogComponent implements OnInit {
	public incExcTemplateValidators: CustomFormGroup;
	public textConstants: typeof TEXT = TEXT;

	@Input()
	public templateLoaded: InclusionExclusionTemplateModel;

	constructor(
		private snack: SnackBarHelperComponent,
		private templateService: IncExcCommentTemplateService
	) {}

	public ngOnInit() {
		this.incExcTemplateValidators = GetIncExcCommentTemplateValidation();
	}

	/**
	 * Edits the selected template
	 * @return {Observable<InclusionExclusionTemplateModel>}
	 */
	public editTemplate(): Observable<InclusionExclusionTemplateModel> {
		this.incExcTemplateValidators.Message.setValue(this.templateLoaded.message);
		this.incExcTemplateValidators.Title.setValue(this.templateLoaded.title);
		this.incExcTemplateValidators.Type.setValue(this.templateLoaded.isInclusion);
		const errors = ValidatorHelper.checkErrors(this.incExcTemplateValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		return this.templateService.editTemplate(this.templateLoaded);
	}
}
