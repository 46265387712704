import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { CHART_COLOURS } from '../../../../helpers';
import { Chart } from '../../../../helpers/itc-chart';

export const labelTypes = {
	currency: 'currency',
	percentage: 'percentage',
};

@Component({
	selector: 'app-project-search-doughnut-chart',
	templateUrl: './project-search-doughnut-chart.component.html',
})
export class ProjectSearchDoughnutChartComponent implements OnInit, OnDestroy {
	@ViewChild('canvas', { static: true }) private canvas: ElementRef;

	@Input() public colorComplete: CHART_COLOURS;
	@Input() public colorIncomplete: CHART_COLOURS;
	@Input() public innerText: string;
	@Input() public labels: string[];
	@Input() public labelType: string = labelTypes.currency;
	@Input() public tooltipFnc: Function;

	private _data: { valueComplete: number; valueIncomplete: number };
	@Input() set data(value: { valueComplete: number; valueIncomplete: number }) {
		if (value !== undefined && this._data !== value) {
			this._data = value;
			this.drawChart();
		}
	}

	public chart: any = undefined;

	constructor(
		private currencyPipe: CurrencyPipe,
		private percentPipe: PercentPipe
	) {}

	public ngOnInit(): void {
		if (this._data) {
			this.drawChart();
		}
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
		}
	}

	/**
	 * Draw the doughnut chart
	 */
	public drawChart(): void {
		// In the case that we have no value for complete and incomplete we still want to display the ring.
		// This number will be rounded to $0.00 by the currency pipe when viewed
		const valueIncomplete = !this._data.valueIncomplete && !this._data.valueComplete ? 0.001 : this._data.valueIncomplete;
		const colors: Array<CHART_COLOURS> = [this.colorComplete, this.colorIncomplete];
		const chartData: Array<number> = [this._data.valueComplete, valueIncomplete];

		if (!this.chart) {
			const doughnutLabel = {
				id: 'doughnutLabel',
				beforeDatasetsDraw(chart, args, pluginOptions) {
					const { ctx, data } = chart;

					ctx.save();
					const x = chart.getDatasetMeta(0).data[0].x;
					const y = chart.getDatasetMeta(0).data[0].y;
					const dataset = data.datasets[0];
					const fillColor = dataset.backgroundColor[0];
					ctx.font = 'bold 16px sans-serif';
					ctx.fillStyle = fillColor;
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					ctx.fillText(`${(data.datasets[0].data[0] * 100).toFixed(0)}%`, x, y);
				},
			};

			// Draw chart for the first time
			this.chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
				type: 'doughnut',
				data: {
					labels: this.labels || [],
					datasets: [
						{
							data: chartData,
							backgroundColor: colors,
							borderWidth: 0,
						},
					],
				},
				options: {
					cutout: '70%',
					// elements: {
					//   center: {
					//     text: this.innerText || '',
					//     fontStyle: 'Helvetica',
					//     sidePadding: 30
					//   }
					// },
					// legend: {
					//   display: false
					// },
					hover: { mode: undefined },
					responsive: false,
					maintainAspectRatio: false,
					plugins: {
						datalabels: {
							display: false,
						},
						tooltip: {
							callbacks: {
								label: context => {
									let label = context.dataset.label || '';

									if (label) {
										label += ': ';
									}

									if (this.labelType === labelTypes.currency) {
										label += this.currencyPipe.transform(context.dataset.data[context.dataIndex]);
									} else if (this.labelType === labelTypes.percentage) {
										label += this.percentPipe.transform(context.dataset.data[context.dataIndex]);
									}
									return label;
								},
							},
						},
					},
				},
				plugins: [doughnutLabel],
			});
		} else {
			// Update chart data
			for (let i = 0; i < chartData.length; i++) {
				this.chart.data.datasets[0].data[i] = chartData[i];
				this.chart.data.datasets[0].backgroundColor[i] = colors[i];
				// this.chart.options.elements.center.text = this.innerText || '';
			}
			this.chart.update();
		}
	}
	protected readonly undefined = undefined;
}

// Plugin to display text inside the center of the doughnut.
// From: https://stackoverflow.com/questions/20966817/how-to-add-text-inside-the-doughnut-chart-using-chart-js
// Chart['pluginService'].register({
//   beforeDraw: function (chart) {
//     if (chart.config.options.elements.center) {
//       //Get ctx from string
//       const ctx = chart.chart.ctx;
//
//       //Get options from the center object in options
//       const centerConfig = chart.config.options.elements.center;
//       const fontStyle = centerConfig.fontStyle || 'Arial';
//       const txt = centerConfig.text;
//       const color = centerConfig.color || '#000';
//       const sidePadding = centerConfig.sidePadding || 20;
//       const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
//       //Start with a base font of 30px
//       ctx.font = '900 20px ' + fontStyle;
//
//       //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
//       const stringWidth = ctx.measureText(txt).width;
//       const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
//
//       // Find out how much the font can grow in width.
//       const widthRatio = elementWidth / stringWidth;
//       const newFontSize = Math.floor(30 * widthRatio);
//       const elementHeight = (chart.innerRadius * 2);
//
//       // Pick a new font size so it will not be larger than the height of label.
//       const fontSizeToUse = Math.min(newFontSize, elementHeight);
//
//       //Set font settings to draw it correctly.
//       ctx.textAlign = 'center';
//       ctx.textBaseline = 'middle';
//       const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
//       const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
//       ctx.font = 'bold' + fontSizeToUse + 'px ' + fontStyle;
//       ctx.fillStyle = color;
//
//       //Draw text in center
//       ctx.fillText(txt, centerX, centerY);
//     }
//   }
// });
