export class StripeProductModel {
	public description: string;
	public isActive: boolean;
	public name: string;
	public prices?: StripeProductPriceModel[] = [];
	public stripeId: string;

	constructor(json: Partial<StripeProductModel>) {
		Object.assign(this, json);

		if (this.prices) {
			this.prices = this.prices.map(price => new StripeProductPriceModel(price));
		}
	}
}

export enum StripeAddonType {
	additionalUserLicense = 'additionalUserLicense',
}

export class StripeAddonProductModel extends StripeProductModel {
	public addonType: StripeAddonType;
	public relatedProductStripeIds: string[];

	constructor(json: Partial<StripeAddonProductModel>) {
		super(json);
	}
}

export class StripeSubscriptionProductModel extends StripeProductModel {
	public productAddons: StripeAddonProductModel[];
	public canAssignUserAccessLevels: boolean;
	public canExportDocuments: boolean;
	public documentLimit: number;
	public hasBulkDataImport: boolean;
	public hasCustomTemplates: boolean;
	public hasMeasuringToolAccess: boolean;
	public hasTrial: boolean;
	public projectLimit: number;
	public supportHours: number;
	public userLimit: number;
	public companyLimit: number;
	public mentorship: boolean;
	public databaseAccess: number;
	public templateLibraryAccess: number;

	constructor(json: Partial<StripeSubscriptionProductModel>) {
		super(json);

		if (this.productAddons) {
			this.productAddons = this.productAddons.map(price => new StripeAddonProductModel(price));
		}
	}
}

export class StripeProductPriceModel {
	public amount?: number;
	public currency: string;
	public formattedRecurringInterval: string = '';
	public product?: StripeSubscriptionProductModel;
	public productAddon?: StripeAddonProductModel;
	public recurring?: {
		interval: string;
		intervalCount: number;
	};
	public stripeId: string;
	public stripeProductId: string;

	constructor(json: Partial<StripeProductPriceModel>) {
		Object.assign(this, json);

		if (this.recurring) {
			if (this.recurring.intervalCount === 1) {
				this.formattedRecurringInterval = `${this.recurring.interval}`;
			} else {
				this.formattedRecurringInterval = `${this.recurring.intervalCount} ${this.recurring.interval}s`;
			}
		}

		if (this.product) {
			this.product = new StripeSubscriptionProductModel(this.product);
		}
		if (this.productAddon) {
			this.productAddon = new StripeAddonProductModel(this.productAddon);
		}

		// Stripe prices are in cents. Divide by 100 to get value in dollars
		this.amount = json.amount / 100;
	}
}
