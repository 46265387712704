export const STRING_CONSTANTS = {
	active: 'active',
	blue: 'blue',
	green: 'green',
	inactive: 'inactive',
	name: 'name',
	red: 'red',
	variations: 'variations',
};

export const CoatingSystemImageHeight: number = 39;
