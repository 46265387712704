import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// initialize sentry on non-local environments
if (environment.env !== 'local') {
	Sentry.init({
		environment: environment.env,
		dsn: 'https://dd707d8b42bf5a7f9466e08e5faab0b2@o4506562645262336.ingest.sentry.io/4506562651553792',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ['localhost', /^https:\/\/paintprojex\.com\/api/],
			}),
			/// TODO (Callan): For some reason enabling this causes the system to operate slower, with a number of pending requests.
			/// I need to investigate whether this can be resolved and used, or if we should look at alternatives to sentry.
			//new Sentry.Replay({
			//	maskAllText: false,
			//	blockAllMedia: false,
			//	maskAllInputs: false,
			//	useCompression: true,
			//
			//}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		//// Session Replay
		/// TODO (Callan): Disabled session replay because it makes the frontend abysmally slow for some reason. Investigate & fix this.
		//replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		//replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
