import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ProjectSessionGuard } from '../../../../app/route-guards/project-session-guard.service';
import { NavigationWarningService } from './navigation-warning.service';

/**
 * This guard is a combination of the NavigationWarningGuard and ProjectSessionGuard. The reason this guard
 * is necessary is to prevent the project session guard canDeactivate executing before a user has selected an
 * option on the navigation guard (stay on this page / leave page). This guard ensures the project session guard
 * logic is only executed once an option as been selected (and does not execute at all if stay on page was chosen).
 */
@Injectable()
export class NavigationWarningProjectSessionGuard {
	constructor(
		private modalService: BsModalService,
		private navigationWarningService: NavigationWarningService,
		private projectSessionGuard: ProjectSessionGuard
	) {}

	public canDeactivate(component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
		return this.navigationWarningService.showModalRequest().pipe(
			switchMap((canDeactivate: boolean) => {
				if (canDeactivate) {
					return this.projectSessionGuard.canDeactivate(component, currentRoute, currentState, nextState);
				} else {
					return of(false);
				}
			}),
			tap((canDeactivate: boolean) => {
				return of(canDeactivate);
			})
		);
	}
}
