import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { ReferenceCode } from '../../../interfaces';
import { ChargeModel, GetSubstrateValidation } from '../../../models';
import { BoqService } from '../../../services/boq.service';
import { RefCodeService } from '../../../services/reference-code.service';

@Component({
	selector: 'app-substrate-edit-dialog',
	templateUrl: './substrate-edit-dialog.component.html',
	styleUrls: ['./substrate-edit-dialog.component.scss'],
})
export class SubstrateEditDialogComponent implements OnInit {
	public substrates: ReferenceCode[];
	public validators;
	public substrate: ReferenceCode;
	public loadingRefPromise: Promise<any>;

	@Input() public charge: ChargeModel;
	@Input() public projectId: string;
	@Output() public substrateChange: EventEmitter<ReferenceCode> = new EventEmitter<ReferenceCode>();

	constructor(
		private snack: SnackBarHelperComponent,
		private refCodeService: RefCodeService,
		private boqService: BoqService
	) {}

	public ngOnInit() {
		this.validators = GetSubstrateValidation();

		this.loadingRefPromise = this.refCodeService.getRefCodesWithTypesAndStatus(['substrate'], true).toPromise();

		this.loadingRefPromise.then(ref => {
			this.substrates = ref.substrate;
			this.substrate = this.charge?.substrate || null;
			this.substrateChange.emit(this.substrate);
		});
	}

	public onSave() {
		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		return this.boqService.editSubstrateBoq(this.charge.id, this.projectId, this.substrate.id);
	}
}
