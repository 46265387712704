import { SupplierProductModel } from './supplier-product.model';
import { SupplierModel } from './supplier.model';

export class SupplierProductOrder {
	public chargeId: string;
	public duration: number;
	public _id: string;
	public isDaily: boolean;
	public paidTransport: boolean = false;
	public supplier: SupplierModel; // Not saved to db
	public supplierProduct: SupplierProductModel;

	constructor(json?: Partial<SupplierProductOrder>) {
		if (json) {
			Object.assign(this, json);
			if (json.supplier) {
				this.supplier = new SupplierModel(json.supplier);
			}
			if (json.supplierProduct) {
				this.supplierProduct = new SupplierProductModel(json.supplierProduct);
			}
		}
	}
}
