import { UntypedFormGroup } from '@angular/forms';

import { ERROR_MESSAGES } from '../helpers';
import { AddressDetail, Company, Project } from '../interfaces';
import { BaseModel } from './base.model';
import { EntityModel } from './entity.model';
import { SupplierLocationFavouriteModel } from './supplier-location-favourite.model';

export class UserModel extends BaseModel {
	public department: string;
	public displayPictureKey: string;
	public displayPictureUrl: string;
	public defaultPayRate: any;
	public email: string;
	public entity: EntityModel;
	public firstName: string;
	public get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}
	public imageUrl: string;
	public incExcCommentFavouriteTemplates: Array<string>;
	public intercomUserHash: string;
	public isActive: boolean = true;
	public lastName: string;
	public payrollID: string;
	public phone: string;
	public position: string;
	public projects: Project[] = [];
	public providers: Company[] = [];
	public role: string;
	public showGridView: boolean;
	public supplierLocationFavourites: Array<SupplierLocationFavouriteModel> = [];
	public tours: any;
	public address: AddressDetail;
	public emergencyContactName: string;
	public emergencyContactPhone: string;
	public dob: Date;
	public preferredFullName: string;
	public pronouns: string;
	public hiredOn: Date;
	public employmentType: string;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
			if (json.entity) {
				this.entity = new EntityModel(json.entity);
			} else if (json.id) {
				console.error(ERROR_MESSAGES.missingEntity);
			}
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}

export class AuthUserModel extends UserModel {
	public expiresAt: number;
	public status: boolean;
	public token: string;
}
