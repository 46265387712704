import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Constants, LOGO_UPLOADER_TITLES } from '../../../helpers';

@Component({
	selector: 'app-logo-uploader-dialog',
	templateUrl: './logo-uploader-dialog.component.html',
	styleUrls: ['./logo-uploader-dialog.component.scss'],
})
export class LogoUploaderDialogComponent implements OnInit {
	@ViewChild('iframeId', { static: false })
	private iframeId: ElementRef;

	@Input()
	public imageUrl: string;
	@Input()
	public removeLogo: boolean = true;
	@Input()
	public disabled: boolean = false;
	@Input()
	public title: string = LOGO_UPLOADER_TITLES.logo;
	@Input()
	public uploadingFilePromise: Promise<any>;
	@Input()
	public readonly hasAvatarBuilder: boolean = false;

	@Output()
	private deleteLogo: EventEmitter<void> = new EventEmitter();
	@Output()
	private filesChanged: EventEmitter<File> = new EventEmitter();

	public baseAvatarUrl: SafeResourceUrl;
	public file: File | Blob;
	public isAvatarImage: boolean = false;

	constructor(private sanitizer: DomSanitizer) {}

	public ngOnInit() {
		this.baseAvatarUrl = this.sanitizer.bypassSecurityTrustResourceUrl(Constants.BASE_AVATAR_URL);
	}

	public getImageFile(): Promise<File | Blob> {
		return new Promise<File | Blob>(resolve => {
			if (this.isAvatarImage) {
				this.iframeId.nativeElement.contentWindow.postMessage('svg', '*');
				window.addEventListener('message', function (event) {
					if (event.data.name === 'svg' && event.data.svg) {
						const svgBlob: Blob = new Blob([event.data.svg], {
							type: 'image/svg+xml;charset=utf-8',
						});
						resolve(svgBlob);
					}
				});
			} else {
				resolve(this.file);
			}
		});
	}

	public onDeleteLogo() {
		this.deleteLogo.emit();
	}

	public setFile(file: File): void {
		this.file = file;
	}
}
