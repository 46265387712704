import { Injectable } from '@angular/core';
import { StateModel } from '../models';
import { ClientService } from './client.service';
import { StateService } from './state.service';

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private states: StateModel[] = [];
	private clientCount: number;

	constructor(
		private stateService: StateService,
		private clientService: ClientService
	) {}

	public getStates(): StateModel[] {
		return this.states;
	}

	public getStateById(id: string): StateModel {
		return this.states.find((state: StateModel) => state.id === id);
	}

	public cacheStates(): void {
		this.stateService.getStates().subscribe((states: StateModel[]) => {
			this.states = states.map(state => new StateModel(state));
		});
	}

	// clients/companies
	public getClientCount(searchParams: any): number {
		this.clientService.postCount(searchParams).subscribe((count: number) => {
			this.clientCount = count;
		});

		return this.clientCount;
	}
}
