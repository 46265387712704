import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NumberHelper } from '../../../helpers';
import { defaultPrecision, InputTextAlignment } from './common/display-input-number.constants';
import { InputNumberConfig } from './common/display-input-number.models';

@Component({
	selector: 'app-display-input-number',
	templateUrl: './display-input-number.component.html',
	styleUrls: ['./display-input-number.component.scss'],
})
export class DisplayInputNumberComponent {
	@Input()
	public allowNegativeNumber: boolean;
	@Input()
	public disabled: boolean;
	@Input()
	public formControlValue: FormControl;

	@Input()
	public set config(config: InputNumberConfig) {
		this._config = new InputNumberConfig(config);
	}
	public get config(): InputNumberConfig {
		return this._config;
	}

	@Input()
	public set value(value: number) {
		if (value !== this.internValue) {
			this.internValue = this.roundValue(value);
		}
	}

	@Output()
	public valueChange: EventEmitter<number> = new EventEmitter<number>();

	public internValue: number;
	public textAlignment: typeof InputTextAlignment = InputTextAlignment;

	private _config: InputNumberConfig = new InputNumberConfig();

	constructor() {}

	public changeValue() {
		let result = parseFloat(this.internValue.toString());
		if (!NumberHelper.isNumber(result)) {
			result = 0;
		}

		// Clamp value between min and max if either is provided
		if (NumberHelper.isNumber(this._config.min) || NumberHelper.isNumber(this._config.max)) {
			const min = this._config.min || -Infinity;
			const max = this._config.max || Infinity;
			result = NumberHelper.clamp(result, min, max);
		}

		// Round the value to the given precision if it exists
		if (NumberHelper.isNumber(this._config.precision)) {
			result = Number(result.toFixed(this._config.precision));
		}

		this.internValue = result;
		this.valueChange.emit(result);
	}

	private roundValue(value: number): number {
		if (value) {
			// Round to given precision if it exists otherwise default to previous default value retaining prior functionality
			const roundedValue: string = value.toFixed(NumberHelper.isNumber(this._config.precision) ? this._config.precision : defaultPrecision);
			if (value.toString() !== roundedValue) {
				return Number(roundedValue);
			}
		}
		return value;
	}
}
