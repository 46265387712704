// Roles are not in alphabetical order. They are in Logical order by Rank. First goes the Role with the most permissions
import { PermissionsActions, PermissionsPages, PermissionsRoles } from './permissions.constants';

export const PermissionsMap = {
	[PermissionsRoles.superAdmin]: {
		[PermissionsPages.client]: {
			[PermissionsActions.create]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.assign]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.delete]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {
			[PermissionsActions.addRemoveBrands]: true,
			[PermissionsActions.addRemoveCoatingSystem]: true,
			[PermissionsActions.addRemoveColour]: true,
			[PermissionsActions.addRemoveCompetitor]: true,
			[PermissionsActions.addRemoveFiles]: true,
			[PermissionsActions.addRemoveIncExc]: true,
			[PermissionsActions.addRemoveLabourRates]: true,
			[PermissionsActions.addRemoveProduct]: true,
			[PermissionsActions.addRemoveSuppliers]: true,
			[PermissionsActions.addRemoveMarketingItems]: true,
			[PermissionsActions.editBrands]: true,
			[PermissionsActions.editCoatingSystem]: true,
			[PermissionsActions.editColour]: true,
			[PermissionsActions.editIncExc]: true,
			[PermissionsActions.editLabourRates]: true,
			[PermissionsActions.editProduct]: true,
			[PermissionsActions.editSuppliers]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.project]: {
			[PermissionsActions.addVariations]: true,
			[PermissionsActions.approvals]: true,
			[PermissionsActions.assign]: true,
			[PermissionsActions.assignTask]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.createCharges]: true,
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.createRelated]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {
			[PermissionsActions.editCompanies]: true,
			[PermissionsActions.editRefCodes]: true,
			[PermissionsActions.editRoles]: true,
			[PermissionsActions.editStates]: true,
			[PermissionsActions.editUsers]: true,
			[PermissionsActions.importData]: true,
			[PermissionsActions.view]: true,
		},
	},
	[PermissionsRoles.admin]: {
		[PermissionsPages.client]: {
			[PermissionsActions.create]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.assign]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.delete]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {
			[PermissionsActions.addRemoveBrands]: true,
			[PermissionsActions.addRemoveCoatingSystem]: true,
			[PermissionsActions.addRemoveColour]: true,
			[PermissionsActions.addRemoveCompetitor]: true,
			[PermissionsActions.addRemoveProduct]: true,
			[PermissionsActions.addRemoveSuppliers]: true,
			[PermissionsActions.addRemoveMarketingItems]: true,
			[PermissionsActions.editBrands]: true,
			[PermissionsActions.editCoatingSystem]: true,
			[PermissionsActions.editColour]: true,
			[PermissionsActions.editProduct]: true,
			[PermissionsActions.editSuppliers]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.project]: {
			[PermissionsActions.addVariations]: true,
			[PermissionsActions.approvals]: true,
			[PermissionsActions.assign]: true,
			[PermissionsActions.assignTask]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.createCharges]: true,
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.createRelated]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},

		[PermissionsPages.settings]: {
			[PermissionsActions.view]: true,
		},
	},
	[PermissionsRoles.teamLeader]: {
		[PermissionsPages.client]: {
			[PermissionsActions.create]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.assign]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.delete]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {
			[PermissionsActions.addRemoveBrands]: true,
			[PermissionsActions.addRemoveCoatingSystem]: true,
			[PermissionsActions.addRemoveColour]: true,
			[PermissionsActions.addRemoveCompetitor]: true,
			[PermissionsActions.addRemoveProduct]: true,
			[PermissionsActions.addRemoveSuppliers]: true,
			[PermissionsActions.addRemoveMarketingItems]: true,
			[PermissionsActions.editBrands]: true,
			[PermissionsActions.editCoatingSystem]: true,
			[PermissionsActions.editColour]: true,
			[PermissionsActions.editProduct]: true,
			[PermissionsActions.editSuppliers]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.project]: {
			[PermissionsActions.addVariations]: true,
			[PermissionsActions.approvals]: true,
			[PermissionsActions.assign]: true,
			[PermissionsActions.assignTask]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.createCharges]: true,
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.createRelated]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.contracts]: {
		[PermissionsPages.client]: {
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.create]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {
			[PermissionsActions.addRemoveBrands]: true,
			[PermissionsActions.addRemoveCoatingSystem]: true,
			[PermissionsActions.addRemoveColour]: true,
			[PermissionsActions.addRemoveCompetitor]: true,
			[PermissionsActions.addRemoveProduct]: true,
			[PermissionsActions.addRemoveSuppliers]: true,
			[PermissionsActions.addRemoveMarketingItems]: true,
			[PermissionsActions.editBrands]: true,
			[PermissionsActions.editCoatingSystem]: true,
			[PermissionsActions.editColour]: true,
			[PermissionsActions.editProduct]: true,
			[PermissionsActions.editSuppliers]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.project]: {
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.user]: {
		[PermissionsPages.client]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.create]: true,
			[PermissionsActions.edit]: true,
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {},
		[PermissionsPages.project]: {
			[PermissionsActions.createRelated]: true,
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.operations]: {
		[PermissionsPages.client]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {},
		[PermissionsPages.project]: {
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.field]: {
		[PermissionsPages.client]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {},
		[PermissionsPages.project]: {
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.trainee]: {
		[PermissionsPages.client]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
			[PermissionsActions.create]: true,
			[PermissionsActions.share]: true,
		},
		[PermissionsPages.library]: {},
		[PermissionsPages.project]: {
			[PermissionsActions.createRelated]: true,
			[PermissionsActions.createChargesRelated]: true,
			[PermissionsActions.editRelated]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
	[PermissionsRoles.viewer]: {
		[PermissionsPages.client]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.contact]: {
			[PermissionsActions.view]: true,
		},
		[PermissionsPages.export]: {
			[PermissionsActions.access]: true,
		},
		[PermissionsPages.library]: {},
		[PermissionsPages.project]: {
			[PermissionsActions.view]: true,
			[PermissionsActions.viewRelated]: true,
		},
		[PermissionsPages.settings]: {},
	},
};
