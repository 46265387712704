import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '../helpers';
import { BrandModel } from '../models';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root',
})
export class BrandService extends BaseService<BrandModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.brand, BrandModel);
	}
}

@Injectable()
export class GetBrandsResolver {
	constructor(private brandService: BrandService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.brandService.postList({ isActive: true });
	}
}
