import { UntypedFormGroup } from '@angular/forms';

import { Brand } from '../interfaces';
import { BaseModel } from './base.model';

export class BrandModel extends BaseModel implements Brand {
	// Properties
	public documentType: string;
	public imageUrl: string;
	public isActive: boolean;
	public logo: string;
	public name: string;
	//non db property
	public logoFile: File;
	public isSelected: boolean;

	constructor(json?: Partial<BrandModel>) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getPostObject() {
		const postObject = super.getPostObject();
		delete postObject['imageUrl'];
		delete postObject['isSelected'];

		return postObject;
	}

	public getFormData(): FormData {
		if (this.logoFile) {
			const formData = new FormData();
			formData.append('logo', this.logoFile);
			return formData;
		}
		return undefined;
	}
}
