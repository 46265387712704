import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../../../../helpers';
import { LabourCost, LabourCostResponse, LabourCostSaveObj, LabourCostsResponse, LabourCostsResponseData, Project } from '../../../../interfaces';
import { RefCodeService } from '../../../../services/reference-code.service';
import { ActiveReferenceCodesResponse } from './labour-costs.interfaces';

@Injectable()
export class LabourCostsService {
	constructor(
		private http: HttpClient,
		private refCodeService: RefCodeService
	) {}

	/**
	 * Retrieves the current active reference code data for the given code types
	 */
	public getActiveRefCode(): Observable<ActiveReferenceCodesResponse> {
		return this.refCodeService.getRefCodesWithTypesAndStatus(['labour_type', 'profile', 'substrate'], true);
	}

	/**
	 * Retrieves all labourCosts for a given project
	 * @param {string} projectId
	 * @returns {Observable<*>}
	 */
	public getLabourCosts(projectId: string) {
		return this.http.post<LabourCostsResponse>(`${Constants.BASE_API_URL}/project/labourcost/list`, { projectId }).pipe(
			map(res => {
				return res.labourCosts;
			})
		);
	}

	/**
	 * update the labourCost with the new labourCost passed in
	 * @param {LabourCost} labourCosts
	 * @param {string} projectId
	 * @returns {Observable<*>}
	 */
	public editLabourCosts(labourCosts: LabourCostSaveObj[], projectId: string): Observable<LabourCostsResponseData> {
		return this.http.post<LabourCostsResponse>(`${Constants.BASE_API_URL}/project/labourcost/edit`, { labourCosts, projectId }).pipe(
			map((res: LabourCostsResponseData) => {
				return {
					labourCosts: res.labourCosts,
					project: res.project ? new Project(res.project) : undefined,
				};
			})
		);
	}

	/**
	 * Adds a new labourCost
	 * @param {LabourCost} labourCost
	 * @param {string} projectId
	 * @returns {Observable<*>}
	 */
	public addLabourCost(labourCost: LabourCostSaveObj, projectId: string) {
		return this.http.post<LabourCostResponse>(`${Constants.BASE_API_URL}/project/labourcost/create`, { labourCost, projectId }).pipe(map(res => res.labourCost));
	}

	/**
	 * Delete labour cost
	 * @param {LabourCost} labourCostId
	 * @param {string} projectId
	 * @returns {Observable<*>}
	 */
	public deleteLabourCost(labourCostId: string, projectId: string) {
		return this.http.post<{ status: boolean }>(`${Constants.BASE_API_URL}/project/labourcost/delete`, { labourCostId, projectId }).pipe(map(res => res.status));
	}
}
