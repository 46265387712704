import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { BoqResponse, BoqUpdateData, BoqUpdateResponse, ItemsResponseData, ReferenceCode } from '../interfaces';
import { BoqModel, ChargeModel } from '../models';

@Injectable()
export class BoqService {
	constructor(private http: HttpClient) {}

	/**
	 * Get a boq for the given id
	 * @param {string} projectId
	 * @param {string} boqId
	 * @returns {Observable<*>}
	 */
	public getBoq(projectId: string, boqId: string) {
		return this.http.get<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/${boqId}`).pipe(map(res => res.boq));
	}

	/**
	 * Adds a new boq
	 * @param boq
	 * @param projectId
	 */
	public addBoq(boq: BoqModel, projectId: string): Observable<BoqUpdateData> {
		return this.http
			.post<BoqUpdateData>(`${Constants.BASE_API_URL}/project/${projectId}/boq/`, {
				boq: boq.getPostObject(),
				projectId: projectId,
			})
			.pipe(
				map((result: BoqUpdateData) => {
					return {
						boq: result.boq,
						project: result.project,
					};
				})
			);
	}

	/**
	 * update the boq with the new boq passed in
	 * @param boq
	 * @param projectId
	 */
	public editBoq(boq: BoqModel, projectId: string): Observable<BoqModel> {
		return this.http
			.put<BoqUpdateResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/${boq.id}`, {
				update: boq.getPostObject(),
				projectId,
			})
			.pipe(map((result: BoqUpdateData) => result.boq));
	}

	/**
	 * update the boq with the new boq passed in, return updated boq and updated project
	 * @param boq
	 * @param projectId
	 */
	public editBoqWithUpdateData(boq: BoqModel, projectId): Observable<BoqUpdateData> {
		return this.http
			.put<BoqUpdateResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/${boq.id}`, {
				update: boq.getPostObject(),
				projectId,
			})
			.pipe(
				map((result: BoqUpdateData) => {
					return {
						boq: result.boq,
						project: result.project,
					};
				})
			);
	}

	/**
	 * update the boq substrate with the new boq passed in
	 * @param boqId
	 * @param projectId
	 * @param substrateId
	 */
	public editSubstrateBoq(boqId: string, projectId: string, substrateId: string) {
		return this.http
			.post<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/edit-substrate`, {
				boqId,
				projectId,
				substrateId,
			})
			.pipe(map(res => res.boq));
	}

	/**
	 * update the boq colour with the new boq passed in
	 * @param boqId
	 * @param projectId
	 * @param colourId
	 */
	public editColourBoq(boqId: string, projectId: string, colourId: string) {
		return this.http
			.post<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/edit-colour`, {
				boqId,
				projectId,
				colourId,
			})
			.pipe(map(res => res.boq));
	}

	/**
	 * update the boq location with the new boq passed in
	 * @param boqId
	 * @param projectId
	 * @param environment
	 * @param subLocation1
	 * @param subLocation2
	 * @param subLocation3
	 */
	public editLocationBoq(boqId: string, projectId: string, environment: string, subLocation1: string, subLocation2: string, subLocation3: string): Observable<ChargeModel> {
		return this.http
			.post<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/edit-location`, {
				boqId,
				projectId,
				environment,
				subLocation1,
				subLocation2,
				subLocation3,
			})
			.pipe(map(res => res.boq));
	}

	/**
	 * Finds all charges with a given subLocation and updates them with a new one
	 * @param oldSublocation
	 * @param newSublocation
	 * @param projectId
	 * @returns
	 */
	public editSublocation(oldSublocation: string, newSublocation: string, projectId: string): Observable<boolean> {
		return this.http
			.post<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/edit-sublocation`, {
				oldSublocation,
				newSublocation,
				projectId,
			})
			.pipe(map(res => res.status));
	}

	/***
	 * Toggles the active state of a Bill of Quantity
	 * @param projectId
	 * @param id
	 * @param activeStatus
	 */
	public postActiveToggle(projectId: string, id: string, activeStatus: boolean) {
		return this.http
			.post<BoqResponse>(`${Constants.BASE_API_URL}/project/${projectId}/boq/toggle-activation`, {
				id: id,
				isActive: activeStatus,
				projectId: projectId,
			})
			.pipe(map(res => BoqModel.createFromJson(res.boq)));
	}

	/**
	 * Post to edit-action action of endpoint and return an instance of modelType
	 * @param isEbaRate
	 * @param projectId
	 */
	public postBulkEditIsEbaRate(isEbaRate: boolean, projectId: string): Observable<boolean> {
		return this.http
			.post<ItemsResponseData<BoqModel>>(`${Constants.BASE_API_URL}/project/${projectId}/boq/edit-is-eba-rate`, {
				isEbaRate,
				projectId,
			})
			.pipe(map(res => true));
	}
}

@Injectable()
export class GetBoqResolver {
	constructor(private boqService: BoqService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.boqService.getBoq(route.params.projectId, route.params.boqId);
	}
}
