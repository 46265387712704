import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../../../../helpers';
import { ItemResponseData, ItemsResponseData } from '../../../../interfaces';
import { ProjectModel } from '../../../../models';
import { InvoiceModel } from './invoice.models';

export interface InvoicePaginateResponse {
	items: InvoiceModel[];
	total: number;
	projectValue: number;
	claimed: number;
	status: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class InvoiceHttpService {
	constructor(private http: HttpClient) {}

	/**
	 * Gets a single invoice for provided invoice id
	 * @param id
	 * @param projectId
	 */
	public postDetails(id: string, projectId: string): Observable<InvoiceModel> {
		return this.http.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/details`, { id, projectId }).pipe(map(res => new InvoiceModel(res.item)));
	}

	/**
	 * Gets a paginated list of invoices based on the search query
	 */
	public postPaginate(
		skip: number,
		limit: number,
		invoiceNo?: number,
		clientIds?: string[],
		dateFrom?: Date,
		dateTo?: Date,
		projectId?: string,
		status?: string[]
	): Observable<InvoicePaginateResponse> {
		return this.http.post<InvoicePaginateResponse>(`${Constants.BASE_API_URL}/invoice/paginate`, {
			skip,
			limit,
			invoiceNumber: invoiceNo ?? undefined,
			clientIds: clientIds ?? undefined,
			dateFrom,
			dateTo,
			projectId,
			status: status ?? undefined,
		});
	}

	/**
	 * Gets a paginated list of live projects and their corresponding invoices
	 */
	public postPaginateLiveProjects(skip: number, limit: number, projectRef?: number, clientIds?: string[]): Observable<ItemsResponseData<ProjectModel>> {
		return this.http.post<ItemsResponseData<ProjectModel>>(`${Constants.BASE_API_URL}/invoice/live-projects`, {
			skip,
			limit,
			projectRef: projectRef ?? undefined,
			clientIds: clientIds ?? undefined,
		});
	}

	/**
	 * Gets a list of active invoices for the provided project
	 * @param projectId
	 */
	public postList(projectId: string): Observable<InvoiceModel[]> {
		return this.http.post<ItemsResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/list`, { projectId }).pipe(
			map(res => {
				return res.items.map(item => {
					return new InvoiceModel(item);
				});
			})
		);
	}

	/**
	 * Creates a new invoice
	 * @param invoice
	 * @param projectId
	 * @param paymentTerms
	 */
	public createInvoice(invoice: InvoiceModel, projectId: string, paymentTerms: string): Observable<InvoiceModel> {
		return this.http
			.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/create`, { invoice, projectId, paymentTerms })
			.pipe(map(res => new InvoiceModel(res.item)));
	}

	/**
	 * Updates invoice's isActive state
	 * @param id
	 * @param isActive
	 * @param projectId
	 */
	public editActive(id: string, isActive: boolean, projectId: string): Observable<InvoiceModel> {
		return this.http
			.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/edit-active`, { id, isActive, projectId })
			.pipe(map(res => new InvoiceModel(res.item)));
	}

	public markSent(id: string): Observable<InvoiceModel> {
		return this.http.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/mark-sent`, { id }).pipe(map(res => new InvoiceModel(res.item)));
	}

	/**
	 * Updates invoice's isPaid state
	 * @param id
	 * @param isPaid
	 * @param projectId
	 */
	public editPaid(id: string, isPaid: boolean, projectId: string): Observable<InvoiceModel> {
		return this.http
			.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/edit-paid`, { id, isPaid, projectId })
			.pipe(map(res => new InvoiceModel(res.item)));
	}

	/**
	 * Updates invoice's payments data
	 * @param id
	 * @param payment
	 * @param projectId
	 */
	public addPayment(id: string, payment: number, date: Date, comment: string, projectId: string): Observable<InvoiceModel> {
		return this.http
			.post<ItemResponseData<InvoiceModel>>(`${Constants.BASE_API_URL}/invoice/add-payment`, { id, payment, date, comment, projectId })
			.pipe(map(res => new InvoiceModel(res.item)));
	}

	public getProjectInvoiceClaimTotal(projectId: string): Observable<number> {
		return this.http.post<{ projectTotal: number }>(`${Constants.BASE_API_URL}/invoice/project-total`, { projectId }).pipe(map(res => res.projectTotal));
	}
}
