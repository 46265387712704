import { Component, Input, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import { CoatingSystemsTabComponent } from '../../../../components/tabs/coating-systems-tab/coating-systems-tab.component';
import { ColoursTabComponent } from '../../../../components/tabs/colours-tab/colours-tab.component';
import { ProductsTabComponent } from '../../../../components/tabs/products-tab/products-tab.component';
import { SuppliersTabComponent } from '../../../../components/tabs/suppliers-tab/suppliers-tab.component';
import { Project } from '../../../../interfaces';
import { ProjectService } from '../../../../services/project.service';

@Component({
	selector: 'app-project-details-tabs',
	templateUrl: './project-details-tabs.component.html',
})
export class ProjectDetailsTabsComponent {
	@ViewChild('staticTabs', { static: false })
	public staticTabs: TabsetComponent;
	@ViewChild(ColoursTabComponent, { static: false })
	public coloursTabComponent: ColoursTabComponent;
	@ViewChild(CoatingSystemsTabComponent, { static: false })
	public coatingSystemsTabComponent: CoatingSystemsTabComponent;
	@ViewChild(ProductsTabComponent, { static: false })
	public productsTabComponent: ProductsTabComponent;
	@ViewChild(SuppliersTabComponent, { static: false })
	public suppliersTabComponent: SuppliersTabComponent;

	@Input()
	public set project(project: Project) {
		if (project) {
			this._project = project;

			if (project.id) {
				this.projectService.postDistinctCounts(project.id).subscribe(item => {
					this.coatingSystemCount = item.coatingSystemCount;
					this.colourCount = item.colourCount;
					this.productCount = item.productCount;
					this.supplierCount = item.suppliersCount;
				});
			}
		}
	}

	public get project(): Project {
		return this._project;
	}

	public coatingSystemCount = 0;
	public colourCount = 0;
	public productCount = 0;
	public supplierCount = 0;

	private _project: Project;

	constructor(private projectService: ProjectService) {}

	public updateTabs(): void {
		const index = this.staticTabs.tabs.findIndex(x => x.active === true);

		switch (index) {
			case 0:
				this.coloursTabComponent.load();
				break;
			case 1:
				this.coatingSystemsTabComponent.load();
				break;
			case 2:
				this.productsTabComponent.load();
				break;
			case 3:
				this.suppliersTabComponent.load();
				break;
		}
	}
}
