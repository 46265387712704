import { NavigationMenuItem } from './navigation-menu.interfaces';

export class NavigationMenuConfig {
	public logoURL: string;
	public navigationItems: NavigationMenuItem[];

	constructor(model?: Partial<NavigationMenuConfig>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
