import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Constants } from '../../../helpers';
import { ItemLinkConfig } from '../../../interfaces';
import { CoatingSystemImageHeight } from './common/generic-cards.constants';

@Component({
	selector: 'app-generic-cards',
	templateUrl: './generic-cards.component.html',
	styleUrls: ['./generic-cards.component.scss'],
})
export class GenericCardsComponent {
	@Input()
	public set cardItems(cardItems: any[]) {
		const newCardItems: any[] = cardItems;
		for (const item of newCardItems) {
			item.hasPicture =
				(item.coatProducts && item.coatProducts.length) ||
				item.displayPictureKey ||
				item.pictureKey ||
				item.pictureUrl ||
				item.displayPictureUrl ||
				item.logo ||
				item.hexColour ||
				item.thumbnailKey;
		}
		this._cardItems = newCardItems;
	}
	public get cardItems(): any[] {
		return this._cardItems;
	}

	@Input() public headers: any[] = [];
	@Input() public type: string;
	@Input() public readonly imagePromises: Promise<void>[];
	@Input() public readonly disablePointer: Boolean = false;
	@Input() public isEditOrder: Boolean = false;
	@Input() public itemLinkConfig: ItemLinkConfig;
	@Output() public itemSelected: EventEmitter<any> = new EventEmitter();

	public libraryTypesConst = Constants.LIBRARY_TYPES;
	public coatingSystemImageHeight = CoatingSystemImageHeight;

	private _cardItems: any[] = [];

	public selectItem(item: any): void {
		this.itemSelected.emit(item);
	}
}
