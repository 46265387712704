import { UntypedFormGroup, Validators } from '@angular/forms';

import { CustomValidators, ValidatorHelper } from '../helpers';
import { Division } from '../interfaces';
import { BaseModel } from './base.model';
import { CompanyModel } from './company.model';
import { UserModel } from './user.model';

export class DivisionModel extends BaseModel implements Division {
	// Properties
	public description?: string;
	public entity: string;
	public company: CompanyModel;
	public users: UserModel[];
	public teamLeader?: UserModel;
	public isActive: boolean = true;
	public name: string;
	public order: number;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}

export function getDivisionValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.required],
		},
		{
			name: 'Details',
			validators: [Validators.required],
		},
		{
			name: 'Company',
			validators: [Validators.required],
		},
	]);
}
