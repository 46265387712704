import { UpperCasePipe } from '@angular/common';

import { TEXT } from '../../../../helpers/text.constants';

const upperCasePipe: UpperCasePipe = new UpperCasePipe();

export const ModalTextConst = {
	notification: TEXT.notification,
	confirm: TEXT.confirm,
	continue: TEXT.continue,
	complete: TEXT.complete,
	open: TEXT.open,
	gotIt: upperCasePipe.transform(TEXT.gotIt) + '!',
	requestToSave: TEXT.requestToSave,
	submit: TEXT.submit,
	save: TEXT.save,
	edit: TEXT.edit,
	close: TEXT.close,
	delete: TEXT.delete,
};
