// Framework imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// 3rd party imports
// @itc-Core imports
// project specific imports
import { Constants } from '../../../../helpers';
import { AlertHttpService } from '../../../../pages/alert/common/alert.http.service';
import { AlertModel } from '../../../../pages/alert/common/alert.models';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { SaasAdminMessageModel } from './communication.models';

@Injectable({
	providedIn: 'root',
})
export class CommunicationService {
	private paintCommunicationSubject: BehaviorSubject<String[] | undefined> = new BehaviorSubject(undefined);
	public paintCommunication = this.paintCommunicationSubject.asObservable();

	constructor(
		private httpClient: HttpClient,
		private alertHttpService: AlertHttpService,
		private userService: LocalStorageService
	) {}

	/**
	 * Returns the active saas admin messages
	 */
	public getActiveMessages(): Observable<SaasAdminMessageModel[]> {
		return this.httpClient
			.post<{ items: SaasAdminMessageModel[] }>(`${Constants.BASE_API_URL}/${Constants.END_POINTS.saasAdminMessage}/get-active-messages`, {})
			.pipe(map(res => res.items));
	}

	/**
	 * Returns the messages from the admin dashboard and evaluate if each message has been added as a notification for the user, if not a new alert is created
	 */
	public getCommunication(): void {
		this.getActiveMessages().subscribe((saasAdminMessages: SaasAdminMessageModel[]) => {
			if (saasAdminMessages.length) {
				this.paintCommunicationSubject.next(saasAdminMessages.map(saasAdminMessage => saasAdminMessage.message));
				for (const message of saasAdminMessages) {
					this.alertHttpService
						.getDetails(message.id)
						.pipe(
							tap(alert => {
								// if the saasAdminMessage does not exist as an alert for the user, it will be created automatically
								if (!alert) {
									let newAlert = new AlertModel();
									newAlert.user = this.userService.getUserId();
									newAlert.adminMessage = message.id;
									this.alertHttpService.createAlert(newAlert).subscribe();
								}
							})
						)
						.subscribe();
				}
			} else {
				this.paintCommunicationSubject.next(undefined);
			}
		});
	}
}
