import * as moment from 'moment';

import { variables } from '_variables';
import { ContentCardDropdownItem } from '../../@itc-core/components/components/content-card/common/content-card.interfaces';
import { budgetFinancialYearsCurrentFuture, budgetFinancialYearsPast } from './constants';

export class DateHelper {
	/**
	 * Calculates how many months are between two dates.
	 * @param startDate
	 * @param endDate
	 * @private
	 */
	public static calculateMonthsBetweenDates(startDate: Date, endDate: Date): number {
		let monthsDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12;
		monthsDifference -= startDate.getMonth();
		monthsDifference += endDate.getMonth();
		return monthsDifference;
	}

	/**
	 * Retrieves the year of the start of the current financial year period
	 * @param date
	 */
	public static getFinancialYearStart(date: Date): number {
		let financialYear = date.getFullYear();
		if (date.getMonth() <= 5) {
			financialYear--;
		}
		return financialYear;
	}

	/**
	 * Determines whether a given month/year combination (number values) is within a given date range (Date objects)
	 * @param month 0 - Jan, 1 - Feb, etc.
	 * @param year
	 * @param startDate
	 * @param endDate
	 */
	public static isMonthYearWithinDateRange(month: number, year: number, startDate: Date, endDate: Date): boolean {
		return (
			(year > startDate.getFullYear() || (year === startDate.getFullYear() && month >= startDate.getMonth())) &&
			(year < endDate.getFullYear() || (year === endDate.getFullYear() && month <= endDate.getMonth()))
		);
	}

	public static priorMonth(date, formatString?) {
		if (formatString) {
			return moment(date).subtract(1, 'months').format(formatString);
		} else {
			return moment(date).subtract(1, 'months').format('YYYY-MM-DD');
		}
	}

	/**
	 * Get the date range of the current financial year
	 */
	public static getFinancialYearDateRange(date: Date = new Date()): { fromDate: moment.Moment; toDate: moment.Moment } {
		const result: { fromDate: moment.Moment; toDate: moment.Moment } = { fromDate: undefined, toDate: undefined };
		// If today's month is before July
		if (date.getMonth() < 6) {
			result.fromDate = moment().set('year', date.getFullYear()).set('month', 6).startOf('month').subtract(1, 'year');
			result.toDate = moment().set('year', date.getFullYear()).set('month', 5).endOf('month');
		} else {
			result.fromDate = moment().set('year', date.getFullYear()).set('month', 6).startOf('month');
			result.toDate = moment().set('year', date.getFullYear()).set('month', 5).add(1, 'year').endOf('month');
		}

		return result;
	}

	/**
	 * Returns an array of dropdown items in the format expected by an itc-content-card's dropdown field
	 * Each dropdown returns items with the following fields:
	 * key: string; - The start of the financial year as a string E.g. '2022'
	 * value: string; - The label to be displayed for the dropdown item E.g. 'FY 2022-2023'
	 * indicatorColour: string - The colour for the coloured dot besides the dropdown items
	 * @param currentFinancialYear
	 * @param numberOYearsCurrentFuture
	 * @param numberOfYearsPast
	 */
	public static getFinancialYearDropdownItems(
		currentFinancialYear: string,
		numberOYearsCurrentFuture: number = budgetFinancialYearsCurrentFuture,
		numberOfYearsPast: number = budgetFinancialYearsPast
	): ContentCardDropdownItem[] {
		const financialYearDropdownItems = [];
		// Iterate backwards to maintain chronological ordering of years
		for (let i = numberOfYearsPast; i > 0; i--) {
			const financialYearStart = (Number(currentFinancialYear) - i).toString();
			const financialYearEnd = (Number(currentFinancialYear) - (i - 1)).toString();
			financialYearDropdownItems.push({
				key: financialYearStart,
				value: `FY ${financialYearStart}-${financialYearEnd}`,
				indicatorColour: variables.targetColour,
			});
		}

		// add quarterly periods
		for (let i = 0; i < 4; i++) {
			const financialYearStart = Number(currentFinancialYear).toString();
			const financialYearEnd = (Number(currentFinancialYear) + 1).toString();
			const key = `Q${i + 1} FY ${financialYearStart}-${financialYearEnd}`;
			financialYearDropdownItems.push({
				key: key,
				value: key,
				indicatorColour: variables.wonColour,
			});
		}

		for (let j = 0; j < numberOYearsCurrentFuture; j++) {
			const financialYearStart = (Number(currentFinancialYear) + j).toString();
			const financialYearEnd = (Number(currentFinancialYear) + j + 1).toString();
			financialYearDropdownItems.push({
				key: financialYearStart,
				value: `FY ${financialYearStart}-${financialYearEnd}`,
				indicatorColour: financialYearStart === currentFinancialYear ? variables.wonColour : variables.targetColour,
			});
		}
		return financialYearDropdownItems;
	}

	/**
	 * Retrieves the number of days in a given year (accounts for leap years)
	 * @param year
	 */
	public static getDaysInYear(year: number): number {
		if (year % 4 === 0) {
			return 366;
		} else {
			return 365;
		}
	}
}
