// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  overflow-x: hidden;
  width: 550px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/bulk-boq-edit-dialog/bulk-boq-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,YAAA;AACD","sourcesContent":[".modal-container {\n\toverflow-x: hidden;\n\twidth: 550px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
