// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 200px;
  max-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/logo-uploader/logo-uploader.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,iBAAA;AACD","sourcesContent":["img {\n\tmax-width: 200px;\n\tmax-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
