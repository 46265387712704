import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ContentCardModel } from '../../../../../@itc-core/components/components/content-card/common/content-card.models';
import { SaveIncExcTemplateDialogComponent } from '../../../../dialog/components';
import { Constants } from '../../../../helpers';
import { IncExcCommentTemplate, Project, UserIncExcCommentTemplate } from '../../../../interfaces';
import { UserModel } from '../../../../models';
import { AuthService } from '../../../../services/auth.service';
import { IncExcCommentTemplateService } from '../../../../services/inc-exc-comment-template.service';
import { ModalService } from '../../../../services/modal.service';
import { UserService } from '../../../../services/user.service';
import { IncExcText } from './common/inc-exc-text-const';

interface UserIncExcCommentTemplateList extends UserIncExcCommentTemplate {
	isDisabled: boolean;
}

interface TemplateListItems {
	true: UserIncExcCommentTemplateList[];
	false: UserIncExcCommentTemplateList[];
}

@Component({
	selector: 'app-inc-exc-comments',
	templateUrl: './inc-exc-comments.component.html',
	styleUrls: ['./inc-exc-comments.component.scss', '../project-details.component.scss'],
})
export class IncExcCommentsComponent implements OnInit {
	@ViewChild('saveIncExcTemplateDialog', { static: true })
	private saveIncExcTemplateDialogRef: ElementRef;
	@ViewChild(SaveIncExcTemplateDialogComponent, { static: false })
	private saveIncExcTemplateDialogComponent: SaveIncExcTemplateDialogComponent;

	@Input()
	public project: Project;
	@Input()
	public currentUser: UserModel;
	@Output('deselectTab')
	public deselectTabEmitter: EventEmitter<void> = new EventEmitter<void>();

	public set userTemplates(templates: TemplateListItems) {
		this.setUserTemplates(templates);
	}

	public get userTemplates(): TemplateListItems {
		return this._userTemplates;
	}

	public excCardInputConfig: ContentCardModel;
	public excTemplates: UserIncExcCommentTemplate[] = [];
	public incCardInputConfig: ContentCardModel;
	public incTemplates: UserIncExcCommentTemplate[] = [];
	public isAdmin: boolean;
	public listTitles = Constants.INC_EXC_TITLES;
	public loadingTemplatesSubscription: Subscription;
	public maxLength: number = 3000;
	public selectedExcTemplate: UserIncExcCommentTemplate;
	public selectedIncTemplate: UserIncExcCommentTemplate;
	public selectedTab: boolean = false;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public text: typeof IncExcText = IncExcText;
	public toggleFavouritePromise: Promise<UserModel>;

	private _userTemplates: TemplateListItems;

	constructor(
		private authService: AuthService,
		private modalService: ModalService,
		private templateService: IncExcCommentTemplateService,
		private userService: UserService
	) {}

	public ngOnInit() {
		this.setInputCardConfigs();
		if (this.project.inclusions === undefined) {
			this.project.inclusions = '';
		}
		if (this.project.exclusions === undefined) {
			this.project.exclusions = '';
		}
	}

	public appendTemplate(userTemplate: UserIncExcCommentTemplateList, templateType: boolean) {
		// Appending to inclusions
		if (templateType) {
			this.project.inclusions += userTemplate.template.message;
			this.reevaluateTheList(templateType);
		} else if (templateType === false) {
			this.project.exclusions += userTemplate.template.message;
			this.reevaluateTheList(templateType);
		}
	}

	public isDisabled(messageTemplate: UserIncExcCommentTemplateList, templateType: boolean) {
		if (templateType) {
			return this.maxLength - ((this.project.inclusions || '').length + messageTemplate.template.message.length) < 0;
		} else if (templateType === false) {
			return this.maxLength - ((this.project.exclusions || '').length + messageTemplate.template.message.length) < 0;
		}
	}

	/**
	 * Indicates if a selected template is a favourite one.
	 * @param template
	 */
	public isSelectedTemplateFavourite(template: IncExcCommentTemplate) {
		return template && this.currentUser.incExcCommentFavouriteTemplates.includes(template.id);
	}

	/**
	 * Initialises template dropdown.
	 * @param company
	 */
	public onCompanyChanged(company): void {
		this.project.company = company;
		this.refreshTemplates();
	}

	public onDeselectTab(): void {
		this.selectedTab = false;
	}

	public onExcTemplateChange(): void {
		this.project.exclusions = this.selectedExcTemplate.template.message;
	}

	public onIncTemplateChange(): void {
		this.project.inclusions = this.selectedIncTemplate.template.message;
	}

	public onSelectTab(tabEvent: TabDirective): void {
		if (tabEvent.active) {
			this.isAdmin = this.authService.isAdmin();
			this.userTemplates = { true: [], false: [] };
			this.selectedTab = true;
			this.refreshTemplates();
		}
	}

	public onTabClick(): void {
		if (this.selectedTab === true) {
			this.selectedTab = false;
			this.deselectTabEmitter.emit();
		}
	}

	public reevaluateTheList(templateType: boolean): void {
		// Loops through the list of items and reassigns the isDisabledFlag
		if (templateType) {
			// Inclusions
			for (const userTemplate of this.userTemplates.true) {
				userTemplate.isDisabled = this.isDisabled(userTemplate, templateType);
			}
		} else if (templateType === false) {
			// Exclusions
			for (const userTemplate of this.userTemplates.false) {
				userTemplate.isDisabled = this.isDisabled(userTemplate, templateType);
			}
		}
	}

	public setUserTemplates(templates: TemplateListItems): void {
		templates.true = this.sortTemplates(templates.true);
		templates.false = this.sortTemplates(templates.false);
		// sort the list in templates
		this._userTemplates = templates;
		this.incTemplates = this.sortTemplates(templates.true);
		this.excTemplates = this.sortTemplates(templates.false);

		this.reevaluateTheList(true);
		this.reevaluateTheList(false);
	}

	public showSaveTemplateDialog(isInclusion: boolean): void {
		const templateMessage = isInclusion ? this.project.inclusions : this.project.exclusions;
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.addTemplate,
			content: this.saveIncExcTemplateDialogRef,
			submitButton: {
				click: () => {
					this.saveIncExcTemplateDialogComponent.saveTemplate(isInclusion, templateMessage, this.project.company.id).then(userTemplate => {
						const newUserTemplates = this.userTemplates;
						newUserTemplates[String(isInclusion)].push(userTemplate);
						newUserTemplates[String(isInclusion)] = newUserTemplates[String(isInclusion)];
						this.userTemplates = newUserTemplates;
						this.modalService.closeModal();
					});
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	public toggleSelectedFavourite(event, selectedTemplate: UserIncExcCommentTemplateList) {
		// Stops favourite button from executing template append function from outer (click) event
		event.stopPropagation();

		this.toggleFavouritePromise = selectedTemplate.isFavourite
			? this.userService.removeTemplate(selectedTemplate.template.id).toPromise()
			: this.userService.addTemplate(selectedTemplate.template.id).toPromise();

		this.toggleFavouritePromise.then(user => {
			this.currentUser = user;
			selectedTemplate.isFavourite = !selectedTemplate.isFavourite;

			const newUserTemplates = this.userTemplates;
			newUserTemplates[String(selectedTemplate.template.isInclusion)] = newUserTemplates[String(selectedTemplate.template.isInclusion)];
			this.userTemplates = newUserTemplates;
		});
	}

	/**
	 * Gets the userTemplates linked to the project company and fill their dropdown.
	 */
	private refreshTemplates(): void {
		if (!this.project.company) {
			return;
		}

		this.loadingTemplatesSubscription = this.templateService
			.postList({ companyId: this.project.company.id, isActive: true })
			.pipe(
				finalize(() => {
					this.loadingTemplatesSubscription.unsubscribe();
				})
			)
			.subscribe(templates => {
				const newUserTemplates = { true: [], false: [] };
				for (const template of templates) {
					newUserTemplates[String(template.isInclusion)].push({
						template,
						isFavourite: this.isSelectedTemplateFavourite(template),
						isDisabled: false,
					});
				}

				this.userTemplates = {
					false: newUserTemplates.false,
					true: newUserTemplates.true,
				};
			});
	}

	private setInputCardConfigs(): void {
		this.incCardInputConfig = {
			hasDivider: true,
			titleText: this.text.incComments,
		};
		this.excCardInputConfig = {
			hasDivider: true,
			titleText: this.text.excComments,
		};
	}

	/**
	 * Return the sorted version of the given array. sorted first by isFavourite and then by name>
	 * @param templates
	 * @return userTemplates
	 */
	private sortTemplates(templates: UserIncExcCommentTemplateList[]): UserIncExcCommentTemplateList[] {
		//Uses slice to clone the array.
		return templates.slice(0).sort((a, b) => {
			if (a.isFavourite !== b.isFavourite) {
				return a.isFavourite ? -1 : 1;
			}
			return a.template.order > b.template.order ? 1 : -1;
		});
	}
}
