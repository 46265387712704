import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ClientProjectSummary, CompaniesResponse, Company, CompanyResponse, PaginateResponse, ReferenceCode } from '../interfaces';
import { UserModel } from '../models';

@Injectable()
export class ClientService {
	//this is can keep the cached data for
	//clients and so if we have can use
	//instead of making the api call.
	// Cached client success rate observable and data
	public clientSuccessRatesObservable = new BehaviorSubject<any>({});
	public clientSuccessRates = {};
	private saveClientSubject = new Subject<void>();
	saveClient$ = this.saveClientSubject.asObservable();

	constructor(private http: HttpClient) {}

	/**
	 * Gets all companies
	 * @returns {Observable<any>}
	 */
	public getCompanies() {
		return this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/client/list`, {
				params: { isClient: false },
			})
			.pipe(
				map(res => {
					return res.companies.map(item => new Company(item));
				})
			);
	}

	/**
	 * Gets all clients
	 * @returns {Observable<any>}
	 */
	public getClients() {
		return this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/client/list`, {
				params: { isClient: true },
			})
			.pipe(map(res => res.companies.map(item => new Company(item))));
	}

	/**
	 * Gets all clients
	 * @returns {Observable<any>}
	 */
	public getClientAndContactNames() {
		return this.http.post<any>(`${Constants.BASE_API_URL}/client/get-client-names-and-contacts`, { params: { isClient: true } }).pipe(map(result => result.clients));
	}

	public getClientsSlim() {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/client/list`, {
				params: { isActive: true, isClient: true },
				slim: true,
			})
			.pipe(map(res => res.companies.map(item => new Company(item))));
	}

	/**
	 * Gets all active clients
	 * @returns {Observable<any>}
	 */
	public getActiveClients() {
		return this.http
			.post<CompaniesResponse>(`${Constants.BASE_API_URL}/client/list`, {
				params: {
					isClient: true,
					isActive: true,
				},
			})
			.pipe(map(res => res.companies.map(item => new Company(item))));
	}

	/**
	 * Get a companyClient for the given id
	 * @param {string} id
	 * @returns {Observable<any>}
	 */
	public getClient(id: string) {
		return this.http.get<CompanyResponse>(`${Constants.BASE_API_URL}/client/${id}`).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Adds a new companyClient
	 * @returns {Observable<any>}
	 * @param client
	 */
	public addClient(client: Company) {
		const clientPostObject: any = Object.assign({}, client);
		clientPostObject.isClient = true;
		delete clientPostObject.isProvider;

		for (const contact of clientPostObject.contacts) {
			if (Array.isArray(clientPostObject.estimators)) {
				contact.estimators = contact.estimators.map(estimator => estimator.id);
			} else if (contact.estimators instanceof UserModel) {
				contact.estimators = [contact.estimators.id];
			}
		}

		return this.http.post<CompanyResponse>(`${Constants.BASE_API_URL}/client/`, client).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Update a companyClient
	 * @param {Company} client
	 */
	public updateClient(client: Company) {
		// Create post object to send to backend.
		const clientPostObject: any = Object.assign({}, client);
		clientPostObject.isClient = true;
		delete clientPostObject.isProvider;

		/*
		 * Note: I hate that this needs to exist. I hate that paint projex is so poorly built. Why aren't we using BaseModel
		 * for the company model? WHo knows, but If I change it now it will break other parts of the system so instead we need this shitty hack.
		 * - Callan + Chase
		 */
		for (const contact of clientPostObject.contacts) {
			if (Array.isArray(clientPostObject.estimators)) {
				contact.estimators = contact.estimators.map(estimator => estimator.id);
			} else if (contact.estimators instanceof UserModel) {
				contact.estimators = [contact.estimators.id];
			}
		}

		return this.http.put<CompanyResponse>(`${Constants.BASE_API_URL}/client/${client.id}`, clientPostObject).pipe(map(res => new Company(res.company)));
	}

	/**
	 * Gets client for pagination
	 * @returns {Observable<any>}
	 */
	public postPaginate(search) {
		return this.http.post<PaginateResponse<Company>>(`${Constants.BASE_API_URL}/company/clientpagin`, search).pipe(map(res => res.paginatedObject));
	}

	/**
	 * Gets totalCount
	 * @returns {Observable<any>}
	 */
	public postCount(search) {
		return this.http.post<any>(`${Constants.BASE_API_URL}/client/count`, { params: search }).pipe(map(res => res.total));
	}

	/**
	 * Merge two clients.
	 * @param clientId1
	 * @param clientId2
	 * @param mergedClient
	 */
	public mergeClient(clientId1, clientId2, mergedClient) {
		return this.http
			.post<CompanyResponse>(`${Constants.BASE_API_URL}/client/merge`, {
				clientId1,
				clientId2,
				mergedClient,
			})
			.pipe(map(res => new Company(res.company)));
	}

	/**
	 * Get client success rates
	 * @param clientIds
	 * @returns {Observable<any>}
	 */
	public postSuccessRates(clientIds) {
		const newClientIds = [];

		// If any id exists in the cache
		for (const id of clientIds) {
			if (id && !this.clientSuccessRates[id]) {
				newClientIds.push(id);
			}
		}

		// No new clients to get data for
		if (newClientIds.length === 0) {
			return of(this.clientSuccessRatesObservable.getValue());
		}

		return this.http
			.post<any>(`${Constants.BASE_API_URL}/client/success-rates`, {
				clients: newClientIds,
			})
			.pipe(
				map(res => {
					Object.assign(this.clientSuccessRates, res.results);
					this.clientSuccessRatesObservable.next(this.clientSuccessRates);
					return this.clientSuccessRates;
				})
			);
	}

	/**
	 * Get client project summary
	 * @param clientId
	 * @returns {Observable<ClientProjectSummary>}
	 */
	public getProjectSummary(clientId): Observable<ClientProjectSummary> {
		return this.http.get<any>(`${Constants.BASE_API_URL}/client/${clientId}/project-summary`).pipe(map(response => response.results));
	}

	/**
	 * Reset the client success rates cache
	 */
	public clearClientSuccessRates() {
		this.clientSuccessRates = {};
	}

	public emitSaveClient() {
		this.saveClientSubject.next();
	}
}

@Injectable()
export class GetClientResolver {
	constructor(private clientService: ClientService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.clientService.getClient(route.params.id);
	}
}

@Injectable()
export class GetClientsResolver {
	constructor(private clientService: ClientService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.clientService.getClients();
	}
}

@Injectable()
export class GetActiveClientsResolver {
	constructor(private clientService: ClientService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.clientService.getActiveClients();
	}
}
