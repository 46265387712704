import { ReferenceCode } from './reference-code';
import { State } from './state';

// LabourRate object
export interface LabourRate {
	afterHoursRateEba: number;
	afterHoursRateNonEba: number;
	heightAllowance?: number;
	id?: string;
	isActive: boolean;
	isSelected?: boolean;
	labourType: ReferenceCode;
	rateEBA: number;
	rateCost?: number;
	rateNonEBA: number;
	state: State;
	siteAllowance?: number;
}
