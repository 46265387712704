import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Constants, matAutocompleteDropdownLimit } from '../../helpers';
import { RegexHelper } from '../../helpers';
import { StringHelper } from '../../helpers';
import { StateAutocompleteItemInterface } from '../../interfaces';
import { Division } from '../../interfaces';
import { State } from '../../interfaces';
import { textConstants } from './common/project-filter.constants';
import { ProjectFilterIsValidFields, SearchParams } from './common/project-filter.interfaces';
import { ProjectFilterModel } from './common/project-filter.models';

@Component({
	selector: 'paint-project-filter',
	templateUrl: './project-filter.component.html',
	styleUrls: ['./project-filter.component.scss'],
})
export class ProjectFilterComponent implements OnInit {
	@Input()
	public config: ProjectFilterModel = new ProjectFilterModel();

	@Output()
	public triggerSearch: EventEmitter<SearchParams> = new EventEmitter<SearchParams>();

	public currentDivision: Division;
	public currentState: string;
	public filteredStates: StateAutocompleteItemInterface[];
	public noItemsId: string = 'no_items';
	public projectStatusArray = Constants.PROJECT_STATUS_ARRAY;
	public selectedStatusArray: string[];
	public startDate: Date = new Date();
	public endDate: Date = new Date();
	public stateFilteredOptions: Observable<StateAutocompleteItemInterface[]>;
	public stateFormControl: UntypedFormControl = new UntypedFormControl();
	public textConstants: typeof textConstants = textConstants;

	private isValidFields: ProjectFilterIsValidFields;

	constructor() {}

	public ngOnInit(): void {
		this.startDate = this.config.defaultDates.startDate ? this.config.defaultDates.startDate : undefined;
		this.endDate = this.config.defaultDates.endDate ? this.config.defaultDates.endDate : undefined;
		this.setIsValidFields();
		this.setSelectedStatusArray();
		this.setStatesObservable();
		this.updateSearch();
	}

	// Clears an autocomplete field and reopens the filtered items dropdown
	public clearAutocomplete(trigger: MatAutocompleteTrigger, formControl: UntypedFormControl): void {
		formControl.setValue('');
		setTimeout(() => {
			trigger.openPanel();
		}, 0);
	}

	public onDivisionChange(division: Division): void {
		this.currentDivision = division;
		this.updateSearch();
	}

	public onDropdownClosed(formControl: UntypedFormControl, field: string): void {
		// Clear the input if our flag indicates typed input (instead of item selected)
		if (!this.isValidFields[field]) {
			formControl.setValue('');
		}
	}

	public onStateChange(state: State): void {
		this.currentState = state ? state.id : undefined;
		this.updateSearch();
	}

	public updateSearch(): void {
		if (!this.startDate || !this.endDate) {
			return;
		}
		// Emit back our search terms to refresh the dashboard data
		this.triggerSearch.emit({
			dates: {
				startDate: this.startDate,
				endDate: this.endDate,
			},
			division: this.currentDivision,
			state: this.currentState,
			status: this.selectedStatusArray,
		});
	}

	private setIsValidFields(): void {
		this.isValidFields = {
			division: false,
			state: false,
		};
	}

	private setSelectedStatusArray(): void {
		this.selectedStatusArray = this.projectStatusArray.map(projectStatus => projectStatus.value);
	}

	private setStatesObservable(): void {
		this.stateFilteredOptions = this.stateFormControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				if (typeof value !== 'string') {
					value = value.name;
					this.stateFormControl.setValue(value);
					this.isValidFields.state = true;
				} else {
					this.isValidFields.state = false;
				}
				// Auto refresh search if user clears input
				if (value === '') {
					this.onStateChange(undefined);
				}

				let returnData = this.config.states.sort((a, b) => (a.name > b.name ? 1 : -1));
				// Don't filter if no search term
				if (value || value.length) {
					returnData = returnData.filter(state => state.name.match(new RegExp(RegexHelper.escape(value), 'gi')));
				}
				this.filteredStates = returnData.slice(0, matAutocompleteDropdownLimit);

				if (this.filteredStates.length < 1) {
					this.filteredStates.push({ id: this.noItemsId, name: StringHelper.toTitleCase(this.textConstants.noItems) });
				}

				return this.filteredStates;
			})
		);
	}
}
