// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin-top-45px {
  margin-top: 45px;
}

.position-left-0 {
  left: 0;
}

.position-right-0 {
  right: 0;
}

.corner-badge {
  width: 22px;
  height: 22px;
  background-color: #99cb3c;
  margin-left: -22px;
}
.corner-badge .number {
  color: white;
}

.object-fit-contain {
  object-fit: contain;
  object-position: left;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/generic-cards/coating-system-layer-view/coating-system-layer-view.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,OAAA;AACD;;AAEA;EACC,QAAA;AACD;;AAEA;EACC,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AACD;AAAC;EACC,YAAA;AAEF;;AAEA;EACC,mBAAA;EACA,qBAAA;AACD","sourcesContent":[".margin-top-45px {\n\tmargin-top: 45px;\n}\n\n.position-left-0 {\n\tleft: 0;\n}\n\n.position-right-0 {\n\tright: 0;\n}\n\n.corner-badge {\n\twidth: 22px;\n\theight: 22px;\n\tbackground-color: #99cb3c;\n\tmargin-left: -22px;\n\t.number {\n\t\tcolor: white;\n\t}\n}\n\n.object-fit-contain {\n\tobject-fit: contain;\n\tobject-position: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
