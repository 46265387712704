import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../helpers';
import { FileFolderModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class FilesFolderService extends BaseService<FileFolderModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.filesFolder, FileFolderModel);
	}
}
