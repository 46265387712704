import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ItcSplitButtonDropdownComponent } from '../split-button-dropdown.component';

@NgModule({
	declarations: [ItcSplitButtonDropdownComponent],
	exports: [BsDropdownModule, ItcSplitButtonDropdownComponent],
	imports: [BsDropdownModule.forRoot(), CommonModule, MatButtonModule, FontAwesomeModule],
	providers: [],
})
export class ItcSplitButtonDropdownModule {}
