import { UntypedFormGroup } from '@angular/forms';

import { Colour } from '../interfaces';
import { BaseModel } from './base.model';

export class ColourModel extends BaseModel implements Colour {
	// Properties
	public base: string;
	public blue: number;
	public brand: string;
	public code: string;
	public documentType: string;
	public green: number;
	public hexColour: string;
	public isActive: boolean = false;
	public name: string;
	public red: number;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
