export const whiteLabelConfig = {
	app: {
		title: 'Paint Projex',
		logoSideNav: '../assets/img/LogoSideNav_Primary_Blue.svg',
		watermarkImg: '../assets/img/Measuring_Tool_Watermark_blue.svg',
		logoWhite: '../assets/img/Logo_Primary_White.svg',
	},
	dashboard: {
		dashboardStrikeRate: {
			showBrushIcon: true,
		},
	},
	login: {
		showBrushIcon: true,
		logoPrimaryColor: '../assets/img/Logo_Primary_COL_blue.png',
		styleSetting: 'white-label',
	},
};
