import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
	imports: [MatSnackBarModule, MatDialogModule, MatSelectModule],
	exports: [MatSnackBarModule, MatDialogModule, MatSelectModule],
})
export class MaterialModule {}
