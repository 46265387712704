import { Text } from '../../../common/text.constants';

export class ItcModalConfig {
	public additionalButtonText: string = Text.help;
	public cancelButtonText: string = Text.cancel;
	public confirmButtonText: string = Text.confirm;
	public isConfirmDisabled: boolean = false;
	public showActiveToggle: boolean = false;
	public showAdditionalButton: boolean = false;
	public showCancelButton: boolean = false;
	public showCloseModalButton: boolean = false;
	public title?: string;

	constructor(model?: Partial<ItcModalConfig>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
