// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog,
dialog::backdrop {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

dialog[open] {
    animation: dialog-in 200ms ease-out forwards;
}

dialog.closing[open] {
    animation: dialog-out 200ms ease-in forwards;
}

dialog[open]::backdrop {
    animation: backdrop-in 200ms ease-out forwards;
}

dialog.closing[open]::backdrop {
    animation: backdrop-out 200ms ease-in forwards;
}


@keyframes backdrop-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes backdrop-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes dialog-in {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes dialog-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

@media (prefers-reduced-motion) {
    .drawer {
        dialog[open] {
            animation: none;
        }
        dialog[open]::backdrop {
            animation: none;
        }

        dialog.closing[open] {
            animation: none;
        }
        dialog.closing[open]::backdrop {
            animation: none;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/@mos-core/components/command-palette/command-palette.component.css"],"names":[],"mappings":"AAAA;;IAEI,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,8CAA8C;AAClD;;;AAGA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;QACV,sBAAsB;IAC1B;IACA;QACI,UAAU;QACV,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,mBAAmB;IACvB;IACA;QACI,UAAU;QACV,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;;QAEA;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;IACJ;AACJ","sourcesContent":["dialog,\ndialog::backdrop {\n    backdrop-filter: blur(5px);\n}\n\ndialog[open] {\n    animation: dialog-in 200ms ease-out forwards;\n}\n\ndialog.closing[open] {\n    animation: dialog-out 200ms ease-in forwards;\n}\n\ndialog[open]::backdrop {\n    animation: backdrop-in 200ms ease-out forwards;\n}\n\ndialog.closing[open]::backdrop {\n    animation: backdrop-out 200ms ease-in forwards;\n}\n\n\n@keyframes backdrop-in {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n@keyframes backdrop-out {\n    0% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n\n@keyframes dialog-in {\n    0% {\n        opacity: 0;\n        transform: scale(0.95);\n    }\n    100% {\n        opacity: 1;\n        transform: scale(1);\n    }\n}\n\n@keyframes dialog-out {\n    0% {\n        opacity: 1;\n        transform: scale(1);\n    }\n    100% {\n        opacity: 0;\n        transform: scale(0.95);\n    }\n}\n\n@media (prefers-reduced-motion) {\n    .drawer {\n        dialog[open] {\n            animation: none;\n        }\n        dialog[open]::backdrop {\n            animation: none;\n        }\n\n        dialog.closing[open] {\n            animation: none;\n        }\n        dialog.closing[open]::backdrop {\n            animation: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
