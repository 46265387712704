import { Component, Input, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Constants, ObjectHelper } from '../../../helpers';
import { Product } from '../../../interfaces';
import { ProjectService } from '../../../services/project.service';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-products-tab',
	templateUrl: './products-tab.component.html',
})
export class ProductsTabComponent implements OnInit {
	public _projectId: string;
	@Input() set projectId(value: string) {
		if (value) {
			this._projectId = value;
		}
	}

	@Input() totalCount: number;
	public products: Product[] = [];
	public headers: any[] = [
		Constants.LIBRARY_HEADER_TYPES.productTitle,
		Constants.LIBRARY_HEADER_TYPES.finish,
		Constants.LIBRARY_HEADER_TYPES.quality,
		Constants.LIBRARY_HEADER_TYPES.productCode,
		Constants.LIBRARY_HEADER_TYPES.pictureKey,
	];
	public loadPromise: Promise<Product[]>;
	public tabIconPath: string = Constants.SVG_ICON_PATHS.shoppingBasket;
	public selectedTab: boolean = false;

	constructor(
		private projectService: ProjectService,
		private s3Service: S3Service
	) {
		//
	}

	public ngOnInit() {}

	/**
	 * Download images for each product to display.
	 */
	private getProductImages(): void {
		for (const product of this.products) {
			if (product.pictureKey) {
				this.s3Service.getSignedUrl(product.pictureKey).subscribe(url => {
					product.imageUrl = url;
				});
			} else if (product.pictureUrl) {
				product.imageUrl = product.pictureUrl;
			}
		}
	}

	onSelectTab(tabEvent: TabDirective) {
		if (tabEvent.active && this._projectId) {
			this.loadPromise = this.projectService.postDistinctProducts(this._projectId).toPromise();
			this.loadPromise.then(items => {
				this.products = items;
				this.totalCount = this.products.length;
				this.setDisplayItems();
			});
			this.selectedTab = true;
		}
	}

	onDeselectTab() {
		this.selectedTab = false;
	}

	load() {
		this.loadPromise = this.projectService.postDistinctProducts(this._projectId).toPromise();
		this.loadPromise.then(items => {
			this.products = items;
			this.totalCount = this.products.length;

			this.setDisplayItems();
		});
	}

	/**
	 * Generates a list of items for display on the html from the raw itemList.
	 * Required to display a specific value for nested objects.
	 */
	public setDisplayItems(): void {
		if (!this.products || !this.products.length) {
			return;
		}
		for (const item of this.products) {
			// Add the proper display string for each header type.
			for (const header of this.headers) {
				if (header.pathFirstName && header.pathLastName) {
					item[header.key] =
						item[header.pathFirstName[0]] && item[header.pathLastName[0]]
							? `${item[header.pathFirstName[0]][header.pathFirstName[1]]} ${item[header.pathLastName[0]][header.pathLastName[1]]}`
							: '';
				} else if (header.isArray && header.path && header.path.length > 1) {
					item[header.key] = item[header.path[0]].map(mapItem => {
						return ObjectHelper.getFieldWithPath(mapItem, header.path[1]);
					});
				} else if (header.path && header.path.length > 0) {
					item[header.key] = item[header.path[0]] ? ObjectHelper.getFieldWithPath(item[header.path[0]], header.path[1]) : '';
				} else {
					item[header.key] = item[header.key];
				}
			}

			if (item.pictureKey) {
				this.s3Service.getSignedUrl(item.pictureKey).subscribe(url => {
					item.imageUrl = url;
				});
			} else if (item.pictureUrl) {
				item.imageUrl = item.pictureUrl;
			}
		}
	}
}
