import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../../../core/core.module';
import { ChargeOutRatesTableModule } from '../charge-out-rates-table/charge-out-rates-table.module';
import { CoatProductsTableModule } from '../coat-product-table/coat-products-table.module';
import { CoatingSystemFormComponent } from './coating-system-form.component';

@NgModule({
	declarations: [CoatingSystemFormComponent],
	imports: [ChargeOutRatesTableModule, CoatProductsTableModule, CoreModule, CommonModule],
	exports: [CoatingSystemFormComponent],
})
export class CoatingSystemFormModule {}
