import { UntypedFormGroup, Validators } from '@angular/forms';

import { Constants, ValidatorHelper } from '../helpers';
import { Charge, Colour, EmbeddedCoatingSystem, LabourCost, LabourRate, Product, ReferenceCode, Supplier } from '../interfaces';
import { BaseModel } from './base.model';
import { BoqDimensionsModel } from './boq-dimensions.model';

export class ChargeModel extends BaseModel implements Charge {
	// Properties
	public _id?: string;
	public areaDimensionsUnits: number;
	public boq: string;
	public chargeCategory: ReferenceCode;
	public chargeOutAmounts: {
		chargeCategory: string;
		ratePerUnit: number;
		margin: number;
		comment: string;
		internalComment: string;
	};
	public order: number;
	public chargeOutHeightFeeTotal: number;
	public chargeOutLabourCostPerUnit: number;
	public chargeOutLabourCostTotal: number;
	public chargeOutMaterialCostPerUnit: number;
	public chargeOutMaterialCostTotal: number;
	public chargeType: string;
	public coatingSystem: EmbeddedCoatingSystem;
	public colour: Colour;
	public comment: string;
	public description: string;
	public difficulty: number;
	public dimensions: BoqDimensionsModel;
	public discount: number;
	public discountPercentage: number;
	public distribution: [
		{
			product: string;
			totalRequiredLitre: number;
			totalLabourHour: number;
			distribution: {
				totalUnits: number;
				size: number;
				cost: number;
			};
			totalWasteCost: number;
		},
	];
	public embeddedSupplierRate: {
		supplier?: Supplier;
		name?: string;
		dailyHireRate?: number;
		weeklyHireRate?: number;
		transportCost?: number;
		duration?: number;
		thumbnailKey?: string;
		isDaily?: boolean;
	};
	public estimatedDimensions: BoqDimensionsModel;
	public fileKeys: string[];
	public grossTotalChargeOut: number;
	public heightRange: string;
	public isActive: boolean;
	public isAssociatedToMeasuringToolPage: boolean;
	public isDiscountPercentage: boolean;
	public isExportHidden: boolean;
	public isSelected: boolean;
	public environment: string;
	public labourCost?: LabourRate | LabourCost;
	public labourCostPerUnit: number;
	public labourCostTotal: number;
	public labourHoursTotal: number;
	public margin: number;
	public markUp: number;
	public materialCostPerUnit: number;
	public materialCostTotal: number;
	public overtimeRate: number;
	public product: Product;
	public project: string;
	public rate: number;
	public requiredPaintUnit: number;
	public subLocation1: string;
	public subLocation2: string;
	public subLocation3: string;
	public substrate: ReferenceCode;
	public totalCharge: number;
	public totalCost: number;
	public totalProfit: number;
	public totalCostPerUnit: number;
	public totalRate: number;
	public useEstimatedValues: boolean = false;
	public wasteCostPerUnit: number;
	public wasteCostTotal: number;
	public division: string;
	public variation?: string;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);

			if (json._id) {
				this.id = json._id;
			}
		}
		this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public location: string;

	public getFormData(): FormData {
		return undefined;
	}

	public getPostObject(): ChargeModel {
		return JSON.parse(JSON.stringify(this, ChargeModel.removeKeysReplacer));
	}
}

export function GetApplicationTargetsValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Difficulty',
			validators: [Validators.max(100), Validators.min(0), Validators.required],
		},
	]);
}

export function GetAdditionalChargeChargesValidation(isDiscountDisabled: boolean, charge, cost, discount, labourHoursTotal) {
	const formValidators = [
		{
			name: 'Charge',
			validators: [Validators.min(0), Validators.required],
			value: charge,
		},
		{
			name: 'Cost',
			validators: [Validators.min(0), Validators.required],
			value: cost,
		},
		{
			name: 'LabourHoursTotal',
			validators: [Validators.min(0), Validators.required],
			value: labourHoursTotal,
		},
	];
	if (isDiscountDisabled) {
		formValidators.push({
			name: 'Discount',
			validators: [Validators.min(0), Validators.required],
			value: discount,
		});
	}
	return ValidatorHelper.formBuilder(formValidators);
}

export function GetBoqChargesValidation(isDiscountDisabled: boolean, charge: ChargeModel) {
	const formValidators = [
		{
			name: 'Difficulty',
			validators: [Validators.required],
			value: charge.difficulty,
		},
		{
			name: 'RatePerUnit',
			validators: [Validators.min(0), Validators.required],
			value: charge.chargeOutAmounts.ratePerUnit,
		},
		{
			name: 'GP',
			validators: [Validators.required],
			value: charge.chargeOutAmounts.margin,
		},
		{
			name: 'Profit',
			validators: [Validators.required],
			value: charge.totalProfit,
		},
		{
			name: 'LabourHoursTotal',
			validators: [Validators.min(0), Validators.required],
			value: charge.labourHoursTotal,
		},
	];

	if (!charge.useEstimatedValues) {
		formValidators.push({
			name: 'Units',
			validators: [Validators.min(0), Validators.required],
			value: charge.dimensions.units,
		});
	}

	if (isDiscountDisabled) {
		formValidators.push({
			name: 'Discount',
			validators: [Validators.min(0), Validators.required],
			value: charge.discount,
		});
	}
	return ValidatorHelper.formBuilder(formValidators);
}

export function GetBoqLocationValidation(charge: ChargeModel) {
	return ValidatorHelper.formBuilder([
		{
			name: 'Environment',
			validators: [Validators.required],
			value: charge.environment,
		},
		{
			name: 'SubLocation1',
			validators: [Validators.maxLength(255), Validators.required],
			value: charge.subLocation1,
		},
		{
			name: 'SubLocation2',
			validators: [Validators.maxLength(255)],
			value: charge.subLocation2,
		},
		{
			name: 'SubLocation3',
			validators: [Validators.maxLength(255)],
			value: charge.subLocation3,
		},
	]);
}

export function GetAdditionalChargeLocationValidation(charge: ChargeModel) {
	return ValidatorHelper.formBuilder([
		{
			name: 'Environment',
			validators: [Validators.required],
			value: charge.environment,
		},
		{
			name: 'SubLocation1',
			validators: [Validators.maxLength(255), Validators.required],
			value: charge.subLocation1,
		},
	]);
}
