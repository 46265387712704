import { UntypedFormGroup } from '@angular/forms';

import { Supplier } from '../interfaces';
import { AddressDetailModel } from './address-detail.model';
import { BaseModel } from './base.model';
import { ContactDetailModel } from './contact-detail.model';
import { ContactModel } from './contact.model';
import { SupplierLocationModel } from './supplier-location.model';
import { SupplierProductModel } from './supplier-product.model';

export class SupplierModel extends BaseModel implements Supplier {
	public addressDetail: AddressDetailModel = new AddressDetailModel();
	public brands: string[];
	public contactDetail: ContactDetailModel = new ContactDetailModel();
	public documentType: string;
	public isActive: boolean;
	public locations: SupplierLocationModel[] = [];
	public mainContact: ContactModel = new ContactModel();
	public name: string;
	public products: SupplierProductModel[] = [];
	public supplierType: string;

	constructor(json?: Partial<SupplierModel>) {
		super();
		if (json) {
			this.deserialize(json);

			if (json.products && json.products.length > 0) {
				this.products = this.products.map(product => {
					return new SupplierProductModel(product, json.id);
				});
			}
			if (json.locations && json.locations.length > 0) {
				this.locations = this.locations.map(location => {
					return new SupplierLocationModel(location);
				});
			}
		}
		this.formGroup = this.createForm();
		this.ignoreList = ['products'];
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
