import { Injectable } from '@angular/core';
import { RouterEvent } from '@angular/router';
import { Intercom } from '@supy-io/ngx-intercom';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { STRIPE_CUSTOMER_URL } from '../helpers';
import { UserModel } from '../models';

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	public intercomStateChangeObservable: Observable<boolean>;
	private isIntercomActiveSubject: Subject<boolean> = new Subject<boolean>();

	constructor(private intercom: Intercom) {
		// Initialise state change observable
		this.emitIntercomStateChange(false);
	}

	/**
	 * Opens intercom with a prefilled message
	 * @param message
	 */
	public message(message: string): void {
		this.intercom.showNewMessage(message);
	}

	/**
	 * Open Intercom
	 */
	public openIntercom(user: UserModel): void {
		// open Intercom
		if (this.intercom) {
			this.intercom.boot({
				alignment: 'left',
				app_id: environment.intercomAppId,
				custom_launcher_selector: '#intercomLauncher',
				email: user.email,
				//entity: user.entity.email,
				//entityUrl: `${STRIPE_CUSTOMER_URL}/${user.entity.stripeId}`,
				hide_default_launcher: true,
				name: user.firstName + ' ' + user.lastName,
				user_hash: user.intercomUserHash,
				user_id: user.id,
			});

			// Emit intercom state change
			this.emitIntercomStateChange(true);
		}
	}

	/**
	 * Shut intercom down
	 */
	public shutIntercomDown(): void {
		if (this.intercom) {
			this.intercom.shutdown();

			// Emit intercom state change
			this.emitIntercomStateChange(false);
		}
	}

	/**
	 * Update Intercom
	 * @param event RouterEvent
	 */
	public updateIntercom(event: RouterEvent): void {
		if (this.intercom) {
			this.intercom.trackEvent('Viewed Page', {
				url: event.url,
			}); // track usage
			this.intercom.update({}); // refresh messenger data
		}
	}

	/**
	 * Emit Intercom active state change
	 */
	private emitIntercomStateChange(isActive: boolean): void {
		this.isIntercomActiveSubject.next(isActive);
		this.intercomStateChangeObservable = this.isIntercomActiveSubject.asObservable();
	}
}
