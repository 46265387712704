import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent } from '../../../helpers';
import { ApplicationMethod, EmbeddedCoatingSystem, GetCoatingSystemValidation, Product, ReferenceCode } from '../../../interfaces';
import { BrandModel, CoatingSystemModel } from '../../../models';
import { BrandService } from '../../../services/brand.service';
import { CoatingSystemService } from '../../../services/coating-system.service';
import { RefCodeService } from '../../../services/reference-code.service';
import { ChargeOutRatesTableComponent } from '../charge-out-rates-table/charge-out-rates-table.component';
import { CoatProductsTableComponent } from '../coat-product-table/coat-products-table.component';
import { CoatingSystemFormTextConst } from './common/coating-system-form.constants';

@Component({
	selector: 'app-coating-system-form',
	templateUrl: './coating-system-form.component.html',
})
export class CoatingSystemFormComponent implements OnChanges, OnInit {
	@ViewChild(ChargeOutRatesTableComponent, { static: false })
	public chargeOutRatesRef: ChargeOutRatesTableComponent;
	@ViewChild(CoatProductsTableComponent, { static: false })
	public coatProductsRef: CoatProductsTableComponent;

	@Input()
	public set coatingSystem(value: CoatingSystemModel) {
		if (value) {
			this._coatingSystem = value;
		} else {
			this._coatingSystem = new EmbeddedCoatingSystem();
		}
		this.setCoatingSystems();
		this.setIsTitleGenerated(this.hasGeneratedTitle());
	}

	public get coatingSystem() {
		return this._coatingSystem;
	}

	@Input()
	public set products(products: Product[]) {
		this._products = products;
		this.filterProductsByBrand();
	}

	public get products() {
		return this._products;
	}

	@Input()
	public isCreateForm: boolean = false;
	@Input()
	public isModalView: boolean = false;
	@Input()
	public substrates: ReferenceCode[] = [];

	@Output()
	public productChanged: EventEmitter<Product> = new EventEmitter<Product>();

	public applicationMethods: Array<ApplicationMethod> = Constants.APPLICATION_METHODS_ARRAY;
	public brands: BrandModel[] = [];
	public brandsObservable: Observable<BrandModel[]>;
	public coatingSystemsObservable: Observable<CoatingSystemModel[]>;
	public existingCoatingSystems: CoatingSystemModel[] = [];
	public filteredProducts: Product[] = [];
	public isTitleGenerated = false;
	public loadingRefPromise: Promise<any>;
	public prefillCoatingSystem: CoatingSystemModel;
	public productBrandFilterId: string;
	public textConstants: typeof CoatingSystemFormTextConst = CoatingSystemFormTextConst;
	public validators = GetCoatingSystemValidation();

	private _coatingSystem;
	private _products;

	constructor(
		public snack: SnackBarHelperComponent,
		private brandService: BrandService,
		private coatingSystemService: CoatingSystemService,
		private refCodeService: RefCodeService
	) {}

	public ngOnInit(): void {
		this.loadingRefPromise = this.refCodeService.getRefCodesWithTypesAndStatus(['substrate'], true).toPromise();
		this.loadingRefPromise.then(ref => {
			this.substrates = ref.substrate;
		});

		this.brandsObservable = this.brandService.postList({ isActive: true }).pipe(
			tap((brands: BrandModel[]) => {
				this.brands = brands;
			})
		);

		this.setIsTitleGenerated(this.hasGeneratedTitle());
	}

	public ngOnChanges() {
		this.prefillCoatingSystem = undefined;
	}

	public filterProductsByBrand(): void {
		if (this.productBrandFilterId) {
			this.filteredProducts = this.products.filter(product => product.brand === this.productBrandFilterId);
		} else {
			this.filteredProducts = this.products;
		}
	}

	/**
	 * Enables title generation based on the product and substrate. Displays a snack error if one could not be generated.
	 */
	public onClickGenerateTitle(): void {
		this.setIsTitleGenerated(!this.isTitleGenerated);
		if (this.isTitleGenerated) {
			const title = this.getGeneratedTitle();
			if (title) {
				this.coatingSystem.title = title;
			} else {
				this.snack.snackWarning('This requires a product and substrate be selected.');
			}
		}
	}

	/**
	 * Generates a new coating system title
	 */
	public onChangeProductOrSubstrate() {
		if (this.isTitleGenerated) {
			const title = this.getGeneratedTitle();
			if (title) {
				this.coatingSystem.title = title;
			}
		}
		this.setCoatingSystems();
	}

	public prefillFromExistingCoatingSystem(): void {
		if (this.prefillCoatingSystem) {
			this.coatingSystem.coatProducts = this.prefillCoatingSystem.coatProducts;
			this.coatingSystem.title = this.prefillCoatingSystem.title;
			// Need to trigger the various setters tied to coating system
			this.coatingSystem = new CoatingSystemModel(this.coatingSystem);
		}
	}

	public productBrandFilterChanged(): void {
		this.coatingSystem.product = undefined;
		this.filterProductsByBrand();
	}

	public setCoatingSystems(): void {
		if (this.coatingSystem.product && this.coatingSystem.substrate) {
			const query = {
				product: this.coatingSystem.product.id,
				substrate: this.coatingSystem.substrate.id,
			};
			this.coatingSystemsObservable = this.coatingSystemService.postList(query).pipe(
				tap(coatingSystems => {
					this.existingCoatingSystems = coatingSystems;
				})
			);
		} else {
			this.existingCoatingSystems = [];
		}
	}

	/**
	 * Changes the value of the coating system's product.
	 */
	public setMainProduct(newProduct: Product): void {
		this.coatingSystem.product = newProduct;
		this.productChanged.emit(this.coatingSystem.product);
	}

	/**
	 * Returns true if this coating system has a generated title.
	 */
	private hasGeneratedTitle() {
		if (this.coatingSystem && this.coatingSystem.product && this.coatingSystem.substrate) {
			return this.coatingSystem.title === `${this.coatingSystem.product.title} - ${this.coatingSystem.substrate.title}`;
		}
		return false;
	}

	/**
	 * Returns a title based on the product and substrate.
	 */
	private getGeneratedTitle() {
		if (this.coatingSystem && this.coatingSystem.product && this.coatingSystem.substrate) {
			return `${this.coatingSystem.product.title} - ${this.coatingSystem.substrate.title}`;
		}
		return '';
	}

	/**
	 * Sets isTitleGenerated and disables the input
	 * @param active
	 */
	private setIsTitleGenerated(active: boolean) {
		this.isTitleGenerated = active;
		if (this.validators && this.validators.Title) {
			active ? this.validators.Title.disable() : this.validators.Title.enable();
		}
	}
}
