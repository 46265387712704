import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NavigationWarningService } from './navigation-warning.service';

@Injectable()
export class NavigationWarningGuard {
	constructor(private navigationWarningService: NavigationWarningService) {}

	public canDeactivate(): Observable<boolean> {
		return this.navigationWarningService.showModalRequest();
	}
}
