import { UntypedFormGroup } from '@angular/forms';

import { AddressDetail, ContactDetail, Marketing } from '../interfaces';
import { BaseModel } from './base.model';

export class MarketingModel extends BaseModel implements Marketing {
	// Properties
	public addressDetail: AddressDetail;
	public contactDetail: ContactDetail;
	public documentType: string;
	public isActive: boolean;
	public name: string;
	public organizer: string;
	public cost: number;
	public marketingType: string;
	public notes: string;
	public startDate: Date;
	public endDate: Date;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		// this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
