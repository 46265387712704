/**
 * Created by Matt Shipman from In The Code on 15/3/18.
 */

export class ArrayHelper {
	/**
	 * Returns an array of distinct items.
	 * @param items Array of items
	 * @param uniquePredicate Function to apply to each item in the array. Should return a unique string for this item.
	 * If items is an array of objects, you will need to provide this for accurate results. E.g. (item) => item.id (where id is unique).
	 * @param keepFullItem Whether the results array will contain the full item or just the unique string for the item.
	 * @returns {Array<any>}
	 */
	public static getDistinctArray(items: Array<any>, uniquePredicate: (item) => string = item => item, keepFullItem: boolean = true): Array<any> {
		const results = [];
		const map = {};

		items.forEach(item => {
			// Get a unique string to map to this item
			const uniqueString = uniquePredicate(item);
			// If the map doesn't contain this item, it is unique
			if (!map[uniqueString]) {
				map[uniqueString] = true;
				// Either store the whole item or the unique string in the results array
				results.push(keepFullItem ? item : uniqueString);
			}
		});

		return results;
	}

	/**
	 * Helper to find an object in an array with the given id
	 * @param arr
	 * @param id
	 * @returns {any}
	 */
	public static findObjectInArrayWithId<T>(arr: Array<T & { id?: string }>, id: string): T {
		return arr.find(x => x.id === id);
	}

	/**
	 * Grouping object by sub/properties
	 * @param items
	 * @param property
	 * @returns {{keyObj: ObjectConstructor; boqItems: any[]}}
	 */
	public static objectGroupByProperty(items, property?, secondProperty?, thirdProperty?, forthProperty?) {
		function groupBySubCategory() {
			const result = {};
			for (const item of items) {
				if (item.isActive) {
					const pathItem1 = property.split('.');
					const pathItem2 = secondProperty.split('.');

					const value1 = item[pathItem1[0]];
					const value2 = item[pathItem2[0]];
					let thirdValue = undefined;
					let forthValue = undefined;

					if (secondProperty && thirdProperty) {
						thirdValue = item[thirdProperty];
					}
					if (secondProperty && forthProperty) {
						forthValue = item[forthProperty];
					}

					let key;

					if (value1 !== 'undefined' && value1 !== undefined && value2 !== 'undefined' && value2 !== undefined && value1 !== null && value2 !== null) {
						if (pathItem1.length > 1 && pathItem2.length > 1) {
							const keyItem1 = value1[pathItem1[1]];
							const keyItem2 = value2[pathItem2[1]];
							key = `${keyItem1}`;
							if (keyItem2) key += ` - ${keyItem2}`;
						} else if (pathItem1.length > 1) {
							const keyItem1 = value1[pathItem1[1]];
							key = `${keyItem1}`;
							if (value2) key += ` - ${value2}`;
						} else if (pathItem2.length > 1) {
							const keyItem2 = value2[pathItem2[1]];
							key = `${value1}`;
							if (keyItem2) key += ` - ${keyItem2}`;
						} else {
							key = `${value1}`;
							if (value2) key += ` - ${value2}`;
						}

						if (thirdValue) {
							key += ` - ${thirdValue}`;
						} else if (forthValue) {
							key += ` - ${forthValue}`;
						}

						if (result[key]) {
							result[key].push(item);
						} else {
							result[key] = [item];
						}
					}
				}
			}
			return result;
		}

		function groupByCategory() {
			const result = {};
			for (const item of items) {
				const pathItem = property.split('.');

				const value = item[pathItem[0]];
				let keyItem;

				if (value !== 'undefined' && value !== undefined && value !== null) {
					if (pathItem.length > 1) {
						keyItem = value[pathItem[1]];
					} else {
						keyItem = value;
					}

					if (result[keyItem]) {
						result[keyItem].push(item);
					} else {
						result[keyItem] = [item];
					}
				}
			}
			return result;
		}

		function groupByNoCategory() {
			const result = {};
			result['Total Items'] = [];
			for (const item of items) {
				result['Total Items'].push(item);
			}
			return result;
		}

		let groupByResult = {};

		if (secondProperty) {
			groupByResult = groupBySubCategory();
		} else if (property) {
			groupByResult = groupByCategory();
		} else {
			groupByResult = groupByNoCategory();
		}
		return groupByResult;
	}

	/**
	 * Tree View has to be an array of object keys and children as values
	 * @param items
	 * @param property
	 * @returns {any[]}
	 */
	public static treeViewObjectGroupByProperty(items, property?, activeItem?) {
		const groupByResult = [];

		if (items.length > 0) {
			const groupByProperty = this.objectGroupByProperty(items, property);

			for (const item in groupByProperty) {
				groupByResult.push({ isFolder: true, name: item, children: groupByProperty[item], isOpen: item === activeItem });
			}
		}
		groupByResult.sort((a, b) => (a.name > b.name ? 1 : -1)); //Sort by name
		return groupByResult;
	}
}
