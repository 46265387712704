import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Constants } from '../../../helpers';
import { ProjectTime } from '../../../interfaces';

@Component({
	selector: 'app-project-time-view-dialog',
	templateUrl: './project-time-view-dialog.component.html',
})
export class ProjectTimeViewDialogComponent {
	@Input() public readonly projectTime: ProjectTime;
	@Input() public readonly currentUserId: string;
	@Input() public readonly isEditDisabled: boolean;
	@Output() public remove: EventEmitter<ProjectTime> = new EventEmitter<ProjectTime>();
	public readonly dateFormats = Constants.DATE_FORMATS;

	constructor() {}

	public onRemoveClick() {
		this.remove.emit(this.projectTime);
	}
}
