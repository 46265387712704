import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../../../core/core.module';
import { ChargeOutRatesTableComponent } from './charge-out-rates-table.component';

@NgModule({
	declarations: [ChargeOutRatesTableComponent],
	imports: [CoreModule, CommonModule],
	exports: [ChargeOutRatesTableComponent],
})
export class ChargeOutRatesTableModule {}
