import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../helpers';

@Injectable()
export class ImportDataService {
	constructor(private http: HttpClient) {}

	/**
	 * Imports data from the given csv file.
	 * @param file
	 * @param importDataType
	 */
	public importData(file, importDataType: string) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('importDataType', importDataType);

		return this.http.post<{
			importStatus: boolean;
			importMessage: string;
			importErrors: string[];
			importResult: string;
		}>(`${Constants.BASE_API_URL}/import-data/`, formData);
	}
}
