import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommunicationService } from './common/communication.service';

@Component({
	selector: 'paint-communication',
	templateUrl: './communication.component.html',
	styleUrls: ['./communication.component.scss'],
})
export class CommunicationComponent implements OnInit {
	public communicationObservable: Observable<String[]>;
	public currentSlide: number = 0;
	public tooltipMessages: String[] = [];

	constructor(private communicationService: CommunicationService) {}

	public ngOnInit(): void {
		this.communicationObservable = this.communicationService.paintCommunication.pipe(
			tap((messages: string[]) => {
				return this.cleanCommunicationMessages(messages);
			})
		);
	}

	public slideChanged(slide: number): void {
		this.currentSlide = slide;
	}

	private cleanCommunicationMessages(messages: string[]) {
		return (this.tooltipMessages = messages.map(message => {
			// Extract the message string from the rich text HTML string
			return message.replace(/(<([^>]+)>)/gi, '');
		}));
	}
}
