import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { Comment, GetProjectCommentValidation } from '../../../interfaces';
import { CommentService } from '../../../services/comment.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
	selector: 'app-add-edit-comment-dialog',
	templateUrl: './add-edit-comment-dialog.component.html',
	styleUrls: ['./add-edit-comment-dialog.scss'],
})
export class AddEditCommentDialogComponent {
	@Input()
	public set comment(comment: Comment) {
		this._comment = comment;
		if (comment) {
			this.isInternal = comment.isInternal;
			this.newCommentMessage = comment.message;
		} else {
			this.isInternal = true;
		}
		this.validators = GetProjectCommentValidation();
	}
	public get comment(): Comment {
		return this._comment;
	}

	public addCommentPromise: Promise<Comment>;
	public isInternal: boolean;
	public newCommentMessage: string;
	public validators;

	private _comment: Comment;

	constructor(
		private snack: SnackBarHelperComponent,
		private localStorageService: LocalStorageService,
		private commentService: CommentService
	) {}

	/**
	 * Will add a new comment in the project to the database
	 * @param projectId: string
	 */
	public addComment(projectId: string): Observable<Comment> {
		const userId = this.localStorageService.getUserId();
		const newComment = {
			message: this.newCommentMessage,
			sender: userId,
			isInternal: this.isInternal,
			updatedAt: new Date(),
			project: projectId,
		};

		const errors = ValidatorHelper.checkErrors(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}

		return this.commentService.addComment(newComment, projectId);
	}

	/**
	 * Will edit comment and save the project to the database
	 * @param projectId
	 */
	public editComment(projectId: string): Observable<Comment> {
		if (this.comment) {
			const userId = this.localStorageService.getUserId();
			const commentUpdate = {
				project: projectId,
				company: null,
				sender: userId,
				id: this.comment.id,
				message: this.newCommentMessage,
				updatedAt: new Date(),
				isInternal: this.isInternal,
			};

			const errors = ValidatorHelper.checkErrors(this.validators);
			if (errors) {
				this.snack.snackError(errors);
				return;
			}

			return this.commentService.editComment(commentUpdate, projectId);
		}
	}

	public resetForm(): void {
		this.newCommentMessage = '';
		this.validators = GetProjectCommentValidation();
		this.isInternal = true;
	}

	/**
	 *
	 * @param isInternal
	 */
	public setInternal(isInternal: boolean): void {
		this.isInternal = isInternal;
	}
}
