import { Feature } from '../helpers/feature';
import { FeatureFlagService } from '../services/feature-flag.service';
import { inject } from '@angular/core';

export const featureFlagGuard = (feature: Feature) => {
	return () => {
		const features = inject(FeatureFlagService);
		return features.has(feature);
	};
};
