import { Component, Input, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

import { Constants } from '../../../helpers';
import { ColourModel } from '../../../models';
import { ProjectService } from '../../../services/project.service';

@Component({
	selector: 'app-colours-tab',
	templateUrl: './colours-tab.component.html',
})
export class ColoursTabComponent implements OnInit {
	public _projectId: string;
	@Input() set projectId(value: string) {
		if (value) {
			this._projectId = value;
		}
	}

	public colours: ColourModel[] = [];
	public headers = Constants.LIBRARY_TYPES.projectColour.headers;
	public loadPromise: Promise<ColourModel[]>;
	@Input() totalCount: number;
	public tabIconPath: string = Constants.SVG_ICON_PATHS.brush;
	public selectedTab: boolean = true;

	constructor(private projectService: ProjectService) {
		//
	}

	public ngOnInit(): void {}

	onSelectTab(tabEvent: TabDirective) {
		if (tabEvent.active && this._projectId) {
			this.loadPromise = this.projectService.postDistinctColours(this._projectId).toPromise();
			this.loadPromise.then(items => {
				this.colours = items;
				this.totalCount = this.colours.length;
			});
			this.selectedTab = true;
		}
	}

	onDeselectTab() {
		this.selectedTab = false;
	}

	load() {
		this.loadPromise = this.projectService.postDistinctColours(this._projectId).toPromise();
		this.loadPromise.then(items => {
			this.colours = items;
			this.totalCount = this.colours.length;
		});
	}
}
