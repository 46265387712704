// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-text {
  white-space: pre;
}

.task-view-modal {
  min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/task-view-dialog/task-view-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,gBAAA;AACD","sourcesContent":[".action-text {\n\twhite-space: pre;\n}\n\n.task-view-modal {\n\tmin-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
