import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';

// Interfaces

export interface Region {
	id: string;
	name: string;
}

// Responses

export interface RegionResponse {
	status: boolean;
	region?: Region;
}

// Validators

export function GetRegionValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
	]);
}
