import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { IconButtonComponent } from '../icon-button.component';

@NgModule({
	declarations: [IconButtonComponent],
	exports: [IconButtonComponent],
	imports: [AngularSvgIconModule, CommonModule, FontAwesomeModule],
	providers: [],
})
export class IconButtonModule {}
