import { FormGroup } from '@angular/forms';

import { UserGroup } from '../interfaces';
import { BaseModel } from './base.model';
import { UserModel } from './user.model';

export class UserGroupModel extends BaseModel implements UserGroup {
	// Properties
	isActive: boolean;
	name: string;
	teamLeader: UserModel;
	users: Array<UserModel>;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		this.formGroup = this.createForm();
	}

	public createForm(): FormGroup {
		return new FormGroup({});
	}

	getFormData(): FormData {
		return undefined;
	}
}
