import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SelectedProjectService {
	private selectedProjectKey = 'selectedProject';
	private selectedProjectSubject = new BehaviorSubject<any>(null);

	constructor() {
		this.loadSelectedProject();
	}

	private loadSelectedProject() {
		const storedProject = localStorage.getItem(this.selectedProjectKey);
		if (storedProject) {
			this.selectedProjectSubject.next(JSON.parse(storedProject));
		}
	}

	public setSelectedProject(project: any) {
		localStorage.setItem(this.selectedProjectKey, JSON.stringify(project));
		this.selectedProjectSubject.next(project);
	}

	public getSelectedProject() {
		return this.selectedProjectSubject.asObservable();
	}
}