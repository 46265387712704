import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(service: ShepherdService): Step.StepOptions[] {
	const handleNext = () => {
		service.next();
	};

	const handleBack = () => {
		service.back();
	};

	function handleCompleteTour() {
		service.complete();
	}

	function handleCancel() {
		service.cancel();
	}


	return [
		{
			id: 'step3',
			title: 'Is this company a parent company',
			text: ['If this company is a parent company, select "Parent Company" and then select which company it is the parent to at the bottom of the slideover.<br><br> Click \'Next\' to proceed.'],
			highlightClass: 'highlight',
			attachTo: {
				element: '.checkboxSelect',
				on: 'left',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 300);
				});
			},
			when: {
				show: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.addEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.addEventListener('click', handleCancel);
					}
				},
				hide: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
				cancel: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
			},
			buttons: [
				{
					text: 'Next',
					action: function() {
						handleNext();
					},
				},
			],
		},
		{
			id: 'step4',
			title: 'Complete the company\'s details',
			text: ['Complete the necessary fields for the company\'s details. <br><br><span class="tw-text-sm">This is where you will enter the company details such as the trading name, head office contact details, ABN, etc. (this is the company\'s general details).</span><br><br> Click \'Next\' to proceed.'],
			highlightClass: 'highlight',
			attachTo: {
				element: '.clientDetails',
				on: 'left',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 300);
				});
			},
			when: {
				show: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.addEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.addEventListener('click', handleCancel);
					}
				},
				hide: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
				cancel: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
			},
			buttons: [
				{
					text: 'Next',
					action: function() {
						handleNext();
					},
				},
			],
		},
		{
			id: 'step5',
			title: 'Complete the company\'s location',
			text: ['This is where you will enter the company\'s location (generally the head office location). <br><br>Click \'Next\' to proceed.'],
			highlightClass: 'highlight',
			attachTo: {
				element: '.clientLocation',
				on: 'left',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 300);
				});
			},
			when: {
				show: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.addEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.addEventListener('click', handleCancel);
					}
				},
				hide: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
				cancel: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
			},
			buttons: [
				{
					text: 'Back',
					classes: 'back-button',
					action: function() {
						handleBack();
					},
				},
				{
					text: 'Next',
					action: function() {
						handleNext();
					},
				},
			],
		},

		//This is commented out until we can determine a good way to return steps conditionally
		// - We only want to show these steps if the user selected the 'Parent Company' checkbox

		// {
		// 	id: 'step6',
		// 	title: 'Click \'Add Contact\'',
		// 	text: ['This is where you will enter the details of the contact people that you communicate with at your newly entered Company. <br><br> Click \'Next\' to proceed.'],
		// 	highlightClass: 'highlight',
		// 	attachTo: {
		// 		element: '.normal',
		// 		on: 'left',
		// 	},
		// 	modalOverlayOpeningRadius: 5,
		// 	modalOverlayOpeningPadding: 1,
		// 	floatingUIOptions: {
		// 		middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
		// 	},
		// 	scrollTo: {
		// 		block: 'center',
		// 		behavior: 'smooth',
		// 	},
		// 	beforeShowPromise: function() {
		// 		return new Promise(function(resolve) {
		// 			setTimeout(function() {
		// 				window.scrollTo(0, 0);
		// 				resolve(null);
		// 			}, 300);
		// 		});
		// 	},
		// 	when: {
		// 		show: () => {
		// 			const addContactButton = document.querySelector(`.normal`);
		// 			if (addContactButton) {
		// 				addContactButton.addEventListener('click', handleNext);
		// 			}
		// 			const closeButton = document.querySelector(`.normalSlideButton`);
		// 			if (closeButton) {
		// 				closeButton.addEventListener('click', handleCancel);
		// 			}
		// 			const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
		// 			if (xButton) {
		// 				xButton.addEventListener('click', handleCancel);
		// 			}
		// 		},
		// 		hide: () => {
		// 			const addContactButton = document.querySelector(`.normal`);
		// 			if (addContactButton) {
		// 				addContactButton.removeEventListener('click', handleNext);
		// 			}
		// 			const closeButton = document.querySelector(`.normalSlideButton`);
		// 			if (closeButton) {
		// 				closeButton.removeEventListener('click', handleCancel);
		// 			}
		// 			const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
		// 			if (xButton) {
		// 				xButton.removeEventListener('click', handleCancel);
		// 			}
		// 		},
		// 		cancel: () => {
		// 			const addContactButton = document.querySelector(`.normal`);
		// 			if (addContactButton) {
		// 				addContactButton.removeEventListener('click', handleNext);
		// 			}
		// 			const closeButton = document.querySelector(`.normalSlideButton`);
		// 			if (closeButton) {
		// 				closeButton.removeEventListener('click', handleCancel);
		// 			}
		// 			const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
		// 			if (xButton) {
		// 				xButton.removeEventListener('click', handleCancel);
		// 			}
		// 		},
		// 	},
		// 	buttons: [
		// 		{
		// 			text: 'Back',
		// 			classes: 'back-button',
		// 			action: function() {
		// 				handleBack();
		// 			},
		// 		},
		// 		{
		// 			text: 'Next',
		// 			action: function() {
		// 				const addContactButton: HTMLElement = document.querySelector(`.normal`);
		// 				addContactButton.click();
		// 			},
		// 		},
		// 	],
		// },
		// {
		// 	id: 'step7',
		// 	title: 'Add Company Contact',
		// 	text: ['Enter the contact details of the person you are communicating with at your newly created company. You can also assign a Relationship Holder if someone within your company manages the relationship with this contact person.'],
		// 	highlightClass: '',
		// 	attachTo: {
		// 		element: '.dialogContent .contactDialog',
		// 		on: 'left',
		// 	},
		// 	modalOverlayOpeningRadius: 5,
		// 	modalOverlayOpeningPadding: 1,
		// 	floatingUIOptions: {
		// 		middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
		// 	},
		// 	scrollTo: {
		// 		block: 'center',
		// 		behavior: 'smooth',
		// 	},
		// 	beforeShowPromise: function() {
		// 		return new Promise(function(resolve) {
		// 			setTimeout(function() {
		// 				window.scrollTo(0, 0);
		// 				resolve(null);
		// 			}, 500);
		// 		});
		// 	},
		// 	when: {
		// 		show: () => {
		// 			const dialog = document.querySelector(`.dialogContent .contactDialog`);
		// 			if (dialog) {
		// 				// Add CSS rule to remove backdrop
		// 				const styleSheet = document.styleSheets[0];
		// 				styleSheet.insertRule(`
        //             dialog::backdrop {
        //                 background: none !important;
        //                 z-index: auto !important;
        //             }`, styleSheet.cssRules.length);
		// 			}
		// 			const closeButton = document.querySelector(`.dialogContent .normalDialogButton`);
		// 			if (closeButton) {
		// 				closeButton.addEventListener('click', handleBack);
		// 			}
		// 			const dialogContent = document.querySelector('.dialogContent .dialogContent');
		// 			document.addEventListener('click', function(event) {
		// 				if (dialogContent && !dialogContent.contains(event.target as Node)) {
		// 					handleBack();
		// 				}
		// 			}, );
		// 		},
		// 		hide: () => {
		// 			const closeButton = document.querySelector(`.dialogContent .normalDialogButton`);
		// 			if (closeButton) {
		// 				closeButton.removeEventListener('click', handleBack);
		// 			}
		// 			// Remove CSS rule to restore backdrop
		// 			const styleSheet = document.styleSheets[0];
		// 			for (let i = 0; i < styleSheet.cssRules.length; i++) {
		// 				if (styleSheet.cssRules[i].cssText.includes('dialog::backdrop')) {
		// 					styleSheet.deleteRule(i);
		// 					break;
		// 				}
		// 			}
		// 		},
		// 		cancel: () => {
		// 			const closeButton = document.querySelector(`.dialogContent .normalDialogButton`);
		// 			if (closeButton) {
		// 				closeButton.removeEventListener('click', handleBack);
		// 			}
		// 			// Remove CSS rule to restore backdrop
		// 			const styleSheet = document.styleSheets[0];
		// 			for (let i = 0; i < styleSheet.cssRules.length; i++) {
		// 				if (styleSheet.cssRules[i].cssText.includes('dialog::backdrop')) {
		// 					styleSheet.deleteRule(i);
		// 					break;
		// 				}
		// 			}
		// 		},
		// 	},
		// },
		{
			id: 'step8',
			title: 'Review the company details',
			text: ['Review the company details -  <br>If you didn\'t select \'Parent Company\', make sure to add a company contact person. <br>If you did select \'Parent Company\', make sure to select the children companies. <br><br>Once you\'ve finished, click \'Save\' to save the company\'s details and finish your tour.'],
			highlightClass: 'highlight',
			attachTo: {
				element: '[data-cy="slideOverOpen"]',
				on: 'left',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 300);
				});
			},
			when: {
				show: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.addEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.addEventListener('click', handleCancel);
					}
				},
				hide: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
				cancel: () => {
					const closeButton = document.querySelector(`.normalSlideButton`);
					if (closeButton) {
						closeButton.removeEventListener('click', handleCancel);
					}
					const xButton: HTMLElement = document.querySelector('[ng-reflect-message="Close"]');
					if (xButton) {
						xButton.removeEventListener('click', handleCancel);
					}
				},
			},
			buttons: [
				{
					text: 'Back',
					classes: 'back-button',
					action: function () {
						handleBack();
					},
				},
				{
					text: 'Save',
					action: function () {
						const saveButton: HTMLElement = document.querySelector('.primarySlideButton');
						saveButton.click();
					},
				},
			],
		},
	];
}
