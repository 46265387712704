import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { ExportService } from '../../../services/export.service';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-doc-viewer',
	templateUrl: './doc-viewer.component.html',
})
export class DocViewerComponent implements OnInit, OnDestroy {
	constructor(
		private s3Service: S3Service,
		private exportService: ExportService
	) {}

	@Input() private set url(url: string) {
		if (!url) {
			return;
		}
		this.finishedLoading();
		this._url = url;
		this.safeUrl = this.exportService.getPreviewSafeGoogleEmbedUrl(url);
		this.loadingIframePromise = this.getLoadingIframePromise();
	}

	@Input() private set key(key: string) {
		if (!key) {
			return;
		}
		this.finishedLoading();
		this.loadingFileUrlPromise = this.s3Service.getSignedUrl(key).toPromise();
		this.loadingFileUrlPromise.then(url => (this._url = url));
		this.loadingFileUrlPromise.then(url => (this.safeUrl = this.exportService.getPreviewSafeGoogleEmbedUrl(url)));
		this.loadingIframePromise = this.getLoadingIframePromise();
	}

	@Input() public loadingKeyPromise: Promise<string> | Subscription;
	public safeUrl: SafeResourceUrl;
	public loadingFileUrlPromise: Promise<string>;
	public loadingIframePromise: Promise<{}>;

	private timeoutId: any;
	private _url: string;
	// How many milliseconds between reloading the iframe (this value + timeoutIncrement)
	private timeoutInterval = 3000;
	// Each time the reload method is called, the time until the next reload will increase by this amount
	private timeoutIncrement = 1000;
	public finishedLoading = () => {};

	public ngOnInit() {}

	public ngOnDestroy(): void {
		// Resolve the iframe finished loading promise
		if (this.finishedLoading) {
			this.finishedLoading();
		}
	}

	/**
	 * On load method called when the iframe is finished loading. Resolves the loadingIframePromise()
	 * @param event
	 */
	public onLoad(event) {
		if (event.target.src !== '') {
			this.finishedLoading();
		}
	}

	/**
	 * Creates a new promise with a deferred resolve to call when the iframe is finished loading.
	 */
	public getLoadingIframePromise() {
		this.timeoutId = setTimeout(this.reloadIframe, (this.timeoutInterval += this.timeoutIncrement));
		const finishedLoadingPromise = new Promise((resolve: any) => {
			this.finishedLoading = resolve;
		});

		// Stop trying to reload the iframe after we have already finished loading
		finishedLoadingPromise.then(() => clearTimeout(this.timeoutId));

		return finishedLoadingPromise;
	}

	/**
	 * Sets the safeUrl which triggers the iframe to reload. Will continue to call itself at longer intervals until
	 * clearTimeout is called (when the iframe finishes loading).
	 */
	private reloadIframe = () => {
		this.safeUrl = this.exportService.getPreviewSafeGoogleEmbedUrl(this._url);
		this.timeoutId = setTimeout(this.reloadIframe, (this.timeoutInterval += this.timeoutIncrement));
	};
}
