import { NgModule } from '@angular/core';
import { DatePickerComponent } from './date-picker.component';
import { PopoverComponent } from '../popover/popover.component';
import { CommonModule, NgClass } from '@angular/common';
import { Calendar, LucideAngularModule, ChevronLeft, ChevronRight } from 'lucide-angular';

@NgModule({
	declarations: [DatePickerComponent],
	imports: [
		CommonModule,
		NgClass,
		PopoverComponent,
		LucideAngularModule.pick({Calendar, ChevronLeft, ChevronRight}),

	],
	exports: [
		DatePickerComponent,
	],
})
export class DatePickerModule {}