import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(productTourService: ProductTourService, service: ShepherdService): Step.StepOptions[] {
	let userNavItem = 2;

	const navItems = {
		2: 'Clients',
		5: 'Schedule',
	}

	if (productTourService.getCurrentTour() !== 'Clients') {
		userNavItem = 5;
	}

	const selector = `.desktop .sidenav-${userNavItem}`;

	function handler() {
		const scheduleElement = document.querySelector(`.desktop .sidenav-${userNavItem}`);
		if (scheduleElement) {
			scheduleElement.removeEventListener('click', handler);
		}
		service.next();
	}

	return [
		{
			id: 'step1',
			title: `Navigate to ${navItems[userNavItem]}`,
			text: [`Click on ${navItems[userNavItem]}`],
			attachTo: {
				element: `.desktop .sidenav-${navItems[userNavItem]}`,
				on: 'right',
			},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 1,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			when: {
				show: () => {
					const scheduleElement = document.querySelector(`.desktop .sidenav-${navItems[userNavItem]}`);
					if (scheduleElement) {
						scheduleElement.addEventListener('click', handler);
					}
				},
				cancel: () => {
					const scheduleElement = document.querySelector(`.desktop .sidenav-${navItems[userNavItem]}`);
					if (scheduleElement) {
						scheduleElement.removeEventListener('click', handler);
					}
				},
			},
		},
	];
}