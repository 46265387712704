export class PageJumpHelper {
	/**
	 * helper that jumps the page to a specific id in the html
	 * @param {string} id
	 */
	public static pageJump(id: string) {
		window.setTimeout(() => {
			document.getElementById(id) ? window.scrollTo(0, document.getElementById(id).offsetTop - 64) : null;
		}, 1700); //wait 1.7s for the DOM to update
	}
}
