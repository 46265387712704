import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { projectStatuses } from '../../../components/project/common/project.constants';
import { Constants } from '../../../helpers';
import { UserModel } from '../../../models';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { S3Service } from '../../../services/s3.service';
import { HeaderMappingItems, HeaderText } from './common/header.constants';
import { HeaderMappingItem, ProjectHeaderTitle } from './common/header.interfaces';
import { HeaderService } from './common/header.service';

@Component({
	selector: 'paint-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	public editedBy: string = '';
	public expandedObservable: Observable<boolean>;
	public hasRouteNav: boolean = false;
	public headerDisplayValue: string = '';
	public headerText: typeof HeaderText = HeaderText;
	public hideHeader: boolean = false;
	public isEntityActive: boolean = true;
	public isExpanded: boolean;
	public lastSaved: Date = new Date();
	public monitorRouteChanges: Observable<NavigationEnd>;
	public projectStatusesDetails: typeof projectStatuses = projectStatuses;
	public showTitle: boolean = true;
	public showTitleMeasuringTool: boolean = false;
	public status: string = '';
	public titleChange: Observable<ProjectHeaderTitle>;
	public userPictureUrl: Observable<string>;
	public currentUser: UserModel;

	private pagesWithRouteNav: string[] = [Constants.ROUTE_LINKS.project, Constants.ROUTE_LINKS.libraries];

	constructor(
		private authService: AuthService,
		private router: Router,
		private headerService: HeaderService,
		private s3Service: S3Service,
		private localStorageService: LocalStorageService
	) {}

	public ngOnInit(): void {
		this.isEntityActive = this.authService.isEntityActive();

		this.monitorRouteChanges = this.router.events.pipe(
			filter((event: Event) => {
				return event instanceof NavigationEnd;
			}),
			tap((event: NavigationEnd) => {
				return this.assignHeaderValues(event.urlAfterRedirects);
			})
		);
		this.titleChange = this.headerService.titleChange.pipe(
			tap((result: ProjectHeaderTitle) => {
				this.headerDisplayValue = `${result.reference} - ${result.title}`;
				this.status = result.status;
				this.editedBy = result.editedBy;
				this.lastSaved = result.lastSaved;
			})
		);
		this.expandedObservable = this.headerService.expandedChange.pipe(
			tap(expandedChange => {
				this.isExpanded = expandedChange;
			})
		);
		this.currentUser = this.localStorageService.getUserDetails();
		if (this.currentUser.displayPictureKey) {
			this.userPictureUrl = this.s3Service.getSignedUrl(this.currentUser.displayPictureKey);
		}
	}

	/**
	 * Assign header values for the navigated page
	 * @param pageUrl {String} The url of the navigated page
	 */
	private assignHeaderValues(pageUrl: string): void {
		const urlArray: string[] = pageUrl.split('/').slice(1);

		setTimeout(() => {
			this.setCustomMargin(urlArray);
		});
		if (!this.headerService.isPageTitleDefined) {
			this.setHeaderTitle(urlArray);
		} else {
			this.setShowTitle(urlArray);
		}
	}
	private setCustomMargin(pageUrl: string[]): void {
		this.hideHeader = false;
		if (pageUrl[0] === '') {
			// hide header if on dashboard
			this.hideHeader = true;
		} else {
			// add custom margin on pages that contain a route nav menu
			this.hasRouteNav = this.pagesWithRouteNav.includes(pageUrl[0]);
		}
	}

	private setHeaderTitle(urlArray: string[]): void {
		// Pull out page url from router event url
		const page = urlArray[1];
		// Match to mapping item
		const matchedHeader = HeaderMappingItems.find((item: HeaderMappingItem) => {
			return item.routes.includes(page);
		});

		this.setShowTitle(urlArray, matchedHeader);

		// Assign header and status display value except for project route which uses headerService.titleChange
		if (page !== Constants.ROUTE_LINKS.project) {
			this.headerDisplayValue = matchedHeader?.displayText;
			this.status = matchedHeader?.displayText;
		}
	}

	/**
	 * Determine whether to hide the title, undefined values for matched header to be treated as true
	 * @param matchedHeader
	 * @param urlArray
	 * @private
	 */
	private setShowTitle(urlArray: string[], matchedHeader?: HeaderMappingItem): void {
		const titleException = [this.headerText.overview, this.headerText.measuringTool];
		this.showTitle = matchedHeader?.showTitle !== false && !urlArray.some(value => titleException.includes(value));
		this.showTitleMeasuringTool = urlArray.includes(this.headerText.measuringTool);
	}
}
