import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { AdditionalCharge, Project } from '../interfaces';
import { BoqModel, ChargeModel } from '../models';

@Injectable()
export class CalculationService {
	constructor(private http: HttpClient) {}

	/**
	 * Calculate Bill of Quantity
	 * @param {Project} projectId
	 * @param {BoqModel} boq
	 * @param {string} chargeOutType
	 * @returns {Observable<*>}
	 */
	public postBoqCalculate(projectId: string, boq: BoqModel | ChargeModel, chargeOutType: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/calculation/boq`, {
				projectId: projectId,
				boq: boq.getPostObject(),
				chargeOutType: chargeOutType,
			})
			.pipe(map(res => BoqModel.createFromJson(res.boq)));
	}

	/**
	 * Calculate Project
	 * @param {Project} project
	 * @returns {Observable<*>}
	 */
	public postProjectCalculate(project: Project) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/calculation/project`, {
				project: project,
				projectId: project.id,
			})
			.pipe(map(res => res.project));
	}

	/**
	 * Calculate AdditionalCharges
	 * @param {AdditionalCharge | ChargeModel} additionalCharge
	 * @param {string} projectId
	 * @returns {Observable<*>}
	 */
	public postAdditionalChargeCalculate(additionalCharge: AdditionalCharge | ChargeModel, projectId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/calculation/additional-charge`, {
				additionalCharge: additionalCharge,
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	/**
	 * Markup calculation when total charge is edited
	 * @param {ChargeModel} additionalCharge Additional charge to be recalculated
	 * @param {string} projectId
	 * @returns {Observable<ChargeModel>}
	 */
	public postMarkupFromAdditionalChargeCalculate(additionalCharge: ChargeModel, projectId: string): Observable<ChargeModel> {
		return this.http
			.post<{ additionalCharge: ChargeModel }>(`${Constants.BASE_API_URL}/calculation/markup-from-additional-charge`, {
				additionalCharge: additionalCharge.getPostObject(),
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	public postGpFromAdditionalChargeCalculate(additionalCharge: ChargeModel, projectId: string): Observable<ChargeModel> {
		return this.http
			.post<{ additionalCharge: ChargeModel }>(`${Constants.BASE_API_URL}/calculation/gp-from-additional-charge`, {
				additionalCharge: additionalCharge.getPostObject(),
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	/**
	 * Rate recalculations when total charge is edited
	 * @param {ChargeModel} boqCharge Boq Charge to be recalculated
	 * @param {string} projectId
	 * @returns {Observable<ChargeModel>}
	 */
	public postRateFromBoqChargeCalculate(boqCharge: ChargeModel, projectId: string): Observable<ChargeModel> {
		return this.http
			.post<{ boq: ChargeModel }>(`${Constants.BASE_API_URL}/calculation/rate-from-boq-charge`, {
				boq: boqCharge.getPostObject(),
				projectId: projectId,
			})
			.pipe(map(res => res.boq));
	}

	/**
	 * Recalculates varying fields (based on chargeCategory) for an additional charge when its total cost is edited
	 * @param {ChargeModel} additionalCharge Additional charge to be recalculated
	 * @param {string} projectId
	 * @returns {Observable<any>}
	 */
	public postRecalculateAdditionalChargeWithNewCost(additionalCharge: ChargeModel, projectId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/calculation/recalculate-additional-charge-with-new-cost`, {
				additionalCharge: additionalCharge.getPostObject(),
				projectId: projectId,
			})
			.pipe(map(res => res.additionalCharge));
	}

	/**
	 * Recalculate a Boq's units when the labour hours are edited
	 * @param {ChargeModel} boqCharge Boq charge to be recalculated
	 * @param {string} projectId Project Id of the charge
	 * @returns {Observable<any>}
	 */
	postRecalculateProductionRatesFromLabourHours(boqCharge: ChargeModel, projectId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/calculation/recalculate-production-rates-from-labour-hours`, {
				boq: boqCharge.getPostObject(),
				projectId: projectId,
			})
			.pipe(map(res => res.boqCharge));
	}
}
