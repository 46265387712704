import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncateString',
})
export class TruncateStringPipe implements PipeTransform {
	/**
	 *  Truncate the text with a given length and add '...' at the end of the text.
	 * @param value
	 * @param length
	 */
	public transform(value: string, length: number): string {
		if (value && value.length >= length) {
			return value.substring(0, length) + '...';
		} else {
			return value;
		}
	}
}
