import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

import { RegEx } from '../../../@itc-core/common/itc-core-regex.constants';
import { Constants, FileMimeTypeEnum, SnackBarHelperComponent } from '../../helpers';

@Directive({
	selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
	@Input()
	public multi: boolean = false;
	/**
	 * List of allowed file types
	 */
	@Input()
	private allowedFileMimeTypes: {
		type: string;
		array: Array<FileMimeTypeEnum>;
	};

	/**
	 * Event emitter for file drop
	 */
	@Output()
	public readonly filesDropped = new EventEmitter<File[]>();

	/**
	 * Host binding for file-over class
	 */
	@HostBinding('class.file-over')
	public isActive: boolean;

	/**
	 * Turn into background grey and disabled if disabled attribute is true;
	 */
	@HostBinding('class.bg-disabled')
	public _disabled: boolean;
	@Input()
	set disabled(disabled: boolean) {
		this._disabled = disabled;
	}
	get disabled() {
		return this._disabled;
	}

	/**
	 * Listen for drag over event, set file-over class true
	 * @param event
	 */
	@HostListener('dragover', ['$event'])
	public onDragOver(event): void {
		event.preventDefault();
		event.stopPropagation();
		this.isActive = !this.disabled && true;
	}

	/**
	 * Listen for drag leave event, set file-over class false
	 * @param event
	 */
	@HostListener('dragleave', ['$event'])
	public onDragLeave(event): void {
		event.preventDefault();
		event.stopPropagation();
		this.isActive = false;
	}

	/**
	 * Listen for file drop event, set file-over class false and emit first file if it is in the allowed list
	 * @param event
	 */
	@HostListener('drop', ['$event'])
	public onDrop(event): void {
		event.preventDefault();
		event.stopPropagation();
		if (this.disabled) {
			return;
		}

		const files = event.dataTransfer.files;

		if (files.length > 0) {
			const validFiles = [];
			for (const file of files) {
				// TODO: MIME Type are not grantee to be supplied by the browser,  sometime type property is just a empty string
				// recommended solution is to find the file signature by the first 4 bits of the file (npm package)
				let fileType = file.type;

				if ((!fileType || fileType === '') && file.name) {
					const regex = RegEx.fileExtension;
					const extension = regex.exec(file.name)[1];
					fileType = Constants.getFileMimeType(extension);
				}

				if (this.allowedFileMimeTypes.array && this.allowedFileMimeTypes.array.length) {
					if (this.allowedFileMimeTypes.array.indexOf(fileType) !== -1) {
						validFiles.push(file);
					} else {
						this.snack.snackError('Incorrect file type.');
					}
					// If it is only a single file uploader, do not continue looping
					if (!this.multi) {
						break;
					}
				}
			}
			this.filesDropped.emit(validFiles);
		}
		this.isActive = false;
	}

	constructor(private snack: SnackBarHelperComponent) {
		//
	}
}
