import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';

export interface ReferenceCode {
	id?: string;
	title: string;
	code1?: string;
	code2?: string;
	code3?: string;
	description?: string;
	isActive: boolean;
	codeType: string;
	isLocked?: boolean;
}

export interface ReferenceCodeType {
	name: string;
	type: string;
}

export interface ReferenceCodeTypesResponse {
	status: boolean;
	referenceCodeTypes: ReferenceCodeType[];
}

export function GetReferenceCodeValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Title',
			validators: [Validators.maxLength(255), Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Code1',
			validators: [Validators.maxLength(50)],
		},
		{
			name: 'Code2',
			validators: [Validators.maxLength(50)],
		},
		{
			name: 'Code3',
			validators: [Validators.maxLength(50)],
		},
		{
			name: 'Description',
			validators: [Validators.maxLength(300)],
		},
	]);
}
