export class DistributionItemModel {
	public cost: number;
	public totalUnits: number;
	public size: number;

	constructor(json?: Partial<DistributionItemModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
