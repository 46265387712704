import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { EmailsResponse, ItemsResponseData } from '../interfaces';
import { EmailsModel } from '../pages/project/emails/common/emails.models';

@Injectable()
export class EmailLogService {
	constructor(private http: HttpClient) {}

	/**
	 * Gets the 25 most recent emails which match the given params.
	 * @param params
	 */
	public postListRecent(params) {
		const projectId = params.project;

		return this.http
			.post<EmailsResponse>(`${Constants.BASE_API_URL}/emaillog/recent`, {
				params,
				projectId,
			})
			.pipe(map(res => res.emails));
	}

	public paginate(skip: number, limit: number, params, dateFrom?: Date, dateTo?: Date): Observable<ItemsResponseData<EmailsModel>> {
		const projectId = params.project;

		return this.http.post<ItemsResponseData<EmailsModel>>(`${Constants.BASE_API_URL}/emaillog/paginate`, {
			skip,
			limit,
			params,
			projectId,
			dateFrom,
			dateTo,
		});
	}
}
