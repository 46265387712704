import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { AwsKey } from '../pages/project/export/common/export.interfaces';

@Injectable()
export class ExportService {
	constructor(
		private http: HttpClient,
		private domSanitizer: DomSanitizer
	) {}

	/**
	 * Get Url for the AWS key
	 * @param {string} awsKey
	 * @returns {Observable<any>}
	 */
	getAwsKeyUrl(awsKey): Observable<AwsKey> {
		return this.http.post<any>(`${Constants.BASE_API_URL}/export/awsurl`, awsKey).pipe(map(res => res.url));
	}

	/**
	 * Check if filesize is larger then ~ 25Mb
	 * @param {string} awsKey
	 * @returns {Observable<boolean>}
	 */
	public isFileTooLarge(awsKey): Observable<boolean> {
		return this.http.post<any>(`${Constants.BASE_API_URL}/export/is-file-too-large`, awsKey).pipe(map(res => res.isFileTooBig));
	}

	/**
	 * Fetch the buffer from aws bucket.
	 * @param {string} awsUrl
	 * @returns {Observable<Blob>}
	 */
	downloadAwsFile(awsUrl): Observable<Blob> {
		return this.http.get(awsUrl, { responseType: 'blob' });
	}

	/**
	 * Creates a google document viewer embed link
	 * @param {string} url
	 */
	getPreviewSafeGoogleEmbedUrl(url): SafeUrl {
		const u = url.indexOf('/') ? encodeURIComponent(url) : url;
		const googleFileUrl = `https://docs.google.com/gview?url=${u}&embedded=true`;
		return this.domSanitizer.bypassSecurityTrustResourceUrl(googleFileUrl);
	}
}
