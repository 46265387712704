import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { EntityEditRequest } from '../interfaces';
import { EntityModel } from '../models';

@Injectable()
export class EntityService {
	constructor(private http: HttpClient) {}

	/**
	 * Updates an entity
	 * @param updateRequest
	 */
	public editEntity(updateRequest: EntityEditRequest): Observable<EntityModel> {
		return this.http.post<any>(`${Constants.BASE_API_URL}/entity/edit`, updateRequest).pipe(map(res => res.item));
	}
}
