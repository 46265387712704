import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { interval, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NavigationMenuIcons } from '../@itc-core/components/navigation-panels/navigation-menu/common/navigation-menu.constants';
import { NavigationMenuConfig } from '../@itc-core/components/navigation-panels/navigation-menu/common/navigation-menu.models';
import { whiteLabelConfig } from '../config/config';
import { environment } from '../environments/environment';
import { CommunicationService } from './core/components/communication/common/communication.service';
import { Constants, NavigationMenuItems, NavigationMenuOptionItems, TIMES_IN_MILLISECONDS } from './helpers';
import { UserModel } from './models';
import { AuthService } from './services/auth.service';
import { EstimatorUserService } from './services/estimator-user/estimator-user.service';
import { ExportService } from './services/export.service';
import { IntercomService } from './services/intercom.service';
import { LocalStorageService } from './services/local-storage.service';
import { ProjectTimeService } from './services/project-time.service';
import { RolePermissionsService } from './services/role-permissions.service';
import { SlideOverService } from './services/slide-over.service';
import { UserTimeService } from './services/user-time-service';
import { UserService } from './services/user.service';
import { CacheService } from './services/cache.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { ProductTourService } from './services/product-tour.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Feature } from './helpers/feature';
import { CommandPaletteComponent } from '../@mos-core/components/command-palette/command-palette.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild(CommandPaletteComponent) commandPalette: CommandPaletteComponent;
	public canCreateNewProject: boolean = false;
	public communicationObservable: Observable<String[]>;
	public featuresMenuIsCollapsed = true;
	public isActive = false;
	public isAuthenticated = false;
	public isContractsAndAbove = false;
	public isEntityActive = false;
	public isSuperAdmin = false;
	public loginLink = '/' + Constants.ROUTE_LINKS.login;
	public menuOpened = false;
	public navConfig: NavigationMenuConfig = new NavigationMenuConfig();
	public navigationMenuItems: typeof NavigationMenuItems = NavigationMenuItems;
	public navigationMenuOptionItems: typeof NavigationMenuOptionItems = NavigationMenuOptionItems;
	public selectedRoute: string;
	public settingsMenuIsCollapsed = true;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public userLink = '/' + Constants.ROUTE_LINKS.user;
	public userLoader: Observable<UserModel>;
	public userMenuIsCollapsed = true;
	public userPicture = '';
	public userRole = '';
	public isApproval: boolean = false;
	public activeTour?: string;
	public isResource = false;
	public creatingProject = false;

	private saasAdminMessagesSubscription: Subscription = new Subscription();

	constructor(
		private authService: AuthService,
		private estimatorUserService: EstimatorUserService,
		private localStorageService: LocalStorageService,
		private intercomService: IntercomService,
		private rolePermissions: RolePermissionsService,
		private userService: UserService,
		private userTimeService: UserTimeService,
		private projectTimeService: ProjectTimeService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public activatedRoute: ActivatedRoute,
		private exportService: ExportService,
		private communicationService: CommunicationService,
		private cacheService: CacheService,
		private slideOverService: SlideOverService,
		private tourService: ProductTourService,
		private features: FeatureFlagService
	) {
		// set the tab title from the config
		document.getElementsByTagName('title')[0].innerHTML = whiteLabelConfig.app.title;

		this.tourService.activeTourSubject.pipe(takeUntilDestroyed()).subscribe(v => (this.activeTour = v));
		this.communicationObservable = this.communicationService.paintCommunication;
	}

	public ngOnInit() {
		// configure tailwind theme
		document.documentElement.style.setProperty('--primary-color', environment.colors.primary);
		document.documentElement.style.setProperty('--primary-color2', environment.colors.primary2);
		document.documentElement.style.setProperty('--primary-color3', environment.colors.primary3);
		document.documentElement.style.setProperty('--accent-white', environment.colors.accentWhite);
		document.documentElement.style.setProperty('--accent-color1', environment.colors.accent1);
		document.documentElement.style.setProperty('--accent-color2', environment.colors.accent2);
		document.documentElement.style.setProperty('--light-primary-color', environment.colors.lightPrimary);

		// Temporary measure until app.component can switch layouts appropriately
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.isResource = this.router.url.includes('/shifts');
			}
		});

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.creatingProject = this.router.url.includes('/create-project');
			}
		});

		// If authenticated, start recording new session time.
		if (this.authService.isAuthenticated()) {
			// Log the user out if they have no entity assigned to them
			if (this.authService.getUserDetail() && !this.authService.getUserDetail().entity) {
				this.logout();
			}

			this.localStorageService.setCurrentSessionTimeStart();
			this.userTimeService.startNew();

			// If authenticated, load states in cache service
			this.cacheService.cacheStates();
		}

		// Check for + refresh saas admin messages to display in the paint-communication component
		this.saasAdminMessagesSubscription.add(
			interval(TIMES_IN_MILLISECONDS.threeMinutes).subscribe(() => {
				if (this.authService.isAuthenticated()) {
					this.communicationService.getCommunication();
				}
			})
		);

		//force the menu to be closed first load
		this.menuOpened = false;

		// check the current auth state and set the current auth status true or false
		this.authService.userDetailsObservable.subscribe(userDetail => {
			if (userDetail && userDetail.token && userDetail.firstName && userDetail.role && userDetail.entity) {
				// TODO change this to use the userDataStream instead of userDetailsObservable
				// Usually openIntercom would be in the authenticate function, but we currently don't run it on refresh
				// (instead we rely on isAuthenticated()) so I have put it here instead.
				this.intercomService.openIntercom(userDetail);
				this.canCreateNewProject = userDetail.entity.canCreateMoreProjects();
				this.communicationService.getCommunication();
				this.isAuthenticated = this.authService.isAuthenticated();
				this.userRole = userDetail.role;
				this.isEntityActive = this.authService.isEntityActive();
				this.isSuperAdmin = this.rolePermissions.isSuperAdmin();
				this.isContractsAndAbove = this.rolePermissions.isContractsAndAbove();
				if (userDetail.displayPictureKey && userDetail.displayPictureKey !== 'undefined' && this.isAuthenticated) {
					this.exportService.getAwsKeyUrl({ awskey: userDetail.displayPictureKey }).subscribe(signedUrl => {
						this.userPicture = signedUrl.awsKey;
					});
				} else {
					this.userPicture = Constants.PROFILE_DEFAULT_PICTURE;
				}
				setTimeout(() => {
					this.menuOpened = true;
				}, 500);
			} else {
				this.canCreateNewProject = false;
				this.isAuthenticated = false;
				this.userRole = '';
				this.menuOpened = false;
				this.isEntityActive = false;
				this.isSuperAdmin = false;
				this.isContractsAndAbove = false;
				this.userPicture = Constants.PROFILE_DEFAULT_PICTURE;
			}
			this.setNavigationMenuConfig();
		});

		this.router.events.subscribe(routeEvent => {
			if (routeEvent instanceof RouterEvent) {
				this.selectedRoute = routeEvent.url;
			}
			// Update Intercom on page change for logged in users
			if (routeEvent instanceof NavigationEnd) {
				if (this.authService.isAuthenticated()) {
					this.intercomService.updateIntercom(routeEvent);
				}
			}

			if (this.selectedRoute.includes('/approval')) {
				this.isApproval = true;
			}
		});

		this.userLoader = this.estimatorUserService.userDataStream.pipe(
			tap((user: UserModel) => {
				if (user.entity) {
					this.canCreateNewProject = user.entity.canCreateMoreProjects();
					this.setNavigationMenuConfig();
				}
			})
		);
	}

	public ngAfterContentChecked() {
		this.changeDetectorRef.detectChanges();
	}

	public onActivate() {
		const scrollToTop = window.setInterval(() => {
			const pos = window.pageYOffset;
			if (pos > 0) {
				window.scroll(0, -1); // how far to scroll on each step
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 0.5);
	}

	// This handles the user closing/refreshing the page
	@HostListener('window:beforeunload', ['$event'])
	public beforeUnloadHander(event) {
		const projectTimeId = this.projectTimeService.getCurrentProjectTimeSession();
		this.userTimeService.complete(projectTimeId);
		this.localStorageService.clearCurrentSessionTimeStart();
	}

	// listen for the escape key to close the command palette
	@HostListener('document:keydown', ['$event'])
	public handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'Escape' && this.commandPalette.isOpen) {
			this.commandPalette.close();
		}
	}

	/**
	 * Controls dropdown menus that appear on mobile.
	 * @param menu
	 */
	public selectMenu(menu?: string): void {
		if (menu === 'user') {
			this.userMenuIsCollapsed = !this.userMenuIsCollapsed;
			this.featuresMenuIsCollapsed = true;
			this.settingsMenuIsCollapsed = true;
		} else if (menu === 'features') {
			this.userMenuIsCollapsed = true;
			this.featuresMenuIsCollapsed = !this.featuresMenuIsCollapsed;
			this.settingsMenuIsCollapsed = true;
		} else if (menu === 'settings') {
			this.userMenuIsCollapsed = true;
			this.featuresMenuIsCollapsed = true;
			this.settingsMenuIsCollapsed = !this.settingsMenuIsCollapsed;
		} else {
			this.userMenuIsCollapsed = true;
			this.featuresMenuIsCollapsed = true;
			this.settingsMenuIsCollapsed = true;
		}
	}

	/**
	 *logout the current user
	 */
	public logout() {
		const projectTimeId = this.projectTimeService.getCurrentProjectTimeSession();
		this.userTimeService.complete(projectTimeId);
		this.localStorageService.clearCurrentSessionTimeStart();
		this.authService.logout();
		this.router.navigate(['/' + Constants.ROUTE_LINKS.login]);
	}

	private setNavigationMenuConfig() {
		const isNewProjectButtonDisabled =
			!(this.rolePermissions.isTrainee() || this.rolePermissions.isUser() || this.rolePermissions.isTeamLeaderAndAbove()) || !this.canCreateNewProject;
		this.navConfig = new NavigationMenuConfig({
			logoURL: whiteLabelConfig.app.logoSideNav,
			navigationItems: [
				{
					route: '/create-project',
					iconURL: NavigationMenuIcons.filledFolder,
					toolTip: 'new project',
					isHidden: false,
					isDisabled: isNewProjectButtonDisabled,
					name: this.navigationMenuItems.newProject,
				},
				{
					route: '/',
					iconURL: NavigationMenuIcons.home,
					toolTip: 'dashboard',
					isHidden: false,
					name: this.navigationMenuItems.dashboard,
				},
				{
					route: '/projects',
					iconURL: NavigationMenuIcons.projects,
					toolTip: 'projects',
					isHidden: false,
					name: this.navigationMenuItems.projects,
				},
				{
					route: '/clients',
					iconURL: NavigationMenuIcons.clients,
					toolTip: 'clients',
					isHidden: false,
					name: this.navigationMenuItems.clients,
				},
				{
					route: '/reports',
					iconURL: NavigationMenuIcons.reporting,
					toolTip: 'reports',
					isHidden: !this.isContractsAndAbove,
					name: this.navigationMenuItems.reports,
				},
				{
					route: '/invoices',
					iconURL: NavigationMenuIcons.invoices,
					toolTip: 'invoices',
					isHidden: !this.isContractsAndAbove,
					name: this.navigationMenuItems.invoices,
				},
				{
					route: '/schedule',
					iconURL: NavigationMenuIcons.calendar,
					toolTip: 'Scheduling',
					name: this.navigationMenuItems.scheduling,
					isHidden: !this.features.has(Feature.ScheduleTool),
				},
				{
					route: '/libraries',
					iconURL: NavigationMenuIcons.database,
					toolTip: 'library',
					isHidden: !this.isContractsAndAbove,
					name: this.navigationMenuItems.library,
				},
			],
		});
	}

	@HostListener('window:keydown', ['$event'])
	public handleCommandPaletteKey(event: KeyboardEvent) {
		if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
			event.preventDefault();
			this.commandPalette.open();
		}
	}

	public openCommandPalette() {
		console.log('openCommandPalette');
		this.commandPalette.open();
	}

	protected readonly createImageBitmap = createImageBitmap;
}
