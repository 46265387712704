export class RegexHelper {
	/**
	 * Returns an alphanumerical string with the provided length
	 * @param input
	 * @param length
	 * @return {string}
	 */
	public static createAlphanumericalWithSpecialLength = (input, length) => {
		const firstPhase = input.replace(/[^0-9a-z]/gi, '');
		if (firstPhase.length > length) {
			return firstPhase.slice(0, length);
		}
		return firstPhase;
	};

	/**
	 * Escapes regex values/characters from a search term
	 * @param text
	 */
	public static escape(text: string): string {
		return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}
}
