// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
  min-width: 500px;
  padding-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/substrate-edit-dialog/substrate-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,gBAAA;EACA,oBAAA;AACD","sourcesContent":[":host {\n\tdisplay: inline-block;\n\tmin-width: 500px;\n\tpadding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
