import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';
import { Region } from './region';

// Interfaces

export interface State {
	id: string;
	name: string;
	country: string;
	abbreviation: string;
	regions: Region[];
	isActive: boolean;
}

// Validators

export function GetStateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Abbreviation',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Country',
			validators: [Validators.required],
		},
	]);
}
