import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { faChevronDown, faSignOutAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { environment } from '../../../../../../environments/environment';
import { Constants } from '../../../../../helpers';
import { AlertService } from '../../../../../pages/alert/common/alert.service';
import { AuthService } from '../../../../../services/auth.service';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { RolePermissionsService } from '../../../../../services/role-permissions.service';
import { HeaderMenuText } from './common/header-menu.constants';
import { DropdownOptions } from './common/header-menu.interfaces';

@Component({
	selector: 'paint-header-menu',
	templateUrl: './header-menu.component.html',
	styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, AfterViewInit {
	public chevronDown: IconDefinition = faChevronDown;
	public dropdownOptions: Array<DropdownOptions> = [];
	public isEntityActive: boolean = true;
	public isEntityOwner: boolean = false;
	public isSuperAdmin: boolean = false;
	public logOutIcon: IconDefinition = faSignOutAlt;
	public userName: string;
	public totalAlerts: number = 0;

	constructor(
		private authService: AuthService,
		private navigationService: NavigationService,
		private localStorageService: LocalStorageService,
		private rolePermissionsService: RolePermissionsService,
		private alertService: AlertService
	) {}

	public ngOnInit(): void {
		this.isEntityActive = this.authService.isEntityActive();
		this.isEntityOwner = this.authService.isEntityOwner();
		const user = this.localStorageService.getUserDetails();
		this.userName = `${user.firstName} ${user.lastName}`;
		this.isSuperAdmin = this.rolePermissionsService.isSuperAdmin();
		this.setDropdownOptions();
	}

	public ngAfterViewInit() {
		/**
		 * Hook for current alerts number
		 */
		this.alertService.alerts.subscribe(total => {
			this.totalAlerts = total;
		});
	}

	public optionSelected(option: DropdownOptions) {
		if (option.route.startsWith('https')) {
			window.open(option.route, '_blank');
		} else {
			this.navigationService.setRoute([option.route]);
		}
	}

	private setDropdownOptions(): void {
		// Do not display user profile and setting options for entity owners with an inactive plan
		if (this.isEntityActive) {
			this.dropdownOptions.push({
				displayText: HeaderMenuText.alerts,
				route: Constants.ROUTE_LINKS.alerts,
			});
			this.dropdownOptions.push({
				displayText: HeaderMenuText.userProfile,
				route: Constants.ROUTE_LINKS.user,
			});
			if (this.isSuperAdmin) {
				this.dropdownOptions.push({
					displayText: HeaderMenuText.settings,
					route: `${Constants.ROUTE_LINKS.admin}/companies`,
				});
			}
		}
		this.dropdownOptions.push({
			displayText: HeaderMenuText.help,
			route: Constants.ROUTE_LINKS.help,
		});
		// Only show subscription page dropdown option if we are on the public SaaS environment
		if (this.isEntityOwner && (environment.env === 'production' || environment.env === 'local' || environment.env === 'development')) {
			this.dropdownOptions.push({
				displayText: HeaderMenuText.subscription,
				route: Constants.ROUTE_LINKS.subscription,
			});
		}
		this.dropdownOptions.push({
			displayText: HeaderMenuText.logOut,
			route: Constants.ROUTE_LINKS.login,
		});
	}
}
