import { TitleCasePipe } from '@angular/common';

import { TEXT } from '../../../../helpers/text.constants';

const titleCase = new TitleCasePipe();

export const CoatingSystemFormTextConst = {
	select: titleCase.transform(TEXT.select) + '...',
	typeHere: titleCase.transform(TEXT.typeHere) + '...',
};
