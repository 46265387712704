/**
 * Created by Matt Shipman from In The Code on 7/3/18.
 */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LocalStorageService } from '../services/local-storage.service';
import { Constants } from './constants';
import { SnackBarHelperComponent } from './snack-bar-helper';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		public snack: SnackBarHelperComponent,
		private localStorageService: LocalStorageService,
		private router: Router
	) {}

	/**
	 * Will add the x-access-token header with the local storage JWT
	 * @param {HttpRequest<any>} req
	 * @param {HttpHandler} next
	 * @returns {Observable<HttpEvent<any>>}
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const local = this.localStorageService.getUserDetails();

		if (local && local.token) {
			const headers = new HttpHeaders({
				'Cache-Control': 'no-cache',
				'Local-Email': local.email,
				'Local-ID': local.id,
				'Local-Role': local.role,
				Pragma: 'no-cache',
				'X-Access-Token': local.token,
			});
			const cloned = req.clone({ headers });

			return next.handle(cloned).pipe(
				map(res => {
					if (res instanceof HttpResponse) {
						if (res.body && res.body.message) {
							this.snack.snackSuccess(res.body.message);
						}
					}
					return res;
				}),
				catchError(error => {
					//Catch the error for accessing to the 401 error that
					//cause after token has been expired.
					if (error instanceof HttpErrorResponse) {
						switch (error.status) {
							case 400:
								return this.handle400Error(req, next, error);
							case 401:
								return this.handle401Error(req, next, error);
							case 403:
								return this.handle403Error(req, next, error);
							case 404:
								return this.handle404Error(req, next, error);
							case 500:
								return this.handle500Error(req, next, error);
							default:
								//this.snack.snackError('An unknown error occurred');
								return observableThrowError('An unknown error occurred.');
						}
					}
				})
			);
		} else {
			return next.handle(req).pipe(
				catchError(error => {
					if (error instanceof HttpErrorResponse) {
						switch (error.status) {
							case 400:
								return this.handle400Error(req, next, error);
							case 401:
								return this.handle401Error(req, next, error);
							case 403:
								return this.handle403Error(req, next, error);
							case 404:
								return this.handle404Error(req, next, error);
							case 500:
								return this.handle500Error(req, next, error);
							default:
								this.router.navigate(['/' + Constants.ROUTE_LINKS.login]);
								return this.handle503Error(req, next);
						}
					}
				})
			);
		}
	}

	private errorChecker(error): void {
		if (error && error.error && error.error.error) {
			this.snack.snackError(error.error.error);
		}
	}

	//Handle the 400 error.
	private handle400Error(req: HttpRequest<any>, next: HttpHandler, error): Observable<never> {
		this.errorChecker(error);
		return observableThrowError('Error 400: Bad Request');
	}

	//Handle the 401 error and try to redirect to the login page with the current url.
	private handle401Error(req: HttpRequest<any>, next: HttpHandler, error): Observable<never> {
		this.errorChecker(error);
		this.router.navigate(['/' + Constants.ROUTE_LINKS.login], {
			queryParams: { is401InterceptLogout: true },
		});
		return observableThrowError('Error 401: Unauthorized');
	}

	//Handle the 403 error.
	private handle403Error(req: HttpRequest<any>, next: HttpHandler, error): Observable<never> {
		if (error && error.error && error.error.error) {
			this.snack.snackError(error.error.error);
		} else {
			this.snack.snackError('Your account role does not have permission for the requested task. Please contact your administrator.');
		}
		return observableThrowError('Error 403: Forbidden');
	}

	//Handle the 404 error.
	private handle404Error(req: HttpRequest<any>, next: HttpHandler, error): Observable<never> {
		this.errorChecker(error);
		return observableThrowError('Error 404: Not Found');
	}

	//Handle the 500 error.
	private handle500Error(req: HttpRequest<any>, next: HttpHandler, error): Observable<never> {
		this.errorChecker(error);
		return observableThrowError('Error 500: Internal Server Error');
	}

	//Handle the 503 error.
	private handle503Error(req: HttpRequest<any>, next: HttpHandler): Observable<never> {
		this.snack.snackError('Server not responding');
		return observableThrowError('Server not responding');
	}
}
