import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export const STEPS_BUTTONS = {
	back: {
		classes: 'back-button',
		secondary: true,
		text: 'Back',
		type: 'back',
	},
	cancel: {
		classes: 'cancel-button',
		secondary: true,
		text: 'Exit',
		type: 'cancel',
	},
	next: {
		classes: 'next-button',
		text: 'Next',
		type: 'next',
	},
};

export function getSteps(userId: string, service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {
	const handler = () => {
		service.next();
	};

	const handlerBack = () => {
		service.back();
	};

	function handleCompleteTour() {
		service.complete();
	}

	return [
		{
			id: 'step4',
			attachTo: {
				element: '.company-details',
				on: 'right',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			buttons: [STEPS_BUTTONS.next],
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Complete Company Details',
			text: [
				'Complete all required fields above. If this Company is a Parent Company, select "Parent Company" and then select which Company it is the parent to. <br><br>Click Next to proceed',
			],
		},
		{
			id: 'step5',
			attachTo: {
				element: '.location-details',
				on: 'left',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.next],
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Complete Location Details',
			text: ['Complete as many fields above relating to your company. <br><br>Click Next to proceed'],
		},
		{
			id: 'step6',
			attachTo: {
				element: '.contact-person-details',
				on: 'top',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			buttons: [
				STEPS_BUTTONS.back,
				{
					text: 'Next',
					action: function () {
						const element: HTMLElement = document.getElementById('itc-button-clicked');
						element.click();
					},
				},
			],
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Complete Contact Person',
			text: ['Add any contacts relating to this Company. <br><br>Click next to proceed'],
			when: {
				show: () => {
					const addContactBtn = document.querySelector(`.itc-button-clicked`);
					if (addContactBtn) {
						addContactBtn.addEventListener('click', handler);
					}
				},
				hide: () => {
					const addContactBtn = document.querySelector(`.itc-button-clicked`);
					if (addContactBtn) {
						addContactBtn.removeEventListener('click', handler);
					}
				},
				cancel: () => {
					const addContactBtn = document.querySelector(`.itc-button-clicked`);
					if (addContactBtn) {
						addContactBtn.removeEventListener('click', handler);
					}
				},
			},
		},
		// Need to work out triggering a step on the 'Add Contact' modal
		{
			id: 'step6',
			attachTo: {
				element: '.itc-modal-dialog',
				on: 'top',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			buttons: [
				{
					text: 'Back',
					classes: 'back-button',
					action: function () {
						const element: HTMLElement = document.getElementById('itc-modal-closed');
						element.click();
						handlerBack();
					},
				},
				{
					text: "Don't Add Contact",
					classes: 'back-button',
					action: function () {
						const element: HTMLElement = document.getElementById('itc-modal-closed');
						element.click();
						handler();
					},
				},
				{
					text: 'Add Contact',
					action: function () {
						const element: HTMLElement = document.getElementById('itc-modal-save');
						element.click();
					},
				},
			],
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Add Contact Person',
			text: ['Complete all required fields below and click Add Contact in this dialog box'],
		},
		{
			id: 'step7',
			attachTo: {
				element: '.save-company-btn',
				on: 'left',
			},
			beforeShowPromise: function () {
				return new Promise(function (resolve) {
					setTimeout(function () {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			modalOverlayOpeningPadding: 5,
			modalOverlayOpeningRadius: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 15, crossAxis: 12 })],
			},
			buttons: [
				{
					text: 'Back',
					classes: 'back-button',
					action: function () {
						const element: HTMLElement = document.getElementById('itc-button-clicked');
						element.click();
						handlerBack();
					},
				},
				{
					text: 'Finish',
					action: () => {
						handleCompleteTour();
					},
				},
			],
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			title: 'Save your changes',
			text: ["Click 'Save Update' to save your company configuration, or click 'Finish' below; either action will finalise your tour."],
			when: {
				show: () => {
					const settingsBtn = document.querySelector('.save-cancel-company-btn');
					if (settingsBtn) {
						settingsBtn.addEventListener('click', handleCompleteTour);
					}
				},
				hide: () => {
					const settingsBtn = document.querySelector('.save-cancel-company-btn');
					if (settingsBtn) {
						settingsBtn.removeEventListener('click', handleCompleteTour);
					}
				},
				cancel: () => {
					const settingsBtn = document.querySelector('.save-cancel-company-btn');
					if (settingsBtn) {
						settingsBtn.removeEventListener('click', handleCompleteTour);
					}
				},
			},
		},
	];
}
