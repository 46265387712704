import { CalculationTypes, UserToolTypes } from '../../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.constants';
import { EmbeddedEstimatorUserTool } from '../../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.interfaces';
import { TEXT } from '../../../helpers/text.constants';

export const backgroundColour: number[] = [240, 240, 240];
export const defaultContingency: number = 10;
export const defaultFillColour: string = 'rgba(255,255,255,1)';
export const defaultFontSize: number = 30;
export const defaultLegendDescriptionWidth: number = 150;
export const defaultLineDash: number[] = [5, 10];
export const defaultScaleStrokeColour: string = 'rgb(255, 0, 0)';
export const defaultScaleStrokeWeight: number = 10;
export const defaultStrokeColour: string = 'rgb(0,0,0)';
export const defaultStrokeWeight: number = 2;
export const defaultTextColour: string = 'rgb(0,0,0)';
export const exportCanvasBackgroundColour: number[] = [255, 255, 255];
export const keyCodes = {
	controlKey: 17, // ctrl
	copyKey: 67, // c (used with ctrl)
	editEdgePoint: 16, // shift
	itemsToolKey: 73, // i
	rulerToolKey: 77, // m
	pageDownKey: 34, // page down,
	pageUpKey: 33, // page up,
	pastePanKey: 86, // v (used with ctrl)
	pointToolKey: 80, // p
	rectangleToolKey: 82, // r
	selectKey: 83, // s
	shiftKey: 16, // shift
	snapToOrthogonalKey: 16, // shift
	undoRedoKey: 90, // z (used with ctrl / shift)
	windowsOrCommandKey: 91, // windows: windows key | mac: command
	zoomInKey: 187 || 107, // +
	zoomOutKey: 189 || 109, // =
};
export const layerViewTypes = {
	all: 'all layers',
	hide: 'hide layers',
	grey: 'grey layers',
};
export const lineSelectableOffset: number = 10;
export const LineStyles = {
	solid: 'Solid',
	dashed: 'Dashed',
};
export const maxZoomSteps: number = 20;
export const minZoomSteps: number = 0;
export const measuringToolConst = {
	thumbnailWidth: 200,
	thumbnailHeight: 200,
};
export const measuringToolPrecision = {
	zero: {
		precisionDisplayKey: '1',
		precisionValue: 0,
	},
	one: {
		precisionDisplayKey: '0.1',
		precisionValue: 1,
	},
	two: {
		precisionDisplayKey: '0.01',
		precisionValue: 2,
	},
	three: {
		precisionDisplayKey: '0.001',
		precisionValue: 3,
	},
	four: {
		precisionDisplayKey: '0.0001',
		precisionValue: 4,
	},
};
export const measuringToolPrecisionArray = [
	measuringToolPrecision.zero,
	measuringToolPrecision.one,
	measuringToolPrecision.two,
	measuringToolPrecision.three,
	measuringToolPrecision.four,
];
export const measuringToolUnits = {
	centimetres: {
		displayName: TEXT.centimetres,
		metresToUnitConversionRate: 100,
		squareMetresToSquareUnitConversionRate: 10000,
		squareUnitAbbreviation: TEXT.squareCentimetreAbbreviation,
		unitAbbreviation: TEXT.centimetreAbbreviation,
	},
	feet: {
		displayName: TEXT.feet,
		metresToUnitConversionRate: 3.28084,
		squareMetresToSquareUnitConversionRate: 10.7639,
		squareUnitAbbreviation: TEXT.squareFootAbbreviation,
		unitAbbreviation: TEXT.footAbbreviation,
	},
	inches: {
		displayName: TEXT.inches,
		metresToUnitConversionRate: 39.3701,
		squareMetresToSquareUnitConversionRate: 1550,
		squareUnitAbbreviation: TEXT.squareInchAbbreviation,
		unitAbbreviation: TEXT.inchAbbreviation,
	},
	metres: {
		displayName: TEXT.metres,
		metresToUnitConversionRate: 1,
		squareMetresToSquareUnitConversionRate: 1,
		squareUnitAbbreviation: TEXT.squareMetreAbbreviation,
		unitAbbreviation: TEXT.metreAbbreviation,
	},
	millimetres: {
		displayName: TEXT.millimetres,
		metresToUnitConversionRate: 1000,
		squareMetresToSquareUnitConversionRate: 1000000,
		squareUnitAbbreviation: TEXT.squareMillimetreAbbreviation,
		unitAbbreviation: TEXT.millimetreAbbreviation,
	},
};
export const measuringToolUnitsArray = [
	// These must be ordered as they will appear in the unit select dropdown. Please do not alphabetise
	measuringToolUnits.millimetres,
	measuringToolUnits.centimetres,
	measuringToolUnits.metres,
	measuringToolUnits.inches,
	measuringToolUnits.feet,
];
export const pasteShapeDisplacementAmount: number = 50;
export const pointSize: number = 16;
export const selectedPointSize: number = 20;
export const textConstants = {
	addPage: TEXT.addPage,
	confirmingMarginOfError: TEXT.confirmingMarginOfError,
	contingencyMargin: TEXT.contingencyMargin,
	pageAddingModalTitle: TEXT.pageAddingModalTitle,
	precision: TEXT.precision,
	recalibrateScale: TEXT.recalibrateScale,
	select: TEXT.select,
	settingScale: TEXT.settingScale,
	units: TEXT.units,
};

export enum DrawingToolType {
	ruler,
	items,
	point,
	rectangle,
	ellipse,
	freeText,
	pointAnnotate,
	rectangleAnnotate,
	ellipseAnnotate,
	callout,
	usableArea,
	combine,
}
export enum ManipToolType {
	deconstruct,
	pan,
	select,
	shapeManip,
	breakOut,
	cutout,
	moveLegend,
	rectangleCutOut,
	flipActiveVerticalAxis,
	flipPageVerticalAxis,
	flipActiveHorizontalAxis,
	flipPageHorizontalAxis,
}

export enum MouseButtonTypes {
	left,
	middle,
	right,
}

export const defaultBoqMapUserTool: EmbeddedEstimatorUserTool = {
	//annotationText: undefined,
	calculationType: CalculationTypes.surfaceArea,
	canvasStyleDetails: {
		fillColour: defaultFillColour,
		fontSize: defaultFontSize,
		lineStyle: LineStyles.solid,
		lineDash: defaultLineDash,
		highlight: false,
		itemSize: 1,
		strokeWeight: defaultStrokeWeight,
		strokeColour: defaultStrokeColour,
		textColour: defaultTextColour,
	},
	name: TEXT.defaultUserToolName,
	defaultHeight: 0,
	totalCeilingArea: 0,
	totalPerimeter: 0,
	totalSurfaceArea: 0,
	totalWallsSurfaceArea: 0,
	userId: undefined,
	userToolType: UserToolTypes.measure,
};
