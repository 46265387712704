import { Component, Input, OnInit } from '@angular/core';
import { faBell, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { Observable, of, timer } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { MinutesToMilliseconds, MinutesToRefresh } from './common/notification-bell.constants';
import { NotificationBellService } from './common/notification-bell.service';

@Component({
	selector: 'paint-notification-bell',
	templateUrl: './notification-bell.component.html',
	styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements OnInit {
	@Input()
	public isLarge = false;
	public isUnreadNotifications: Observable<boolean>;
	public icon: IconDefinition = faBell;

	constructor(
		private authService: AuthService,
		private notificationBellService: NotificationBellService
	) {}

	public ngOnInit(): void {
		/*
		 * FR.4 Section 1 Part b
		 * The system will check for new notifications every 5 minutes.
		 */
		this.isUnreadNotifications = timer(100, MinutesToRefresh * MinutesToMilliseconds).pipe(
			switchMap(() => {
				if (this.authService.isAuthenticated()) {
					return this.notificationBellService.getNotifications();
				} else {
					return of(null);
				}
			}),
			map(list => (list ? list.some(task => !task.isComplete) : false)),
			shareReplay()
		);
	}
}
