import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { ProjectHeaderTitle } from './header.interfaces';

@Injectable({
	providedIn: 'root',
})
export class HeaderService {
	private titleSubject: Subject<ProjectHeaderTitle> = new Subject<ProjectHeaderTitle>();
	public titleChange: Observable<ProjectHeaderTitle> = this.titleSubject.asObservable();

	private expandedSubject: Subject<boolean> = new Subject<boolean>();
	public expandedChange: Observable<boolean> = this.expandedSubject.asObservable();

	public isPageTitleDefined: boolean = false;

	public expand(): void {
		this.expandedSubject.next(true);
	}

	public contract(): void {
		this.expandedSubject.next(false);
	}

	public setHeaderTitle(title: string, reference: number, status: string, isMeasuringTool: boolean, editedBy?: string, lastSaved?: Date): void {
		this.titleSubject.next({
			title: title,
			reference: reference,
			status: status,
			isMeasuringTool: isMeasuringTool,
			editedBy: editedBy,
			lastSaved: lastSaved,
		});
	}

	public setIsPageTitleDefined(isDefined: boolean): void {
		this.isPageTitleDefined = isDefined;
	}
}
