import { UntypedFormGroup } from '@angular/forms';

import { CoatingSystem, EmbeddedCoatProduct, EmbeddedRate, Product, ReferenceCode } from '../interfaces';
import { BaseModel } from './base.model';

export class CoatingSystemModel extends BaseModel implements CoatingSystem {
	// Properties
	public application: string;
	public brand: string;
	public chargeOutRates: EmbeddedRate[] = [];
	public coatProducts: EmbeddedCoatProduct[] = [];
	public code: string;
	public documentType: string;
	public isActive: boolean = true;
	public newWork: string;
	public previouslyPainted: string;
	public product: Product;
	public substrate: ReferenceCode;
	public title: string;

	constructor(json?: any) {
		super();
		if (json) {
			this.deserialize(json);
		}
		// this.formGroup = this.createForm();
	}

	public createForm(): UntypedFormGroup {
		return new UntypedFormGroup({});
	}

	public getFormData(): FormData {
		return undefined;
	}
}
