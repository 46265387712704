import { SupplierProductOrder } from './supplier-product-order.model';
import { SupplierModel } from './supplier.model';

export class SupplierProductSummaryRowItemModel {
	public amount: number;
	public chargeId: string; // Additional Charge id
	public currentSupplierProductOrder: SupplierProductOrder = new SupplierProductOrder();
	public existingSupplierProductOrder: SupplierProductOrder = new SupplierProductOrder();
	public isDaily: boolean;
	public supplier: SupplierModel;
	public totalDuration: number;
	public paidTransport: boolean = false;

	constructor(json?: Partial<SupplierProductSummaryRowItemModel>) {
		if (json) {
			Object.assign(this, json);
		}
	}
}
