export class Shift {
	public id: string = '';
	public user?: string;
	public project: string;
	public startTime: Date;
	public endTime: Date;
	public parent?: string;
	public wasOrphaned?: boolean;
	public rateOverride?: number;
	public isPublished?: boolean;
	public acceptedAt?: Date;
	public rejectedAt?: Date;
	public breakMinutes?: number;
	public notes?: string;
	public isHoliday?: boolean;
	public workerApproved?: boolean;
	public adminApproved?: boolean;
	public employee?: any;
	public isParent: boolean;
	public requiresApproval?: boolean;

	constructor(obj?: Partial<Shift>) {
		if (obj) {
			Object.assign(this, JSON.parse(JSON.stringify(obj)));
		}
	}
}

export interface ShiftResponse {
	shifts: Shift[],
	status: boolean
}

export class ShiftSaveObj {
	public id?: string;
	public user?: string;
	public project: string;
	public startTime: Date;
	public endTime: Date;
	public parent?: string;
	public isParent: boolean;
	public wasOrphaned?: boolean;
	public rateOverride?: number;
	public breakMinutes?: number;
	public notes?: string;
	public isHoliday?: boolean;
	public isPublished?: boolean;
	public requiresApproval?: boolean;
}
