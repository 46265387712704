import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../helpers';
import { UserResponse } from '../interfaces';

@Injectable()
export class ResetPasswordService {
	constructor(private http: HttpClient) {}

	/**
	 * Resets a users password to the provided new password
	 * @param {String} resetPasswordKey
	 * @param {String} newPassword
	 * @returns {Observable<UserResponse>}
	 */
	resetPassword(resetPasswordKey: String, newPassword: String) {
		return this.http.post(`${Constants.BASE_API_URL}/resetpassword/reset-password`, {
			resetPasswordKey,
			newPassword,
		});
	}
}
