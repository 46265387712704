import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, ViewContainerRef } from '@angular/core';

import { ItcBusyComponent } from './component/itc-busy.component';

@Directive({
	selector: '[itcBusy]',
})
export class ItcBusyDirective implements OnDestroy {
	@Input('itcBusy')
	public set isLoading(value: boolean) {
		this._isLoading = value;

		if (value) {
			this.recreateBusyIfNecessary();
		} else {
			this.destroyComponents();
		}
	}

	private _isLoading = false;

	private busyRef: ComponentRef<ItcBusyComponent>;

	public constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef
	) {}

	public ngOnDestroy(): void {
		this.destroyComponents();
	}

	private createBusy(): void {
		const factory = this.componentFactoryResolver.resolveComponentFactory(ItcBusyComponent);
		this.busyRef = this.viewContainerRef.createComponent(factory);
	}

	private destroyComponents(): void {
		if (this.busyRef) {
			this.busyRef.destroy();
		}
	}

	private recreateBusyIfNecessary(): void {
		this.destroyComponents();
		this.createBusy();
	}
}
