import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';

import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Constants, LOCAL_STORAGE_KEYS } from '../helpers';
import { LoginUser, Project, UserRole } from '../interfaces';
import { AuthUserModel, EntityModel, UserModel } from '../models';
import { EstimatorUserService } from './estimator-user/estimator-user.service';
import { IntercomService } from './intercom.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class AuthService {
	public localStorageKeys: typeof LOCAL_STORAGE_KEYS = LOCAL_STORAGE_KEYS;
	public userDetailsObservable = new BehaviorSubject<AuthUserModel>(null); // Used to broadcast auth state change to all subscribers

	private userDetails: AuthUserModel = new AuthUserModel({
		expiresAt: 0,
		displayPictureKey: Constants.LOCAL_STORAGE_USER_PICTURE,
	});

	constructor(
		private estimatorUserService: EstimatorUserService,
		private http: HttpClient,
		private intercomService: IntercomService,
		private localStorageService: LocalStorageService,
		public router: Router
	) {
		// When the service is constructed set the initial user details
		this.userDetailsObservable.next(this.getUserDetail());
	}

	/**
	 * Function to authenticate user with the given email and password
	 * we also pass the remember me flag for extending expire to 7 days
	 * @param {LoginUser} loginUser
	 * @returns {Observable<Object>}
	 */
	public authenticate(loginUser: LoginUser): Observable<AuthUserModel> {
		return this.http.post<AuthUserModel>(`${Constants.BASE_API_URL}/auth/authtoken`, loginUser).pipe(
			tap((res: AuthUserModel) => {
				this.setUser(res);
			}),
			catchError(error => {
				//catch the error from sever side and show as the alert
				//we can later show as toast or message on the page.
				return observableThrowError(error);
			})
		);
	}

	/**
	 * Check if the user is authorised to edit a project. Pass the current user so we have access to the providers.
	 */
	public canEditProject(project: Project, currentUser: UserModel): boolean {
		if (!project || !currentUser || !currentUser.providers || !project.company) {
			return false;
		}

		// Determine if the user has the projects company and if the user is related to the project
		const userHasCompany = currentUser.providers.findIndex(provider => provider.id === project.company.id) !== -1;
		const userIsRelated =
			(project.estimator && project.estimator.id === currentUser.id) ||
			(project.salesPerson && project.salesPerson.id === currentUser.id) ||
			(project.projectManager && project.projectManager.id === currentUser.id);

		return this.isAdmin() ? userHasCompany : userHasCompany && userIsRelated;
	}

	/**
	 * get the User Details
	 * @returns {AuthUserModel}
	 */
	public getUserDetail(): AuthUserModel {
		this.userDetails = this.localStorageService.getUserDetails();

		return this.userDetails;
	}

	/**
	 * check if the connected user is at least the given role.
	 * @param role
	 * @returns {boolean}
	 */
	public hasRolePermission(role: UserRole): boolean {
		const userRole: UserRole = Constants.USER_ROLES[this.userDetails.role];
		if (!userRole || !userRole.rank || !role || !role.rank) {
			return false;
		}
		return userRole.rank >= role.rank;
	}

	/**
	 * check if the user is Admin or not
	 * @returns {boolean}
	 */
	public isAdmin(): boolean {
		// todo catcher here
		return this.localStorageService.getUserKey(this.localStorageKeys.token) && this.hasRolePermission(Constants.USER_ROLES.admin);
	}

	/**
	 * check if the user is authenticated or not
	 * @returns {boolean}
	 */
	public isAuthenticated(): boolean {
		return this.localStorageService.getUserKey(this.localStorageKeys.token) && this.localStorageService.getUserKey(this.localStorageKeys.expiresAt) > Date.now() / 1000;
	}

	/**
	 * Check whether the current user's entity is active
	 */
	public isEntityActive(): boolean {
		return this.userDetails?.entity?.isActive;
	}

	/**
	 * Check whether the current user is an entity owner
	 */
	public isEntityOwner(): boolean {
		return this.userDetails?.id && this.userDetails?.id === this.userDetails?.entity?.owner?.id;
	}

	/**
	 * check if the user is trainee
	 * @returns {boolean}
	 */
	public isTrainee(): boolean {
		//todo catcher here

		let result: boolean = false;
		const userRole: UserRole = Constants.USER_ROLES[this.userDetails.role];
		if (userRole && userRole.rank) {
			result = userRole.rank === Constants.USER_ROLES.trainee.rank;
		}
		return this.localStorageService.getUserKey(this.localStorageKeys.token) && result;
	}

	/**
	 * check if the user is User
	 * @returns {boolean}
	 */
	public isUser(): boolean {
		//todo catcher here

		let result: boolean = false;
		const userRole: UserRole = Constants.USER_ROLES[this.userDetails.role];
		if (userRole && userRole.rank) {
			result = userRole.rank === Constants.USER_ROLES.user.rank;
		}
		return this.localStorageService.getUserKey(this.localStorageKeys.token) && result;
	}

	/**
	 * logout the user and clear the local storage
	 */
	public logout(): void {
		if (this.userDetails && this.isAuthenticated()) {
			this.http
				.post<AuthUserModel>(`${Constants.BASE_API_URL}/auth/logout`, {})
				.pipe(
					tap((res: AuthUserModel) => {
						this.intercomService.shutIntercomDown();
					}),
					catchError(error => {
						//catch the error from sever side and show as the alert
						//we can later show as toast or message on the page.
						return observableThrowError(error);
					})
				)
				.subscribe();
		}
		this.localStorageService.clearUserDetails();
		this.localStorageService.clearProjectSearch();
		this.userDetailsObservable.next(null);
	}

	/**
	 * Update the user details entity
	 * @param entity
	 */
	public updateEntity(entity: EntityModel) {
		this.userDetails.entity = entity;
		this.localStorageService.setUserDetails(this.userDetails);
		this.userDetailsObservable.next(this.userDetails);
	}

	/**
	 * Save the User Details into the local storage
	 * @param userDetails
	 */
	private saveUserDetails(userDetails: AuthUserModel): void {
		this.userDetails = userDetails;
		this.localStorageService.setUserDetails(userDetails);
	}

	private setUser(res: AuthUserModel) {
		this.saveUserDetails(res);
		this.userDetailsObservable.next(this.getUserDetail());

		// store the user details in sentry
		const scope = Sentry.getCurrentScope();
		scope.setUser({
			id: res.id,
			email: res.email,
			username: `${res.firstName} ${res.lastName}`,
		});
	}
}
