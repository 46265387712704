// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colour-modal {
  width: 300px;
}

.colour-sample-area {
  height: 300px;
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/components/colour-edit-dialog/colour-edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,aAAA;EACA,YAAA;AACD","sourcesContent":[".colour-modal {\n\twidth: 300px;\n}\n\n.colour-sample-area {\n\theight: 300px;\n\twidth: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
