import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ItemsResponseData, ReferenceCode } from '../interfaces';
import { ProductModel } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ProductService extends BaseService<ProductModel> {
	constructor(httpClient: HttpClient) {
		super(httpClient, Constants.END_POINTS.product, ProductModel);
	}

	/**
	 * Post to list action in endpoint and return array of modelType
	 * @param postObject
	 * @param populateFields
	 */
	public postPopulatedList(postObject: Partial<ProductModel>, populateFields: string[]): Observable<Array<ProductModel>> {
		return this.httpClient
			.post<ItemsResponseData<ProductModel>>(`${this.baseUrl}/${this.endPoint}/populated-list`, {
				params: postObject,
				populateFields: populateFields,
			})
			.pipe(map(res => res.items.map(product => new ProductModel(product))));
	}
}

@Injectable()
export class GetActiveProductsResolver {
	constructor(private productService: ProductService) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.productService.postList({ isActive: true });
	}
}
