import { UntypedFormGroup } from '@angular/forms';

// import {FormErrorService} from '../services/form-error.service';

export abstract class BaseModel {
	public id: string;
	public updatedAt: Date;
	public createdAt: Date;
	public formGroup: UntypedFormGroup;
	public formValidationErrors: Array<string>;
	public ignoreList: Array<string> = [];

	private static postObjectKeysToRemove: { [key: string]: string } = {
		createdAt: 'createdAt',
		documentType: 'documentType',
		fileFile: 'fileFile',
		formGroup: 'formGroup',
		ignoreList: 'ignoreList',
		logoFile: 'logoFile',
		msdsSheetFile: 'msdsSheetFile',
		order: 'order',
		pictureFile: 'pictureFile',
		specSheetFile: 'specSheetFile',
		thumbnailFile: 'thumbnailFile',
		updatedAt: 'updatedAt',
		fileUrl: 'fileUrl',
	};

	//TODO remove this when unknown(true) is removed from the joi helper
	private static postBoqBulkObjectKeysToRemove: { [key: string]: string } = {
		formGroup: 'formGroup',
		createdAt: 'createdAt',
		updatedAt: 'updatedAt',
		isSelected: 'isSelected',
		totalPrice: 'totalPrice',
		totalProfit: 'totalProfit',
		ignoreList: 'ignoreList',
	};

	/**
	 *
	 * @param json
	 */
	merge(json: object) {
		for (const key in json) {
			if (this.hasOwnProperty(key)) {
				this[key] = json[key];
			}
		}
		return this;
	}

	/**
	 * When creating a post object, JSON.stringify will call this method and ignore any specified keys
	 * @param key
	 * @param value
	 */
	static removeBulkBoqReplacer(key, value) {
		//TODO remove this when unknown(true) is removed from the joi helper
		return BaseModel.postBoqBulkObjectKeysToRemove[key] ? undefined : value;
	}

	/**
	 * When creating a post object, JSON.stringify will call this method and ignore any specified keys
	 * @param key
	 * @param value
	 */
	static removeKeysReplacer(key, value) {
		return BaseModel.postObjectKeysToRemove[key] ? undefined : value;
	}

	/**
	 * Returns an object to post to the server. All properties that have an id are replaced with their id.
	 */
	getPostObject() {
		const postObject: BaseModel = JSON.parse(JSON.stringify(this, BaseModel.removeKeysReplacer));

		this.replaceObjectsWithIds(postObject, this.ignoreList);

		return postObject;
	}

	/**
	 * Recursive function to replace objects with their ids.
	 * @param obj
	 * @param ignoreList
	 */
	private replaceObjectsWithIds(obj: any, ignoreList: Array<string> = []) {
		// Loop through the objects keys

		for (const key in obj) {
			if (ignoreList.includes(key)) {
				continue;
			}
			// If the objects own property is an object, test if it has an id
			if (obj[key] && obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
				if (obj[key].hasOwnProperty('id')) {
					// This property has an id, set the object to the id
					obj[key] = obj[key]['id'];
				} else {
					// This property has no id field, recursively check it's properties
					this.replaceObjectsWithIds(obj[key], ignoreList);
				}
			}
		}
	}

	/**
	 * converting object to the Model
	 * @param input
	 */
	public deserialize(input: any): any {
		Object.assign(this, input);
		return this;
	}

	/**
	 * Clear form validation errors
	 */
	public clearValidationErrors(): void {
		this.formValidationErrors = undefined;
	}

	/**
	 * Creating FormController for the Model
	 */
	public abstract createForm(): void;

	/**
	 * Get validation errors for formGroup
	 * @param formGroup: optional - if not provided, this.formGroup will be used
	 */
	public populateErrors(formGroup?: UntypedFormGroup): void {
		if (formGroup === undefined) {
			formGroup = this.formGroup;
		}
		if (formGroup.valid) {
			this.clearValidationErrors();
		} else {
			// const formErrorService = new FormErrorService();
			// this.formValidationErrors = formErrorService.getErrorsFromForm(formGroup);
		}
	}

	/**
	 * Create FormData to post Files
	 */
	public abstract getFormData(): FormData;
}
