import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Constants } from '../helpers';
import { NewTask, Task, TaskResponse, TasksResponse, TaskWithEmailResponse } from '../interfaces';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class TaskService {
	public tasksObservable: Observable<any> = null;

	constructor(private http: HttpClient) {}

	/**
	 * Gets all tasks
	 * @returns {Observable<any>}
	 */
	public getTasks() {
		this.tasksObservable = this.http.get<TasksResponse>(`${Constants.BASE_API_URL}/task/`).pipe(
			map(res => res.tasks.map(item => new Task(item))),
			shareReplay(1)
		);
		return this.tasksObservable;
	}

	/**
	 * Gets all tasks belonging to a project
	 * @returns {Observable<any>}
	 */
	public getProjectsTasks(projectId) {
		return this.http
			.post<TasksResponse>(`${Constants.BASE_API_URL}/task/project`, {
				projectId,
			})
			.pipe(map(res => res.tasks.map(item => new Task(item))));
	}

	/**
	 * Adds a new task
	 * @param {Task} task
	 * @param {string} projectId
	 * @returns {Observable<{task: Task, email: string}>}
	 */
	public createTask(task: NewTask, projectId: string) {
		return this.http
			.post<TaskWithEmailResponse>(`${Constants.BASE_API_URL}/task/`, {
				task,
				projectId,
			})
			.pipe(map(res => new Task(res.data.task)));
	}

	/**
	 * Get tasks for the given user id (the 10 next)
	 * @param {string} id
	 * @returns {Observable<any>}
	 */
	public getAssignedTasks(userId: string) {
		return this.http.get<TasksResponse>(`${Constants.BASE_API_URL}/task/assigned/${userId}`).pipe(map(res => res.tasks.map(item => new Task(item))));
	}

	/**
	 * Toggle isComplete field for Task
	 * @param {string} taskId
	 * @param {string} projectId
	 * @returns {Observable<Task>}
	 */
	public toggleTaskComplete(taskId: string, projectId: string): Observable<Task> {
		return this.http
			.post<TaskResponse>(`${Constants.BASE_API_URL}/task/toggle-complete`, {
				taskId,
				projectId,
			})
			.pipe(map(res => new Task(res.task)));
	}

	/**
	 * Completes a task
	 * @param {string} taskId
	 * @param {Date} followUpDate
	 * @param {string} followUpAction
	 * @param {string} projectId
	 */
	public completeTask(taskId: string, followUpDate: Date, followUpAction: string, projectId: string) {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/task/complete`, {
				followUpDate,
				followUpAction,
				taskId,
				projectId,
			})
			.pipe(map(res => res.status));
	}

	/**
	 * Gets Tasks for pagination
	 * @returns {Observable<any>}
	 */
	public postPaginate(skip: number, limit: number, userIds: string[]): Observable<TasksResponse> {
		return this.http
			.post<TasksResponse>(`${Constants.BASE_API_URL}/task/paginate`, {
				skip,
				limit,
				userIds,
			})
			.pipe(map(res => new TasksResponse(res)));
	}

	public getQuoteDueDateTask(projectId: string) {
		return this.http
			.post<TaskResponse>(`${Constants.BASE_API_URL}/task/quote-due-date`, {
				projectId,
			})
			.pipe(map(res => new Task(res.task)));
	}
}

@Injectable()
export class GetAssignedTasksResolver {
	constructor(
		private taskService: TaskService,
		private localStorageService: LocalStorageService
	) {}

	public resolve(): Observable<any> | Promise<any> | any {
		return this.taskService.getAssignedTasks(this.localStorageService.getUserId());
	}
}
