import { Validators } from '@angular/forms';

import { CustomValidators } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { InclusionExclusionTemplateModel } from '../models';

export interface IncExcCommentTemplate {
	id?: string;
	title: string;
	isActive: boolean;
	isInclusion: boolean;
	message: string;
	company: string;
}

export function GetIncExcCommentTemplateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Title',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Type',
			validators: [Validators.required],
		},
		{
			name: 'Message',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator, Validators.maxLength(3000)],
		},
	]);
}

export interface UserIncExcCommentTemplate {
	template: InclusionExclusionTemplateModel;
	isFavourite: boolean;
}

export interface IncExcCommentTemplateResponse {
	status: boolean;
	item: InclusionExclusionTemplateModel;
}
