import { defaultFillColour, defaultFontSize, defaultLineDash, defaultStrokeColour, defaultStrokeWeight, defaultTextColour, LineStyles } from '../measuring-tool.constants';

export class MeasuringToolStyle {
	public fillColour: string = defaultFillColour;
	public fontSize: number = defaultFontSize;
	public lineStyle: string = LineStyles.solid;
	public lineDash: number[] = defaultLineDash;
	public highlight: boolean = false;
	public itemSize?: number;
	public strokeWeight: number = defaultStrokeWeight;
	public strokeColour: string = defaultStrokeColour;
	public textColour: string = defaultTextColour;

	constructor(model?: Partial<MeasuringToolStyle>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
