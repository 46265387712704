import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ProjectModel, UserModel } from '../../../../models';
import { ProjectTimelineComponent } from '../project-timeline/project-timeline.component';
import { CommentService } from '../../../../services/comment.service';

@Component({
	selector: 'app-comment-preview',
	templateUrl: './comment-preview.component.html',
	styleUrls: ['./comment-preview.component.scss'],
})
export class CommentPreviewComponent implements OnInit, OnDestroy {
	@Input() public project: ProjectModel;
	@ViewChild('button') public button: ElementRef;
	@ViewChild('modal') public modal: ElementRef;
	@ViewChild('timeline') public timeline: ProjectTimelineComponent;

	public showDialog: boolean = false;
	private clickListener: Function;

	constructor(private renderer: Renderer2, private commentService: CommentService) {}

	public ngOnInit() {
		//subscribe to the service to close the dialog if it isn't the active dialog
		this.commentService.activeDialog$.subscribe(activeDialog => {
			if (activeDialog !== this) {
				this.closeDialog();
			}
		});
	}

	public ngOnDestroy(): void {
		this.removeClickListener();
	}

	public toggleModal(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (!this.showDialog) {
			//open dialog and set it as the active dialog
			this.commentService.setActiveDialog(this);
			this.showDialog = true;
			this.addClickListener();
		} else {
			this.closeDialog();
		}
	}

	public closeDialog(): void {
		this.removeClickListener();
		this.showDialog = false;
	}

	public addClickListener(): void {
		// listen for clicks outside of dialog
		this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
			if (this.modal && !this.modal.nativeElement.contains(event.target)) {
				this.closeDialog();
			}
		});
	}

	public removeClickListener(): void {
		if (this.clickListener) {
			this.clickListener();
			this.clickListener = null;
		}
	}

}
