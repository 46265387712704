import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { GetTaskValidation, Task } from '../../../interfaces';
import { UserModel } from '../../../models';
import { TaskService } from '../../../services/task.service';

@Component({
	selector: 'app-add-followup-task-dialog',
	templateUrl: './add-followup-task-dialog.component.html',
})
export class AddFollowupTaskDialogComponent implements OnInit {
	currentTask: Task;
	taskValidator;
	TASK_TYPES = Constants.TASK_TYPES;
	sendingEmailSubscription: Subscription;
	public dateString: string;
	public time: Date;

	@Input() data: { currentUser: UserModel; targetTask: Task };

	constructor(
		private snack: SnackBarHelperComponent,
		private taskService: TaskService
	) {}

	ngOnInit() {
		this.taskValidator = GetTaskValidation();
		this.currentTask = {
			action: '',
			dueDate: new Date(),
			assignee: this.data.targetTask.assignee,
			author: this.data.currentUser,
			project: this.data.targetTask.project,
			isComplete: false,
			taskType: this.TASK_TYPES.followUp,
		};
		this.taskValidator.TaskAssignee.setValue(this.data.targetTask.assignee);

		this.time = new Date();
		this.dateString = this.time.toISOString().split('T')[0];
	}

	public onSaveFollowUpTask() {
		return new Promise((resolve, reject) => {
			const errors = ValidatorHelper.checkErrors(this.taskValidator);
			if (errors) {
				this.snack.snackError(errors);
				reject();
				return;
			}
			this.sendingEmailSubscription = this.taskService
				.completeTask(this.data.targetTask.id, this.currentTask.dueDate, this.currentTask.action, this.currentTask.project.id)
				.pipe(finalize(() => this.sendingEmailSubscription.unsubscribe()))
				.subscribe(resolve, reject);
		});
	}

	public changeDate() {
		const DateTimeCombinedMoment = moment(this.dateString);
		const timeMoment = moment(this.time);

		DateTimeCombinedMoment.set({
			hour: timeMoment.get('hours'),
			minute: timeMoment.get('minutes'),
			second: 0,
			millisecond: 0,
		});

		this.currentTask.dueDate = DateTimeCombinedMoment.toDate();
	}
}
