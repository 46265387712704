import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TEXT } from '../../../helpers';
import { Attachment } from '../../../interfaces';

@Component({
	selector: 'app-attachment-view-dialog',
	templateUrl: './attachment-view-dialog.component.html',
})
export class AttachmentViewDialogComponent implements OnInit {
	@Input()
	public attachment: Attachment;
	@Input()
	public busyPromises: Promise<any>[];
	@Input()
	public isEditDisabled: boolean = false;

	@Output()
	private openAttachment: EventEmitter<Attachment> = new EventEmitter<Attachment>();
	@Output()
	private toggleActiveAttachment: EventEmitter<Attachment> = new EventEmitter<Attachment>();

	public activeState: boolean;
	public textConstants: typeof TEXT = TEXT;

	constructor() {}

	public ngOnInit(): void {
		this.activeState = this.attachment.isActive;
	}

	public open(): void {
		this.openAttachment.emit(this.attachment);
	}

	public toggleActive(): void {
		this.toggleActiveAttachment.emit(this.attachment);
	}
}
