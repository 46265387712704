import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[numberInputDirective]',
})
export class NumberInputDirective {
	@Input() set allowNegativeNumber(isNegative: boolean) {
		this.REGEX_TYPE = isNegative ? new RegExp(/[\-\d]/g) : new RegExp(/\d/g);
		this.REGEX_PASTE = isNegative ? new RegExp(/^-?\d*(\.\d+)?$/) : new RegExp(/^\d*(\.\d+)?$/gm);
	}

	private REGEX_TYPE: RegExp;
	private REGEX_PASTE: RegExp;
	private PERMITTED_KEYS: Array<string> = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
	private PERMITTED_UNIQUE_KEYS: Array<string> = ['.']; // Keys that should only appear once, eg. decimal point, exponent, ...

	constructor(private element: ElementRef) {}

	@HostListener('keydown', ['$event'])
	public onKeyDown(e: KeyboardEvent): void {
		if (
			this.PERMITTED_KEYS.indexOf(e.key) !== -1 ||
			(e.key === 'a' && e.ctrlKey) || // Allow: Ctrl+A
			(e.key === 'c' && e.ctrlKey) || // Allow: Ctrl+C
			(e.key === 'v' && e.ctrlKey) || // Allow: Ctrl+V
			(e.key === 'x' && e.ctrlKey) || // Allow: Ctrl+X
			(e.key === 'a' && e.metaKey) || // Cmd+A (Mac)
			(e.key === 'c' && e.metaKey) || // Cmd+C (Mac)
			(e.key === 'v' && e.metaKey) || // Cmd+V (Mac)
			(e.key === 'x' && e.metaKey) // Cmd+X (Mac)
		) {
			return; // let it happen, don't do anything
		}

		// IMPORTANT! - for this logic to work <inputs> need to be type='text'
		// Prevent typing multiple of the unique keys
		const value = this.element && this.element.nativeElement ? this.element.nativeElement.value : null;
		if (this.PERMITTED_UNIQUE_KEYS.indexOf(e.key) !== -1) {
			if (value.indexOf(e.key) !== -1) {
				e.preventDefault();
			}

			return;
		}

		// Ensure that it is a number and stop the keypress otherwise,
		if (e.shiftKey || !this.isValidInput(e, this.REGEX_TYPE)) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	public handlePaste(e: ClipboardEvent): void {
		let clipboardData;
		let pastedData;

		// Get pasted data via clipboard API
		clipboardData = e.clipboardData;
		pastedData = clipboardData.getData('Text').trim();

		if (!pastedData.match(this.REGEX_PASTE)) {
			this.element.nativeElement.value = pastedData;
			// Stop data actually being pasted into div
			e.stopPropagation();
			e.preventDefault();
		}
	}

	private isValidInput(e: KeyboardEvent, regEx: RegExp): boolean {
		if (this.element.nativeElement.value.indexOf('-') !== -1 && e.key.match(/-/g)) {
			return false;
		}
		return !!e.key.match(regEx);
	}
}
