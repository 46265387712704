import { Component, NgModuleFactory, OnInit, Type } from '@angular/core';
import { SlideOverService } from '../../services/slide-over.service';

@Component({
	selector: 'paint-slide-over',
	templateUrl: './slide-over.component.html',
})
export class SlideOverComponent implements OnInit {
	public isOpen: boolean = false;
	public component: Type<any>;
	public module: Type<any>;
	public inputs: Record<string, unknown>;
	public title: string;
	public subtitle?: string;

	constructor(private slideOverService: SlideOverService) {}

	public ngOnInit() {
		this.slideOverService.config.subscribe(next => {
			if (next) {
				// only delay the content update if the slide-over is currently open
				if (this.isOpen) {
					this.isOpen = false;
					setTimeout(() => {
						this.update(next);
					}, 300);
				} else {
					this.update(next);
				}
			} else {
				this.isOpen = false;
			}
		});
	}

	private update(next): void {
		this.component = next.component;
		this.inputs = next.inputs;
		this.module = next.module;
		this.title = next.title;
		this.subtitle = next.subtitle;
		this.isOpen = true;
	}

	public close(): void {
		this.slideOverService.close();
	}
}
