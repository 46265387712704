import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { RouteNavigationMenuConfig } from '../../../../@itc-core/components/navigation-panels/route-navigation-menu/common/route-navigation-menu.models';
import { CommunicationService } from '../communication/common/communication.service';

@Component({
	selector: 'paint-route-navigation-container',
	templateUrl: './route-navigation-container.component.html',
	styleUrls: ['./route-navigation-container.component.scss'],
})
export class RouteNavigationContainerComponent {
	public communicationObservable: Observable<String[]>;

	@Input()
	public routeNavigationMenuConfig: RouteNavigationMenuConfig = new RouteNavigationMenuConfig();

	constructor(private communicationService: CommunicationService) {
		this.communicationObservable = this.communicationService.paintCommunication;
	}
}
