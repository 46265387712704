import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { AuthUserModel, UserModel } from '../../models';

@Injectable()
export class EstimatorUserService {
	private userSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
	public userDataStream: Observable<UserModel> = this.userSubject.asObservable().pipe(
		delay(0),
		tap((user: UserModel | null) => {
			this.hasUser = !!user;
		})
	);

	public hasUser: boolean = false;

	constructor() {}

	public setUser(user: UserModel): void {
		this.userSubject.next(user);
	}

	public setUserFromAuth(authUser: AuthUserModel | null) {
		if (authUser) {
			this.userSubject.next(new UserModel(authUser));
		} else {
			this.userSubject.next(null);
		}
	}
}
