import { Component } from '@angular/core';
import {
	ShiftsNavigationMenuComponent
} from '../../core/components/shifts-navigation-container/shifts-navigation-menu/shifts-navigation-menu.component';
import { RouterOutlet } from '@angular/router';
import { ProductTourModule } from '../../components/product-tour/common/product-tour.module';

@Component({
	selector: 'app-shifts-layout',
	templateUrl: './shifts-layout.component.html',
	standalone: true,
	imports: [
		ShiftsNavigationMenuComponent,
		RouterOutlet,
		ProductTourModule,
	],
})
export class ShiftsLayoutComponent {}
