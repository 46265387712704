import { ContentCardDropdownItem, ContentCardLink } from './content-card.interfaces';

export class ContentCardModel {
	public buttonText?: string;
	public dropdownItems?: ContentCardDropdownItem[] = [];
	public fillHeight?: boolean = true;
	public hasDivider?: boolean;
	// This is a hacky fix, the correct method is to make the link IconButtonConfig
	// this issue is that ContentCardLink has text BUT has IconButtonConfig label
	// were too short of time to fix this everywhere right now
	public isAddSuffix?: boolean = true;
	public link?: ContentCardLink;
	public titleText: string = '';

	constructor(model?: Partial<ContentCardModel>) {
		if (model) {
			Object.assign(this, model);
		}
	}
}
