import { variables } from '_variables';
import { ProjectStatus } from './project.interfaces';

export const projectStatuses: { [key: string]: ProjectStatus } = {
	target: {
		value: 'target',
		text: 'Target',
		ordinal: 1,
		colour: variables.targetColour,
	},
	declined: {
		value: 'declined',
		text: 'Declined',
		ordinal: 2,
		colour: variables.declinedColour,
	},
	active: {
		value: 'active',
		text: 'Active',
		ordinal: 3,
		colour: variables.activeColour,
	},
	quoteReady: {
		value: 'quoteReady',
		text: 'Quote Ready',
		ordinal: 4,
		colour: variables.quoteReadyColour,
	},
	quoteApproved: {
		value: 'quoteApproved',
		text: 'Quote Approved',
		ordinal: 5,
		colour: variables.quoteApprovedColour,
	},
	submitted: {
		value: 'submitted',
		text: 'Submitted',
		ordinal: 6,
		colour: variables.submittedColour,
	},
	won: {
		value: 'won',
		text: 'Won',
		ordinal: 7,
		colour: variables.wonColour,
	},
	lost: {
		value: 'lost',
		text: 'Lost',
		ordinal: 8,
		colour: variables.lostColour,
	},
	live: {
		value: 'live',
		text: 'Live',
		ordinal: 9,
		colour: variables.liveColour,
	},
	completed: {
		value: 'completed',
		text: 'Completed',
		ordinal: 10,
		colour: variables.completedColour,
	},
};
