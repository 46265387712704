import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Injectable()
export class NavigationService {
	constructor(
		private router: Router,
		private route: ActivatedRoute
	) {
		//
	}

	/**
	 * Gets the Query Params.
	 * @param {string} param
	 * @returns {string}
	 */
	public getQueryParam(param: string): string {
		return this.route.snapshot.queryParams[param];
	}

	/**
	 * Sets new params to Query Params.
	 * @paramId {string} paramId
	 * @paramId {string} value
	 */
	public setQueryParam(paramId: string, value: string): void {
		const newQuery = {};
		newQuery[paramId] = value;
		void this.router.navigate([], {
			queryParams: newQuery,
			queryParamsHandling: 'merge',
		});
	}

	/**
	 * Removes extra params from Query Params.
	 * @param {string} paramId
	 * @param {boolean} preventNavigation will ensure that the url with the query params will not be in the navigation history
	 */
	public removeQueryParam(paramId?: string, preventNavigation: boolean = true): void {
		// If we have a paramId just remove that
		// otherwise remove all params
		if (paramId) {
			const newQuery = {};
			newQuery[paramId] = undefined;
			void this.router.navigate([], {
				queryParams: newQuery,
				queryParamsHandling: 'merge',
				skipLocationChange: preventNavigation,
			});
		} else {
			void this.router.navigate([], {
				queryParams: undefined,
				skipLocationChange: preventNavigation,
			});
		}
	}

	/**
	 * Adds and Removes params from Query Params simultaneously. Use to avoid async issues.
	 * @param remove
	 * @param add [{paramId: string, value: string}]
	 */
	public buildQueryParams(remove: string[] = [], add: { [paramId: string]: string }[] = []): void {
		const newQuery = {};
		for (const paramId of remove) {
			newQuery[paramId] = undefined;
		}
		for (const query of add) {
			newQuery[query.paramId] = query.value;
		}
		void this.router.navigate([], {
			queryParams: newQuery,
			queryParamsHandling: 'merge',
		});
	}

	/**
	 * Gets the path param
	 * @param {string} param
	 * @param {ActivatedRoute} relativeRoute
	 * @returns {string}
	 */
	public getRelativePathParam(param: string, relativeRoute: ActivatedRoute): string {
		return relativeRoute.snapshot.params[param];
	}

	/**
	 * Navigates to the supplied route, relative to another supplied route
	 * @param param
	 * @param {ActivatedRoute} relativeRoute
	 */
	public setRelativeRoute(param: string, relativeRoute: ActivatedRoute): void {
		void this.router.navigate([param], {
			relativeTo: relativeRoute,
		});
	}

	/**
	 * Navigates to the supplied route
	 * @param params
	 * @param extras
	 */
	public setRoute(params: Array<string>, extras: NavigationExtras = {}): void {
		void this.router.navigate(params, extras);
	}

	/**
	 * Navigates to the supplied route and reloads
	 * @param params
	 * @param extras
	 */
	public setRouteAndReload(params: Array<string>, extras: NavigationExtras = {}): void {
		void this.router.navigate(params, extras).then(() => {
			window.location.reload();
		});
	}
}
