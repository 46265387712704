import { Component, Input, OnInit } from '@angular/core';

import { Email } from '../../../interfaces';
import { ExportService } from '../../../services/export.service';
import { S3Service } from '../../../services/s3.service';

@Component({
	selector: 'app-email-view-dialog',
	templateUrl: './email-view-dialog.component.html',
	styleUrls: ['./email-view-dialog.component.scss'],
})
export class EmailViewDialogComponent implements OnInit {
	@Input() public readonly email: Email;
	public downloadingAttachmentPromise: Promise<Blob | File>;

	constructor(
		private s3Service: S3Service,
		private exportService: ExportService
	) {}

	ngOnInit() {}

	/**
	 * Downloads the given blob with the give name.
	 * @param blob
	 * @param fileName
	 */
	private downloadBlob(blob: Blob, fileName: string) {
		const a = document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		a.remove();
	}

	/**
	 * Downloads the attachments of the given mail.
	 * @param email
	 */
	public openAttachments(email: Email) {
		if (email && email.icalEvent) {
			const icsFile = new Blob([email.icalEvent], { type: 'text/calendar' });
			this.downloadBlob(icsFile, `${email.subject}_calendar`);
		}
		if (email && email.attachmentKey) {
			this.downloadingAttachmentPromise = new Promise((resolve, reject) => {
				this.s3Service.getSignedUrl(email.attachmentKey).subscribe(url => {
					this.exportService.downloadAwsFile(url).subscribe(resolve, reject);
				}, reject);
			});
			this.downloadingAttachmentPromise.then(blob => this.downloadBlob(blob, `${email.subject}_attachment`));
		}
	}
}
