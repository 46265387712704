import { Injectable } from '@angular/core';
import { DialogButtonConfig, DialogComponent } from '../../@mos-core/components/dialog/dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialogService {
	private dialog: DialogComponent;

	public setDialog(dialog: DialogComponent) {
		this.dialog = dialog;
	}

	public confirm(message: string, label: string, confirmAction: () => void): void {
		const buttons: DialogButtonConfig[] = [
			{
				label: label,
				type: 'danger',
				action: () => {
					confirmAction();
					this.dialog.close();
				}
			},
			{
				label: 'Cancel',
				type: 'normal',
				action: () => this.dialog.close()
			}
		];

		this.dialog.message = message;
		this.dialog.buttons = buttons;
		this.dialog.open();
	}
}