import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NavigationWarningService } from '../../../@itc-core/components/navigation-warning/common/navigation-warning.service';

export class DialogButtonConfig {
	label: string;
	class?: string;
	type?: 'normal' | 'primary' | 'danger' | 'caution';
	action?: Function;
}

const DialogButtonStyles: { [key: string]: string } = {
	normal: 'normalDialogButton tw-inline-flex tw-ml-3 tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-1 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto tw-cursor-pointer',
	primary: 'primaryDialogButton tw-inline-flex tw-ml-3 tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-primary2 sm:tw-w-auto tw-cursor-pointer',
	danger: 'dangerDialogButton tw-inline-flex tw-ml-3 tw-justify-center tw-rounded-md tw-bg-red-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-red-500 sm:tw-w-auto tw-cursor-pointer',
	caution: 'cautionDialogButton tw-inline-flex tw-ml-3 tw-justify-center tw-rounded-md tw-bg-amber-400 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-amber-500 sm:tw-w-auto tw-cursor-pointer',
};

@Component({
	selector: 'mos-dialog',
	standalone: true,
	imports: [],
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.css',
})
export class DialogComponent implements AfterViewInit {
	@ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
	@Input() buttons: DialogButtonConfig[];
	@Input() icon?: 'info' | 'warning' | 'danger' | 'success';
	@Input() message?: string;

	private boundDestroy: () => void;

	constructor(private navWarningService: NavigationWarningService) {
		this.boundDestroy = this.destroy.bind(this);
	}

	open() {
		this.dialog.nativeElement.showModal();
	}

	close() {
		this.dialog.nativeElement.classList.add('closing');
		this.dialog.nativeElement.addEventListener('animationend', this.boundDestroy);
	}

	destroy() {
		this.dialog.nativeElement.close();
		this.dialog.nativeElement.classList.remove('closing');
		this.dialog.nativeElement.removeEventListener('animationend', this.boundDestroy);
	}

	ngAfterViewInit() {
		this.dialog.nativeElement.addEventListener('click', (event: MouseEvent) => {
			const target = event.target as Element;
			if (target.nodeName === 'DIALOG') {
				this.close();
				this.navWarningService.updateModalResponse(false);
			}
		});
	}

	protected readonly DialogButtonStyles = DialogButtonStyles;
}
