export enum projectRoutes {
	applicationRates = 'application-rates',
	attachments = 'attachments',
	boqExport = 'boq-export',
	charge = 'charge',
	charges = 'charges',
	comment = 'comment',
	discounts = 'discounts',
	emails = 'emails',
	export = 'export',
	inclusionsExclusions = 'inclusions-exclusions',
	invoice = 'invoice',
	labourCosts = 'labour-costs',
	officeFolder = 'office-folder',
	overview = 'overview',
	productPurchasing = 'product-purchasing',
	qaFolder = 'qa-folder',
	quote = 'quote',
	quoteProposal = 'quote-proposal',
	siteFolder = 'site-folder',
	tasks = 'tasks',
	tenderDocument = 'tender-document',
	time = 'time',
	variationQuote = 'variation-quote',
	variations = 'variations',
	versions = 'versions',
	safetyFolder = 'safety-folder',
}
