/**
 * Created by Tom Broadwood from In The Code on 25/5/18.
 */
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Constants } from './constants';

/**
 * This helper is for everything Validation,
 * it creates FormControls from schemas on the component,
 * throws error messages when it finds errors
 *
 * Component schema should look like this:
 const colourSchema = [
 {
   name: 'Name', validators: [Validators.required,Validators.minLength(3),Validators.maxLength(255)]
   },
 {
    name: 'Green',validators: [Validators.required,Validators.maxLength(3),Validators.minLength(1),Validators.max(255), Validators.min(0),]
  },
 ];
 this.validators = ValidatorHelper.formBuilder(colourSchema);
 */
export class ValidatorHelper {
	//creates FormControl's from Validation parser on component
	public static formBuilder(formValidations, isDisabled: boolean = false): any {
		const result = {};
		for (const item of formValidations) {
			let value = '';
			if (item.value !== undefined && item.value !== null) {
				value = item.value;
			}

			result[item.name] = new UntypedFormControl({ value: value, disabled: isDisabled }, item.validators);
		}
		return result;
	}

	//creates FormControl's from Validation parser on component
	public static formBuilderWithDefault(formValidations) {
		const result = {};
		for (const item of formValidations) {
			result[item.name] = new UntypedFormControl(item.initValue, item.validators);
		}
		return result;
	}

	/**
	 * checks the control for an error, then returns an error message from Constants
	 * @param errors
	 * @param {string} name
	 * @returns {string}
	 */
	public static populateErrorMessage(errors: any, name: string): string {
		let errorMessage = '';

		// tslint:disable-next-line:forin
		for (const errorItem in errors) {
			switch (errorItem) {
				case 'min':
					errorMessage += Constants.VALIDATION_ERROR_MIN(name, errors[errorItem].min);
					break;
				case 'max':
					errorMessage += Constants.VALIDATION_ERROR_MAX(name, errors[errorItem].max + 1);
					break;
				case 'required':
					errorMessage += Constants.VALIDATION_ERROR_REQUIRED(name);
					break;
				// case 'requiredtrue':
				//   errorMessage += Constants.VALIDATION_ERROR_REQUIREDTRUE(name);
				//   break;
				case 'email':
					errorMessage += Constants.VALIDATION_ERROR_EMAIL(name);
					break;
				case 'minlength':
					errorMessage += Constants.VALIDATION_ERROR_MINLENGTH(name, errors[errorItem].requiredLength);
					break;
				case 'maxlength':
					errorMessage += Constants.VALIDATION_ERROR_MAXLENGTH(name, errors[errorItem].requiredLength);
					break;
				case 'minLengthArray':
					errorMessage += Constants.VALIDATION_ERROR_MINLENGTHARRAY(name, errors[errorItem].requiredLength);
					break;
				case 'arrayItemsUnique':
					errorMessage += Constants.VALIDATION_ERROR_ARRAYUNIQUE(errors[errorItem].name);
					break;
				case 'whitespace':
					errorMessage += Constants.VALIDATION_ERROR_WHITESPACE(name);
					break;
				// case 'pattern':
				//   errorMessage += Constants.VALIDATION_ERROR_PATTERN(name);
				//   break;
				// case 'nullvalidator':
				//   errorMessage += Constants.VALIDATION_ERROR_NULLVALIDATOR(name);
				//   break;
				default: //in the event of an error you should always return something to the front
					alert(`This Validation type is not being handled ${errorItem}`);
			}
		}
		return errorMessage;
	}

	/**
	 * checks the FormControl object for errors, if errors => populateErrorMessage
	 * then collects error messages in a string[], logs it, then passes to the front
	 * @returns {any}
	 * @param form
	 */
	public static checkErrors(form: any) {
		let errorString = '';

		if (form instanceof UntypedFormGroup) {
			for (const control in form.controls) {
				const errorObj = form.controls[control].errors;
				if (errorObj) {
					errorString += ValidatorHelper.populateErrorMessage(errorObj, control);
				}
			}
		} else {
			for (const item in form) {
				const errorObj = form[item].errors;
				if (errorObj) {
					errorString += ValidatorHelper.populateErrorMessage(errorObj, item);
				}
			}
		}

		if (errorString.length < 1) {
			return undefined;
		}

		form.isSubmitted = true;

		return errorString;
	}

	/**
	 * Checks for any errors given this structure:
	 *  { name1: { formGroupName1: formGroup, formGroup2: formGroup }, name2: ... }
	 * @param controls
	 * @returns {any}
	 */
	public static checkErrorsInControlGroupArray(controls: any[]) {
		let errorString = '';
		for (const control in controls) {
			if (!controls[control] || !controls.hasOwnProperty(control)) {
				continue;
			}
			const errorSubString = this.checkErrors(controls[control]);
			errorString += errorSubString || '';
		}
		errorString.trim();
		if (errorString.length < 1) {
			return undefined;
		}
		return errorString;
	}

	/**
	 * checks the FormControl object for dirty flags,
	 * @param {FormControl} control
	 */
	public static isDirty(control: UntypedFormControl): Boolean {
		for (const item in control) {
			if (control[item].dirty) {
				control[item].markAsPristine();
				return true;
			}
		}
		return false;
	}

	/**
	 * Enables or disables all form controls in an array
	 * @param {FormControl[]} controls
	 * @param {boolean} enabled
	 * @param {{}} exclude
	 */
	public static setFormControlsEnabled(controls: UntypedFormControl[], enabled: boolean, exclude: {}) {
		Object.keys(controls).forEach(key => {
			if (controls.hasOwnProperty(key) && !exclude.hasOwnProperty(key) && controls[key] instanceof UntypedFormControl) {
				this.setFormControlEnabled(controls[key], enabled);
			}
		});
	}

	/**
	 * Enables or disables a form control
	 * @param {FormControl} control
	 * @param {boolean} enabled
	 */
	public static setFormControlEnabled(control: UntypedFormControl, enabled: boolean) {
		if (enabled) {
			control.enable();
		} else {
			control.disable();
		}
	}

	/**
	 * Dynamically creates an array of form controls based on validation and an id.
	 * If the collection doesn't have a control for that id, a new one is created and added to both the collection and the formGroup.
	 * @param {() => {}} getValidation - function should return an object with form controls
	 * @param validationCollection
	 * @param id
	 * @param {FormGroup} formGroup
	 * @returns {any}
	 * @constructor
	 */
	public static getDynamicValidationControl(getValidation: () => {}, validationCollection, id, formGroup: UntypedFormGroup) {
		if (!validationCollection[id]) {
			validationCollection[id] = getValidation();

			this.addControlToFormGroup(formGroup, validationCollection[id], id);
		} else {
			//If we have this control already, then mark it as pristine.
			this.markControlsAsPristine(formGroup, validationCollection[id], id);
		}

		return validationCollection[id];
	}

	/**
	 * Adds a control to a formGroup with a unique name by concatenating the control name with an id.
	 * @param formGroup
	 * @param control
	 * @param {number} id
	 */
	public static addControlToFormGroup(formGroup, control, id: number) {
		for (const controlKey of Object.keys(control)) {
			formGroup.addControl(`${controlKey}${id}`, control[controlKey]);
		}
	}

	/**
	 * Marks every form control in a formGroup as pristine.
	 * @param formGroup
	 * @param control
	 * @param id
	 */
	public static markControlsAsPristine(formGroup, control, id) {
		for (const controlKey of Object.keys(control)) {
			formGroup.get(`${controlKey}${id}`).markAsPristine();
		}
	}

	/**
	 * Removes form controls from a collection and removes them from the formGroup.
	 * @param collection
	 * @param ids
	 * @param {FormGroup} formGroup
	 * @param {string} controlNames
	 */
	public static removeUnusedFormControls(collection, ids, formGroup: UntypedFormGroup, controlNames: string[]) {
		for (const id of ids) {
			delete collection[id];
			for (const controlName of controlNames) {
				formGroup.get(`${controlName}${id}`).disable();
			}
		}
	}
}
