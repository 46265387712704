import { TEXT } from '../../../../helpers/text.constants';
import { SentenceCasePipe } from '../../../../pipes/sentence-case-pipe';

const sentenceCasePipe: SentenceCasePipe = new SentenceCasePipe();

export const CompanyListText = {
	cancel: TEXT.cancel,
	city: TEXT.city,
	clear: TEXT.clear,
	clearFields: TEXT.clearFields,
	client: TEXT.client,
	clients: TEXT.clients,
	contact: TEXT.contact,
	email: TEXT.email,
	fax: TEXT.fax,
	noItems: TEXT.noItems,
	phone: TEXT.phone,
	name: TEXT.name,
	newClient: TEXT.createNewClient,
	newCompany: TEXT.createNewCompany,
	search: TEXT.search,
	searchClients: TEXT.searchClients,
	state: TEXT.state,
	typeHere: sentenceCasePipe.transform(TEXT.typeHere),
	companyTitle: sentenceCasePipe.transform(TEXT.companyResult),
};
