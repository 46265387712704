import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faFileAlt, faPen } from '@fortawesome/free-solid-svg-icons';

import { Constants } from '../../helpers';
import { ButtonIcons } from './common/icon-button.constants';
import { IconButtonConfig } from './common/icon-button.interfaces';

@Component({
	selector: 'paint-icon-button',
	templateUrl: './icon-button.component.html',
	styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
	@Input()
	public iconConfig: IconButtonConfig;
	@Input()
	public isDisabled: boolean = false;

	@Output()
	public buttonClicked: EventEmitter<void> = new EventEmitter();

	public icon = ButtonIcons;
	public faPen = faPen;
	public faFileAlt = faFileAlt;
	public svgIconPaths = Constants.SVG_ICON_PATHS;

	public onClick() {
		this.buttonClicked.emit();
	}
}
