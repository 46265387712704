import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PaginationControlsDirective } from 'ngx-pagination';

interface PaginationConfig {
	currentPage: number;
	id: string;
	itemsPerPage: number;
	totalItems: number;
}

interface PaginationValues {
	id?: string;
	initPage?: number;
	itemsPerPage: number;
	totalItems: number;
	totalPages: number;
}

@Component({
	selector: 'app-generic-pagination',
	templateUrl: './generic-pagination.component.html',
	styleUrls: ['./generic-pagination.component.scss'],
})
export class GenericPaginationComponent implements OnInit {
	@ViewChild(PaginationControlsDirective) public paginationControls: PaginationControlsDirective;

	@Input()
	public set paginationValues(paginationValues: PaginationValues) {
		this.config = {
			currentPage: paginationValues.initPage || 1,
			id: paginationValues.id || 'custom',
			itemsPerPage: paginationValues.itemsPerPage,
			totalItems: paginationValues.totalItems,
		};

		this.totalPages = paginationValues.totalPages;
		this.currentPage = paginationValues.initPage || 1;
		this.isFirstPage = this.currentPage === 1;
		this.isLastPage = this.currentPage === this.totalPages;
		this.buildPaginationHeader();
	}

	@Output() public newPageRequested: EventEmitter<number> = new EventEmitter();

	public config: PaginationConfig = {
		currentPage: 0,
		id: 'custom',
		itemsPerPage: 0,
		totalItems: 0,
	};

	public autoHide: boolean = false;
	public chevronLeft: IconDefinition = faChevronLeft;
	public chevronRight: IconDefinition = faChevronRight;
	public currentPage: number = 1;
	public directionLinks: boolean = true;
	public isFirstPage: boolean = true;
	public isLastPage: boolean = false;
	public maxSize: number = 7;
	public paginationHeaderText: string = '';
	public responsive: boolean = true;
	public totalPages: number = 0;

	public ngOnInit(): void {
		this.buildPaginationHeader();
	}

	public getNewPage(newPageNo: number): void {
		this.newPageRequested.emit(newPageNo);
	}

	public onPageChange(): void {
		this.getNewPage(this.config.currentPage);
		this.buildPaginationHeader();
	}

	public setCurrentPage(page: number): void {
		this.paginationControls.setCurrent(page);
		this.updateControls();
	}

	public setNextPage(): void {
		this.paginationControls.next();
		this.updateControls();
	}

	public setPreviousPage(): void {
		this.paginationControls.previous();
		this.updateControls();
	}

	/**
	 * Build pagination text
	 * We want to display the total number of items up to and including the current page and the total number of items
	 */
	private buildPaginationHeader(): void {
		// Calculate the total amount of displayed items for the pagination values
		const totalItemsForCurrentPaginationValue = this.config.currentPage * this.config.itemsPerPage;
		// Take the total item number if the calculated pagination total exceeds the total items
		const totalItemsForCurrentPage = totalItemsForCurrentPaginationValue > this.config.totalItems ? this.config.totalItems : totalItemsForCurrentPaginationValue;
		this.paginationHeaderText = `Showing ${totalItemsForCurrentPage} of ${this.config.totalItems} Results`;
	}

	private updateControls(): void {
		setTimeout(() => {
			this.currentPage = this.paginationControls.getCurrent();
			this.isFirstPage = this.paginationControls.isFirstPage();
			this.isLastPage = this.paginationControls.isLastPage();
			this.onPageChange();
		}, 0);
	}
}
