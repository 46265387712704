// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-tool-modal {
  height: 400px;
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/estimator-user-tool-library/estimator-user-tool-library.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,YAAA;AACD","sourcesContent":[".user-tool-modal {\n\theight: 400px;\n\twidth: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
