import { EmbeddedBOQCoatProduct } from './embedded-boq-coat-product';
import { EmbeddedRate } from './embedded-rate';
import { Product } from './product';
import { ReferenceCode } from './reference-code';

export class EmbeddedCoatingSystem {
	public title: string;
	public coatProducts?: EmbeddedBOQCoatProduct[] = [];
	public chargeOutRates?: EmbeddedRate[] = [];
	public currentChargeOutState?: string;
	public product?: Product;
	public substrate?: ReferenceCode;
}
