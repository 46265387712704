import { Component, Input } from '@angular/core';

import { TotalsText } from './common/totals.constants';
import { Totals } from './common/totals.interfaces';

@Component({
	selector: 'app-totals',
	templateUrl: './totals.component.html',
	styleUrls: ['../../charges.component.scss', './totals.component.scss'],
})
export class TotalsComponent {
	@Input()
	public isGrandTotals: boolean = false;

	@Input()
	public totalValues: Totals;

	@Input()
	public rowTitle: string = TotalsText.totals;

	public totalsText = TotalsText;
}
